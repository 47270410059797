
import Vue from 'vue';
import agent from "@/api/agent";
import DropzoneOptions from "@/components/DropzoneOptionsExcel";
import FeedbackResults from "@/components/dialog/FeedbackResults.vue";
import LoadExcelProducts from "@/components/LoadFiles.vue";
import CancelDialogButton from "@/components/dialog/button/CancelDialogButton.vue";
import ConfirmDialogButton from "@/components/dialog/button/ConfirmDialogButton.vue";
import {OperationType} from "@/constants/enums";

export default Vue.extend({
  props: {
    value: {
      type: Boolean,
      default: false,
      required: true,
    },
    priceTableId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      valid: false,
      loading: false,
      step: 1,
      fileUploaded: false,
      excelTemplateUrl: null as URL | null,
      dropzoneOptions: DropzoneOptions,
      upsertPriceTableItems: "api/priceTables/bulk/excel-url",
      colors: {
        background: '#4E009B',
        text:  "#ffffff"
      },
      form: {
        positivePriceVarianceAllowed: null as number | null,
        negativePriceVarianceAllowed: null as number | null,
      },
      request: {} as any,
      fileName: "",
      totalProducts: 0,
      failedProducts: 0,
      errorReportUrl: "" as string | undefined,
      feedbackVisible: false,
      feedbackStatus: "",
      cleanOperation: {
        shouldCleanFile: false
      },
    }
  },
  components: {
    ConfirmDialogButton,
    CancelDialogButton,
    LoadExcelProducts,
    FeedbackResults,
  },
  mounted() {
    this.fetchFileTemplate();
  },
  computed: {
    operationType() {
      return OperationType;
    },
    dialog: {
      get(): Boolean {
        return this.value;
      },
      set(value: Boolean): void {
        this.step = 1;
        this.$emit('input', value);
      },
    },
    templateFileName(): string {
      return 'template-tabela-precos-TouchPay.xlsx';
    },
    firstStepStyle() {
      if (!this.$vuetify.breakpoint.xs) {
        return '';
      }
      return 'ma-1 pa-0 pl-1 pb-0';
    },
    formFieldsStyle() {
      return `d-flex ${this.$vuetify.breakpoint.xs ? 'flex-column' : 'justify-space-around'} align-center pa-1 pt-3 pl-2 pr-2`;
    },
    actionButtonsStyle() {
      return `d-flex ${this.$vuetify.breakpoint.xs ? 'flex-column' : ''} align-center pa-3 pt-4 pb-3`;
    },
    cardTitleStyle() {
      return `${(this.$vuetify.breakpoint.xs ? 'text-center' : 'text-left')} font-weight-medium`;
    }
  },
  methods:{
    onFileUploaded(success: Boolean) {
      if(success) {
        this.fileUploaded = true;
        this.fileName = (this as any).$refs.file.filename;
      }
    },
    onFileRemove(uploadSuccess: Boolean) {
      if(!uploadSuccess) {
        this.fileUploaded = false;
      }
    },
    async startPriceTableItemsUpsertOperation() {
      if (!(this as any).$refs.form.validate()) {
        return;
      }
      this.loading = true;
      let shouldCloseDialog = false;
      let positivePriceVarianceAllowed = this.form.positivePriceVarianceAllowed;
      if (isNaN(Number(positivePriceVarianceAllowed))) {
        positivePriceVarianceAllowed = null;
      }
      let negativePriceVarianceAllowed = this.form.negativePriceVarianceAllowed;
      if (isNaN(Number(negativePriceVarianceAllowed))) {
        negativePriceVarianceAllowed = null;
      }
      try {
        const priceTableId = this.priceTableId;
        const body = {
          fileName: this.fileName,
          positivePriceVarianceAllowed: positivePriceVarianceAllowed,
          negativePriceVarianceAllowed: negativePriceVarianceAllowed
        };
        const upsertPriceTableItemsOperationResult = await agent.PriceTables
          .upsertPriceTableItems(priceTableId, body);
        this.totalProducts = upsertPriceTableItemsOperationResult.numberOfPriceTableItems;
        this.failedProducts = upsertPriceTableItemsOperationResult.numberOfPriceTableItems - upsertPriceTableItemsOperationResult.numberOfPriceTableItemsImported;
        this.errorReportUrl = upsertPriceTableItemsOperationResult.feedbackFileUri?.toString();
        this.feedbackStatus = this.failedProducts === 0 ? "success" : "error";
        this.feedbackVisible = true;
        shouldCloseDialog = true;
      } catch (error) {
        if (error) {
          (this.$refs.feedback as any).handleError("Erro ao importar Excel", error);
        }
      } finally {
        this.loading = false;
      }
      this.resetData(shouldCloseDialog);
    },
    resetData(shouldCloseDialog: boolean, shouldCloseFeedback = false) {
      this.fileUploaded = false;
      this.form.negativePriceVarianceAllowed = null;
      this.form.positivePriceVarianceAllowed = null;
      this.cleanOperation = {
        shouldCleanFile: true,
      };
      (this.$refs as any).form.resetValidation();
      if (shouldCloseDialog) {
        this.dialog = false;
      }
      if (shouldCloseFeedback) {
        this.feedbackVisible = false;
      }
    },
    onClosed() {
      this.$emit('upsertOperationConcluded');
      this.resetData(true, true);
    },
    async fetchFileTemplate() {
      let excelTemplateUrl = null as URL | null;
      try {
          excelTemplateUrl = await agent.PriceTables.fetchPriceTableExcelTemplateFile();
      } catch (error) {
        if (error) {
          (this.$refs.feedback as any)?.handleError("Ocorreu um erro ao buscar o template Excel.", error);
        }
      }
      this.excelTemplateUrl = excelTemplateUrl;
    }
  }
})
