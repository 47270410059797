import {defineStore} from "pinia";
import agent from "@/api/agent";
import {ref} from "vue";
import {useAlertsStore} from "@/stores/alertsStore";
import {DeliveryAddress, environmentInfoInitialState, EnvironmentInformation,} from "@/types/AcquisitionOrders";

export const useAcquisitionOrdersStore = defineStore('acquisitionOrders', () => {
  const alertsStore = useAlertsStore()

  const environmentInfo = ref<EnvironmentInformation>(environmentInfoInitialState.value);
  const deliveryAddresses = ref<DeliveryAddress[]>([]);

  const loadingEnvironmentInfo = ref(false);

  async function getEnvironmentInfo() {
    loadingEnvironmentInfo.value = true;
    await agent.AcquisitionOrders.getEnvironmentInfo()
      .then((response) => {
        environmentInfo.value = response;
        setDeliveryAddresses(environmentInfo.value);
      })
      .catch((error) => alertsStore.showErrorSnackbar(error.response.data))
      .finally(() => loadingEnvironmentInfo.value = false);
  }

  async function setDeliveryAddresses(environmentInfo: EnvironmentInformation) {
    const deliveryAddress = {
      address: environmentInfo.customerInfo.address,
      addressNumber: environmentInfo.customerInfo.addressNumber,
      addressComplement: environmentInfo.customerInfo.addressComplement,
      cep: environmentInfo.customerInfo.cep,
      city: environmentInfo.customerInfo.city,
      brazilState: environmentInfo.customerInfo.brazilState,
    } as DeliveryAddress;

    const emptyDeliveryAddress = {
      address: null,
      addressNumber: null,
      addressComplement: null,
      cep: null,
      city: null,
      brazilState: null,
    } as DeliveryAddress;

    deliveryAddresses.value = [deliveryAddress, emptyDeliveryAddress];
  }

  function hasDeliveryAddress(): boolean {
    return deliveryAddresses.value.length > 0;
  }

  return {
    deliveryAddresses,
    environmentInfo,
    getEnvironmentInfo,
    hasDeliveryAddress,
    loadingEnvironmentInfo
  }
})
