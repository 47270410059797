import { Country, DateTimePrecision } from '@/constants/enums';
import moment from 'moment';

/**Format a given value (any) to "-", if it´s null */
export function formatPlaceHolder(value: any): string {
    return value ? value : '-'
}

/**Format a given string to CNPJ mask. */
export function formatCnpj(cnpj: string): string {
    return (cnpj) ? cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5') : "-"
}

/**Format a given string to CPF mask. */
export function formatCpf(cpf: string): string {
    return (cpf) ? cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4') : "-"
}

/**Format a given document to corresponding document. */
export function formatDocument(document: string | null): string | null {
  if (!document) {
    return null;
  }
  if (document.length === 11) {
    return formatCpf(document)
  } 
  else if (document.length === 14) {
    return formatCnpj(document)
  }
  return document
}

export function formatPhoneNumber(phone: string): string {
  // Remove all non-digit characters (e.g. +, spaces, dashes, etc.)
  const digits = phone.replace(/\D/g, '');

  // If the number includes the country code '55'
  if (digits.length === 12 && digits.startsWith('55')) {
    // Format: +55 (XX) XXXX-XXXX (10-digit local number)
    const country = digits.slice(0, 2);
    const area = digits.slice(2, 4);
    const firstPart = digits.slice(4, 8);
    const secondPart = digits.slice(8);
    return `+${country} (${area}) ${firstPart}-${secondPart}`;
  } else if (digits.length === 13 && digits.startsWith('55')) {
    // Format: +55 (XX) XXXXX-XXXX (11-digit local number)
    const country = digits.slice(0, 2);
    const area = digits.slice(2, 4);
    const firstPart = digits.slice(4, 9);
    const secondPart = digits.slice(9);
    return `+${country} (${area}) ${firstPart}-${secondPart}`;
  } else if (digits.length === 10) {
    // Format local 10-digit numbers: (XX) XXXX-XXXX
    const area = digits.slice(0, 2);
    const firstPart = digits.slice(2, 6);
    const secondPart = digits.slice(6);
    return `(${area}) ${firstPart}-${secondPart}`;
  } else if (digits.length === 11) {
    // Format local 11-digit numbers: (XX) XXXXX-XXXX
    const area = digits.slice(0, 2);
    const firstPart = digits.slice(2, 7);
    const secondPart = digits.slice(7);
    return `(${area}) ${firstPart}-${secondPart}`;
  }

  return phone;
}

/**Format a given date in string format to date format. */
export function formatDateStringToDate(dateStr: string, emptyResponse: boolean = false): Date | null {
    return moment(dateStr, 'DD/MM/YYYY', true).isValid() ? moment(dateStr, 'DD/MM/YYYY').toDate() : emptyResponse ? null : null
}

/**Format a given datetime to date only string. */
export function formatDateTimeToDate(date: Date | string, emptyResponse: boolean = false): string {
    return moment(date).isValid() ? moment(date).format('DD/MM/YYYY') : emptyResponse ? "" : "-"
}

/** Attempts to parse a user-typed string into a Date. */
export function parseGenericStringToDate(userInput: string): Date | null {
  const formats = ['DD/MM/YYYY', 'DDMMYYYY', 'DD-MM-YYYY'];
  const m = moment(userInput, formats, true);

  return m.isValid() ? m.toDate() : null;
}

/**Format a given datetime with minutes precision. */
export function formatDateTimeMinutes(date: Date | null | undefined): string {
    return moment(date).isValid() ? moment(date).format('DD/MM/YYYY HH:mm') : "-"
}

/**Format a given datetime with seconds precision. */
export function formatDateTimeSeconds(date: Date, emptyResponse: boolean = false): string {
    return moment(date).isValid() ? moment(date).format('DD/MM/YYYY HH:mm:ss') : emptyResponse ? "" : "-" 
}

/** Format a given date without timezone adjustments. */
export function formatDateWithoutTimezoneAdjustment(date: Date | null | undefined): string {
    if (!date) {
      return "";
    }
    const dateString = date.toString();
    const year = dateString.slice(0, 4);
    const month = dateString.slice(5, 7);
    const day = dateString.slice(8, 10);
    const hour = dateString.slice(11, 13);
    const minute = dateString.slice(14, 16);
    return `${day}/${month}/${year} - ${hour}:${minute}`;
  }

/**Format a given datetime to its time in the day */
export function formatDateTimeToTimeInDay(date: Date): string {
    return moment(date).isValid() ? moment(date).format('HH:mm:ss') : ''
}

/**Format a given datetime to YMD format (It´s used on UI components) */
export function formatDateTimeToYmdDate(date: Date): string {
    return moment(date).format("YYYY-MM-DD")
}

/**Format a given number to Brazilian REAL */
export function formatCurrencyReal(currency: number | null): string {
    return currency != null ? 'R$ ' + currency.toLocaleString('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2}) : '-' 
}

/**Format a given number to US Dollar */
export function formatCurrencyUsDollar(value: number | null): string {
    return value != null ? '$' + value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '-';
}

/**Format a given number to Percentage */
export function formatPercentage(number: number): string {
    return number !== null ? (number * 100).toLocaleString('pt-BR', { maximumFractionDigits: 2 }) : '-' 
}
/**Format a given last 4 digit Card Holder Number */
export function formatCardHolder(cardHolder: string | null): string {
    return cardHolder ? `*-*-*-${cardHolder}` : "-"
}
export function truncateEmail(email: string): string {
    const atIndex = email.indexOf("@");
    if (atIndex !== -1) {
        const userName = email.slice(0, atIndex + 1);
        return userName + "...";
    } else {
        return email; 
    }
  }

/**Format a given CNPJ or CPF to digits only. */
export function formatCnpjOrCpfToNumbersOnly(value: string): string {
    return value.replace(/[^\d]/g, '');
}

/** Return an employer identification mask according to a given country  */
export function employerIdentificationMask(country: Country, defaultMask: string): string {
  switch (country)
  {
    case Country.BR:
      return "##.###.###/####-##";
    case Country.US:
      return "##-#######";
    default:
      return defaultMask;
  }
}

/** Return an employer identification placeholder according to a given country  */
export function employerIdentificationPlaceholder(country: Country, defaultPlaceHolder: string = ""): string {
  switch (country)
  {
    case Country.BR:
      return "00.000.000/0000-00";
    case Country.US:
      return "00-0000000";
    default:
      return defaultPlaceHolder;
  }
}

/**
 * Displays a date formatted based on the tenant's country and precision level.
 * If the date is invalid, it will return a default or empty response.
 *
 * @param date - The `Date` object to format.
 * @param country - The tenant's country code to determine the date format.
 * @param emptyResponse - (Optional) If true, returns an empty string for invalid dates. Defaults to false (returns `'-'`).
 * @param precision - (Optional) The precision level for formatting the date. Defaults to `DateTimePrecision.Minutes`.
 * @returns A formatted date string or a default value if the date is invalid.
 */
export function displayDateFormatted(
  date: Date | string,
  country: Country,
  emptyResponse: boolean = false,
  precision: DateTimePrecision = DateTimePrecision.Minutes
): string {
  if (!moment(date).isValid()) {
    return emptyResponse ? "" : "-";
  }

  let baseFormat: string;
  switch(country)
  {
    case Country.BR:
    case Country.CO: 
      baseFormat = 'DD/MM/YYYY';
      break;
    case Country.US:
      baseFormat = 'MM/DD/YYYY';
      break;
    default:
      return emptyResponse ? "" : "-";
  }

  switch (precision) {
    case DateTimePrecision.DateOnly:
      return moment(date).format(`${baseFormat}`);
    case DateTimePrecision.Hours:
      return moment(date).format(`${baseFormat} HH`);
    case DateTimePrecision.Minutes:
      return moment(date).format(`${baseFormat} HH:mm`);
    case DateTimePrecision.Seconds:
      return moment(date).format(`${baseFormat} HH:mm:ss`);
    default:
      return emptyResponse ? "" : "-";
  }
}