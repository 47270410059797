export const enumConstants = {
  billType: [
    {name: 'Mensalidade', value: 'ServicesMonthlyFee'},
    {name: 'Taxa de Ativação', value: 'ActivationFee'},
    {name: 'Venda de Equipamento', value: 'EquipmentSale'},
  ]
}

export const enumMappers = {
  billType: (enumValue: string): string => {
    const foundItem = enumConstants.billType.find(item => item.value === enumValue);
    return foundItem ? foundItem.name : '-';
  }
}

export enum Country {
  BR = "BR",
  US = "US",
  CO = "CO"
}

/**
 * Represents the precision level used to convert a date into a string.
 * The higher the value, the more precise the formatting will be.
 *
 * Precision levels:
 * - `DateOnly`: Only the date (e.g., "YYYY-MM-DD").
 * - `Hours`: Date and hours (e.g., "YYYY-MM-DD HH").
 * - `Minutes`: Date, hours, and minutes (e.g., "YYYY-MM-DD HH:mm").
 * - `Seconds`: Date, hours, minutes, and seconds (e.g., "YYYY-MM-DD HH:mm:ss").
 */
export enum DateTimePrecision {
  DateOnly = 0,
  Hours,
  Minutes,
  Seconds,
}

export enum OperationType {
  Products = "Products",
  PriceTableItems = "PriceTableItems",
}
