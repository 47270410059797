export default {
	transactions: {
		title: 'Transacciones',
		status: {
			paid: 'Pagado',
			cancelled: 'Cancelado',
			paymentError: 'Error de Pago',
		},
    installmentType:{
      title: 'Quién Paga la Tarifa: ',
      store: 'Tienda',
      customer: 'Cliente'
    },
    acquirer: 'Adquirente: ',
    acquirerCode: 'Código de Adquirente: ',
    destinationAccount: 'Cuenta Destino: ',
		internalCode: 'Código Interno: ',
		discountCoupon: 'Cupón de Descuento: ',
		errorMessage: 'Mensaje de Error: ',
    installmentCount:'Número de Cuotas: ',
    installmentAmount: 'Valor de la Cuota: ',
    cashlessCard: 'Tarjeta: ',
    brandTransactions: 'Bandeira: ',
		report: {
			dynamic: {
				title: 'Resumen Dinámico de Ventas',
				help: {
					description: `
						<p><strong>En esta página, puedes generar informes de ventas personalizados según tus necesidades. Utiliza las siguientes funciones para configurar tus informes:</strong></p>
						<p><strong>Filtros y Acciones:</strong> Selecciona el período deseado utilizando las fechas de inicio y fin. Además, puedes optar por intervalos fijos o el mismo día.</p>
						<p><strong>Ubicación:</strong> Utiliza este filtro para elegir las ubicaciones de interés. Esto puede incluir diferentes Puntos de Venta (POS) u otras ubicaciones relevantes.</p>
						<p><strong>Producto:</strong> Selecciona productos o categorías específicas para detallar el informe de acuerdo con tus necesidades de análisis.</p>
						<p><strong>Agrupación de Datos:</strong> Elige cómo deseas organizar los datos en el informe. Las opciones de agrupación incluyen Productos, Categoría, Clientes, Puntos de Venta (POS), Estados, Métodos de Pago, Marcas de Tarjetas, Día, Mes, Día de la Semana o Hora.</p>
						<p><strong>Guardar Filtros:</strong> Guarda tus preferencias de filtros para reutilizarlas en informes futuros, agilizando el proceso.</p>
						<p><strong>Exportación de Informes:</strong> Después de configurar los filtros según tus necesidades, puedes exportar el informe en formato Excel haciendo clic en el botón "Excel".</p>
						<p><strong>Recuerda hacer clic en "Actualizar" después de ajustar los filtros para ver la información más reciente.</strong></p>
					`,
				}
			}
		}
	}
}
