export default {
    changeImage: 'Alterar imagem',
    removeImage: 'Remover imagem',
    deleteImageConfirmation: 'Tem certeza que deseja remover a imagem?',
    noEditableImage: 'Não',
    removeEditableImage: 'Remover',
    imageRemoved: 'Imagem removida',
    unableToRemoveImage: 'Não foi possível remover a imagem',
    imageSavedSuccessfully: 'Imagem salva com sucesso!',
    unableToValidateImage: 'Não foi possível validar a imagem',
    confirm: 'Confirmar',
    cancel: 'Cancelar',
    uploadImageError: 'Não foi possível fazer o upload da imagem',
    authorizeUploadError: 'Não foi possível autorizar o upload da imagem: ',
    unknownError: 'Erro desconhecido',
    clickOrDragFileHere: 'Arraste o arquivo ou clique aqui para fazer upload'
};
  