import { render, staticRenderFns } from "./EnvironmentCatalog.vue?vue&type=template&id=0c66c091&scoped=true"
import script from "./EnvironmentCatalog.vue?vue&type=script&lang=ts&setup=true"
export * from "./EnvironmentCatalog.vue?vue&type=script&lang=ts&setup=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c66c091",
  null
  
)

export default component.exports