export default {
    systemPaymentPointOfSale: "Payment system",
    systemCodePointOfSale: "System code",
    enabledInvoicePointOfSale: "Enabled invoice",
    disabledInvoicePointOfSale: "Invoice not enabled",
    configurePointOfSale: "Configure",
    appEnabledPointOfSale: "App enabled",
    appDisabledPointOfSale: "App not enabled",
    displayPointOfSale: "Display",
    registerPointOfSale: "Register",
    picPayPointOfSale: "PicPay",
    picPayTokenPointOfSale: "PicPay Token",
    registeredLocksPointOfSale: "Registered locks",
    noLocksPointOfSale: "No registered locks",
    changePointOfSale: "Change",
    addPointOfSale: "Add",
    thenPointOfSale: "logo",
    clientPointOfSale: "Client",
    locationPointOfSale: "Location",
    internalLocationPointOfSale: "Internal location",
    statePointOfSale: "State",
    residentsQuantityPointOfSale: "Number of residents",
    employeesQuantityPointOfSale: "Number of employees",
    studentsQuantityPointOfSale: "Number of students",
    frequentPeopleQuantityPointOfSale: "Number of frequent people",
    stockPointOfSale: "Stock",
    distributionCenterPointOfSale: "Distribution center",
    sharedStockPointOfSale: "Shared stock",
    routePointOfSale: "Supply route",
    posCodePointOfSale: "POS code",
    manufacturerPointOfSale: "Manufacturer",
    modelPointOfSale: "Model",
    updatePointOfSale: "Update",
    unableToSavePosPointOfSale: "Unable to save point of sale",
    okPointOfSale: "Ok",
    identificationCodePointOfSale: "System identifier code.",
    systemNotFoundPointOfSale: "System not found",
    tokensNotFoundPointOfSale: "Tokens not found",
    selectClientPointOfSale: "Select a client or add a new one.",
    selectLocationPointOfSale: "Select a customer before choosing a location",
    totalNumberOfResidentsPointOfSale: "Select a location before setting the total number of residents",
    localTypePointOfSale: "Location type",
    nameDistributionCenterPointOfSale: "Name of the distribution center",
    distributionCenterNotRegistered: "There are no distribution centers registered",
    distributionCenterConfiguration : `Here we configure the distribution center that is associated with this point of sale.
    When a replenishment is made at this POS, the products will be subtracted from it.
    In addition, when generating Pick Lists for this POS, it will be
    the distribution center considered when checking the availability of each product.
    Sales made at this POS will also have the average cost corresponding to the average cost of the
    product of the distribution center at the time of purchase`,
    cannotBeChangedPointOfSale: `As the POS has shared stock with the point of sale {masterPointOfSaleName},
    its central stock cannot be changed`,
    accessToFunctionality: "To access this functionality, you must have the stock module contracted.",
    noPointsOfSalePointOfSale: "No points of sale",
    withMoreTerminalPointOfSale: "For stores with more than one terminal",
    microMarketsFunction: `This function is only for micro-markets that have
    more than one payment terminal in the same store`,
    terminalInTheSameStore: `If there is more than one terminal in the same store, a point of sale is required for each terminal.
    a point of sale is needed for each terminal. Although they are separate POSs, because it is a single store, there must be a single planogram and a single stock.
    In this way, when any of the store's POS carry out a sale, the same stock is subtracted`,
    registrationAtOnlyOnePdv: `The planogram must be registered in only one POS, which
    we call it <strong>main POS</strong>. The consultation of
    inventory must also be carried out at the main POS. The others
    Store POS are <strong>secondary POS</strong>.
    All secondary POS must have the primary POS in the
    <strong>Shared stock</strong> field. For the POS
    main, this field is empty.`,
    supplyRouteToBetterManagementOfThePos: ` For a better management of your supplies, you
    can create a supply route, making it easier to organize and distribute products
    from the central stock.`,
    selectARouteToLinkToThisPos: `Select a registered supply route to link it to this 
    Point Of Sale. To create a supply route, go to Records > Supply routes.`,
    touchPayReplenishmentPointOfSale: "TouchPay Replenishment",
    machinePointOfSale: "Machine",
    errorFindingTokenPointOfSale: "Could not find a token for the POS.",
    taxRegion: 'Tax region',
    dclubeLabel: 'DClube',
    dclubeHint: 'DClube Access Key',
    dclubeHelperHeader: 'What is DClube?',
    dclubeHelperDescription: `<strong>DClube</strong> is a <strong>discount and points</strong> platform that
    connects <strong>brands and consumers</strong>, offering exclusive advantages at the time of purchase.
    <strong>DClube</strong> helps to retain your customers and encourages recurring purchases in your minimarket,
    making it even more attractive.`,
    dclubeHelperDescriptionHowItWorks: `
    <p><h3>How does it work?</h3></p>
    <strong>DClube offers instant discounts</strong> at the time of purchase for partner products.
    To take advantage of the benefits, the customer needs:
    <p>- To have a registration in the DClube app.</p>
    <p>- To enter the CPF at the beginning of the purchase at points of sale that have DClube activated.</p>
    In this way, the discount is applied automatically, encouraging more purchases and increasing the flow in your business.`,
    dclubeHelperDescriptionHowToActivate: `
    <p><h3>How to join the program?</h3></p>
    Want to offer this advantage in your minimarket? 🚀 <strong>Contact via WhatsApp: +55 (19) 99853-3154</strong> and learn how
    to activate <strong>DClube</strong> at your point of sale!`
}
