
import Vue from 'vue';
import { BuildingManagerReports } from "@/api/agent";
import moment from "moment";
import { formatDateTimeSeconds, formatDateTimeToDate } from "@/utility/TextFormatting";

export default Vue.extend({
  mounted() {
    this.getSalesReport()
  },
  data() {
  const defaultForm = {
    minDate: moment().subtract(30, 'days').format('YYYY-MM-DD'),
    maxDate: moment().format('YYYY-MM-DD')
  }
  return {
    valid: false,
    loading: false,
    form: { ...defaultForm },
    sales: [] as {}[],
    headers: [
      { text: this.$t('bManagerSalesPointOfSaleId'), value: 'pointOfSaleId', align: 'left', sortable: true },
      { text: this.$t('bManagerSalesBoxId'), value: 'boxId', align: 'left', sortable: true },
      { text: this.$t('bManagerSalesLocalCustomerName'), value: 'localCustomerName', align: 'left', sortable: false },
      { text: this.$t('bManagerSalesLocalName'), value: 'localName', align: 'left', sortable: false },
      { text: this.$t('bManagerSalesSpecificLocation'), value: 'specificLocation', align: 'left', sortable: false },
      { text: this.$t('bManagerSalesTotal'), value: 'total', align: 'left', sortable: false },
    ],
    total: 0 as number,
    minDateMenu: false,
    maxDateMenu: false,
    today: moment().format('YYYY-MM-DD'),
    sixtyDaysAgo: moment().subtract(60, 'days').format('YYYY-MM-DD'),
    dialog: {
      show: false,
      posId: null as null | number,
      loading: false,
      items: [] as {}[],
      headers: [
        { text: this.$t('categorySalesHeaderCategoryId'), value: 'categoryId', align: 'left', sortable: false },
        { text: this.$t('categorySalesHeaderCategory'), value: 'category', align: 'left', sortable: false },
        { text: this.$t('categorySalesHeaderQuantity'), value: 'quantity', align: 'left', sortable: false },
        { text: this.$t('categorySalesHeaderTotals'), value: 'totals', align: 'left', sortable: false }
      ]
    },
    rules: {
      required: (value: any) => (value !== null && value !== undefined && value !== '') || this.$t('requiredRules'),

    }
    }
  },
  computed: {
    formattedMinDate() {
      return (this as any).formatDateTimeToDate((this as any).form.minDate);
    },
    formattedMaxDate() {
      return (this as any).formatDateTimeToDate((this as any).form.maxDate);
    },
    isUpdateDisabled() {
      return !(this as any).form.minDate || !(this as any).form.maxDate;
    }
  },
  methods: {
  async getSalesReport() {
    if (!this.form.minDate || !this.form.maxDate) {
      (this.$refs.form as any).validate();
      return;
    }
    this.loading = true;
    try {
      const response = await BuildingManagerReports.getBuildingManagerSalesReport(this.form);
      this.sales = response.posSales;
      this.total = response.totals;
    } catch {
      this.handleError(this.$t('buildingManagerHandleErrorMessage') as string);
    } finally {
      this.loading = false;
    }
  },
  async getSalesExcelReport() {
    if (!this.form.minDate || !this.form.maxDate) {
      (this.$refs.form as any).validate();
      return;
    }
    this.loading = true;
    try {
      const response = await BuildingManagerReports.getBuildingManagerExcelReport(this.form);
      this.handleSuccess(response);
    } catch {
      this.handleError(this.$t('buildingManagerHandleErrorMessage') as string);
    } finally {
      this.loading = false;
    }
  },
  getAllowedMinDates(minDate: Date): boolean {
    if (this.form.maxDate !== null) {
      return this.isDateRangeAllowed(
        new Date(minDate),
        new Date(this.form.maxDate)
      );
    }
    return true;
  },
  getAllowedMaxDates(maxDate: Date): boolean {
    if (this.form.minDate !== null) {
      const minDate = new Date(this.form.minDate);
      return this.isDateRangeAllowed(
        minDate,
        new Date(maxDate)
      );
    }
    return true;
  },
  formatDateTimeSeconds,
  formatDateTimeToDate,
  handleError(message: string) {
    (this.$refs.feedback as any).handleError(message);
  },
  handleSuccess(filename: string) {
    (this.$refs.feedback as any).handleSuccess(this.$t('bManagerExcelDownloadMessage', { filename }));
  },
  openPosCategoryDialog(item: any) {
    this.dialog.show = true;
    this.dialog.posId = (item as any).pointOfSaleId;
    this.loadCategorySales(this.dialog.posId as number);
  },
  isDateRangeAllowed(startDate: Date, endDate: Date): boolean {
    const diffDays = (endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24);
    return diffDays <= 60;
  },
  async loadCategorySales(posId: number) {
    this.dialog.loading = true;
    try {
      const response = await BuildingManagerReports.getCategorySalesByPos(posId, this.form);
      this.dialog.items = response;
    } catch {
      this.handleError(this.$t('buildingManagerGetCategoriesSalesErrorMessage') as string);
    } finally {
      this.dialog.loading = false;
      }
    }
  }
})
