const inventoryTransactionColorMap = {
  "Correction": ["amber lighten-1", false],
  "Sale": ["green darken-1", true],
  "Purchase": ["teal darken-1", true],
  "Reversed": ["orange lighten-1", false],
  "AutomaticCorrection": ["yellow lighten-1", false],
  "Supply": ["blue darken-1", true],
  "Expired": ["red darken-1", true],
  "Damaged": ["pink darken-1", true],
  "Return": ["deep-purple darken-1", true],
  "RemovedFromInventory": ["yellow lighten-3", false],
  "ExpirationDateChanged": ["red lighten-4", false],
  "ExpirationDateAdded": ["red lighten-2", false],
  "ExpirationDateRemoved": ["red lighten-1", true],
  "Picking": ["light-blue lighten-1", false],
  "PickingReturn": ["purple lighten-3", false],
  "Default": ["grey lighten-1", false]
};

export function getColorInventoryTransactionStatus(status) {
  return inventoryTransactionColorMap[status] || inventoryTransactionColorMap["Default"];
}
