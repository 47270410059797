<template>
  <v-card class="mb-16">
    <v-card-title>
      <div class="title"><slot></slot></div>
      <v-spacer></v-spacer>
      <v-col cols="auto">
        <RoutesDropDown
          v-model="options.route"
          @input="getPickLists"
        />
      </v-col>
      <v-btn v-if="allowDelete && selected.length > 0" color="error" @click.native="onDeleteClicked">
        <v-icon left>delete</v-icon>{{ $t('removePickListsPickListTable') }}
      </v-btn>
      <NegativeItemsDialog
        v-if="statusFilter == 'Pending' && distributionCenterEnable"
      />
      <v-tooltip bottom open-delay="50" :disabled="selected.length > 0">
        {{ $t('selectAtLeastOnePickList') }}
        <template #activator="{ on }">
          <span v-on="on">
          <v-btn
            class="ml-2"
            :disabled="selected.length === 0"
            color="info"
            @click.native="getExcel"
          >
            <v-icon left>mdi-file-excel</v-icon>{{ $t('excelPickListTable') }}
          </v-btn>
          </span>
        </template>
      </v-tooltip>
      <v-tooltip v-if="erpSyncEnabled" bottom open-delay="50">
        {{ $t('synchronizationWithManagementSystem') }}
        <template #activator="{ on: tooltip }">
          <span v-on="tooltip">
            <v-btn
            color="success"
            @click.native="syncPickLists"
            class="ml-2"
            >
              <v-icon left>mdi-cached</v-icon>{{ $t('erpManagementSystem') }}
            </v-btn>
          </span>
        </template>
      </v-tooltip>
    </v-card-title>
    <v-divider></v-divider>
    <template v-if="infoMessages.length > 0 && infoMessages.some(i => i.visible)">
      <v-card-text>
        <v-alert
          v-for="message in infoMessages"
          :key="message.pointOfSaleId"
          :type="message.type"
          v-model="message.visible"
          dismissible
        >{{ message.text }}
        </v-alert>
      </v-card-text>
      <v-divider></v-divider>
    </template>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="pickLists"
      :loading="loading.table"
      :options.sync="options"
      :server-items-length="totalItems"
      :no-data-text="$t('noResultsPickListTable')"
      show-select
      :footer-props="{ itemsPerPageOptions: [10, 25, 50, 100] }"
    >

      <template #item="props">
        <tr>
          <td>
            <v-simple-checkbox
              :ripple="false"
              v-model="props.isSelected"
              @input="props.select(props.isSelected)"
            ></v-simple-checkbox>
          </td>
          <td class="clickable" @click="handleClick(props.item)">{{props.item.id}}</td>
          <td class="clickable" @click="handleClick(props.item)">{{props.item.description}}</td>
          <td class="clickable" @click="handleClick(props.item)"><v-icon>{{ props.item.isSecondary ? 'mdi-close' : 'mdi-check' }}</v-icon></td>
          <td class="clickable" @click="handleClick(props.item)">{{props.item.pointOfSale.customerName}}</td>
          <td class="clickable" @click="handleClick(props.item)">{{props.item.pointOfSale.localName}}</td>
          <td class="clickable" @click="handleClick(props.item)">{{props.item.pointOfSale.specificLocation}}</td>
          <td class="clickable" @click="handleClick(props.item)">{{props.item.pointOfSale.id}}</td>
          <td class="clickable" @click="handleClick(props.item)">{{props.item.pointOfSale.route}}</td>
          <td class="clickable" @click="handleClick(props.item)">
            {{props.item.pointOfSale.machineModelName}} / {{props.item.pointOfSale.machineManufacturerName}}
          </td>
          <td class="clickable" @click="handleClick(props.item)">
            {{formatDate(props.item.dateUpdated)}} {{(props.item.isEdited && allowEdit) ? $t('editPickListTable') : ''}}
          </td>
          <td v-if="statusFilter == 'Pending'" class="clickable" @click="handleClick(props.item)">
            {{props.item.startedBy ? props.item.startedBy : '-'}}
            <v-btn v-if="props.item.startedBy" icon @click.stop="openDialogConfirmation(props.item.id)">
              <v-icon>{{ props.item.startedBy ? 'mdi-close' : '' }}</v-icon>
            </v-btn>
          </td>

          <td v-if="hasOmieIntegration && (statusFilter == 'Pending' || statusFilter == 'Separated')">
            <v-tooltip left>
              <template #activator="{ on }">
                <v-btn
                  rounded
                  small
                  :color="getSyncColor(props.item.externalId)"
                  @click.native="omieSync(props.item)"
                  v-on="on"
                  :disabled="false"
                  :loading="props.item.sync"
                >
                  <v-icon left>mdi-cached</v-icon>{{ $t('erpManagementSystem') }}
                </v-btn>
              </template>
            <span>{{ $t('syncWithOmiePickListTable') }}</span>
            </v-tooltip>
          </td>
          <!-- <td class="clickable" @click="handleClick(props.item)">
            <v-chip :color="getColor(props.item.productsAvailability / props.item.totalPickListItems)" dark>
              {{ props.item.productsAvailability }} / {{props.item.totalPickListItems }}
            </v-chip>
          </td> -->

        </tr>
      </template>
    </v-data-table>
    <PickListDetails
      v-model="itemDialog.show"
      :allow-edit="allowEdit"
      :show-current-data="showCurrentData"
      :id="itemDialog.pickListId"
      @splited="onSplited"
    />
    <!-- Remove dialog -->
    <v-dialog v-model="removePickListsDialog.enabled" max-width="400">
      <v-card>
        <v-card-text class="pt-4">
          {{ $t('removeConfirmationPickListTable') }} {{removePickListsDialog.items.length}}
          {{ $t('pickListPickListTable') }} {{removePickListsDialog.items.length > 0 ? 'lists' : 'list'}}?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click.native="removePickListsDialog.enabled = false">{{ $t('noPickListTable') }}</v-btn>
          <v-btn
            color="green darken-1"
            :loading="removePickListsDialog.loading"
            text
            @click.native="onDeleteConfirmed"
          >{{ $t('removePickListTable') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <feedback ref="feedback"></feedback>
    <download-dialog
      v-model="downloadDialog.show"
      :fileName="downloadDialog.fileName"
      @update="(value) => downloadDialog.show = value"
    ></download-dialog>

  <dialog-columns-excel v-model="DialogColumnsExcel" @success="excelSuccess" :inventoryId="selected.map((p) => p.id)"></dialog-columns-excel>
  <DialogConfirmation
      ref="dialogConfirmation"
      v-model="dialogConfirmation"
      :title="$t('releasePickListDialogTitle')"
      :message="$t('releasePickListDialogMessage')"
      :confirm-message="$t('buttons.yes').toString()"
      :cancel-message="$t('buttons.cancel').toString()"
      @confirm="releasePickList"
      @cancel="dialogConfirmation = false"
    />
  </v-card>
</template>

<script>
import PickListDetails from '@/views/picklist/PickListDetails'
import moment from 'moment'
import { shouldRefreshData } from '@/components/TableOptionsComparator'
import NegativeItemsDialog from '@/views/picklist/NegativeItemsDialog';
import DownloadDialog from '@/components/common/DownloadDialog.vue';
import DialogColumnsExcel from './DialogColumnsExcel.vue';
import agent from '@/api/agent';
import DialogConfirmation from '@/components/DialogConfirmation.vue';
import RoutesDropDown from "@/components/filters/RoutesDropDown.vue";

export default {
  props: {
    statusFilter: { type: String, default: null },
    allowDelete: { type: Boolean, default: false },
    allowEdit: { type: Boolean, default: false },
    updatedText: { type: String, require: true},
    showCurrentData: { type: Boolean, default: false }
  },
  watch: {
    options: {
      handler(newOptions, oldOptions) {
        if (shouldRefreshData(newOptions, oldOptions)) {
          this.getPickLists();
        }
      },
      deep: true
    },
    'itemDialog.show'(enabled) {
      if (!enabled) this.itemDialog.pickListId = 0;
    },
    'itemDialog.pickListId'(newId, oldId) {
      if (newId === oldId) return;
      const query = this.$route.query;
      if (query.id == newId) return;
      const newQuery = Object.assign({}, query);
      if (newId > 0) {
        newQuery.id = newId;
      } else {
        delete newQuery.id;
      }
      this.$router.replace({ query: newQuery });
    },
    '$route'(to, from) {
      if (to != from && to.query.id > 0) {
        this.tryToShowPickList(to.query.id);
      }
    },
  },
  data() {
    return {
      pickLists: [],
      selected: [],
      infoMessages: [],
      headers: [{ text: this.$t('idPickListTable'), align: 'left', sortable: false, value: 'id' },
                { text:  this.$t('description'), align: 'left', sortable: false, value: 'description' },
                { text: this.$t('primaryPickListTable'), align: 'left', sortable: false, value: 'isSecondary' },
                { text: this.$t('customerPickListTable'), align: 'left', sortable: false, value: 'pointOfSale.customerName' },
                { text: this.$t('locationPickListTable'), align: 'left', sortable: false, value: 'pointOfSale.localName' },
                { text: this.$t('specificLocationPickListTable'), align: 'left', sortable: false, value: 'pointOfSale.specificLocation' },
                { text: this.$t('pointOfSalePickListTable'), align: 'left', sortable: false, value: 'pointOfSale.id' },
                { text: this.$t('routePickListTable'), align: 'left', sortable: false, value: 'pointOfSale.route' },
                { text: this.$t('machinePickListTable'), align: 'left', sortable: false, value: 'pointOfSale.machineModelName' },
                { text: this.updatedText, align: 'left', sortable: false, value: 'dateUpdated' },
                ...(this.statusFilter === 'Pending') ? [{text: this.$t('startedByPickListTable'), align: 'left', sortable: false, value: 'startedBy'}] : [],
                //{ text: "Produtos em estoque", align: 'left', sortable: false, value: 'inventoryAvailability ' }
                ],
      loading: {
        table: true,
      },
      totalItems: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
        route: null
      },
      itemDialog: {
        show: false,
        pickListId: 0
      },
      removePickListsDialog: {
        enabled: false,
        items: [],
        loading: false
      },
      erpSyncEnabled: false,
      hasOmieIntegration: false,
      downloadDialog: {
        show: false,
        fileName: ""
      },
      DialogColumnsExcel: false,
      distributionCenterEnable: false,
      dialogConfirmation: false,
      selectedPickListIdToRelease: 0,
    }
  },
  mounted() {
    // Check if picklist ERP sync is enabled before diplaying sync button for Amlabs or Omie
    this.axios.get('api/ErpSync/picklists/enabled')
      .then((response) => {
        if (response.data == "AmLabs"){
          this.erpSyncEnabled = true;
        }
        else {
          this.hasOmieIntegration = true;
          if (this.statusFilter == "Pending" || this.statusFilter == "Separated") {
            var sincronization = { text: this.$t('synchronizationPickListTable'), align: 'left', sortable: false, value: 'action'};
            this.headers.push(sincronization);
          }
        }
      });
    this.verifyDistributionCenterEnable();
  },
  methods: {
    handleClick(pickList) {
      this.itemDialog.pickListId = pickList.id;
      this.itemDialog.show = true;
    },
    getPickLists() {
      this.loading.table = true;
      this.axios.get('api/PickLists', { params: { page: this.options.page, pageSize: this.options.itemsPerPage, status: this.statusFilter, routeId: this.options.route } })
        .then((response) => {
          this.loading.table = false;
          if(this.hasOmieIntegration)
            response.data.items.forEach(item => {
              item.sync = false;
            });
          this.pickLists = response.data.items;
          this.totalItems = response.data.totalItems;
          this.removeOutdatedSelections();
          var queryId = this.$route.query.id;
          if (queryId > 0) this.tryToShowPickList(queryId);
        })
    },
    tryToShowPickList(queryId) {
      const pickList = this.pickLists.find(p => p.id == queryId);
      if (pickList != null) {
        this.handleClick(pickList);
        this.$emit('show-me');
      }
    },
    removeOutdatedSelections() {
      let pickListIds = this.pickLists.map((p) => p.id);
      for (let i = this.selected.length - 1; i >= 0; i--) {
        if (!pickListIds.includes(this.selected[i].id)) {
          this.selected.splice(i, 1);
        }
      }
    },
    omieSync(picklist){
      picklist.sync = true;
      this.axios.post(`api/ErpSync/omie/picklist/${picklist.id}`)
        .then(() => {
          this.$refs.feedback.handleSuccess(`${this.$t('synchronizationStarted')} ${picklist.id} ${this.$t('synchronizationStartedPickListTable')}`);
        })
        .catch((error) => this.$refs.feedback.handleError(this.$t('syncErrorPickListTable'), error))
        .then(() => (picklist.sync = false));
    },
    getSyncColor(externalId){
      if (externalId != null) return "success"
      return "error"
    },
    getExcel() {
      this.DialogColumnsExcel = true;
    },
    excelSuccess(fileName) {
      this.downloadDialog.fileName = fileName;
      this.downloadDialog.show = true;
    },
    onPickListsCreated(creationResults) {
      this.getPickLists();
      const messages = creationResults.map((r) => {
        return {
          type: r.pickListId != null ? 'success' : 'error',
          visible: true,
          text: r.pickListId != null ?
            `${this.$t('successRemovePickListTable')} ${r.pickListId} ${this.$t('createdSuccessPickListTable')} ${r.pointOfSaleId}` :
            `${this.$t('createdErrorPickListTable')} ${r.pointOfSaleId}: ${r.error}`
        }
      })
      this.infoMessages.push(...messages);
    },
    onDeleteClicked() {
      this.removePickListsDialog.items = this.selected;
      this.removePickListsDialog.enabled = true;
    },
    onDeleteConfirmed() {
      this.removePickListsDialog.loading = true;
      this.axios.delete('api/PickLists/Bulk', {
        params: { ids: this.removePickListsDialog.items.map((p) => p.id) }
      }).then((response) => {
        this.removePickListsDialog.loading = false;
        this.removePickListsDialog.enabled = false;
        const messages = response.data.map((r) => {
          return {
            type: r.success ? 'success' : 'error',
            visible: true,
            text: r.success ?
              `${this.$t('successRemovePickListTable')} ${r.pickListId} ${this.$t('removeSuccessPickListTable')}` :
              `${this.$t('errorRemovePickListTable')} ${r.pickListId}: ${r.error}`
          }
        })
        this.infoMessages.push(...messages);
        this.getPickLists();
        this.$emit('pick-list-deletion');
      })
    },
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY HH:mm:ss');
    },
    syncPickLists() {
      this.axios.post('api/ErpSync/picklists')
        .then(() => this.$refs.feedback.handleSuccess(this.$t('syncSuccessPickListTable')))
        .catch(() => this.$refs.feedback.handleError(this.$t('syncErrorPickList')))
    },
    getColor(normalization) {
      if(normalization === 1) return 'green'
      else if(normalization === 0) return 'red'
      return 'yellow'
    },
    verifyDistributionCenterEnable() {
      const type = 'DistributionCenter';
      agent.Features.VerifyFeatureIsEnable(type).then((response) =>{
        this.distributionCenterEnable = response;
      }).catch(() => {
        this.distributionCenterEnable = false;
      });
    },
    onSplited(results){
      const messages = results.map((r) => {
        return {
          type: r.pickListId != null ? 'success' : 'error',
          visible: true,
          text: r.pickListId != null ?
            `${this.$t('successRemovePickListTable')} ${r.pickListId} ${this.$t('createdSuccessPickListTable')} ${r.pointOfSaleId}` :
            `${this.$t('createdErrorPickListTable')} ${r.pointOfSaleId}: ${r.error}`
        }
      })
      this.infoMessages.push(...messages);
      this.getPickLists();
    },
    openDialogConfirmation(pickListId){
      this.selectedPickListIdToRelease = pickListId
      this.dialogConfirmation = true;
    },
    releasePickList(){
      this.dialogConfirmation = false;
      if(this.selectedPickListIdToRelease === 0) return;
      const pickListId = this.selectedPickListIdToRelease;
      this.axios.put('api/PickLists/startedBy', null, { params : {pickListId: pickListId, removeUserId: true}})
      .then(() => this.$refs.feedback.handleSuccess(this.$t('releasedPicklistSuccessPickListTable')))
      .catch(() => this.$refs.feedback.handleError(this.$t('releasedPicklistErrorPickListTable')))
      .finally(() => {
        this.getPickLists();
        this.selectedPickListIdToRelease = 0;
      });
    }
  },
  components: {
    RoutesDropDown,
    PickListDetails,
    NegativeItemsDialog,
    DownloadDialog,
    DialogColumnsExcel,
    DialogConfirmation
  }
}
</script>

<style scoped>
  .clickable {
    cursor: pointer
  }
</style>
