export default {
  url: "#",
  headers: { "Cache-Control": "max-age=31536000" },
  method: 'PUT',
  thumbnailWidth: 600,
  thumbnailHeight: 200,
  addRemoveLinks: true,
  acceptedFiles: ".xls, .xlsx",
  maxFiles: 1,
  autoProcessQueue: false,
  dictDefaultMessage:
    "<i class='mdi mdi-cloud-upload-outline font-size-icon'><br><br></i>" +
    "<span class='font-size-text'> Arraste e solte seu arquivo Excel aqui ou selecione um arquivo. </span>" +
    "<br><br><br><span class='gray-background-text font-size-selection'><strong>SELECIONAR O ARQUIVO</strong></span>",
  dictFallbackMessage: "Este navegador não suporta upload de arquivos. Tente uma versão recente do Chrome ou Firefox.",
  dictFileTooBig: "O arquivo é muito pesado, tente um menor que {{maxFilesize}}MB.",
  dictInvalidFileType: "Este formato de arquivo não é suportado, apenas arquivos .xls e .xlsx são aceitos.",
  dictResponseError: "Não foi possível fazer o upload do arquivo (erro {{statusCode}}).",
  dictCancelUpload: "Cancelar upload",
  dictUploadCanceled: "O upload foi cancelado.",
  dictCancelUploadConfirmation: "Deseja cancelar o upload?",
  dictRemoveFile: '',
  dictMaxFilesExceeded: "Limite máximo de arquivos atingido.",
  dictFileSizeUnits: { tb: "TB", gb: "GB", mb: "MB", kb: "KB", b: "b" },
  init: function() {
    this.on('addedfile', function(file) {
      const previewElement = file.previewElement.querySelector('.dz-progress');
      if (previewElement) {
        previewElement.style.display = 'none';
      }
      if (this.files.length > 1) {
        this.removeFile(this.files[0]);
      }
      file.previewElement.querySelector('.dz-remove').style.display = 'none';
    });

    this.on('processing', function(file) {
      const elements = document.getElementsByClassName('dz-progress');
      [].forEach.call(elements, function(element) {
        element.style.visibility = 'visible';
      });

      this.options.url = file.uploadURL;
    });
  },
  sending(file, xhr) {
    const send = xhr.send;
    xhr.send = () => {
      send.call(xhr, file);
    };
  },
};
