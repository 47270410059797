<template>
  <v-expansion-panels hover>
    <v-expansion-panel>

      <v-expansion-panel-header>
        <div>
          <v-icon>filter_list</v-icon>
          {{ $t('filters.filtersActions') }}
        </div>
        <v-spacer></v-spacer>
      </v-expansion-panel-header>

      <v-expansion-panel-content class="pb-2" eager>
        <v-form ref="form" v-model="valid">

          <v-row class="pt-5">

            <v-col cols="12" md="3">
              <interval-date-picker
                  :interval-max-range=31
                  :interval-start-range=30
                  :reset-date="IntervalDatePicker.reset"
                  @intervalDateUpdated="onIntervalDateUpdated"
              ></interval-date-picker>
            </v-col>

            <v-col cols="12" md="3">
              <inventories-drop-down
                  v-model="form.inventoryIds"
                  :inventoryTypeFilter="inventoryTypeFilter"
                  :multiple="true"
              ></inventories-drop-down>
              <UserDropDown
                v-model="form.userId"
                >
              </UserDropDown>
            </v-col>

            <v-col cols="12" md="3">

              <OperationsInventoryType
                v-model="form.operationTypes"
                :operationTypesFilter = "operationTypesFilter"
                :multiple="true">
              </OperationsInventoryType>

              <products-filter
                icon="shopping_cart"
                v-model="form.productId"
              ></products-filter>
            </v-col>

            <v-col cols="12" md="3">
              <RoutesDropDown
                v-model="form.routeId"
              ></RoutesDropDown>
            </v-col>
            <v-col class="search-field px-2" cols="12" md="3">
              <v-row class="ma-0">
                <p class="search-field my-0">
                  {{ $t('inventory.types.title') }}
                </p>
              </v-row>
              <v-row class="ma-0">
                <v-chip-group
                    v-model="form.inventoryTypes"
                    active-class="deep-purple--text text--accent-4"
                    column
                    mandatory
                    multiple
                >
                  <v-chip
                      v-for="(type, index) in inventoryTypes"
                      :key="index"
                      :value="type.value"
                  >
                    {{ type.text }}
                  </v-chip>
                </v-chip-group>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="pa-2" justify="end">
            <v-btn
                class="ma-1"
                color="error"
                text
                @click.native="clearQuery"
            >
              <v-icon left>clear</v-icon>
              {{ $t('buttons.clear') }}
            </v-btn>

            <v-btn
                :disabled="!valid"
                :loading="loading.excel"
                class="ma-1"
                color="info"
                @click.native="getExcel"
            >
              <v-icon left>mdi-file-excel</v-icon>
              {{ $t('buttons.excel') }}
            </v-btn>

            <v-btn
                :disabled="!valid"
                class="ma-1"
                color="success"
                v-on:click.native="emitModification"
            >
              <v-icon left>refresh</v-icon>
              {{ $t('buttons.update') }}
            </v-btn>
          </v-row>
          <download-dialog
            v-model="downloadDialog.show"
            :fileName="downloadDialog.fileName"
            @update="(value) => downloadDialog.show = value"
          />
          <feedback ref="feedback"></feedback>
        </v-form>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import DownloadDialog from '@/components/common/DownloadDialog.vue';
import InventoriesDropDown from '@/components/filters/InventoriesDropDown.vue';
import IntervalDatePicker from "@/components/filters/IntervalDatePicker";
import OperationsInventoryType from '@/components/filters/OperationsInventoryType.vue';
import UserDropDown from '@/components/filters/UserDropDown.vue';
import ProductsFilter from './filters/ProductsFilter.vue';
import RoutesDropDown from "@/components/filters/RoutesDropDown.vue";

export default {
  components: {
    RoutesDropDown,
    IntervalDatePicker,
    InventoriesDropDown,
    DownloadDialog,
    OperationsInventoryType,
    UserDropDown,
    ProductsFilter
  },
  data() {
    return {
      valid: false,
      expansionIndex: 0,
      form: {
        minDate: null,
        maxDate: null,
        inventoryIds: [],
        inventoryTypes: ['distributionCenter', 'pointOfSale'],
        operationTypes:[],
        productId: null,
        userId: null,
        routeId: null,
      },
      loading: {
        excel: false
      },
      IntervalDatePicker: {
        reset: false,
      },
      inventoryTypes: [
        {text: this.$t('inventory.types.center'), value: "distributionCenter"},
        {text: this.$t('inventory.types.pos'), value: "pointOfSale"},
      ],
      downloadDialog: {
        show: false,
        fileName: ""
      }
    };
  },
  mounted() {
    this.emitModification();
  },
  computed: {
    inventoryTypeFilter() {
      if (this.form.inventoryTypes.length === this.inventoryTypes.length) {
        return null;
      } else return this.form.inventoryTypes[0];
    },
    operationTypesFilter:{
      get(){
        return this.form.operationTypes;
      },
      set(value){
        this.form.operationTypes = value;
      }
    },

  },
  methods: {
    getExcel() {
      if (this.$refs.form.validate()) {
        this.loading.excel = true;
        this.axios
            .get('api/inventory/operations/excel', {params: this.form})
            .then((response) => {
              this.loading.excel = false;
              this.downloadDialog.fileName = response.data;
              this.downloadDialog.show = true;
            }).catch(error => this.$refs.feedback.handleError(this.$t('inventory.operation.messages.error.report'), error))
            .finally(() => this.loading.excel = false);
      }
    },
    emitModification() {
      if (this.$refs.form.validate()) {
        this.$emit("modify", this.form);
      }
    },
    newForm() {
      return {
        minDate: this.form.minDate,
        maxDate: this.form.maxDate,
        inventoryIds: [],
        inventoryTypes: ['distributionCenter', 'pointOfSale'],
        operationTypes: [],
        userId: String || null,
        routeId: String || null,
      };
    },
    clearQuery() {
      this.form = this.newForm();
      this.IntervalDatePicker.reset = true;
      this.emitModification();
    },

    onIntervalDateUpdated(minDate, maxDate, resetDate) {
      if (resetDate) {
        this.IntervalDatePicker.reset = false;
      }
      this.form.minDate = minDate;
      this.form.maxDate = maxDate;
    },
  },
};
</script>
