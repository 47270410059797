
import Vue from 'vue';

export default Vue.extend({
  name: "AddCustomButton",
  props: {
    text: {type: String, default: ""},
    icon: {type: String, default: "mdi-plus"},
    disabled: {type: Boolean, default: false},
    displayBlock: {type: Boolean, default: false},
  },
});
