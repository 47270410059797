export default {
    planogramsByPos: 'Planogramas por PDV',
    searchPDV: 'Buscar PDV',
    selectPDVs: 'Seleccione PDVs sin planograma pendiente o borrador',
    planograms: 'Planogramas',
    updateItems: 'Actualizar elementos',
    finishDraft: 'Finalizar borrador',
    finishDrafts: 'Finalizar borradores',
    confirmRemoveDrafts: '¿Está seguro de que desea eliminar los borradores de planograma para los PDVs seleccionados?',
    removeDrafts: 'Eliminar borradores',
    btnNo: 'No',
    btnRemove: 'Eliminar',
    activatePending: 'Activar pendientes',
    confirmActivate: '¿Desea activar el planograma pendiente para los PDVs seleccionados?',
    btnFinalize: 'Finalizar',
    noResults: 'Sin resultados',
    headersPdvID: 'ID PDV',
    headersCustomer: 'Cliente',
    headersLocation: 'Ubicación',
    headersSpecificLocation: 'Ubicación específica',
    headersMachine: 'Máquina',
    headersActivePog: 'POG Activo',
    headersPendingPog: 'POG Pendiente',
    headersDraftPog: 'POG Borrador',
    finalizePogSuccess: 'Planograma finalizado para el PDV',
    finalizePogError: 'No se pudo finalizar el planograma para el PDV',
    activatePogSuccess: 'Planograma activado para el PDV',
    activatePogError: 'No se pudo activar el planograma para el PDV',
    removePogSuccess: 'Planograma',
    removePogSuccessRemoved: 'eliminado',
    removePogError: 'No se pudo eliminar el planograma',
    createPogSuccess: 'Planograma creado para el PDV',
    createPogError: 'No se pudo crear el planograma para el PDV',
    addItemSuccess: 'Elemento ',
    addItemSuccessAdded: 'añadido',
    removeItemSuccess: 'eliminado ',
    updateItemSuccess: 'actualizado' ,
    selectionPos: '¿Desea finalizar el planograma de borrador para los PDVs seleccionados?',
    completionMessage: 'Después de finalizar el planograma, entrará en modo pendiente y no se podrá editar. El planograma pendiente reemplazará al planograma activo al abastecer el PDV o activar el planograma.',
    finishPlanogramError: 'Seleccione solo PDVs con planograma de borrador',
    deletePlanogramError: 'Seleccione solo PDVs con planograma de borrador',
    activatePlanogramError: 'Seleccione solo PDVs con planograma de borrador',
    activatePendingPlanograms: 'Activar planogramas pendientes',
    makePlanogramActive: 'La activación convertirá el planograma pendiente en activo. *Nota: para mantener un recuento de inventario correcto, recomendamos, en lugar de activar, usar la opción de Abastecer al momento de abastecer el punto de venta. De esta manera, además de informar el abastecimiento, se activará el planograma pendiente y se tendrá el inventario correspondiente al abastecimiento.',
    activatePlanogramsError: 'Error al activar el planograma. Inténtalo de nuevo.',
    onFinishedCreationMessage: 'Los planogramas se crearon como borradores.',
    onFinishedCreationMessageAddItem: 'Puedes agregar más elementos, editar elementos existentes o finalizar el borrador.',
    onEntryUpdatedMessage: 'La operación se aplicó al planograma de borrador.',
    onEntryUpdatedMessageAddItem: 'Puedes seguir agregando elementos, editar elementos existentes o finalizar el borrador.',
    handleAddOrUpdateResultText: ' en el planograma ',
    rascunho: 'borrador',
    ativo: 'activo',
    PDV: 'PDV',
    userPriorAlert: "Los puntos de venta que comparten inventario (secundarios) ya no se mostrarán en esta página, solo los puntos de venta primarios."
};
