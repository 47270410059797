
import agent from '@/api/agent';
import { RegistrationRequestAdminEvaluation, RegistrationRequestStatus } from '@/types/CustomerRegistration';

export default {
  props: {
    value: {type: Boolean, required: true},
    selectedRegistration: {type: Object, required: true},
  },
  data() {
    return {
      checked: false as boolean,
      requestData: {} as RegistrationRequestAdminEvaluation,
      rejectionReason: '' as string,
      loading: false as boolean,
    };
  },
  watch: {
    selectedRegistration: {
      handler(newVal: RegistrationRequestAdminEvaluation) {
        (this as any).requestData = newVal;
      },
      immediate: true 
    }
  },
  computed: {
    isApproved() : boolean{
      return (this as any).requestData?.adminApprovalStatus === RegistrationRequestStatus.Approved;
    },
    formattedText():string {
      return (this as any).isApproved
        ? `${(this as any).$t('registrationApprovedDialogText')} `
        : `${(this as any).$t('registrationRejectionDialogText')}`;
    },
  },
  methods: {
    approveOrReject() {
      (this as any).loading = true;
      if (!(this as any).isApproved) {
        (this as any).requestData.adminComment = (this as any).rejectionReason;
      }
      agent.CustomerRegistration.approveOrReject((this as any).requestData as RegistrationRequestAdminEvaluation)
        .then((isEmailSent: boolean) => {
          (this as any).$emit('isEmailSent', isEmailSent);
        })
        .catch((error) => {
          (this as any).$refs.feedback.handleError(error);
        }).finally(() => {
          (this as any).loading = false
          this.closeDialog();
        });
    },
    closeDialog() {
      (this as any).checked = false;
      (this as any).requestData = {};
      (this as any).rejectionReason = '';
      (this as any).$emit('close', (this as any).rejectionReason);
    }
  }
};
