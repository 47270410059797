
import Vue from 'vue';
import { formatDocument, formatDateTimeMinutes } from '@/utility/TextFormatting';
import agent from '@/api/agent';
import { shouldRefreshData, toApiPagination } from '@/components/TableOptionsComparator';
import { RegistrationRequestAdminEvaluation, RegistrationRequestStatus, RegistrationRequestForApproval } from '@/types/CustomerRegistration';
import IconButton from '@/components/buttons/IconButton.vue';
import CommentsDialog from '@/views/newCustomerRegistration/CustomerRegistrationCommentsDialog.vue';
import EvaluationDialog from '@/views/newCustomerRegistration/CustomerRegistrationEvaluationDialog.vue';
import GenerateNewLinkDialog from '@/views/newCustomerRegistration/GenerateNewLinkDialog.vue';

export default Vue.extend({
  components: {
    IconButton,
    CommentsDialog,
    EvaluationDialog,
    GenerateNewLinkDialog,
  },
  data() {
    return {
      registrationLink: '',
      generateNewLinkDialog: false,
      search: {
        text: '',
        valid: true
      },
      totalItems: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['dateCreated'],
        sortDesc: [true],
        mustSort: true
      },
      dialogApprovalProps:{
        dialog: false,
        requestData: {} as RegistrationRequestAdminEvaluation,
      },
      commentsDialog: false,
      selectedItem: null as any,
      registrationRequests: {} as any,
      loading: true,
      show: false,
      aboutDialog: {
        enabled: false,
      },
      translations: {
        response: {
          groups: {
            search: {
              error: this.$t('franchisee.response.groups.search.error').toString(),
            }
          }
        },
        table: {
          noData: this.$t('tables.noData').toString(),
        },
        button: {
          search: this.$t('buttons.search').toString(),
        }
      },
    }
  },
  watch: {
    options: {
      handler(newOptions, oldOptions) {
        if (shouldRefreshData(newOptions, oldOptions)) {
          this.getRegistrationRequests();
        }
      },
    },
  },
  methods: {
    showLink(shouldInvalidateLink: boolean) {
      agent.CustomerRegistration.generateLink(shouldInvalidateLink).then((response) => {
        this.registrationLink = response;
        if (shouldInvalidateLink) {
          this.copyLink();
        }
      });
    },
    copyLink() {
      navigator.clipboard.writeText(this.registrationLink)
        .then(() => {
          (this.$refs.feedback as Vue & { handleSuccess: Function }).handleSuccess(this.$t('linkCopiedToClipboard').toString());
        })
        .catch((error) => {
          (this.$refs.feedback as Vue & { handleError: Function }).handleError(this.$t('copyLinkError').toString(), error);
        });
    },
    getRegistrationRequests() {
      this.loading = true
      let params = toApiPagination(this.options, this.search.text);
      agent.CustomerRegistration.requestsForApproval(params)
        .then((response) => {
          this.registrationRequests = response
          this.totalItems = response.totalItems

          if (response.items.length === 0 || response.totalItems === 0) {
            this.registrationRequests.items = [];
          }
          this.loading = false
        }).catch(
          (error) => (this.$refs.feedback as any).handleError(this.$t('fetchRequestsError').toString(), error)
        )
        .finally(() => {
          this.loading = false
        })
    },
    clearSearch() {
      this.search.text = '';
      this.options.page = 1;
      this.getRegistrationRequests();
    },
    performSearch() {
      this.options.page = 1;
      this.getRegistrationRequests();
    },
    getAlterColorStatus (adminApprovalStatus: RegistrationRequestStatus) {
      if (adminApprovalStatus === RegistrationRequestStatus.Approved) return 'success'
      else if (adminApprovalStatus === RegistrationRequestStatus.Rejected) return 'error'
    },
    getApprovalStatusMessage (adminApprovalStatus: RegistrationRequestStatus) {
      if (adminApprovalStatus === RegistrationRequestStatus.Approved) return  this.$t('adminHasApproved')
      else if (adminApprovalStatus === RegistrationRequestStatus.Rejected) return this.$t('adminHasRejected')
    },
    approveItem(item: RegistrationRequestForApproval) {
      this.dialogApprovalProps.requestData = {
        registrationRequestId: item.id,
        adminApprovalStatus: RegistrationRequestStatus.Approved,
        adminEmail: (this as any).$auth.user().userEmail
      };
      this.dialogApprovalProps.dialog = true;
    },
    rejectItem(item: RegistrationRequestForApproval) {
      this.dialogApprovalProps.requestData = {
        registrationRequestId: item.id,
        adminApprovalStatus: RegistrationRequestStatus.Rejected,
        adminEmail: (this as any).$auth.user().userEmail
      };
      this.dialogApprovalProps.dialog = true;
    },
    isPendingApproval (adminApprovalStatus: RegistrationRequestStatus) {
      return adminApprovalStatus === RegistrationRequestStatus.Pending;
    },
    handleIsEmailSent(isEmailSent: boolean) {
      if (!isEmailSent) {
        (this.$refs.feedback as Vue & { handleError: Function }).handleError(this.$t('emailNotSentError').toString());
      }
    },
    closedDialog() {
      this.dialogApprovalProps.dialog = false;
      this.dialogApprovalProps.requestData = {} as RegistrationRequestAdminEvaluation;

      this.getRegistrationRequests();
    },
    openCommentsDialog(item: any) {
      this.selectedItem = item;
      this.commentsDialog = true;
    },
    closeCommentsDialog() {
      this.commentsDialog = false;
      this.selectedItem = null;
    },
    openGenerateNewLinkDialog() {
      this.generateNewLinkDialog = true;
    },
    closeGenerateNewLinkDialog() {
      this.generateNewLinkDialog = false;
    },
    GenerateNewLinkDialogConfirm() {
      this.showLink(true);
      this.closeGenerateNewLinkDialog();
    },
    formatDocument,
    formatDateTimeMinutes,
  },
  mounted() {
    this.loading = true;
    this.getRegistrationRequests();
    this.showLink(false);
    this.loading = false;
  },
  computed: {
    headers() {
      const headers = [
        { text: this.$t('tables.headers.requestCode'), align: 'left', sortable: true, value: 'requestCode' },
        { text: this.$t('tables.headers.requesterName'), align: 'left', sortable: true, value: 'name' },
        { text: this.$t('tables.headers.documentId'), align: 'left', sortable: true, value: 'documentId' },
        { text: this.$t('tables.headers.requesterEmail'), align: 'left', sortable: false, value: 'email' },
        { text: this.$t('tables.headers.requesterPhone'), align: 'left', sortable: false, value: 'phoneNumber' },
        { text: this.$t('tables.headers.requestDate'), align: 'left', sortable: false, value: 'dateCreated' },
        { text: this.$t('tables.headers.approvedOrRejectedBy'), align: 'left', sortable: false, value: 'adminEmail' },
        { text: this.$t('tables.headers.requests'), align: 'left', sortable: false, value: 'adminApprovalStatus' },
        { text: this.$t('tables.headers.actions'), align: 'left', sortable: false, value: 'adminComment' },
      ];
      return headers;
    }
  }
})
