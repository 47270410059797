export default {
  filters: {
    filtersActions: 'Filtros y Acciones',
    title: 'Filtros',
    groupings: 'Agrupaciones',
    labels: {
      all: 'Todos',
      period: 'Período',
      pos: 'PDV',
      onlySubtractedItems: 'Solo artículos sustraídos',
      advanced: 'Avanzado',
      calendar: 'Calendario',
      inventoryType: 'Tipo de Inventario',
      central: 'Central',
      search: 'Buscar',
      searchProducts: 'Buscar un producto',
      months: 'Meses',
      month: 'Mes',
      day: 'Día',
      dayOfWeek: 'Día de la Semana',
      customer: 'Cliente',
      customers: 'Clientes',
      location: 'Ubicación',
      locationSpecific: 'Ubicación Específica',
      pointOfSale: 'Punto de Venta',
      pointsOfSale: 'Puntos de venta',
      result: 'Resultado',
      minimumPrice: 'Precio Mínimo',
      maximumPrice: 'Precio Máximo',
      product: 'Producto',
      category: 'Categoría',
      lastCardDigits: 'Últimos Dígitos de la Tarjeta',
      date: 'Fecha',
      initialDate: 'Fecha Inicial',
      finalDate: 'Fecha Final',
      initialHour: 'Hora Inicial',
      finalHour: 'Hora Final',
      verificationStatus: 'Estado de Verificación',
      viewOnlyTransactionItemsSubtracted: 'Ver solo transacciones con artículos sustraídos',
      viewProfit: 'Ver Ganancia',
      accessDetails: 'Acceder a Detalles',
      audit: 'Auditoría',
      actions: 'Acciones',
      accessResult: 'Acceder al Resultado',
      operationType: 'Tipo de Operación',
      operation: 'Operación',
      lastMovement: 'Último movimiento',
      rangeOfDays: 'Intervalo de días: ',
      saveFilters: 'Guardar Filtros',
      savedFilters: 'Filtros Guardados',
      states: 'Estados',
      cardBrands: 'Marcas de Tarjetas',
      paymentMethods: 'Métodos de Pago',
      reportName: 'Nombre del informe',
      local: 'Local',
      showAllProducts: 'Mostrar todos los productos',
      showMinimumCritical: 'Mostrar solo mínimos críticos',
      lastXDaysFilter:  'Considerando los últimos {days} días,',
      inventoryForDesiredDays: 'quiero inventario para {days} días',
      inventoryDaysLimit: 'para artículos con hasta {inventoryDaysLeftThreshold} días de inventario.',
      ignoreRupture: 'Ignorar ruptura',
      rating: 'Calificación',
      enviroment: 'Ambiente',
      organization: 'Organización',
      products: 'Productos',
      cardMachine: 'Datáfono',
      payment: 'Pago',
      prices: 'Precios',
      others: 'Otros',
      application: 'Aplicación'
    },
    messages: {
      selectMonth: 'Seleccione un mes',
      selectCustomer: 'Seleccionar un cliente',
      selectLocation: 'Seleccionar una ubicación',
      selectResult: 'Seleccionar resultado para ver',
      selectOperationType: 'Seleccione un tipo de operación',
    },
    timelineDateAlert: 'Intervalo máximo de tres días',
    orderSearch: 'Ordenar / Buscar',
    status: 'Estado',
    customerName: 'Nombre del cliente',
    inventory: 'Inventario',
    types: 'Tipos',
    lastSale: 'Última venta',
    creationDate: 'Fecha de creación (ID del punto de venta)',
    order: 'Ordenar',
    groupBy: 'Agrupar por',
    sort: {
      asc: 'Ascendente',
      desc: 'Descendente',
    },
    saveReport:{
      title:'Guardar filtros de informe',
      savedSuccessfully: 'Guardado con éxito',
      deleteConfirmation: 'Eliminar filtros del informe',
      reportName: 'Nombre del informe',
      error: {
        onlyUser: 'Solo se pueden eliminar filtros de usuario',
        errorWhenSaving: 'Ocurrió un error al guardar los filtros',
        unableToRemove: 'No se puede eliminar el elemento',
      },
    },
    selectALocation: 'Seleccione una ubicación primero',
    selectACustomer: 'Seleccione un cliente primero',
    routes: 'Rutas',
  },
}
