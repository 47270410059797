export default {
  tables: {
    headers: {
      id: "Id",
      pointOfSaleId: "ID PDV",
      actions: "Acciones",
      customer: "Cliente",
      location: "Ubicación",
      locationSubtitle: 'Ubicación / Subtítulo',
      specificLocation: "Ubicación específica",
      idSubEnvironment: "ID del Sub Entorno",
      subEnvironment: "Sub Entorno",
      cnpj: "CNPJ",
      billing: "Facturación (últimos 30 días)",
      machines: "Cant. de máquinas",
      stores: "Cant. de tiendas",
      storeBrand: 'Marca de la tienda',
      nps: "Promedio de NPS",
      name: "Nombre",
      type: "Tipo",
      lastUpdated: "Fecha de actualización",
      items: "Ítems",
      activate: "Activar",
      remove: "Eliminar",
      code: "Código",
      description: "Descripción",
      barCode: "Código de barras",
      categories: 'Categorías',
      category: "Categoría",
      cost: "Costo",
      price: "Precio",
      markUp: "Markup",
      responsible: "Responsable",
      applyPriceTableOnPointOfSale: "Aplicar tabla de precios en puntos de venta",
      removePriceTable: "Eliminar tabla de precios",
      visualizeFranchiseeEnvironment: "Visualizar Ambiente",
      result: 'Resultado',
      value: 'Valor',
      card: 'Tarjeta',
      verificationStatus: 'Estado de Verificación',
      event: 'Evento',
      channel: 'Canal',
      day: 'Día',
      month: 'Mes',
      dateHour: 'Fecha/Hora',
      date: 'Fecha',
      dayOfWeek: 'Día de la Semana',
      initialHour: 'Hora Inicial',
      finalHour: 'Hora Final',
      duration: 'Duración',
      ip: 'IP',
      users: 'Usuarios',
      parameters: 'Parámetros',
      lockCode: 'Código de Bloqueo',
      lockName: 'Nombre de Bloqueo',
      details: 'Detalles',
      deviceName: 'Nombre del Dispositivo',
      openingResponsible: 'Responsable de Apertura',
      cpf: 'CPF',
      ageRestriction: 'Restricción de Edad',
      status: 'Estado',
      product: 'Producto',
      productCode: 'Código del Producto',
      productCategory: 'Categoría del Producto',
      quantity: 'Cantidad',
      qty: 'Cant.',
      cmv: 'CMV',
      margin: 'Margen',
      selection: 'Selección',
      nit: "NIT",
      ein: "EIN",
      associatedPos: 'PDV Asociado',
      inventory: 'Inventario',
      inventoryId: 'ID del Inventario',
      inventoryAddress: 'Dirección del Inventario',
      lastInventory: 'Último Inventario',
      lastPurchaseCost: 'Costo Últ. Compra',
      meanCost: 'Costo Medio',
      totalCost: 'Costo Total',
      ratings: 'Calificaciones',
      reserved: 'Reservado',
      minimumCritical: 'Mínimo Crítico',
      minimumQuantity: 'Cant. Mín. Compra',
      previousQuantity: 'Cantidad Anterior',
      originInventory: 'Inventario de Origen',
      movimentQuantity: 'Cantidad Movida',
      destinyInventory: 'Inventario de Destino',
      endQuantity: 'Cantidad Final',
      dateCreated: 'Fecha de Creación',
      operation: 'Operación',
      operationId: 'ID de la Operación',
      email: "Email",
      active: "Activo",
      wiFiPercentage: 'Wi-Fi (%)',
      mobilePercentage: 'Móvil (%)',
      offlinePercentage: 'Sin conexión (%)',
      pointOfSale: 'Punto de Venta',
      productDescription: 'Desc. del Producto',
      dateOfReading: 'Fecha de Lectura',
      pointOfSaleReading: 'PDV Leído',
      typeOfAlert: 'Tipo de Alerta',
      operationType: 'Tipo de Operación',
      pickList: 'Lista de Selección',
      finished: 'Finalizado en',
      elapsedtime: 'Tiempo Transcurrido',
      totalLosses: 'Total de Pérdidas',
      capacity: 'Capacidad',
      validDate: 'Fecha de Validez',
      confirmedQuantity: 'Cantidad Confirmada',
      supply: 'A Surtir',
      supplyQuantity: 'Cantidad a Surtir',
      separetedQuantity: 'Cantidad Separada',
      removals: 'Remociones',
      serialNumber: 'Número de Serie',
      distributionCenter: 'Centro de Distribución',
      posCode: 'Código del PDV',
      version: 'Versión',
      password: 'Contraseña',
      model: 'Modelo',
      modelManufacturer: 'Modelo / Fabricante',
      paymentSystem: 'Sist. de pago',
      expires: 'Expira en',
      currentQty: 'Cant. actual',
      pos: 'PDV',
      qtySold: 'Cant. Vendida',
      soldValue: 'Valor Vendido',
      expiredQty: 'Cant. Expirada',
      expiredValue: 'Valor Expirado',
      damagedQty: 'Cant. Dañada',
      damagedValue: 'Valor Dañado',
      stolenQty: 'Cant. Desviada',
      stolenValue: 'Valor Desviado',
      otherLossesQty: 'Cant. Otras Pérdidas',
      otherLossesValue: 'Valor Otras Pérdidas',
      gainQty: 'Cant. Ganancias',
      gainValue: 'Valor Ganancias',
      defaultPrice: 'Precio predeterminado',
      occurrences: 'Incidencias',
      totalDays: 'Tiempo Total (Días)',
      totalLossesValue: 'Total Pérdidas',
      startDate: 'Fecha de Inicio',
      endDate: 'Fecha Final',
      time: 'Tiempo (Días)',
      losses: 'Pérdidas (Un.)',
      lossesValue: 'Pérdidas',
      originalValue: 'Valor Original ({sign})',
      discount: 'Descuento ({sign})',
      paymentAmount: 'Monto Pagado ({sign})',
      marginPercentage: 'Margen (%)',
      marginValue: 'Margen ({sign})',
      totalTaxes: 'Impuesto ({sign})',
      method: 'Método',
      nfce: 'NFC-e',
      unitsToBuySuggestion: 'Sugerencia(Unid)',
      ruptures: 'Rupturas: {days} día(s)',
      withdraw: 'Salidas: {days} día(s)',
      minimumQuantityAbbreviated: 'Cant. Mín. (Unid.)',
      conversionUnitRatio: 'Un. Conversión',
      convertedToBuySuggestion: 'Sugerencia (Unid.C.)',
      convertedMinimumPurchaseQuantityAbbreviated: 'Cant. Mín.(Unid.C.)',
      daysInInventory: 'Tiempo de inventario (días)',
      requestCode: 'Código de solicitud',
      requesterName: 'Nombre del solicitante',
      requesterEmail: 'Email del solicitante',
      requesterPhone: 'Teléfono del solicitante',
      requestDate: 'Fecha/hora de la solicitud',
      approvedOrRejectedBy: 'Aprobado/rechazado por',
      requests: 'Solicitudes',
      documentId: 'Documento de identidad',
      previousAverageCost: 'Costo promedio anterior',
      finalAverageCost: 'Costo promedio final',
      availability: 'Disponibilidad',
      groupIdPublic: 'Público',
      groupIdGroup: 'Grupo: ',
      complete: 'Completo',
      incomplete: 'Incompleto',
      state: 'Estado',
      cfop: 'CFOP',
      cstIcms: 'CST-ICMS',
      cstPis: 'CST-PIS',
      cstCofins: 'CST-COFINS',
      cstIpi: 'CST-IPI',
      taxation: 'Régimen Fiscal',
      numberOfProducts: 'Nº de Productos',
      comments: 'Comentarios',
      noComments: 'Sin comentarios',
      route: 'Rota',
      qtyTotalReserved: 'Cant. Total Reservada',
      qtyInStock: 'Cant. en Stock',
      qtyAvailableForPickList: 'Cant. Disponible para Lista de Selección',
    },
    noData: "Sin resultados",
    none: "Sin datos",
    total: "Total",
  }
}
