export default {
  awaitingApproval: 'Aguardando Aprovação',
  clientRequester: 'Cliente Solicitante',
  paymentProcessorAccounts: 'Contas de Adquirentes',
  apiToken: 'Token da API',
  activationCode: 'Código de Ativação',
  noPaymentProcessorAlert: 'Nenhuma conta de adquirente disponível. Cadastre uma para selecionar.',
  registerNewAccount: 'Cadastrar nova conta',
  deliveryAddress: 'Endereço para Entrega',
  registerNewAddress: 'Cadastrar Novo ou Editar Endereço',
  address: 'Endereço',
  editAddress: 'Editar Endereço',
  termsAndConditions: 'Termos e Condições',
  termsAndConditionsText: 'Ao fazer seu pedido, você concorda com as Condições de Uso da AMLabs',
  finishOrder: 'Finalizar Pedido',
  fantasyName: 'Nome Fantasia',
  billingDocumentId: 'CNPJ da Empresa',
  customerEmail: 'Email do Cliente',
  customerPhone: 'Telefone do Cliente',
  productAddedToPurchase: 'Produto adicionado para compra',
  midasCloseOrderAddressNumber: "Numero do endereço",
  midasCloseOrderAddress: "Endereço",
  midasCloseOrderAddressComplement: "Complemento",
  midasCloseOrderCep: "CEP",
  midasCloseOrderCity: "Cidade",
  midasCloseOrderState: "Estado",
  noAddressAvailableAlert: "Nenhum endereço disponível. Cadastre um para selecionar.",
  withdrawAtCompany: 'Retirar na empresa: AV. Monte Castelo, 575 - Jardim Proença, Campinas - SP, CEP: 13026-241',
  productSoldByAmLabs: 'Produto vendido pela AMLabs',
  midasCloseOrderQuantity: 'Quantidade',
  midasCloseOrderSave: 'Salvar',
  missingPaymentProcessorError: 'Selecione uma conta de adquirente para finalizar o pedido',
  missingAddressInformationError: 'Há informações faltando no endereço de entrega. Preencha-as para finalizar o pedido',
  missingPhoneInformationError: 'Há informações faltando no telefone do cliente. Preencha-as para finalizar o pedido',
  missingEmailInformationError: 'Há informações faltando no email do cliente. Preencha-as para finalizar o pedido',
  missingApiTokenInformationError: 'Há informações faltando no token da API. Preencha-as para finalizar o pedido',
  missingActivationCodeInformationError: 'Há informações faltando no código de ativação. Preencha-as para finalizar o pedido',
  cepRules: 'O CEP deve conter 8 dígitos',
  cepNeeded: 'Preencha o CEP',
  midasCloseOrderAddressComplementHint: 'Apartamento/Bloco. Caso contrário, coloque N/A',
  cep:'CEP',
  cepError: 'CEP inválido, Por favor, insira um CEP válido',
  editPagseguroAccount: 'Editar Conta Pagseguro',
  documentIdIsOk:'CNPJ Válido na Receita Federal e Na Inscrição Estadual',
  documentIdIsNotOk:"CNPJ irregular - {documentId} - Status na Receita Federal: {status} e Inscrição Estadual: {ieStatus} Regularize com seu contador/Receita/Sefaz antes de prosseguir com o pedido.",
  missingDocumentIdError:'CNPJ Incorreto ou Inexistente. Por gentileza contate o nosso suporte para verificar',
  statusEnum: {
    Active: "ATIVA",
    Suspended: "SUSPENSA",
    Unqualified: "INAPTA",
    Down: "BAIXADA",
    Null: "NULA",
  },
  ieStatus: {
    Ok: "ATIVA",
    NotOk: "IRREGULAR"
  },
  recommended: 'Recomendado',
  choosePaymentProcessor: 'Em qual maquininha você deseja vincular uma conta?',
  existingAccountOrCreateNewOne: 'Você já possui uma conta criada em alguma maquininha ou deseja criar uma nova?',
  doYouHaveAnExistingAccount: 'Você já possui uma conta criada?',
  existingAccount: 'Já possuo conta na maquininha',
  iWantToCreateAnAccount: 'Desejo criar uma conta',
  connectTo: 'Conecte-se na',
  registeredEmailIn: 'E-mail cadastrado na',
  connect: 'Conectar',
  connectionSavedSuccessfully: 'Conexão salva com sucesso!',
  paymentProcessorAccountVinculation: 'Seus dados foram salvos, e o processo de vínculo da conta seguirá em andamento.',
  createAccountIn: 'Criação da conta',
  nameOfPersonResponsibleForCompanyDocumentId: 'Nome completo do responsável pelo CNPJ',
  companyDocumentId: 'CNPJ da empresa',
  documentIdOfPersonResponsibleForCompanyDocumentId: 'CPF do responsável pelo CNPJ',
  dateOfBirthOfPersonResponsibleForCompanyDocumentId: 'Data de nascimento do responsável pelo CNPJ',
  emailToRegisterIn: 'E-mail para cadastro na conta',
  foreseenMonthlyRevenue: 'Faturamento mensal previsto (TPV)',
  next: 'Próximo',
  finishRegistration: 'Finalizar cadastro',
  registrationSavedSuccessfully: 'Cadastro salvo com sucesso!',
  paymentProcessorDataSavedSuccessfully: 'Seus dados foram salvos. O processo de criação da conta seguirá em andamento <span style=\'font-weight:700;\'>somente</span> se a conta informada for utilizada para a solicitação do pedido. <span style=\'font-weight:700;\'>Caso o pedido seja realizado, entraremos em contato em breve</span> para discutirmos mais detalhes sobre taxas da maquininha e demais informações necessárias.',
  currentNetworth: 'Qual é o valor do seu patrimônio atual?',
  monthlyIncome: 'Qual é a sua renda mensal?',
  politicallyExposedPerson: 'Você é uma pessoa exposta politicamente?',
  addPersonalDocumentIdPhoto: 'ADICIONE UMA FOTO DO SEU DOCUMENTO (RG OU CNH)',
  addCompanyDocumentIdPhoto: 'ADICIONE UMA FOTO DO SEU CARTÃO CNPJ',
  acceptedFileFormats: 'Formato de arquivo aceito: (PNG ou JPEG). Não é aceito PDF<br>Tamanho máximo de arquivo: 10MB',
  imageSubmittedSuccessfully: 'Imagem submetida com sucesso!',
  missingPagSeguroInfo: 'Informações da PagSeguro faltando. Verifique o Token da API e o Código de Ativação',
  deleteEntry: 'Excluir'
};
