import {defineStore} from "pinia";
import {ref} from "vue";
import {
  CreatePaymentProcessorAccountDto,
  createPaymentProcessorAccountDtoInitialState,
  MonthlyIncomeLabels,
  NetWorthLabels,
  PaymentProcessor,
  PaymentProcessorCompany,
  paymentProcessorInitialState
} from "@/types/AcquisitionOrders";
import agent from "@/api/agent";
import {useAlertsStore} from "@/stores/alertsStore";
import {useFeatureFlagsStore} from "@/stores/featureFlagsStore";
import {useCatalogsStore} from "@/stores/catalogsStore";

interface PaymentProcessorCardInformation {
  company: PaymentProcessorCompany;
  name: string;
  image: string;
  recommended: boolean;
  enabled: boolean;
}

export const usePaymentProcessorsStore = defineStore('paymentProcessors', () => {
  const alertsStore = useAlertsStore()
  const catalogStore = useCatalogsStore();
  const featureFlagsStore = useFeatureFlagsStore();

  const paymentProcessorAccounts = ref<PaymentProcessor[]>([]);

  const paymentProcessor = ref<PaymentProcessor>({...paymentProcessorInitialState.value});

  const createPaymentProcessorAccountDto = ref<CreatePaymentProcessorAccountDto>({...createPaymentProcessorAccountDtoInitialState.value});

  const paymentProcessorCompanySelectionDialog = ref(false);
  const paymentProcessorChooseExistingDialog = ref(false);
  const paymentProcessorCreatingExistingDialog = ref(false);
  const paymentProcessorExistingCreatedDialog = ref(false);
  const paymentProcessorCreateNewDialog = ref(false);
  const paymentProcessorNewCreatedDialog = ref(false);
  const paymentProcessorPagSeguroAdditionalInfoDialog = ref(false);

  const loadingCreatingPaymentProcessor = ref(false);
  const loadingPaymentProcessorAccounts = ref(false);
  const loadingDeletingPaymentProcessors = ref<Record<number, boolean>>({});

  const dateOfBirthString = ref<string | null>(null);

  const showAdditionalCards = ref(false);

  const isEditingPagSeguro = ref(false);

  async function getPaymentProcessorAccounts() {
    loadingPaymentProcessorAccounts.value = true;
    await agent.PaymentProcessorAccounts.getEnvironmentPaymentProcessorAccounts()
      .then((response) => paymentProcessorAccounts.value = response)
      .catch((error) => alertsStore.showErrorSnackbar(error.response.data))
      .finally(() => loadingPaymentProcessorAccounts.value = false);
  }

  async function postPaymentProcessor() {
    loadingCreatingPaymentProcessor.value = true;
    await agent.PaymentProcessorAccounts.postPaymentProcessorAccount(paymentProcessor.value)
      .then(() => {
        onPostPaymentProcessorSuccess();
      })
      .catch((error) => alertsStore.showErrorSnackbar(error.response.data))
      .finally(() => loadingCreatingPaymentProcessor.value = false);
  }

  async function createPaymentProcessorAccount() {
    loadingCreatingPaymentProcessor.value = true;
    await agent.PaymentProcessorAccounts.createPaymentProcessorAccount(createPaymentProcessorAccountDto.value)
      .then(() => {
        onCreatePaymentProcessorSuccess();
      })
      .catch((error) => alertsStore.showErrorSnackbar(error.response.data))
      .finally(() => loadingCreatingPaymentProcessor.value = false);
  }

  async function deletePaymentProcessor(id: number, isNewlyCreatedAccount: boolean) {
    loadingDeletingPaymentProcessors.value[id] = true;
    await agent.PaymentProcessorAccounts.deletePaymentProcessorAccount(id, isNewlyCreatedAccount)
      .then(() => getPaymentProcessorAccounts())
      .catch((error) => alertsStore.showErrorSnackbar(error.response.data))
      .finally(() => loadingDeletingPaymentProcessors.value[id] = false);
  }

  function resetPaymentProcessorFlow() {
    paymentProcessor.value = {...paymentProcessorInitialState.value};

    paymentProcessorChooseExistingDialog.value = false;
    paymentProcessorCompanySelectionDialog.value = false;
    paymentProcessorCreatingExistingDialog.value = false;
    paymentProcessorExistingCreatedDialog.value = false;
    paymentProcessorCreateNewDialog.value = false;
    paymentProcessorNewCreatedDialog.value = false;
    paymentProcessorPagSeguroAdditionalInfoDialog.value = false;
    showAdditionalCards.value = false;
    isEditingPagSeguro.value = false;
  }

  function startPaymentProcessorFlow() {
    paymentProcessorCompanySelectionDialog.value = true;
  }

  function onPaymentProcessorCompanySelected(processorCompany: PaymentProcessorCompany) {
    paymentProcessor.value = { ...paymentProcessorInitialState.value };
    createPaymentProcessorAccountDto.value = { ...createPaymentProcessorAccountDtoInitialState.value };
    paymentProcessor.value.processorCompany = processorCompany;
    createPaymentProcessorAccountDto.value.processorCompany = processorCompany;
    paymentProcessorCompanySelectionDialog.value = false;
    paymentProcessorChooseExistingDialog.value = true;
  }

  function onExistingPaymentProcessorSelected(){
    paymentProcessorChooseExistingDialog.value = false;
    paymentProcessorCreatingExistingDialog.value = true;
  }

  function onCreateNewPaymentProcessorSelected(){
    paymentProcessorChooseExistingDialog.value = false;
    paymentProcessorCreateNewDialog.value = true;
  }

  function onCreateNewPaymentProcessorAdditionalInfoSelected() {
    if(!paymentProcessorCompanyIsPagSeguro()) {
      createPaymentProcessorAccount();
    }
    else {
      paymentProcessorCreateNewDialog.value = false;
      paymentProcessorPagSeguroAdditionalInfoDialog.value = true;
    }
  }

  function returnFromPaymentProcessorChooseExisting() {
    paymentProcessorChooseExistingDialog.value = false;
    paymentProcessorCompanySelectionDialog.value = true;
  }

  function returnFromPaymentProcessorCreatingExisting() {
    paymentProcessorCreatingExistingDialog.value = false;
    paymentProcessorChooseExistingDialog.value = true;
  }

  function returnFromPaymentProcessorCreateNew() {
    paymentProcessorCreateNewDialog.value = false;
    paymentProcessorChooseExistingDialog.value = true;
  }

  function returnFromPagSeguroAdditionalInfo() {
    paymentProcessorPagSeguroAdditionalInfoDialog.value = false;
    paymentProcessorCreateNewDialog.value = true;
  }

  async function onPostPaymentProcessorSuccess() {
    await getPaymentProcessorAccounts();
    paymentProcessorCreatingExistingDialog.value = false;
    paymentProcessorExistingCreatedDialog.value = true;
  }

  async function onCreatePaymentProcessorSuccess() {
    await getPaymentProcessorAccounts();
      paymentProcessorCreateNewDialog.value = false;
      paymentProcessorPagSeguroAdditionalInfoDialog.value = false;
      paymentProcessorNewCreatedDialog.value = true;
  }

  function paymentProcessorCompanyIsPagSeguro() {
    return paymentProcessor.value.processorCompany === PaymentProcessorCompany.PagSeguro;
  }

  function shouldUpdatePagSeguroInfo(processor: PaymentProcessor) {
    return processor.processorCompany === PaymentProcessorCompany.PagSeguro
      && (!processor.apiToken || !processor.activationCode);
  }

  function editPagSeguroInfo(processor: PaymentProcessor) {
    isEditingPagSeguro.value = true;
    paymentProcessor.value = { ...processor };
    paymentProcessorCreatingExistingDialog.value = true;
  }

  async function updatePagSeguroInfo() {
    loadingCreatingPaymentProcessor.value = true;
    await agent.PaymentProcessorAccounts.updatePagSeguroInfo(paymentProcessor.value)
      .then(() => {
        onPostPaymentProcessorSuccess();
      })
      .catch((error) => alertsStore.showErrorSnackbar(error.response.data))
      .finally(() => loadingCreatingPaymentProcessor.value = false);
  }

  function hasPaymentProcessors(): boolean {
    return paymentProcessorAccounts.value.length > 0;
  }

  function getNetWorthOptions() {
    return Object.entries(NetWorthLabels).map(([value, text]) => ({
      value: Number(value),
      text,
    }));
  }

  function getMonthlyIncomeOptions() {
    return Object.entries(MonthlyIncomeLabels).map(([value, text]) => ({
      value: Number(value),
      text,
    }));
  }

  function isPoliticallyExposed() {
    return [
      { text: 'Sim', value: true },
      { text: 'Não', value: false },
    ];
  }

  function mapPaymentProcessorCompanyToName(company?: PaymentProcessorCompany) {
    const companyValue = company ?? paymentProcessor.value.processorCompany;
    switch (companyValue) {
      case PaymentProcessorCompany.PagSeguro:
        return 'PagSeguro';
      case PaymentProcessorCompany.Rede:
        return 'Rede';
      case PaymentProcessorCompany.Stone:
        return 'Stone';
    }
  }

  function getPaymentProcessorsCards() {
    const processors: PaymentProcessorCardInformation[] = [
      {
        company: PaymentProcessorCompany.PagSeguro,
        name: "PagSeguro",
        image: "/assets/paymentProcessors/payment-processor-pagseguro.png",
        recommended: false,
        enabled: featureFlagsStore.flags.paymentProcessorFlags.pagSeguro,
      },
      {
        company: PaymentProcessorCompany.Rede,
        name: "Rede",
        image: "/assets/paymentProcessors/payment-processor-rede.png",
        recommended: false,
        enabled: featureFlagsStore.flags.paymentProcessorFlags.rede,
      },
      {
        company: PaymentProcessorCompany.Stone,
        name: "Stone",
        image: "/assets/paymentProcessors/payment-processor-stone.png",
        recommended: false,
        enabled: featureFlagsStore.flags.paymentProcessorFlags.stone,
      },
    ];

    const enabledPaymentProcessors = processors.filter(p => p.enabled);

    let environmentPaymentProcessors;

    switch (catalogStore.environmentCatalog?.tenantId) {
      case 3144:
        environmentPaymentProcessors = [
          enabledPaymentProcessors.find(p => p.company === PaymentProcessorCompany.Stone),
          ...enabledPaymentProcessors.filter(p => p.company !== PaymentProcessorCompany.Stone),
        ];
        break;
        //Add more cases here
      default:
        environmentPaymentProcessors = [
          enabledPaymentProcessors.find(p => p.company === PaymentProcessorCompany.PagSeguro),
          ...enabledPaymentProcessors.filter(p => p.company !== PaymentProcessorCompany.PagSeguro),
        ];
        break;
    }

    return environmentPaymentProcessors.map((processor, index) => ({
      ...processor,
      recommended: index === 0,
    })) as PaymentProcessorCardInformation[];
  }

  return {
    createPaymentProcessorAccount,
    createPaymentProcessorAccountDto,
    dateOfBirthString,
    deletePaymentProcessor,
    editPagSeguroInfo,
    getMonthlyIncomeOptions,
    getNetWorthOptions,
    getPaymentProcessorAccounts,
    getPaymentProcessorsCards,
    hasPaymentProcessors,
    isEditingPagSeguro,
    isPoliticallyExposed,
    loadingCreatingPaymentProcessor,
    loadingDeletingPaymentProcessors,
    loadingPaymentProcessorAccounts,
    mapPaymentProcessorCompanyToName,
    onCreateNewPaymentProcessorAdditionalInfoSelected,
    onCreateNewPaymentProcessorSelected,
    onExistingPaymentProcessorSelected,
    onPaymentProcessorCompanySelected,
    paymentProcessor,
    paymentProcessorAccounts,
    paymentProcessorChooseExistingDialog,
    paymentProcessorCompanyIsPagSeguro,
    paymentProcessorCompanySelectionDialog,
    paymentProcessorCreateNewDialog,
    paymentProcessorCreatingExistingDialog,
    paymentProcessorExistingCreatedDialog,
    paymentProcessorNewCreatedDialog,
    paymentProcessorPagSeguroAdditionalInfoDialog,
    postPaymentProcessor,
    resetPaymentProcessorFlow,
    returnFromPagSeguroAdditionalInfo,
    returnFromPaymentProcessorChooseExisting,
    returnFromPaymentProcessorCreateNew,
    returnFromPaymentProcessorCreatingExisting,
    shouldUpdatePagSeguroInfo,
    showAdditionalCards,
    startPaymentProcessorFlow,
    updatePagSeguroInfo,
  }
});
