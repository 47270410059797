export default {
    titlePosWithoutPickList: 'All points of sale',
    titleWithoutPickList: 'Points of sale without pending Pick List',
    showAllPosPosWithoutPickList: 'Show all POVs',
    selectAtLeastOnePosWithoutPickList: 'Select at least one POS',
    generatePickLists: 'Generate Pick Lists',
    searchPosWithoutPickList: 'Search',
    noResultsPosWithoutPickList: 'No results',
    machineModelColumnPosWithoutPickList: 'Machine',
    codeColumnPosWithoutPickList: 'POS Code',
    customerColumnPosWithoutPickList: 'Customer',
    locationColumnPosWithoutPickList: 'Location',
    specificLocationColumnPosWithoutPickList: 'Specific location',
    routeColumnPosWithoutPickList: 'Route',
    idColumnPosWithoutPickList: 'POS',
  };
