
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class CustomerInfoNeedsUpdate extends Vue {
  @Prop({ required: true }) visible!: boolean;
  @Prop({ required: true }) profileRoute!: string;

  closeDialog() {
    this.$emit('close');
  }

  redirectToProfile() {
    this.$router.push({ path: this.profileRoute });
    this.closeDialog();
  }
}
