<template>
    <v-select
      v-model="selected"
      :items="countries"
      item-text="name"
      item-value="code"
    >
    <template v-slot:item="slotProps">
      <i :class="['mr-2', 'em', slotProps.item.flag]"></i>
      {{ slotProps.item.name }}
    </template>
    <template v-slot:selection="slotProps">
      <i :class="['mr-2', 'em', slotProps.item.flag]"></i>
      {{ slotProps.item.name }}
    </template>
    </v-select>
  </template>
  
  <script>
  export default {
    name: "CountryFlagDropdown",
    props: {
      value: {
        type: String,
        default: null,
      },
    },
    data() {
      return {
        selected: this.value,
        // TODO: get countries flag
        countries: [
          { name: "Brasil", flag: "brazil", code: "+55" },
          { name: "USA", flag: "usa", code: "+1" },
          { name: "Colombia", flag: "colombia", code: "+57" },
        ],
      };
    },
    watch: {
      selected(newVal) {
        this.$emit("input", newVal); // Emit the selected value to the parent
      },
    },
  };
  </script>
  
  <style scoped>
  .em {
    font-size: 1.2rem; /* Adjust flag size */
  }
  </style>
  