// Class returned when a request was sent to the web socket.
// It provides a listen method to listen for the specific request.
class SocketRequestCompletion {
  constructor(
    private socketService: SocketService,
    public requestId: string | null,
  ) {}

  public listen<T extends SocketResponse>(listener: (response: T) => void): SocketRequestCompletion {
    this.socketService.listen((response) => {
      if (this.requestId == null || response.requestId === this.requestId) {
        listener(response as T);
      }
    });
    return this;
  }
}

export interface SocketResponse {
  requestId: string | null;
}

class SocketService {
  private socket: WebSocket | null = null;
  private connected = false;
  private listener: ((response: SocketResponse) => void) | null = null;

  public connect(token: string): Promise<void> {
    if (this.connected) return Promise.resolve();

    const url = `${process.env.VUE_APP_SOCKET_ENDPOINT}?authorization=${token}`;

    return new Promise((resolve, reject) => {
      this.socket = new WebSocket(url);

      this.socket.onopen = () => {
        this.connected = true;
        console.log("WebSocket connected");
        resolve();
      };

      this.socket.onerror = (error) => {
        this.connected = false;
        this.socket = null;
        console.error("WebSocket error:", error);
        reject(error);
      };

      this.socket.onclose = () => {
        this.connected = false;
        this.socket = null;
        console.log("WebSocket closed.");
      };

      this.socket.onmessage = (event) => {
        console.log("Received from web socket:", event.data);
        if (!this.listener) return;
        const response = JSON.parse(event.data);
        if (typeof response !== "object") return;
        this.listener(response);
      }
    });
  }

  public disconnect() {
    if (this.socket) {
      this.socket.close();
      this.connected = false;
      this.listener = null;
    }
  }

  public listen(listener: (response: SocketResponse) => void): SocketService {
    this.listener = listener;
    return this;
  }

  private tryGetUUID(): string|null {
    try {
      return (self.crypto as any).randomUUID() as string;
    } catch (_) {
      return null;
    }
  }

  public send(action: string, parameters: unknown): SocketRequestCompletion {
    if (!this.socket || !this.connected) {
      throw new Error("WebSocket not connected. Unable to send message.");
    }

    const requestId = this.tryGetUUID();

    const request = { action, requestId, parameters };
    console.log("Requesting to web socket:", request);
    this.socket.send(JSON.stringify(request));
    return new SocketRequestCompletion(this, requestId);
  }
}

export default SocketService;
