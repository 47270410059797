
import VideoAlerts from "./VideoAlerts.vue";
import SuspiciousTransactions from "./SuspiciousTransactions.vue";
import SuspiciousMasterHelp from './helpers/SuspiciousMasterHelp.vue';
import SuspiciousAccess from './SuspiciousAccess.vue';
import VideoDialog from "./VideoDialog.vue";
import Vue from "vue";
import {CameraHubs} from "@/api/agent";
import {CameraClickEvent, CameraHub, CameraHubStreamRequest} from "@/types/CameraHub";
import SocketService from "@/api/SocketService";

export default Vue.extend({
    data() {
      return {
        activeTab: 0,
        customers: [],
        isTokenActivated: true,
        cameraHubs: [] as CameraHub[],
        socket: new SocketService(),
        videoDialog: {
          enabled: false,
          url: null as string|null,
          message: null as string|null,
        }
      }
    },
    mounted() {
      this.axios.get("api/Customers/Active").then((response) => {
        this.customers = response.data;
      });

      this.axios.get("api/Suspicious/transaction-sync-status").then((response)=> {
        this.isTokenActivated = response.data.active;
        });

      this.getCameraHubs();
    },
    beforeDestroy() {
      this.socket.disconnect();
    },
    methods: {
      getCameraHubs() {
        CameraHubs.getCameraHubs().then((response) => {
          this.cameraHubs = response.cameraHubs;
          if (this.cameraHubs.length > 0) this.setupSocket();
        });
      },
      setupSocket() {
        this.socket.connect((this as any).$auth.token());
      },
      hasCameraHub(pointOfSaleId: number): boolean {
        return this.cameraHubs.some((ch: CameraHub) => ch.pointOfSaleId == pointOfSaleId);
      },
      showFeedbackMessage(newFeedback: { type: any, content: any }) {
        if (newFeedback.type == 'error') {
          (typeof newFeedback.content) == 'string'
            ? (this.$refs.feedback as any).handleError(newFeedback.content)
            : (this.$refs.feedback as any).handleError('Erro', newFeedback.content);
        }
        else {
          (this.$refs.feedback as any).handleSuccess(newFeedback.content);
        }
      },
      showError(errorMessage: string) {
        (this.$refs.feedback as any).handleError(errorMessage);
      },
      onCameraClick(event: CameraClickEvent) {
        this.videoDialog.enabled = true;
        const cameraHub = this.cameraHubs.find((ch: CameraHub) => ch.pointOfSaleId == event.pointOfSaleId);
        if (cameraHub === undefined) {
          this.showError("Câmera não encontrada");
          return;
        }

        const request = new CameraHubStreamRequest(
          this.socket, cameraHub.id, cameraHub.agent, event.initialDate, event.duration,
          event.pointOfSaleCustomerName, event.pointOfSaleLocalName);

        (this.$refs.videoDialog as any).startStreamRequest(request);
      }
    },
    components: {
        VideoAlerts,
        VideoDialog,
        SuspiciousTransactions,
        SuspiciousMasterHelp,
        SuspiciousAccess,
    },
});
