<template>
  <v-layout row wrap>
    <v-flex xs12>

      <!-- A) Show a preview if there's already an imageUrl and we're not in uploading mode -->
      <template v-if="imageUrl && !uploadingMode">
        <v-img
          :src="imageUrl"
          contain
        />
        <!-- Child no longer has a 'Change Image' button. 
             The parent will trigger uploading instead. -->
      </template>

      <!-- B) Otherwise, if uploadingMode is true, show the Dropzone -->
      <template v-else-if="uploadingMode">
        <div id="dropzone-container">
          <vue-dropzone
            ref="dropzone"
            id="dropzone"
            :options="dropzoneOptions"
            @vdropzone-thumbnail="onThumbnail"
            @vdropzone-success="onSuccess"
            @vdropzone-error="onUploadError"
            @vdropzone-complete="onComplete"
          />
          <v-btn
            v-if="uploadButtonVisible"
            color="primary"
            :loading="uploading"
            @click.native="uploadFiles"
            class="mr-2 mt-2"
          >
            <v-icon left>cloud_upload</v-icon>
            {{ $t("confirm") }}
          </v-btn>
          <v-btn
            v-if="cancelButtonVisible"
            :disabled="uploading"
            color="error"
            @click.native="cancelClicked"
            class="mt-2"
          >
            <v-icon left>clear</v-icon>
            {{ $t("cancel") }}
          </v-btn>
        </div>
      </template>
    </v-flex>

    <feedback ref="feedback"></feedback>
  </v-layout>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import dropzoneDefaultOptions from "@/components/DropzoneOptions";

export default {
  name: "S3ImageUploader",
  props: {
    /**
     * If this is set, we show a preview 
     * (unless 'uploadingMode' is true).
     */
    imageUrl: {
      type: [String, null],
      default: null,
    },
    /**
     * Tells us which endpoint to call (e.g. "payment-processor").
     */
    entityType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      uploading: false,
      uploadButtonVisible: false,
      cancelButtonVisible: false,
      /**
       * Start as false so we don't automatically show the dropzone
       * if imageUrl is null. The parent can call .enableUploading()
       * to show it.
       */
      uploadingMode: false, 
      uploadedImagePath: null,
      dropzoneOptions: {
      ...dropzoneDefaultOptions,
      maxFilesize: 10, // MB
      dictDefaultMessage: this.$t("clickOrDragFileHere"), 
      // or any custom text you want
    },
    };
  },
  created() {
    this.setupDropzone();
  },
  methods: {
    /**
     * Public method: the parent calls this 
     * to show the dropzone.
     */
    enableUploading() {
      this.uploadingMode = true;
    },

    /**
     * Configure how Dropzone obtains a pre-signed URL 
     * (same logic as before).
     */
    setupDropzone() {
      this.dropzoneOptions.accept = (file, done) => {
        this.axios
          .get(`api/images/${this.entityType}/putUrl?contentType=${file.type}`)
          .then((res) => {
            file.uploadRequest = res.data; // { path, url }
            file.uploadURL = res.data.url; 
            done();
          })
          .catch((error) => {
            const errMsg = error.response?.data || this.$t("unknownError");
            done(errMsg); // Pass only the actual message
          });
      };
    },

    onThumbnail(file) {
      if (file.status === "error") {
        return;
      }
      this.uploading = false;
      this.uploadButtonVisible = true;
      this.cancelButtonVisible = true;
    },

    uploadFiles() {
      this.uploading = true;
      this.$refs.dropzone.processQueue();
    },

    onSuccess(file) {
      const { path } = file.uploadRequest;
      this.uploadedImagePath = path;
      this.$emit("uploaded", path);
      this.$refs.feedback.handleSuccess(this.$t("imageSavedSuccessfully"));
    },

    onUploadError(file, message) {
      this.$refs.feedback.handleError(message);
      this.$refs.dropzone.removeFile(file);
      this.uploadButtonVisible = false;
      this.cancelButtonVisible = false;
      this.uploading = false;
    },

    onComplete() {
      this.uploading = false;
      this.uploadButtonVisible = false;
    },

    async cancelClicked() {
      if (this.uploadedImagePath) {
        try {
          await this.axios.delete(`api/images/${this.entityType}?path=${this.uploadedImagePath}`);
        } catch (error) {
          this.$refs.feedback.handleError(error.response?.data);
        }
      }

      this.$emit("uploaded", null);
      this.uploadedImagePath = null;
      this.$refs.dropzone.removeAllFiles();
      this.uploadButtonVisible = false;
      this.cancelButtonVisible = false;
      this.uploading = false;
      this.uploadingMode = false;
    },
  },
  components: {
    "vue-dropzone": vue2Dropzone,
  },
};
</script>

<style>
#dropzone {
  text-align: center;
  border: none;
}
#dropzone-container {
  position: relative;
  text-align: center;
  border: 2px dashed #ccc; /* optional dashed border */
  border-radius: 8px;
  padding: 20px;
  /* etc. */
}

#dropzone-container .v-btn {
  margin-top: 12px; 
}

</style>
