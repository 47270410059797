export default {
    newProductDialog: 'Novo produto',
    editProductDialog: 'Editar produto',
    ageRestricted: 'Proibido para menores',
    conversionUnit: 'Unidade de conversão',
    codeProductDialog: 'Código',
    descriptionProductDialog: 'Descrição',
    categoryProductDialog: 'Categoria',
    defaultPriceProductDialog: "Preço padrão ({sign})",
    barCodeProductDialog: 'Código de barras',
    ncmProductDialog: 'NCM',
    extipiProductDialog: 'EX-TIPI',
    cestProductDialog: 'CEST',
    originProductDialog: 'Origem',
    purchaseSettings: 'Configurações de Compras',
    addTaxGroupProductDialog: 'Adicionar Cenário Fiscal',
    saveProductDialog: 'Salvar',
    closeProductDialog: 'Fechar',
    backToPlanogram: 'Voltar planograma',
    fiscalInformation: 'Informações Fiscais',
    fiscalScenariosProductDialog: 'Cenários Fiscais',
    productsMustBeAddedByErp: 'Os produtos devem ser adicionados pelo ERP',
    noPermissionToAddProduct: 'Sem permissão para adicionar produto',
    noFiscalScenariosRegistered: 'Não há cenários fiscais cadastrados',
    errorSavingItemProductDialog: 'Erro ao salvar item',
    fiscalScenarioProductDialog: 'Cenário Fiscal',
    principalProductDialog: 'Principal',
    deleteProductDialog: 'Excluir',
    fiscalScenarioAddedSuccessfully: 'Cenário Fiscal adicionado com sucesso',
    unitProductDialog: 'Unidade',
    quantityPerProductDialog: 'Quantidade por ',
    informationUpdatedSuccessfully: 'Informações atualizadas com sucesso',
    synchronizedWithErp: 'Este produto está sincronizado com o ERP. Alguns campos só podem ser alterados pelo ERP.',
    noTaxScenarios: 'Não há cenários fiscais para o produto',
    uniMustBeA: 'A quantidade por "unidade" deve ser 1',
    saveItemErrorProductDialog: 'Erro ao salvar produto',
    getTaxGroupsCfop: ' - CFOP: ',
  };
