export default {
    removePickListsPickListTable: 'Remover Pick Lists',
    selectAtLeastOnePickList: 'Selecione pelo menos uma Pick List',
    excelPickListTable: 'Excel',
    synchronizationWithManagementSystem: 'Sincronizar pick-lists com o sistema de gestão',
    noResultsPickListTable: 'Sem resultados',
    idPickListTable: 'ID Pick List',
    description: 'Descrição',
    primaryPickListTable: 'Principal',
    customerPickListTable: 'Cliente',
    locationPickListTable: 'Local',
    specificLocationPickListTable: 'Local Específico',
    pointOfSalePickListTable: 'PDV',
    routePickListTable: 'Rota',
    machinePickListTable: 'Máquina',
    startedByPickListTable: 'Iniciada por',
    updatedActionPickListTable: 'Atualizada em',
    syncWithOmiePickListTable: 'Sincronização com sistema de gestão Omie',
    erpManagementSystem: 'ERP',
    removeConfirmationPickListTable: 'Tem certeza que deseja remover',
    pickListPickListTable: ' pick ',
    noPickListTable: 'Não',
    removePickListTable: 'Remover',
    successRemovePickListTable: 'Pick List ',
    removeSuccessPickListTable: ' removida',
    errorRemovePickListTable: 'Não foi possível remover a Pick List ',
    synchronizationStarted: 'A pick-list',
    synchronizationStartedPickListTable: ' está sendo sincronizada com o Omie e em alguns instantes estará disponível.',
    syncErrorPickListTable: 'Não foi possível sincronizar a pick-list',
    generateReportErrorPickListTable: 'Não foi possível gerar o relatório',
    createdSuccessPickListTable: ' criada para o PDV ',
    createdErrorPickListTable: 'Não foi possível criar a Pick List para o PDV ',
    syncSuccessPickListTable: 'As pick-lists estão sendo sincronizadas com o ERP AMLabs.',
    syncErrorPickList: 'Não foi possível sincronizar as pick-lists.',
    editPickListTable: '(editada)',
    synchronizationPickListTable: 'Sincronização',
    releasedPicklistSuccessPickListTable: 'A Pick List foi liberada com sucesso.',
    releasedPicklistErrorPickListTable: 'Não foi possível liberar a Pick List.',
    releasePickListDialogTitle: 'Liberar a Pick List ?',
    releasePickListDialogMessage: 'Ao confirmar esta ação, a Pick List será liberada e outro operador poderá realizá-la.',
};
