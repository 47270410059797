import {ref} from "vue";

export type Catalog = {
  description: string;
  tenantId: number;
  items: CatalogItem[];
}

export type CatalogItem = {
  id: number;
  name: string;
  description: string;
  isFavorite: boolean;
  technicalInformation: string;
  imageUrl: string;
  hasNeedOfPaymentProcessor: boolean | null;
}

export interface OrderedCatalogItem extends CatalogItem {
  selectedQuantity: number;
}

export type HasUpdateDto ={
  hasUpdate: boolean;
}

export const catalogItemInitialState = ref<CatalogItem>({
  id: 0,
  name: '',
  description: '',
  isFavorite: false,
  technicalInformation: '',
  imageUrl: '',
  hasNeedOfPaymentProcessor: null
});

export type EnvironmentInformation = {
  contractId: number;
  billingDocumentId: string | null;
  customerInfo: CustomerInfo;
}

export const environmentInfoInitialState = ref<EnvironmentInformation>({
  contractId: 0,
  billingDocumentId: null,
  customerInfo: {
    city: null,
    cep: null,
    address: null,
    addressNumber: null,
    brazilState: null,
    phone: null,
    fantasyName: null,
    email: null
  },
});

export type CustomerInfo = {
  city: string | null;
  cep: string | null;
  address: string | null;
  addressNumber: number | null;
  addressComplement?: string | null;
  brazilState: string | null;
  phone: string | null;
  fantasyName: string | null;
  email: string | null;
}

export type CustomerInfoForProfileDto = {
  email: string | null;
  phone: string | null;
  dateOfBirth: Date | null;
  addressTypes: number[] | null;
  needsToUpdateInfo: boolean;
}

export enum AddressTypes {
  Street = 0,
  Condominium = 1,
  Company = 2,
  Other = 3
}

export type AcquisitionOrderRequest = {
  orderItems: AcquisitionOrderItemRequest[];
  tenantId: number;
  groupId?: number;
  documentId?: string | null;
  fantasyName: string | null;
  email: string | null;
  phoneNumber: string | null;
  deliveryAddress: DeliveryAddress | null;
  paymentProcessorId: number | null;
  isPaymentProcessorAccountNewlyCreated: boolean | null;
}

export const acquisitionOrderRequestInitialState = ref<AcquisitionOrderRequest>({
  orderItems: [],
  tenantId: 0,
  groupId: 0,
  fantasyName: null,
  email: null,
  phoneNumber: null,
  deliveryAddress: null,
  paymentProcessorId: 0,
  isPaymentProcessorAccountNewlyCreated: false
});

export type ValidateDocumentIdDto = {
  documentId: string;
  status: CnpjStatus;
  ieIsValid: boolean;
}

export type DeliveryAddress = {
  city: string | null;
  cep: string | null;
  address: string | null;
  addressNumber: number | null;
  addressComplement: string | null;
  brazilState: string | null;
}

export type AcquisitionOrderItemRequest = {
  quantity: number;
  catalogItemId: number;
}

export type PaymentProcessor = {
  id: number | null;
  email: string | null;
  activationCode: string | null;
  apiToken: string | null;
  processorCompany: PaymentProcessorCompany | null,
  isNewlyCreatedAccount: boolean | null;
}

export enum PaymentProcessorCompany {
  PagSeguro,
  Rede,
  Stone,
}

export const paymentProcessorInitialState = ref<PaymentProcessor>({ id: 0, email: null, activationCode: null, apiToken: null, processorCompany: null, isNewlyCreatedAccount: null });

export type CreatePaymentProcessorAccountDto = {
  name: string | null;
  companyDocumentId?: string | null;
  personalDocumentId: string | null;
  dateOfBirth?: Date | null;
  email: string | null;
  foreseenMonthlyRevenue?: number | null;
  netWorth?: number | null;
  monthlyIncome?: number | null;
  politicallyExposedPerson?: boolean | null;
  personalDocumentImageUrl?: string | null;
  companyDocumentImageUrl?: string | null;
  processorCompany?: PaymentProcessorCompany | null;
}

export const createPaymentProcessorAccountDtoInitialState = ref<CreatePaymentProcessorAccountDto>({
  name: null, companyDocumentId: null, personalDocumentId: null, dateOfBirth: null, email: null, foreseenMonthlyRevenue: null, netWorth: null,
  monthlyIncome: null, politicallyExposedPerson: null, personalDocumentImageUrl: null, companyDocumentImageUrl: null, processorCompany: null});

export enum CnpjStatus {
  Active,
  Suspended,
  Unqualified,
  Down,
  Null,
}

export enum NetWorth {
  UpTo50k = 0,
  From50kTo200k = 1,
  From200kTo500k = 2,
  Above500k = 3,
}

export enum MonthlyIncome {
  From1kTo5k = 0,
  From5kTo10k = 1,
  Above10k = 2,
}

export const NetWorthLabels: Record<NetWorth, string> = {
  [NetWorth.UpTo50k]: "De R$ 0 até R$ 50 mil",
  [NetWorth.From50kTo200k]: "De R$ 50 mil até R$ 200 mil",
  [NetWorth.From200kTo500k]: "De R$ 200 mil até R$ 500 mil",
  [NetWorth.Above500k]: "Acima de R$ 500 mil",
};

export const MonthlyIncomeLabels: Record<MonthlyIncome, string> = {
  [MonthlyIncome.From1kTo5k]: "De R$ 1 mil até R$ 5 mil",
  [MonthlyIncome.From5kTo10k]: "De R$ 5 mil até R$ 10 mil",
  [MonthlyIncome.Above10k]: "Acima de R$ 10 mil",
};
