<template>
  <v-row>

    <portal to="toolbarTitle"><users-feedbacks-help></users-feedbacks-help></portal>
    
    <v-col cols="12">
      <users-feedbacks-filters :activeTab="activeTab" @modify="updateForm"></users-feedbacks-filters>
      <v-tabs
        v-model="activeTab"
        grow
        background-color="#009B92 lighten-4"
        active-class="custom-active-tab"
        class="custom-tabs"
      >
        <v-tab :key="0">{{$t('usersFeedback.pointOfSale')}}</v-tab>
        <v-tab :key="1">{{$t('usersFeedback.application')}}</v-tab>
        <v-tab-item :key="0">
          <users-feedbacks-pos :form="form"></users-feedbacks-pos>          
        </v-tab-item>
        <v-tab-item :key="1">
          <users-feedbacks-app :form="form"></users-feedbacks-app>          
        </v-tab-item>
      </v-tabs>      
    </v-col>
  </v-row>
</template>

<script>
import UsersFeedbacksApp from './UsersFeedbacksApp.vue';
import UsersFeedbacksPos from './UsersFeedbacksPos.vue';
import UsersFeedbacksFilters from './UsersFeedbacksFilters.vue';
import UsersFeedbacksHelp from './UsersFeedbacksHelp.vue';

export default {
  data() {
    return {
      activeTab: 0,
      form: {},
      };
  },
  components: {
    UsersFeedbacksApp,
    UsersFeedbacksPos,
    UsersFeedbacksFilters,
    UsersFeedbacksHelp,
  },
  computed: {
    routeParams() {
      return Object.assign({}, this.$route.query);
    }
  },
  methods: {
    updateForm(newForm) {
      this.form = { ...newForm };
    },
  },
};
</script>

<style scoped>
.custom-active-tab {
  color: #009B92;
}
::v-deep .custom-tabs .v-tabs-bar .v-tabs-slider {
  background-color: #009B92;
}
</style>

