<template>
  <v-dialog v-model="dialog" width="unset" @keydown.esc="dialog = false">
    <v-card v-if="pickList != null">
      <v-card-title class="blue-grey lighten-5">
        <div v-if="!$vuetify.breakpoint.smAndDown">
          <div class="title">{{pickList.pointOfSale.customerName}}</div>
          <div class="info-subtitle">
            {{pickList.pointOfSale.localName}} / {{pickList.pointOfSale.specificLocation}}<br>
            {{pickList.pointOfSale.machineModelName}} / {{pickList.pointOfSale.machineManufacturerName}}
          </div>
        </div>
        <div class="ml-2">
          <v-btn text small outlined :disabled="selected.length == 0" @click.native="emptySelected">
            {{ $t('resetSelectedPickListDetails') }}
          </v-btn>
          <v-switch class="mt-2" hide-details v-model="showItemsWithZero" :label="$t('showZeroPickListDetails')" />
          <v-switch v-if="hasInventory" class="mt-2" hide-details v-model="showItemsWithoutEnoughQuantity" label="Apenas itens sem estoque suficiente" />
        </div>
        <div class="ml-2 my-2">
          <v-text-field
            class="pt-0"
            v-model="search.text"
            append-icon="search"
            :label="$t('searchProductPickListDetails')"
            single-line
            hide-details
            clearable
            filled
            rounded
            dense
            @click:clear="search.text = ''"
            @click:append="search"
          ></v-text-field>
        </div>
        <v-spacer></v-spacer>
        <div
          class="my-4 ml-2 mr-5 d-flex flex-column align-center justify-center"
        >
          <v-row justify-center>
            <v-btn
              v-if="pickList.status === 'Pending' && pickList.parentPickListId === null"
              color="success"
              @click="openDialogConfirmation"
            >
              {{ $t('picklistSplitButton') }}
            </v-btn>
          </v-row>
          <v-row class="mt-5">
            <v-btn
              color="error"
              text
              @click="clearColumnFilters(null)"
            >
            <v-icon left>close</v-icon>Limpar filtros de colunas
            </v-btn>
          </v-row>
        </div>
        <div class="text-right">
          <div class="info-subtitle">
            <span v-if="$vuetify.breakpoint.smAndDown">
              <strong>{{ pickList.pointOfSale.customerName }}</strong><br>
              {{pickList.pointOfSale.localName}} / {{pickList.pointOfSale.specificLocation}}
            </span><br>
              {{ $t('pickListInfoPickListDetails') }} {{pickList.id}} -
            <span :class="getStatusClass(pickList.status)">{{translateStatus(pickList.status)}}</span><br>
              {{ $t('pickListInfoPosDetails') }} {{pickList.pointOfSale.id}} / {{ $t('pickListInfoPlanogramDetails') }} {{pickList.planogramId}}<br>
              {{ $t('pickListInfoCreatedDetails') }} {{formatDate(pickList.dateCreated)}}<br>
              {{ $t('pickListInfoUpdatedDetails') }} {{formatDate(pickList.dateUpdated)}}<br>
            <span v-if="pickList.parentPickListId">
              {{ $t('pickListInfoParentDetails') }} {{ pickList.parentPickListId }}
            </span>
          </div>
        </div>
        <v-btn icon class="ml-2" @click.native="dialog = false"><v-icon>close</v-icon></v-btn>
      </v-card-title>
      <v-divider></v-divider>

        <v-data-table
          v-model="selected"
          item-key="productId"
          :headers="filteredHeaders"
          :items="items"
          :loading="loading.table"
          show-select
          hide-default-footer
          disable-pagination
          :sort-by="sort.values"
          :sort-desc="sort.desc"
          :multi-sort="true"
          @click:row="(_, props) => props.select(!props.isSelected)"
          :search="search.text"
          :item-class="itemRowBackground"
          fixed-header
          :height="fixedHeaderHeight"
        >

        <template v-slot:[`header.availableInventory`]="{ header }">
          <v-btn icon small @click="openFilterDialog(header.text)">
            <v-icon :color="getFilterColor(header.value)">mdi-filter-variant</v-icon>
          </v-btn>
          <span>{{ header.text }}</span>
        </template>

        <template v-slot:[`header.productInventoryQuantity`]="{ header }">
          <v-btn icon small @click="openFilterDialog(header.text)">
            <v-icon :color="getFilterColor(header.value)">mdi-filter-variant</v-icon>
          </v-btn>
          <span>{{ header.text }}</span>
        </template>

        <template #item.availableInventory="{ item }">
          {{ item.availableInventory ?? 0 }}
        </template>

        <template #item.productInventoryQuantity="{ item }">
          {{ item.productInventoryQuantity ?? 0 }}
        </template>


          <template #item.productInventoryReservedQuantity="{ item }">
          {{ ((+item.picklistsQuantityReserved) + (+item.quantity)) ?? '-' }}
        </template>

          <template v-slot:[`header.quantity`]="{ header }">
          <v-btn icon small @click="openFilterDialog(header.text)">
            <v-icon :color="getFilterColor(header.value)">mdi-filter-variant</v-icon>
          </v-btn>
          <span>{{ header.text }}</span>
        </template>

        <template v-slot:[`header.planogramEntry.currentQuantity`]="{ header }">
          <v-btn icon small @click="openFilterDialog(header.text)">
            <v-icon :color="getFilterColor(header.value)">mdi-filter-variant</v-icon>
          </v-btn>
          <span>{{ header.text }}</span>
        </template>

        <template v-slot:[`header.count`]="{ header }">
          <v-btn icon small @click="openFilterDialog(header.text)">
            <v-icon :color="getFilterColor(header.value)">mdi-filter-variant</v-icon>
          </v-btn>
          <span>{{ header.text }}</span>
        </template>

          <template #item.quantity="{ item }">
            <v-edit-dialog
              v-if="allowEdit"
              large
              :cancel-text="$t('cancelPickListDetails')"
              :save-text="$t('toSavePickListDetails')"
              @open="openQuantityText(item)"
              @save="saveQuantityText(item)"
              @cancel="cancelQuantityText"
            >{{item.quantity.toString() + (item.isEdited ? '*' : '')}}
              <v-icon class="pb-1 pl-1">edit</v-icon>

              <template #input>
                <v-text-field
                  v-model="editedQuantity"
                  :label="$t('quantityPickListDetails')"
                  :rules="[rules.integer]"
                  single-line
                ></v-text-field>
              </template>

            </v-edit-dialog>
            <template v-else>
              {{item.quantity}}
            </template>
          </template>

        </v-data-table>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click.native="dialog = false">{{ $t('closePickListDetails') }}</v-btn>
        </v-card-actions>

    </v-card>

    <v-dialog v-model="filterDialog" max-width="600px" @keydown.esc="filterDialog = false" @keydown.enter="applyFilters">
      <v-card>
        <v-card-title>Filtro: {{ currentFilterColumn }}</v-card-title>
        <v-card-text>

          <v-row>
            <v-col cols="12" sm="8">
              <v-select
                v-model="selectedCategory"
                :items="filterOptions"
                clearable
                placeholder="Selecione uma opção. Ex: Maior ou igual a..."
              />
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                :label="currentFilterColumn"
                v-model="selectedValue"
                type="number"
              />
            </v-col>

          </v-row>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <ClearButton @clear="clearColumnFilters(currentFilterColumn)" />
          <v-btn color="primary" text @click="applyFilters">Aplicar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <feedback ref="feedback"></feedback>
    <PickListSplitDialog
      ref="dialogConfirmation"
      v-model="dialogConfirmation"
      @confirm="splitPickList"
    />
  </v-dialog>
</template>

<script>
import moment from 'moment';
import agent from '@/api/agent';
import { SUPPORT_ROLE } from '@/constants/Roles';
import ClearButton from '@/components/buttons/ClearButton.vue';
import PickListSplitDialog from "@/views/picklist/PickListSplitDialog.vue";

export default {
  props: {
    value: { type: Boolean, required: true },
    id: { type: Number, required: true },
    allowEdit: { type: Boolean, default: false },
    showCurrentData: { type: Boolean, default: false }
  },
  components: {
    PickListSplitDialog,
    ClearButton
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    hasInventory() {
      return ((this.pickList||{}).items||[]).some((i) => i.availableInventory != null);
    },
    filteredHeaders() {
      return this.showCurrentData ?
        this.headers.filter((h) => (h.value !== 'availableInventory' && h.value !== 'productInventoryReservedQuantity' && h.value !== 'productInventoryQuantity') || this.hasInventory):
        this.headers.filter((h) => !h.value.startsWith('planogramEntry') && h.value !== 'availableInventory' && h.value !== 'productInventoryReservedQuantity' && h.value !== 'productInventoryQuantity');
    },
    fixedHeaderHeight() {
      const headerHeight = 48;
      const rowHeight = 48;
      const minHeight = 100;
      const mobileHeight = 300;
      const rows = this.items.length;

      if (rows === 0) {
        return minHeight;
      }
      if(this.$vuetify.breakpoint.smAndDown) {
        return mobileHeight;
      }

      return Math.min(
        headerHeight + rowHeight * rows,
        window.innerHeight - 300
      )
    },
    userIsSupport() { // Used for unreleased features
      return this.$auth.check(SUPPORT_ROLE);
    },
    selectedCategory: {
      get() {
        const filter = this.customFilters.find(i => i.text === this.currentFilterColumn);
        return filter ? filter.selectedCategory : null;
      },
      set(value) {
        const filter = this.customFilters.find(i => i.text === this.currentFilterColumn);
        if (filter) {
          filter.selectedCategory = value;
        }
      }
    },
    selectedValue: {
      get() {
        const filter = this.customFilters.find(i => i.text === this.currentFilterColumn);
        return filter ? filter.value : null;
      },
      set(value) {
        const filter = this.customFilters.find(i => i.text === this.currentFilterColumn);
        if (filter) {
          filter.value = value;
        }
      }
    }
  },
  watch: {
    dialog: function(enabled) {
      if (enabled) {
        this.getItems();
      }
      else {
        this.items = [];
      }
    },
    showItemsWithZero: function() {
      this.applyFilters();
    },
    showItemsWithoutEnoughQuantity: function() {
      this.applyFilters();
    }
  },
  data() {
    return {
      pickList: null,
      items: [],
      selected: [],
      showItemsWithZero: false,
      showItemsWithoutEnoughQuantity: false,
      headers: [
        { text: this.$t('tables.headers.productCode'), align: 'left', sortable: true, value: 'productCode' },
        { text: this.$t('tables.headers.description'), align: 'left', sortable: true, value: 'productDescription' },
        { text: this.$t('tables.headers.barCode'), align: 'left', sortable: true, value: 'productBarCode' },
        { text: this.$t('tables.headers.category'), align: 'left', sortable: true, value: 'productCategoryName' },
        { text: this.$t('tables.headers.quantity'), align: 'left', sortable: true, value: 'quantity' },
        { text: this.$t('currentQuantityPickListDetails'), align: 'left', sortable: true, value: 'planogramEntry.currentQuantity' },
        { text: this.$t('desiredQuantityPickListDetails'), align: 'left', sortable: true, value: 'planogramEntry.quantityToSupply' },
        { text: this.$t('criticalMinimumPickListDetails'), align: 'left', sortable: true, value: 'planogramEntry.minimumQuantity' },
        { text: this.$t('salesThisWeekPickListDetails'), align: 'left', sortable: true, value: 'count' },
        { text: this.$t('tables.headers.qtyInStock'), align: 'left', sortable: true, value: 'availableInventory' },
        { text: this.$t('tables.headers.qtyTotalReserved'), align: 'left', sortable: true, value: 'productInventoryReservedQuantity' },
        { text: this.$t('tables.headers.qtyAvailableForPickList'), align: 'left', sortable: true, value: 'productInventoryQuantity'},
      ],
      loading: {
        table: true,
      },
      editedQuantity: 0,
      rules :{
        integer: (v) => /^\d+$/.test(v) || this.$t('integerRulesPositive')
      },
      search: {
        text: '',
        valid: true
      },
      sort: {
        values: ['quantity', 'productCategoryName'],
        desc: [true, false]
      },
      filterDialog: false,
      currentFilterColumn: '',
      customFilters: [
        { text: this.$t('tables.headers.qtyAvailableForPickList'), column: 'productInventoryQuantity', value: 0, selectedCategory: null },
        { text: this.$t('centralStockQuantity'), column: 'availableInventory', value: 0, selectedCategory: null },
        { text: this.$t('quantityPickListDetails'), column: 'quantity', value: 0, selectedCategory: null },
        { text: this.$t('currentQuantityPickListDetails'), column: 'planogramEntry.currentQuantity', value: 0, selectedCategory: null },
        { text: this.$t('salesThisWeekPickListDetails'), column: 'count', value: 0, selectedCategory: null },
      ],
      filterOptions: ['Maior ou igual a', 'Menor ou igual a', 'Igual a'],
      dialogConfirmation: false
    }
  },
  methods: {
    getItems() {
      if (this.id == null || this.id === 0) return;
      this.loading.table = true;
      this.axios.get(`api/PickLists/${this.id}`)
        .then((response) => {
          this.loading.table = false;
          this.pickList = response.data;
          this.applyFilters();
          this.pickList.items.forEach(item => {
            item.picklistsQuantityReserved = item.productInventoryReservedQuantity ? (+item.productInventoryReservedQuantity || 0) - (+item.quantity || 0) : 0;
          })
        })
    },
    openQuantityText(item) {
      this.editedQuantity = item.quantity;
    },
    saveQuantityText(item) {
      const quantityToSave = this.editedQuantity;
      if (typeof this.rules.integer(quantityToSave) === 'string') {
        this.editedQuantity = 0;
        this.$refs.feedback.handleError(this.$t('quantityNotChangedPickListDetails'));
        return;
      }
      this.loading.table = true;
      this.axios.put(`api/PickLists/${this.id}/Quantity/${quantityToSave}`, [item.productId])
        .then(() => {
          item.quantity = quantityToSave;
          item.isEdited = true;
        }).catch((error) => {
          this.$refs.feedback.handleError(this.$t('changeErrorPickListDetails'), error);
        }).then(() => {
          this.loading.table = false;
          this.editedQuantity = 0;
        })
    },
    emptySelected() {
      const itemsToEmpty = this.selected.map(i => i.productId);
      this.loading.table = true;
      this.axios.put(`api/PickLists/${this.id}/Quantity/0`, itemsToEmpty)
        .then(() => {
          this.selected = [];
          this.getItems();
        }).catch((error) => {
          this.$refs.feedback.handleError(this.$t('emptySelectedError'), error);
          this.loading.table = false;
        })
    },
    cancelQuantityText() {
      this.editedQuantity = 0;
    },
    translateStatus(status) {
      if (status === 'Pending') return this.$t('pendingPickListDetails');
      if (status === 'Used') return this.$t('usedPickListDetails');
    },
    getStatusClass(status) {
      if (status === 'Pending') return 'orange--text text--darken-2';
      if (status === 'Used') return 'green--text text--darken-2';
    },
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY HH:mm:ss');
    },
    itemRowBackground: function (item) {
     if (this.hasInventory && item.productInventoryQuantity < item.quantity) return 'red lighten-4'
    },
    openDialogConfirmation() {
      this.dialogConfirmation = true;
    },
    splitPickList(){
      agent.PickLists.splitPickList(this.pickList.id, { option: "InventoryAddress" })
        .then((response) => {
          this.$emit('splited', response);
          this.dialogConfirmation = false;
          this.dialog = false;
        }).catch((error) => {
          this.$refs.feedback.handleError(this.$t('picklistSplitError'), error);
        })
    },
    openFilterDialog(headerName) {
      this.currentFilterColumn = headerName;
      this.filterDialog = true;
    },
    applyFilters() {
      let filteredItems = this.pickList.items;

      if (!this.showItemsWithZero) {
        filteredItems = filteredItems.filter(i => i.quantity > 0);
      }

      if (this.showItemsWithoutEnoughQuantity) {
        filteredItems = filteredItems.filter(i => i.availableInventory < i.quantity);
      }

      let columnsToFilter = this.customFilters.filter(i => i.selectedCategory);
      columnsToFilter.forEach((filter) => {
        switch (filter.selectedCategory) {
          case 'Maior ou igual a':
            filteredItems = filteredItems.filter(i => this.getNestedProperty(i, filter.column) >= filter.value);
            break;
          case 'Menor ou igual a':
            filteredItems = filteredItems.filter(i => this.getNestedProperty(i, filter.column) <= filter.value);
            break;
          case 'Igual a':
            filteredItems = filteredItems.filter(i => this.getNestedProperty(i, filter.column) == filter.value);
            break;
        }
      });

      this.items = filteredItems;
      this.filterDialog = false;
    },
    clearColumnFilters(columnFilter) {
      this.customFilters
        .forEach((filter) => {
          if (columnFilter && filter.text !== columnFilter)
            return;

          filter.selectedCategory = null;
          filter.value = 0;
      });

      this.applyFilters();
    },
    getNestedProperty(obj, path) {
      return path.split('.').reduce((acc, part) => acc && acc[part], obj);
    },
    getFilterColor(value) {
      let columnToFilter = this.customFilters.filter(i => i.column === value)[0];
      if (columnToFilter.selectedCategory != null) {
        return 'red';
      }
      return 'black';
    }
  }
}
</script>
