export default {
    usersFeedback: {
      title: 'Evaluaciones',
    help: {
        description: `<h3>¡Descubre lo que tus clientes piensan de tu(s) tienda(s)!</h3>
          <p>
            Recibe comentarios directamente de tus clientes y mejora la experiencia de compra en tus tiendas. 
            Después de completar una compra, los clientes son invitados a dejar una reseña, ayudándote a comprender sus opiniones sobre tu establecimiento. 
          </p>
          <p>Las reseñas se categorizan en dos secciones:</p>
          <ul>
            <li><strong>Vía Terminal:</strong> Disponible para todos los clientes, permitiendo reseñas rápidas e inmediatas.</li>
            <li><strong>Vía Aplicación:</strong> Exclusivo para clientes con la versión White Label de nuestra aplicación. Si tienes alguna duda, ponte en contacto con nuestro equipo comercial.</li>
          </ul>
          <p></p>
          <p>
            Utiliza estas reseñas para comprender mejor las preferencias de tus clientes e identificar áreas que necesitan mejoras. 
            Presta siempre atención a las reseñas recibidas para garantizar que tus tiendas ofrezcan la mejor experiencia posible a tus clientes.
          </p>
          `,
    },
      application: 'Aplicación',
      pointOfSale: 'Punto de Venta',
    },
  };