export default {
    pickListPointOfSaleNameColumn: 'Point of Sale',
    pickListIdColumn: 'Pick List ID',
    pickListDateCreateColumn: 'Creation Date',
    pickListDateUpdateColumn: 'Update Date',
    pickListStatusColumn: 'Status',
    pickListPlanogramIdColumn: 'Planogram ID',
    pickListMachineModelColumn: 'Machine Model',
    pickListManufactureMachineColumn: 'Machine Manufacturer',

    productIdColumn: 'Product ID',
    productDescriptionColumn: 'Description',
    productQuantityColumn: 'Quantity',
    productBarCodeColumn: 'Bar Code',
    productCodeColumn: 'Product Code',

    productCategoryNameColumn: 'Category',
    productCurrentQuantityColumn: 'Current Quantity',
    productQuantityToSupplyColumn: 'Desired Quantity',
    productMinimumQuantityColumn: 'Critical Minimum',
    distributionCenterAddressColumn: 'Stock Address',

    PickLisTDialogTitle: 'Which columns would you like to include in your Excel?',
    PickListDialogSubTitle: 'Customize the pick list with the columns that should appear',
    PickListDialogFixedColumns: 'Fixed Columns of the Pick list',
    PickListDialogOptionalColumns: 'Optional Columns of the Pick list',
    PickListDialogItemsFixedColumns: 'Fixed Columns of Items',
    PickListDialogItemsOptionalColumns: 'Optional Columns of Items',
    PickListDialogExcelButton: 'Download',
    PickListDialogCloseButton: 'Close',
};
