import { defineStore } from 'pinia';
import { ref } from 'vue';
import agent from '@/api/agent';

export const useDataStore = defineStore('data', () => {
  const hasUpdate = ref(false);
  const hasUpdateAcquisitionOrder = ref(false);
  const loading = ref(false);
  const needsToUpdateInfo = ref(false)

  // Check pending status
  async function verifyNotification() {
    loading.value = true;
    await agent.AcquisitionOrders.hasUpdate().then((response) => {
      hasUpdateAcquisitionOrder.value = response.hasUpdate;
    }).catch((error) => {
      console.error('Error fetching acquisition orders:', error);
    });
    try {
      const response = await agent.ChangeOwnershipRequest.hasPendingStatus();
      hasUpdate.value = Boolean(response);

    } catch (error) {
      console.error('Erro ao buscar pedidos pendentes:', error);
    } finally {
      loading.value = false;
    }
  }
  async function fetchCustomerInfo(tenantId: any, groupId: any)  {
    await agent.CustomerInfo.get(tenantId, groupId)
      .then((customerInfo) => {
        needsToUpdateInfo.value = customerInfo.needsToUpdateInfo;
      })
      .catch((error) => {
        needsToUpdateInfo.value = false;
        console.error('Error fetching customer info:', error);
      });
  }

  // Set storage
  function setStorage(update: boolean) {
    hasUpdate.value = update;
  }

  return {
    hasUpdate,
    verifyNotification,
    hasUpdateAcquisitionOrder,
    setStorage,
    fetchCustomerInfo,
    needsToUpdateInfo
  };
});
