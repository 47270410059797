
import {Vue} from 'vue-property-decorator';
import CancelDialogButton from "@/components/dialog/button/CancelDialogButton.vue";
import ConfirmDialogButton from "@/components/dialog/button/ConfirmDialogButton.vue";
import agent from "@/api/agent";

export default Vue.extend({
  components: {ConfirmDialogButton, CancelDialogButton},
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false,
    },
    purchaseId: {
      type: Number,
      required: true
    },
    isLastPurchase: {
      type: Boolean,
      required: true,
      default: false,
    }
  },
  computed: {
    dialog: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      }
    },
    averageCostWarning(): string {
      return this.isLastPurchase
        ? this.$t('purchase.reversal.warning.averageCostChanges').toString()
        : this.$t('purchase.reversal.warning.averageCostDoesNotChange').toString();
    },
  },
  methods: {
    resetData(): void {
      this.dialog = false;
    },
    async reverseTransaction(): Promise<void> {
      this.loading = true;
      await agent.Purchases.delete(this.purchaseId)
        .then(() => {
          this.$emit('success', this.$t('purchase.reversal.success'));
        })
        .catch((error) => {
          this.$emit('error', this.$t('purchase.reversal.error'), error);
        })
        .finally(() => {
          this.loading = false;
          this.resetData();
        });
    }
  },
  data() {
    return {
      loading: false,
    }
  },
})
