export default {
  dictDefaultMessage: "Arraste um arquivo de imagem para cá, cole (Ctrl+V) ou clique para escolher",
  dictFallbackMessage: "Este navegador não é suportado para subir imagens. Tente uma versão recente do Chrome ou Firefox",
  dictFileTooBig: "O arquivo é muito pesado, tente um menor que {{maxFilesize}}MB",
  dictInvalidFileType: "Esse formato de foto não é suportado",
  dictResponseError: "Não foi possível subir a imagem (erro {{statusCode}})",
  dictCancelUpload: "Cancelar upload",
  dictUploadCanceled: "O upload foi cancelado",
  dictCancelUploadConfirmation: "Deseja cancelar o upload?",
  dictRemoveFile: "Remover",
  dictMaxFilesExceeded: "Limite máximo de arquivos atingido",
  dictDefaultMessageXml: `Arraste e solte seu arquivo aqui, ou selecione um arquivo. `,
  dictDefaultMessageXmlSelectFile: `SELECIONAR ARQUIVO`,
  dictInvalidFileTypeXml: "Esse formato de arquivo não é suportado",
  dictResponseErrorXml: "Não foi possível subir a arquivo (erro {{statusCode}})",
  dictUploadedFileXml: "Arquivo carregado!",
  dictErrorXml: "Erro desconhecido",
  dictDoneXml: "Não foi possível autorizar o upload da arquivo:",
};
