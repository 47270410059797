export default {
  validations: {
    minCodeLength: "The code must have {length} characters",
    requiredField: "This field is required",
    insertValidValue: "Insert a valid value",
    chooseAction: "Choose at least one action",
    invalidEIN: "Enter a valid EIN",
    invalidCNPJ: "Enter a valid CNPJ",
    invalidCPF: "Enter a valid CPF",
    invalidNIT: "Enter a valid NIT",
    insertInteger: "Insert an integer",
    invalidEmail: "Enter a valid email",
    invalidPositiveValue: "Enter a positive value",
  }
};