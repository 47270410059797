
import Vue from "vue";
import CloseButton from "@/components/buttons/CloseButton.vue";
import {OperationType} from "@/constants/enums";

export default Vue.extend({
  props: {
    value: { type: Boolean, required: true },
    status: { type: String, required: true },
    totalCount: { type: Number, default: 0 },
    failedCount: { type: Number, default: 0 },
    errorReportUrl: { type: String || null, default: ""},
    operationType: { type: String, default: () => OperationType.Products }
  },
  computed: {
    dialog: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        if (!value) {
          this.$emit("input", value);
        }
      },
    },
    url: {
      get(): URL | null{
        if((this as any).errorReportUrl) {
          return (this as any).errorReportUrl;
        }
        return null;
      },
    },
    operationTypeMapped(): string {
      return this.operationType === OperationType.PriceTableItems
        ? "Itens"
        : "Produtos"
    },
    successFeedbackMessage(): string {
      return `${this.operationTypeMapped} importados com sucesso.`;
    },
    operationResultMessage(): string {
      if (this.operationTypeMapped === 'Itens') {
        return `${this.failedCount}/${this.totalCount} ${this.operationTypeMapped} apresentaram divergências durante a importação.`;
      }
      return `${this.failedCount}/${this.totalCount} ${this.operationTypeMapped} não foram importados.`;
    },
    partialErrorsResultMessage(): string {
      if (this.operationTypeMapped === 'Itens') {
        return `${this.operationTypeMapped} com divergências ${this.failedCount}/${this.totalCount}`;
      }
      return `${this.operationTypeMapped} com erros cadastrais ${this.failedCount}/${this.totalCount}`;
    },
    reportMessage(): string {
      if (this.operationTypeMapped === 'Itens') {
        return 'Ver relatório com divergências';
      }
      return 'Ver relatório de erros';
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
  components: {
    CloseButton,
  },
});
