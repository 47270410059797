
import Vue from 'vue';
import Hls from 'hls.js';
import { CameraHubs } from "@/api/agent";
import { useAlertsStore } from "@/stores/alertsStore";
import { downloadFromUrl } from '@/components/Download';
import {
  CameraHubStreamEvent,
  CameraHubStreamEventStatus,
  CameraHubStreamRequest,
  CameraHubVideoRequest,
  VideoInfo
} from "@/types/CameraHub";

export default Vue.extend({
  props: {
    value: { type: Boolean, required: true, default: false },
  },
  computed: {
    dialog: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean): void {
        (this as any).$emit('input', value);
        if (!value) {
          if (this.hls) {
            this.hls.detachMedia();
            this.hls.destroy();
            this.hls = null;
            console.log("hls destroyed")
            this.loading = false;
            this.message = null;
            this.url = null;
            this.videoInfo = null;
          }
        }
      },
    }
  },
  data() {
    return {
      hls: null as Hls|null,
      url: null as string|null,
      message: null as string|null,
      messageType: 'info' as string,
      loading: true as boolean,
      videoInfo: null as VideoInfo|null,
      videoRequest: null as CameraHubVideoRequest|null,
      alertsStore: useAlertsStore(),
      downloadLoading: false as boolean
    }
  },
  watch: {
    url(newValue: string|null, oldValue: string|null) {
      if (newValue && newValue !== oldValue) {
        console.log("video url updated");
        this.playVideo(newValue);
      }
    }
  },
  methods: {
    playVideo(url: string) {
      if (this.hls == null) {
        this.hls = new Hls();
      }
      let video = this.$refs["video"] as HTMLMediaElement;
      this.hls.loadSource(url);
      this.hls.attachMedia(video);
      this.hls.on(Hls.Events.ERROR, function (event, data) {
        console.error("Error", event, data);
      });
      this.hls.on(Hls.Events.MANIFEST_PARSED, function () {
        console.log("video should play");
        (video as any).play();
      });
    },
    startStreamRequest(request: CameraHubStreamRequest) {
      this.videoInfo = request.videoInfo;
      this.videoRequest = request.videoRequest;
      this.onUrlFetchStarted();
      try {
        request.socket
          .send('stream', request.videoRequest)
          .listen<CameraHubStreamEvent>((response) => {
            console.log("Received response", response);
            switch (response.event) {
              case CameraHubStreamEventStatus.COMPLETED:
                this.onUrlFetchComplete(response.url!);
                break;
              case CameraHubStreamEventStatus.ERROR:
                this.onUrlFetchError("Não foi possível continuar: " + response.message);
                break;
              default:
                this.onUrlFetchUpdate(response.message!);
            }
          });
      } catch(_) {
        this.onUrlFetchError("Não foi possível conectar ao serviço");
      }
    },
    onUrlFetchStarted() {
      this.url = null;
      this.loading = true;
      this.messageType = 'info';
      this.message = "Buscando vídeo";
    },
    onUrlFetchUpdate(message: string) {
      this.message = message;
    },
    onUrlFetchError(message: string) {
      this.loading = false;
      this.messageType = 'error';
      this.message = message;
    },
    onUrlFetchComplete(url: string) {
      this.url = url;
      this.loading = false;
      this.messageType = 'success';
      this.message = "Vídeo disponível";
    },
    download() {
      if (this.videoRequest == null) {
        this.alertsStore.showErrorSnackbar("Sem informações do vídeo para baixar");
        return;
      }
      this.downloadLoading = true;
      CameraHubs.download(this.videoRequest)
        .then((url) => {
          downloadFromUrl(url)
          this.alertsStore.showInfoSnackbar("Download do vídeo pronto para iniciar. " +
            "Caso não inicie, habilite a exibição de popups no seu navegador.");
        }).catch((error) => this.alertsStore.showErrorSnackbar(
          `Não foi possível fazer o download do vídeo: ${error}`))
        .finally(() => this.downloadLoading = false);
    }
  }
});
