
import {Vue} from 'vue-property-decorator';

/**
 * A confirmation button intended to approve an action within a dialog or modal.
 * It offers customization through props for its label, style, disabled state, or text-only rendering.
 * When clicked, it emits a "confirm" event to initiate subsequent actions.
 */
export default Vue.extend({
  name: 'ConfirmDialogButton',
  props: {
    label: {type: String, default: ''},
    kind: {type: String, default: 'default'},
    disabled: {type: Boolean, default: false},
    asTextButton: {type: Boolean, default: false},
    displayTooltip: {type: Boolean, default: true},
    tooltip: {type: String, default: ''},
    submitButton: {type: Boolean, default: false},
    loading: {type: Boolean, default: false},
  },
  computed: {
    buttonLabel(): string {
      return this.label || this.$t('buttons.confirm').toString();
    },
    color(): string {
      switch (this.kind) {
        case 'default':
          return 'primary';
        case 'danger':
          return 'error';
        default:
          return 'primary';
      }
    },
    tooltipText(): string {
      return this.tooltip || this.buttonLabel;
    }
  }
});
