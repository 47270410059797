export default {
    usersFeedback: {
      title: 'Avaliações',
      help: {
        description: `<h3>Descubra o que seus clientes acham de sua(s) loja(s)!</h3>
          <p>
            Receba avaliações direto de seus clientes e aprimore a experiência de compra em suas lojas. 
            Ao finalizar uma compra, os clientes são convidados a deixar uma avaliação, ajudando você a entender o que estão achando do seu estabelecimento. 
          </p>
          <p>As avaliações são categorizadas em duas seções:</p>
          <ul>
            <li><strong>Via Terminal:</strong> Disponível para todos os clientes, permitindo avaliações rápidas e imediatas.</li>
            <li><strong>Via Aplicativo:</strong> Exclusivo para clientes com a versão White Label do nosso aplicativo. Em caso de dúvidas, entre em contato com nosso time comercial.</li>
          </ul>
          <p></p>
          <p>
            Utilize essas avaliações para compreender melhor as preferências dos seus clientes e identificar áreas que necessitam de melhorias. 
            Esteja sempre atento as avaliações recebidas para garantir que suas lojas ofereçam a melhor experiência possível aos seus clientes.
          </p>
          `,
      },
      application: 'Aplicativo',
      pointOfSale: 'Ponto de venda',
    },
  };