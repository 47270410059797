<template>

<div class="d-flex mt-4">
    <v-toolbar-title
      v-text="$route.name">
    </v-toolbar-title>

  <v-row class="align-center">
    <v-tooltip right v-if="pageIsListedInMenus">
      <template #activator="{ on, attrs }">
        <v-btn
          v-on="on"
          v-bind="attrs"
          icon
          color="amber"
          class="ml-4 pt-1"
          @click="handleFavorite($route.path)"
        >
          <v-icon>
            {{ isPageFavorited($route.path) }}
          </v-icon>
        </v-btn>
      </template>
      <span>{{ getTooltipText($route.path) }}</span>
    </v-tooltip>
  </v-row>

    <v-spacer></v-spacer>
      <portal-target
        name="toolbarTitle">
      </portal-target>
    <tenant-feedback></tenant-feedback>

</div>
</template>

<script>
import TenantFeedbackDialog from '@/components/TenantFeedbackDialog.vue';

export default {
  props: {
    listedPages: { type: Array, default: () => {}}
  },
  components: {
    "tenant-feedback": TenantFeedbackDialog
  },
  data() {
    return {
      favoritePagesRoutes: []
    }
  },
  computed: {
    pageIsListedInMenus() {
      return this.listedPages.some(p => p.route == this.$route.path && p.permission == true);
    }
  },
  mounted() {
    this.getFavoritePages();
  },
  methods: {
    isPageFavorited(pageRoute) {
      return this.favoritePagesRoutes.includes(pageRoute) ? 'mdi-star' : 'mdi-star-outline';
    },
    getTooltipText(pageRoute) {
      return this.favoritePagesRoutes.includes(pageRoute) ? this.$t('toRemovefavoritePageMobile') : this.$t('favoritePageMobile');
    },
    getFavoritePages() {
      this.axios.get("api/FavoritePages")
        .then((response) => {
          this.favoritePagesRoutes = response.data
          this.$emit("favoritePagesUpdate", response.data)
        })
        .catch((error) => alert(error))
    },
    handleFavorite(pageRoute) {
      let request = {
        pageRoute: pageRoute,
        requestType: this.favoritePagesRoutes.includes(pageRoute) ? "Remove" : "Add"
      }

      this.axios.put("api/FavoritePages", request)
        .then(() => this.getFavoritePages())
        .catch((error) => alert(error))
    },
  },
}

</script>

<style scoped>
.d-flex {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.align-center {
  align-items: center;
  height: 10px;
}

</style>
