import { render, staticRenderFns } from "./AcquisitionsOrdersApprovalCommentsDialog.vue?vue&type=template&id=6f106f88&scoped=true"
import script from "./AcquisitionsOrdersApprovalCommentsDialog.vue?vue&type=script&lang=js"
export * from "./AcquisitionsOrdersApprovalCommentsDialog.vue?vue&type=script&lang=js"
import style0 from "./AcquisitionsOrdersApprovalCommentsDialog.vue?vue&type=style&index=0&id=6f106f88&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f106f88",
  null
  
)

export default component.exports