<template>
  <v-card class="mb-16">
    <v-card-title>
      <div class="title">{{ showAllPos ? $t('titlePosWithoutPickList') : $t('titleWithoutPickList') }}<slot></slot></div>
      <v-spacer></v-spacer>
      <v-switch class="text-body-1 mr-4" :label="$t('showAllPosPosWithoutPickList')" v-model="showAllPos"></v-switch>
      <v-tooltip bottom open-delay="50" :disabled="selected.length > 0">
        {{ $t('selectAtLeastOnePosWithoutPickList') }}
        <template #activator="{ on: tooltip }">
          <span v-on="tooltip">
            <pick-list-creation :selected-pos="selectedPos" @pick-list-creation="onPickListsCreated">
              <template #activator="{ on: dialog }">
                <v-btn
                  v-on="dialog"
                  color="success"
                  :loading="loading.createButton"
                  :disabled="selected.length === 0"
                >
                  <v-icon left>format_list_bulleted</v-icon>{{ $t('generatePickLists') }}
                </v-btn>
              </template>
            </pick-list-creation>
          </span>
        </template>
      </v-tooltip>
      <div>
        <v-text-field
          v-model="search"
          append-icon="search"
          :label="$t('searchPosWithoutPickList')"
          filled
          rounded
          dense
          single-line
          hide-details
          class="ms-4"
          :no-data-text="$t('noResultsPosWithoutPickList')">
        </v-text-field>
      </div>
    </v-card-title>
    <v-divider></v-divider>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="pos"
      :loading="loading.table"
      :search="search"
      show-select
      hide-default-footer
      disable-pagination
      must-sort
      :no-data-text="$t('noResultsPosWithoutPickList')"
      :item-class="getItemClass"
      @click:row="(item, props) => props.select(!props.isSelected)"
    >
      <template #item.machineModelName="{ item }">
        <td>{{ item.machineModelName }} / {{ item.machineManufacturerName }}</td>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import PickListCreationDialog from '@/views/picklist/PickListCreationDialog';

export default {
  computed: {
    selectedPos() {
      return this.selected.map(pos => pos.id);
    }
  },
  watch: {
    showAllPos() {
      this.getPos();
    }
  },
  data() {
    return {
      pos: [],
      selected: [],
      headers: [{ text: this.$t('idColumnPosWithoutPickList'), align: 'left', sortable: true, value: 'id' },
                { text: this.$t('customerColumnPosWithoutPickList'), align: 'left', sortable: true, value: 'customerName' },
                { text: this.$t('locationColumnPosWithoutPickList'), align: 'left', sortable: true, value: 'localName' },
                { text: this.$t('specificLocationColumnPosWithoutPickList'), align: 'left', sortable: true, value: 'specificLocation' },
                { text: this.$t("routeColumnPosWithoutPickList"), align: 'left', sortable: true, value: 'route' },
                { text: this.$t('machineModelColumnPosWithoutPickList'), align: 'left', sortable: true, value: 'machineModelName' },
                { text: this.$t('codeColumnPosWithoutPickList'), align: 'left', sortable: true, value: 'companyCode' }],
      loading: {
        table: true,
        createButton: false
      },
      showAllPos: false,
      search: ''
    }
  },
  mounted() {
    this.getPos();
  },
  methods: {
    getPos() {
      this.selected = [];
      this.loading.table = true;
      this.axios.get(`api/PickLists/AvailablePointsOfSale?showAllPointsOfSale=${this.showAllPos}`)
        .then((response) => {
          this.loading.table = false;
          this.pos = response.data.filter((p) => p.targetPlanogramId != null);
        });
    },
    onPickListsCreated(data) {
      this.getPos();
      this.$emit('pick-list-creation', data);
    },
    onPickListsDeleted() {
      this.getPos();
    },
    getItemClass(item) {
      if (item.currentPickListId !== null) return "grey--text";
    }
  },
  components: {
    'pick-list-creation': PickListCreationDialog,
  }
}
</script>
