export default {
  products: {
    title: 'Produtos',
    data: {
      purchaseSettings: {
        conversionUnits: {
          title: 'Unidades de Conversão',
          box: 'Caixa(s)',
          unit: 'Unidade(s)',
          package: 'Fardo(s)',
          bag: 'Pacote(s)',
          unknown: 'Desconhecido',
          ratioAndName: '{ratio} por {name}',
        },
      },
      description: 'Descrição do Produto',
      code: 'Código',
      unitValue: 'Valor Unitário',
    },
    register: {
      select: 'Selecione um produto',
      isSelect: 'Produto já selecionado',
      notRegistred: 'Não tem esse produto cadastrado?',
    },
    help: {
      description: `
        <p>Gerencie seus produtos visualizando informações como imagem, ID, Código Interno (SKU), descrição, categoria, preço padrão, Código de Barras (EAN), data de criação e disponibilidade (Público ou Privado). É possível cadastrar, editar, excluir produtos e exportar os dados para Excel.</p>
        <p><strong>Para produtos públicos, apenas a rede/licenciadora poderá editá-los e excluí-los. Você pode utilizá-los e exportar os dados para Excel, mas sem a possibilidade de alteração.</strong></p>

        <p><strong>Categorias:</strong></p>
        <p>Exibe o ID, nome da categoria e a quantidade de produtos. Você pode excluir categorias conforme necessário.</p>

        <p><strong>Agrupamentos:</strong></p>
        <p>Agrupe produtos similares em um único item. Exibe ID, descrição, categoria, preço padrão e data de criação, sem incluir Código de Barras (EAN) ou a opção de exportar para Excel. Ideal para gerenciar variações do mesmo produto, como diferentes sabores ou tamanhos.</p>
      `,
    },
    warning: {
      barCode: {
        title: 'Produto sem código de barras',
        description: `Se optar por cadastrar o produto sem código de barras, ele não será reconhecido por leitores ou
          funções baseadas no código de barras. O cadastro do código é recomendado para evitar problemas e garantir
          o funcionamento do sistema.`
      }
    },
    errors: {
      conversionUnit: {
        update: 'Não foi possível atualizar a unidade de conversão'
      }
    }
  }
}
