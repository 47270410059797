
import Vue from 'vue';
import agent from "@/api/agent";
import CancelButton from "@/components/buttons/CancelButton.vue";
import NextButton from "@/components/buttons/NextButton.vue";
import BackButton from "@/components/buttons/BackButton.vue";
import SaveButton from "@/components/buttons/SaveButton.vue";
import DropzoneOptions from "@/components/DropzoneOptionsExcel";
import LoadExcelProducts from "@/components/LoadFiles.vue";
import FeedbackResults from "@/components/dialog/FeedbackResults.vue";

export default Vue.extend({
  props: {
    value: {
      type: Boolean,
      default: false,
      required: true,
    }
  },
  components: {
    FeedbackResults,
    SaveButton,
    NextButton,
    CancelButton,
    BackButton,
    LoadExcelProducts
  },
  data() {
    return {
      valid: false as Boolean,
      loading: false as Boolean,
      step: 1 as Number,
      fileUploaded: false as Boolean,
      modelImport: new URL("https://touchpay-tenant-data.s3.sa-east-1.amazonaws.com/public/Cadastro+de+produtos-TouchPay.xlsx"),
      dropzoneOptions: DropzoneOptions,
      importProduct: "api/products/bulk/excel-url" as string,
      colors: {
        background: '#03009B'as string,
        text:  "#ffffff" as string
      },
      request: {} as any,
      fileName: "" as string,
      totalProducts: 0 as Number,
      failedProducts: 0 as Number,
      errorReportUrl: "" as string,
      feedbackVisible: false as Boolean,
      feedbackStatus: "" as string,
    }
  },
  computed: {
    dialog: {
      get(): Boolean {
        return this.value;
      },
      set(value: Boolean): void {
        this.step = 1;
        this.$emit("close", value);
      },
    }
  },
  methods:{
    onFileUploaded(success: Boolean) {
      if(success) {
        this.fileUploaded = true;
        this.fileName = (this as any).$refs.file.filename;
      }
    },
    onFileRemove(uploadSuccess: Boolean) {
      if(!uploadSuccess) {
        this.fileUploaded = false;
      }
    },
    importExcel() {
      this.loading = true;
      const nameFile = this.fileName;

      agent.Products.importProducts( { nameFile } )
        .then(response => {
          this.totalProducts = response.totalProducts;
          this.failedProducts = response.totalProducts - response.totalProductsImported;
          this.errorReportUrl = response.excelUrl;
          this.feedbackStatus = this.failedProducts === 0 ? "success" : "error";
          this.feedbackVisible = true;
        }).catch((error) => {
          (this as any).$refs.feedback.handleError("Erro ao importar Excel:", error );
        }).finally(() => {
          this.loading = false;
        });
      this.resetData();
    },
    resetData() {
      this.fileUploaded = false;
    },
    onClosed() {
      this.feedbackVisible = false;
      this.dialog = false;
    }
  }
})
