import Vue from 'vue';
import VueI18n from 'vue-i18n';
import AppLoginEn from './en/AppLoginEn';
import AppLoginEs from './es/AppLoginEs';
import AppLoginPt from './pt/AppLoginPt';
import WebNavigationDrawerEn from './en/WebNavigationDrawerEn';
import WebNavigationDrawerEs from './es/WebNavigationDrawerEs';
import WebNavigationDrawerPt from './pt/WebNavigationDrawerPt';
import AppEn from './en/AppEn';
import AppEs from './es/AppEs';
import AppPt from './pt/AppPt';
import WebAppBarEn from './en/WebAppBarEn';
import WebAppBarEs from './es/WebAppBarEs';
import WebAppBarPt from './pt/WebAppBarPt';
import TenantFeedbackDialogEn from './en/TenantFeedbackDialogEn';
import TenantFeedbackDialogEs from './es/TenantFeedbackDialogEs';
import TenantFeedbackDialogPt from './pt/TenantFeedbackDialogPt';
import TenantFeedbackEn from './en/TenantFeedbackEn';
import TenantFeedbackEs from './es/TenantFeedbackEs';
import TenantFeedbackPt from './pt/TenantFeedbackPt';
import DashboardHelpEn from './en/DashboardHelpEn';
import DashboardHelpEs from './es/DashboardHelpEs';
import DashboardHelpPt from './pt/DashboardHelpPt';
import HelpEn from './en/HelpEn';
import HelpEs from './es/HelpEs';
import HelpPt from './pt/HelpPt';
import MobileNavigationDrawerEn from './en/MobileNavigationDrawerEn';
import MobileNavigationDrawerEs from './es/MobileNavigationDrawerEs';
import MobileNavigationDrawerPt from './pt/MobileNavigationDrawerPt';
import DashboardEn from './en/DashboardEn';
import DashboardEs from './es/DashboardEs';
import DashboardPt from './pt/DashboardPt';
import TotalPurchaseEn from './en/DashboardComponentsEn/TotalPurchaseEn';
import TotalPurchaseEs from './es/DashboardComponentsEs/TotalPurshaseEs';
import TotalPurchasePt from './pt/DashboardComponentsPt/TotalPurchasePt';
import AverageTicketEn from './en/DashboardComponentsEn/AverageTicketEn';
import AverageTicketEs from './es/DashboardComponentsEs/AverageTicketEs';
import AverageTicketPt from './pt/DashboardComponentsPt/AverageTicketPt';
import BarChartEn from './en/BarChartEn';
import BarChartEs from './es/BarChartEs';
import BarChartPt from './pt/BarChartPt';
import PointOfSalesMultipleSelectorEn from './en/SelectoresEn/PointOfSalesMultipleSelectorEn';
import PointOfSalesMultipleSelectorEs from './es/SelectoresEs/PointOfSalesMultipleSelectorEs';
import PointOfSalesMultipleSelectorPt from './pt/SelectoresPt/PointOfSalesMultipleSelectorPt';
import PosMostSalesEn from './en/DashboardComponentsEn/PosMostSalesEn';
import PosMostSalesEs from './es/DashboardComponentsEs/PosMostSalesEs';
import PosMostSalesPt from './pt/DashboardComponentsPt/PosMostSalesPt';
import DateIntervalsEn from './en/SelectoresEn/DateIntervalsEn';
import DateIntervalsEs from './es/SelectoresEs/DateIntervalsEs';
import DateIntervalsPt from './pt/SelectoresPt/DateIntervalsPt';
import LastSupplyEn from './en/DashboardComponentsEn/LastSupplyEn';
import LastSupplyEs from './es/DashboardComponentsEs/LastSupplyEs';
import LastSupplyPt from './pt/DashboardComponentsPt/LastSupplyPt';
import ConnectivityPointOfSaleEn from './en/DashboardComponentsEn/ConnectivityPointOfSaleEn';
import ConnectivityPointOfSaleEs from './es/DashboardComponentsEs/ConnectivityPointOfSaleEs';
import ConnectivityPointOfSalePt from './pt/DashboardComponentsPt/ConnectivityPointOfSalePt';
import ProductMostSoldEn from './en/DashboardComponentsEn/ProductMostSoldEn';
import ProductMostSoldEs from './es/DashboardComponentsEs/ProductMostSoldEs';
import ProductMostSoldPt from './pt/DashboardComponentsPt/ProductMostSoldPt';
import MarketIntelAuthorizationEn from './en/DashboardMarketIntelComponentsEn/MarketIntelAuthorizationEn';
import MarketIntelAuthorizationEs from './es/DashboardMarketIntelComponentsEs/MarketIntelAuthorizationEs';
import MarketIntelAuthorizationPt from './pt/DashboardMarketIntelComponentsPt/MarketIntelAuthorizationPt';
import TopSellingProductsEn from './en/DashboardMarketIntelComponentsEn/TopSellingProductsEn';
import TopSellingProductsEs from './es/DashboardMarketIntelComponentsEs/TopSellingProductEs'
import TopSellingProductsPt from './pt/DashboardMarketIntelComponentsPt/TopSellingProductsPt';
import TopSellingProductsHelpEn from './en/DashboardMarketIntelComponentsEn/TopSellingProductsHelpEn';
import TopSellingProductsHelpEs from './es/DashboardMarketIntelComponentsEs/TopSellingProductsHelpEs';
import TopSellingProductsHelpPt from './pt/DashboardMarketIntelComponentsPt/TopSellingProductsHelpPt';
import InformationEn from './en/InformationEn';
import InformationEs from './es/InformationEs';
import InformationPt from './pt/InformationPt';
import BillingByResidentsHelpEn from './en/DashboardMarketIntelComponentsEn/BillingByResidentsHelpEn';
import BillingByResidentsHelpEs from './es/DashboardMarketIntelComponentsEs/BillingByResidentsHelpEs';
import BillingByResidentsHelpPt from './pt/DashboardMarketIntelComponentsPt/BillingByResidentsHelpPt';
import BillingByResidentsEn from './en/DashboardMarketIntelComponentsEn/BillingByResidentsEn';
import BillingByResidentsEs from './es/DashboardMarketIntelComponentsEs/BillingByResidentsEs';
import BillingByResidentsPt from './pt/DashboardMarketIntelComponentsPt/BillingByResidentsPt';
import StateSelectorFilterEn from './en/SelectoresEn/StateSelectorFilterEn';
import StateSelectorFilterEs from './es/SelectoresEs/StateSelectorFilterEs';
import StateSelectorFilterPt from './pt/SelectoresPt/StateSelectorFilterPt';
import MarketAverageTicketHelpEn from './en/DashboardMarketIntelComponentsEn/MarketAverageTicketHelpEn';
import MarketAverageTicketHelpEs from './es/DashboardMarketIntelComponentsEs/MarketAverageTicketHelpEs';
import MarketAverageTicketHelpPt from './pt/DashboardMarketIntelComponentsPt/MarketAverageTicketHelpPt';
import MarketAverageTicketEn from './en/DashboardMarketIntelComponentsEn/MarketAverageTicketEn';
import MarketAverageTicketEs from './es/DashboardMarketIntelComponentsEs/MarketAverageTicketEs';
import MarketAverageTicketPt from './pt/DashboardMarketIntelComponentsPt/MarketAverageTicketPt';
import ProductSalesInfoHelpEn from './en/DashboardMarketIntelComponentsEn/ProductSalesInfoHelpEn';
import ProductSalesInfoHelpEs from './es/DashboardMarketIntelComponentsEs/ProductSalesInfoHelpEs';
import ProductSalesInfoHelpPt from './pt/DashboardMarketIntelComponentsPt/ProductSalesInfoHelpPt';
import ProductSalesInfoEn from './en/DashboardMarketIntelComponentsEn/ProductSalesInfoEn';
import ProductSalesInfoEs from './es/DashboardMarketIntelComponentsEs/ProductSalesInfoEs';
import ProductSalesInfoPt from './pt/DashboardMarketIntelComponentsPt/ProductSalesInfoPt';
import ValidationsEn from './en/ValidationsEn';
import ValidationsEs from './es/ValidationEs';
import ValidationsPt from './pt/ValidationsPt';
import DateIntervalIntelCardFilterEn from './en/DateIntervalIntelCardFilterEn';
import DateIntervalIntelCardFilterEs from './es/DateIntervalIntelCardFilterEs';
import DateIntervalIntelCardFilterPt from './pt/DateIntervalIntelCardFilterPt';
import PlanogramMainEn from './en/PlanogramMainEn';
import PlanogramMainEs from './es/PlanogramMainEs';
import PlanogramMainPt from './pt/PlanogramMainPt';
import PlanogramCreationDialogEn from './en/PlanogramCreationDialogEn';
import PlanogramCreationDialogEs from './es/PlanogramCreationDialogEs';
import PlanogramCreationDialogPt from './pt/PlanogramCreationDialogPt';
import PointOfSalesSelectorEn from './en/SelectoresEn/PointOfSalesSelectorEn';
import PointOfSalesSelectorEs from './es/SelectoresEs/PointOfSalesSelectorEs';
import PointOfSalesSelectorPt from './pt/SelectoresPt/PointOfSalesSelectorPt';
import PlanogramEn from './en/PlanogramEn';
import PlanogramEs from './es/PlanogramEs';
import PlanogramPt from './pt/PlanogramPt';
import PlanogramAddOrUpdateDialogEn from './en/PlanogramAddOrUpdateDialogEn';
import PlanogramAddOrUpdateDialogEs from './es/PlanogramAddOrUpdateDialogEs';
import PlanogramAddOrUpdateDialogPt from './pt/PlanogramAddOrUpdateDialogPt';
import PlanogramEntryDialogEn from './en/PlanogramEntryDialogEn';
import PlanogramEntryDialogEs from './es/PlanogramEntryDialogEs';
import PlanogramEntryDialogPt from './pt/PlanogramEntryDialogPt';
import RulesEn from './en/RulesEn';
import RulesEs from './es/RulesEs';
import RulesPt from './pt/RulesPt';
import PlanogramRemoveItemDialogEn from './en/PlanogramRemoveItemDialogEn';
import PlanogramRemoveItemDialogEs from './es/PlanogramRemoveItemDialogEs';
import PlanogramRemoveItemDialogPt from './pt/PlanogramRemoveItemDialogPt';
import ProductsFilterEn from './en/SelectoresEn/ProductsFilterEn';
import ProductsFilterEs from './es/SelectoresEs/ProductsFilterEs';
import ProductsFilterPt from './pt/SelectoresPt/ProductsFilterPt';
import PlanogramMainHelpEn from './en/PlanogramMainHelpEn';
import PlanogramMainHelpEs from './es/PlanogramMainHelpEs';
import PlanogramMainHelpPt from './pt/PlanogramMainHelpPt';
import PlanogramStatusDescriptionDialogEn from './en/PlanogramStatusDescriptionDialogEn';
import PlanogramStatusDescriptionDialogEs from './es/PlanogramStatusDescriptionDialogEs';
import PlanogramStatusDescriptionDialogPt from './pt/PlanogramStatusDescriptionDialogPt';
import PlanogramStatusChipEn from './en/PlanogramStatusChipEn';
import PlanogramStatusChipEs from './es/PlanogramStatusChipEs';
import PlanogramStatusChipPt from './pt/PlanogramStatusChipPt';
import PlanogramDraftEditionEn from './en/PlanogramDraftEditionEn';
import PlanogramDraftEditionEs from './es/PlanogramDraftEditionEs';
import PlanogramDraftEditionPt from './pt/PlanogramDraftEditionPt';
import AddEntriesBulkEn from './en/AddEntriesBulkEn';
import AddEntriesBulkEs from './es/AddEntriesBulkEs';
import AddEntriesBulkPt from './pt/AddEntriesBulkPt';
import InventoryChangeDialogEn from './en/InventoryChangeDialogEn';
import InventoryChangeDialogEs from './es/InventoryChangeDialogEs';
import InventoryChangeDialogPt from './pt/InventoryChangeDialogPt';
import BulkProgressDialogEn from './en/BulkProgressDialogEn';
import BulkProgressDialogEs from './es/BulkProgressDialogEs';
import BulkProgressDialogPt from './pt/BulkProgressDialogPt';
import ProductsMasterEn from './en/ProductsMasterEn';
import ProductsMasterEs from './es/ProductsMasterEs';
import ProductsMasterPt from './pt/ProductsMasterPt';
import ProductsEn from './en/ProductsEn';
import ProductsEs from './es/ProductsEs';
import ProductsPt from './pt/ProductsPt';
import DownloadDialogEn from './en/SelectoresEn/DownloadDialogEn';
import DownloadDialogEs from './es/SelectoresEs/DownloadDialogEs';
import DownloadDialogPt from './pt/SelectoresPt/DownloadDialogPt';
import ProductDialogEn from './en/ProductDialogEn';
import ProductDialogEs from './es/ProductDialogEs';
import ProductDialogPt from './pt/ProductDialogPt';
import EditableImageEn from './en/EditableImageEn';
import EditableImageEs from './es/EditableImageEs';
import EditableImagePt from './pt/EditableImagePt';
import DropzoneOptionsEn from './en/DropzoneOptionsEn';
import DropzoneOptionsEs from './es/DropzoneOptionsEs';
import DropzoneOptionsPt from './pt/DropzoneOptionsPt';
import ProductDialogHelpEn from './en/ProductDialogHelpEn';
import ProductDialogHelpEs from './es/ProductDialogHelpEs';
import ProductDialogHelpPt from './pt/ProductDialogHelpPt';
import TaxationsEn from './en/TaxationsEn';
import TaxationsEs from './es/TaxationsEs';
import TaxationsPt from './pt/TaxationsPt';
import CrudSelectEn from './en/CrudSelectEn';
import CrudSelectEs from './es/CrudSelectEs';
import CrudSelectPt from './pt/CrudSelectPt';
import CategoriesEn from './en/CategoriesEn';
import CategoriesEs from './es/CategoriesEs';
import CategoriesPt from './pt/CategoriesPt';
import ProductGroupsEn from './en/ProductGroupsEn';
import ProductGroupsEs from './es/ProductGroupsEs';
import ProductGroupsPt from './pt/ProductGroupsPt';
import ProductGroupsHelpEn from './en/ProductGroupsHelpEn';
import ProductGroupsHelpEs from './es/ProductGroupsHelpEs';
import ProductGroupsHelpPt from './pt/ProductGroupsHelpPt';
import ProductGroupDialogHelpEn from './en/ProductGroupDialogHelpEn';
import ProductGroupDialogHelpEs from './es/ProductGroupDialogHelpEs';
import ProductGroupDialogHelpPt from './pt/ProductGroupDialogHelpPt';
import ProductGroupDialogEn from './en/ProductGroupDialogEn';
import ProductGroupDialogEs from './es/ProductGroupDialogEs';
import ProductGroupDialogPt from './pt/ProductGroupDialogPt';
import DownloadsEn from './en/DownloadsEn';
import DownloadsEs from './es/DownloadsEs';
import DownloadsPt from './pt/DownloadsPt';
import TransactionsEn from './en/TransactionsEn';
import TransactionsEs from './es/TransactionsEs';
import TransactionsPt from './pt/TransactionsPt';
import InvoiceDialogEn from './en/InvoiceDialogEn';
import InvoiceDialogEs from './es/InvoiceDialogEs';
import InvoiceDialogPt from './pt/InvoiceDialogPt';
import InvoiceXmlEn from './en/InvoiceXmlEn';
import InvoiceXmlEs from './es/InvoiceXmlEs';
import InvoiceXmlPt from './pt/InvoiceXmlPt';
import InvalidationRequestEn from './en/InvalidationRequestEn';
import InvalidationRequestEs from './es/InvalidationRequestEs';
import InvalidationRequestPt from './pt/InvalidationRequestPt';
import InvalidationsDialogEn from './en/InvalidationsDialogEn';
import InvalidationsDialogEs from './es/InvalidationsDialogEs';
import InvalidationsDialogPt from './pt/InvalidationsDialogPt';
import BulkRetryInvoiceEn from './en/BulkRetryInvoiceEn';
import BulkRetryInvoiceEs from './es/BulkRetryInvoiceEs';
import BulkRetryInvoicePt from './pt/BulkRetryInvoicePt';
import TransactionsHelpEn from './en/TransactionsHelpEn';
import TransactionsHelpEs from './es/TransactionsHelpEs';
import TransactionsHelpPt from './pt/TransactionsHelpPt';
import PaymentMethodsEn from './en/PaymentMethodsEn';
import PaymentMethodsEs from './es/PaymentMethodsEs';
import PaymentMethodsPt from './pt/PaymentMethodsPt';
import PointOfSaleEn from './en/PointOfSaleEn';
import PointOfSaleEs from './es/PointOfSaleEs';
import PointOfSalePt from './pt/PointOfSalePt';
import LocksEn from './en/LocksEn';
import LocksEs from './es/LocksEs';
import LocksPt from './pt/LocksPt';
import LockFormEn from './en/LockFormEn';
import LockFormEs from './es/LockFormEs';
import LockFormPt from './pt/LockFormPt';
import QrCodeOperatorEn from './en/QrCodeOperatorEn';
import QrCodeOperatorEs from './es/QrCodeOperatorEs';
import QrCodeOperatorPt from './pt/QrCodeOperatorPt';
import InvoiceConfigEn from './en/InvoiceConfigEn';
import InvoiceConfigEs from './es/InvoiceConfigEs';
import InvoiceConfigPt from './pt/InvoiceConfigPt';
import MobileActivationEn from './en/MobileActivationEn';
import MobileActivationEs from './es/MobileActivationEs';
import MobileActivationPt from './pt/MobileActivationPt';
import PointOfSaleImageSelectorEn from './en/SelectoresEn/PointOfSaleImageSelectorEn';
import PointOfSaleImageSelectorEs from './es/SelectoresEs/PointOfSaleImageSelectorEs';
import PointOfSaleImageSelectorPt from './pt/SelectoresPt/PointOfSaleImageSelectorPt';
import PosTypeSelectorEn from './en/SelectoresEn/PosTypeSelectorEn';
import PosTypeSelectorEs from './es/SelectoresEs/PosTypeSelectorEs';
import PosTypeSelectorPt from './pt/SelectoresPt/PosTypeSelectorPt';
import PointOfSaleTypesEn from './en/PointOfSaleTypesEn';
import PointOfSaleTypesEs from './es/PointOfSaleTypesEs';
import PointOfSaleTypesPt from './pt/PointOfSaleTypesPt';
import LocalTypeSelectorEn from './en/SelectoresEn/LocalTypeSelectorEn';
import LocalTypeSelectorEs from './es/SelectoresEs/LocalTypeSelectorEs';
import LocalTypeSelectorPt from './pt/SelectoresPt/LocalTypeSelectorPt';
import NetworkIconEn from './en/NetworkIconEn';
import NetworkIconEs from './es/NetworkIconEs';
import NetworkIconPt from './pt/NetworkIconPt';
import RemoteCreditEn from './en/RemoteCreditEn';
import RemoteCreditEs from './es/RemoteCreditEs';
import RemoteCreditPt from './pt/RemoteCreditPt';
import SalesChartEn from './en/SalesChartEn';
import SalesChartEs from './es/SalesChartEs';
import SalesChartPt from './pt/SalesChartPt';
import SupplyEn from './en/SupplyEn';
import SupplyEs from './es/SupplyEs';
import SupplyPt from './pt/SupplyPt';
import PushNotificationDialogEn from './en/PushNotificationDialogEn';
import PushNotificationDialogEs from './es/PushNotificationDialogEs';
import PushNotificationDialogPt from './pt/PushNotificationDialogPt';
import RebootDialogEn from './en/RebootDialogEn';
import RebootDialogEs from './es/RebootDialogEs';
import RebootDialogPt from './pt/RebootDialogPt';
import PosMachineStatusEn from './en/PosMachineStatusEn';
import PosMachineStatusEs from './es/PosMachineStatusEs';
import PosMachineStatusPt from './pt/PosMachineStatusPt';
import PosWithoutPickListEn from './en/PosWithoutPickListEn';
import PosWithoutPickListEs from './es/PosWithoutPickListEs';
import PosWithoutPickListPt from './pt/PosWithoutPickListPt';
import NegativeItemsDialogEn from './en/NegativeItemsDialogEn';
import NegativeItemsDialogEs from './es/NegativeItemsDialogEs';
import NegativeItemsDialogPt from './pt/NegativeItemsDialogPt';
import NegativeProductDetailsEn from './en/NegativeProductDetailsEn';
import NegativeProductDetailsEs from './es/NegativeProductDetailsEs';
import NegativeProductDetailsPt from './pt/NegativeProductDetailsPt';
import PickListsEn from './en/PickListsEn';
import PickListsEs from './es/PickListsEs';
import PickListsPt from './pt/PickListsPt';
import PickListTableEn from './en/PickListTableEn';
import PickListTableEs from './es/PickListTableEs';
import PickListTablePt from './pt/PickListTablePt';
import PickListDetailsEn from './en/PickListDetailsEn';
import PickListDetailsEs from './es/PickListDetailsEs';
import PickListDetailsPt from './pt/PickListDetailsPt';
import TransactionsReportsFiltersEn from './en/TransactionsReportsFiltersEn';
import TransactionsReportsFiltersEs from './es/TransactionsReportsFiltersEs';
import TransactionsReportsFiltersPt from './pt/TransactionsReportsFiltersPt';
import TransactionsReportsEn from './en/TransactionsReportsEn';
import TransactionsReportsEs from './es/TransactionsReportsEs';
import TransactionsReportsPt from './pt/TransactionsReportsPt';
import FilterNamesTranslationsEn from './en/FilterNamesTranslationsEn';
import FilterNamesTranslationsEs from './es/FilterNamesTranslationsEs';
import FilterNamesTranslationsPt from './pt/FilterNamesTranslationsPt';
import TaxingBulkRemoveDialogEn from './en/TaxingBulkRemoveDialogEn';
import TaxingBulkRemoveDialogEs from './es/TaxingBulkRemoveDialogEs';
import TaxingBulkRemoveDialogPt from './pt/TaxingBulkRemoveDialogPt';
import TaxingUsaEn from './en/TaxingUsaEn';
import TaxingUsaEs from './es/TaxingUsaEs';
import TaxingUsaPt from './pt/TaxingUsaPt';
import TaxingUsaProductsDialogEn from './en/TaxingUsaProductsDialogEn';
import TaxingUsaProductsDialogEs from './es/TaxingUsaProductsDialogEs';
import TaxingUsaProductsDialogPt from './pt/TaxingUsaProductsDialogPt';
import TaxingBrazilEn from './en/TaxingBrazilEn';
import TaxingBrazilEs from './es/TaxingBrazilEs';
import TaxingBrazilPt from './pt/TaxingBrazilPt';
import TaxingDialogEn from './en/TaxingDialogEn';
import TaxingDialogEs from './es/TaxingDialogEs';
import TaxingDialogPt from './pt/TaxingDialogPt';
import TaxingProductsDialogEn from './en/TaxingProductsDialogEn';
import TaxingProductsDialogEs from './es/TaxingProductsDialogEs';
import TaxingProductsDialogPt from './pt/TaxingProductsDialogPt';
import TenantGroupDialogEn from './en/UsersEn/TenantGroupDialogEn';
import TenantGroupDialogEs from './es/UsersEs/TenantGroupDialogEs';
import TenantGroupDialogPt from './pt/UsersPt/TenantGroupDialogPt';
import UsersDialogHelpEn from './en/UsersEn/UsersDialogHelpEn';
import UsersDialogHelpEs from './es/UsersEs/UsersDialogHelpEs';
import UsersDialogHelpPt from './pt/UsersPt/UsersDialogHelpPt';
import UserDialogEn from './en/UsersEn/UserDialogEn';
import UserDialogEs from './es/UsersEs/UserDialogEs';
import UserDialogPt from './pt/UsersPt/UserDialogPt';
import UserEn from './en/UsersEn/UserEn';
import UserEs from './es/UsersEs/UserEs';
import UserPt from './pt/UsersPt/UserPt';
import BuildingManagerDropDownEn from './en/SelectoresEn/BuildingManagerDropDownEn';
import BuildingManagerDropDownEs from './es/SelectoresEs/BuildingManagerDropDownEs';
import BuildingManagerDropDownPt from './pt/SelectoresPt/BuildingManagerDropDownPt';
import RolesAndRoutesEn from './en/RolesAndRoutesEn/RolesAndRoutesEn';
import RolesAndRoutesEs from './es/RolesAndRoutesEs/RolesAndRoutesEs';
import RolesAndRoutesPt from './pt/RolesAndRoutesPt/RolesAndRoutesPt';
import BuildingManagerSalesReportEn from './en/BuildingManagerSalesReportEn';
import BuildingManagerSalesReportEs from './es/BuildingManagerSalesReportEs';
import BuildingManagerSalesReportPt from './pt/BuildingManagerSalesReportPt';
import DialogProductsUnusedEn from './en/PlanogramEn/DialogProductsUnusedEn';
import DialogProductsUnusedEs from './es/PlanogramEs/DialogProductsUnusedEs';
import DialogProductsUnusedPt from './pt/PlanogramPt/DialogProductsUnusedPt';
import DialogCreateDraftProductUnusedEn from './en/PlanogramEn/DialogCreateDraftProductUnusedEn';
import DialogCreateDraftProductUnusedEs from './es/PlanogramEs/DialogCreateDraftProductUnusedEs';
import DialogCreateDraftProductUnusedPt from './pt/PlanogramPt/DialogCreateDraftProductUnusedPt';
import PosMinoritySalesEn from './en/DashboardComponentsEn/PosMinoritySalesEn';
import PosMinoritySalesEs from './es/DashboardComponentsEs/PosMinoritySalesEs';
import PosMinoritySalesPt from './pt/DashboardComponentsPt/PosMinoritySalesPt';
import PosMinoritySalesHelpEn from './en/PosMinoritySalesHelpEn';
import PosMinoritySalesHelpEs from './es/PosMinoritySalesHelpEs';
import PosMinoritySalesHelpPt from './pt/PosMinoritySalesHelpPt';
import PosMostSalesHelpEn from './en/PosMostSalesHelpEn';
import PosMostSalesHelpEs from './es/PosMostSalesHelpEs';
import PosMostSalesHelpPt from './pt/PosMostSalesHelpPt';
import NewDashboardEn from './en/NewDashboardEn';
import NewDashboardEs from './es/NewDashboardEs';
import NewDashboardPt from './pt/NewDashboardPt';
import ProductsCloseToExpirationEn from './en/DashboardComponentsEn/ProductsCloseToExpirationEn';
import ProductsCloseToExpirationEs from './es/DashboardComponentsEs/ProductsCloseToExpirationEs';
import ProductsCloseToExpirationPt from './pt/DashboardComponentsPt/ProductsCloseToExpirationPt';
import ProductsCloseToExpirationHelpEn from './en/DashboardComponentsEn/ProductsCloseToExpirationHelpEn';
import ProductsCloseToExpirationHelpEs from './es/DashboardComponentsEs/ProductsCloseToExpirationHelpEs';
import ProductsCloseToExpirationHelpPt from './pt/DashboardComponentsPt/ProductsCloseToExpirationHelpPt';
import LockDialogEn from './en/DashboardComponentsEn/LockDialogEn';
import LockDialogEs from './es/DashboardComponentsEs/LockDialogEs';
import LockDialogPt from './pt/DashboardComponentsPt/LockDialogPt';
import LastSupplyHelpEn from './en/LastSupplyHelpEn';
import LastSupplyHelpEs from './es/LastSupplyHelpEs';
import LastSupplyHelpPt from './pt/LastSupplyHelpPt';
import LastSupplyNewEn from './en/LastSupplyNewEn';
import LastSupplyNewEs from './es/LastSupplyNewEs';
import LastSupplyNewPt from './pt/LastSupplyNewPt';
import StatusPicklistEn from './en/DashboardComponentsEn/StatusPicklistEn';
import StatusPicklistEs from './es/DashboardComponentsEs/StatusPicklistEs';
import StatusPicklistPt from './pt/DashboardComponentsPt/StatusPicklistPt';
import SalesByPaymentMethodEn from './en/DashboardComponentsEn/SalesByPaymentMethodEn';
import SalesByPaymentMethodEs from './es/DashboardComponentsEs/SalesByPaymentMethodEs';
import SalesByPaymentMethodPt from './pt/DashboardComponentsPt/SalesByPaymentMethodPt';
import SalesByPaymentMethodHelpEn from './en/DashboardComponentsEn/SalesByPaymentMethodHelpEn';
import SalesByPaymentMethodHelpEs from './es/DashboardComponentsEs/SalesByPaymentMethodHelpEs';
import SalesByPaymentMethodHelpPt from './pt/DashboardComponentsPt/SalesByPaymentMethodHelpPt';
import SalesReportsPt from './pt/SalesReports';
import SalesReportsEn from './en/SalesReports';
import SalesReportsEs from './es/SalesReports';
import RevenueMonthEn from './en/DashboardComponentsEn/RevenueMonthEn';
import RevenueMonthEs from './es/DashboardComponentsEs/RevenueMonthEs';
import RevenueMonthPt from './pt/DashboardComponentsPt/RevenueMonthPt';
import MonthsEn from './en/MonthsEn';
import MonthsEs from './es/MonthsEs';
import MonthsPt from './pt/MonthsPt';
import MostSoldCategoriesEn from './en/DashboardComponentsEn/MostSoldCategoriesEn';
import MostSoldCategoriesEs from './es/DashboardComponentsEs/MostSoldCategoriesEs';
import MostSoldCategoriesPt from './pt/DashboardComponentsPt/MostSoldCategoriesPt';
import LossesProductAndPosHelpEn from './en/LossesProductAndPosHelpEn';
import LossesProductAndPosHelpEs from './es/LossesProductAndPosHelpEs';
import LossesProductAndPosHelpPt from './pt/LossesProductAndPosHelpPt';
import LossesProductAndPosEn from './en/LossesProductAndPosEn';
import LossesProductAndPosEs from './es/LossesProductAndPosEs';
import LossesProductAndPosPt from './pt/LossesProductAndPosPt';
import StepDialogManualEn from './en/PurchaseInvoice/StepDialogManualEn';
import StepDialogManualEs from './es/PurchaseInvoice/StepDialogManualEs';
import StepDialogManualPt from './pt/PurchaseInvoice/StepDialogManualPt';
import InvoiceManualEn from './en/PurchaseInvoice/InvoiceManualEn';
import InvoiceManualEs from './es/PurchaseInvoice/InvoiceManualEs';
import InvoiceManualPt from './pt/PurchaseInvoice/InvoiceManualPt';
import ProductsManualEn from './en/PurchaseInvoice/ProductsManualEn';
import ProductsManualEs from './es/PurchaseInvoice/ProductsManualEs';
import ProductsManualPt from './pt/PurchaseInvoice/ProductsManualPt';
import DetailDialogEn from './en/PurchaseInvoice/DetailDialogEn';
import DetailDialogEs from './es/PurchaseInvoice/DetailDialogEs';
import DetailDialogPt from './pt/PurchaseInvoice/DetailDialogPt';
import SalesSummaryEn from './en/DashboardComponentsEn/SalesSummaryEn';
import SalesSummaryEs from './es/DashboardComponentsEs/SalesSummaryEs';
import SalesSummaryPt from './pt/DashboardComponentsPt/SalesSummaryPt';
import SalesByWeekdayEn from './en/DashboardComponentsEn/SalesByWeekdayEn';
import SalesByWeekdayEs from './es/DashboardComponentsEs/SalesByWeekdayEs';
import SalesByWeekdayPt from './pt/DashboardComponentsPt/SalesByWeekdayPt';
import NpsFeedbackDialogEn from './en/NpsFeedbackDialogEn';
import NpsFeedbackDialogEs from './es/NpsFeedbackDialogEs';
import NpsFeedbackDialogPt from './pt/NpsFeedbackDialogPt';
import ProductsBarCodeDropdownEn from './en/SelectoresEn/ProductsBarCodeDropdownEn'
import ProductsBarCodeDropdownEs from './es/SelectoresEs/ProductsBarCodeDropdownEs'
import ProductsBarCodeDropdownPt from './pt/SelectoresPt/ProductsBarCodeDropdownPt'
import StepsDialogMasterPt from './pt/stepsDialogMasterPt';
import StepsDialogMasterEn from './en/stepsDialogMasterEn';
import StepsDialogMasterEs from './es/stepsDialogMasterEs';
import XmlDialogPt from './pt/XmlDialogPt';
import XmlDialogEn from './en/XmlDialogEn';
import XmlDialogEs from './es/XmlDialogEs';
import XmlProductsDialogPt from './pt/XmlProductsDialogPt';
import XmlProductsDialogEn from './en/XmlProductsDialogEn';
import XmlProductsDialogEs from './es/XmlProductsDialogEs';
import DetailXmlDialogPt from './pt/DetailXmlDialogPt';
import DetailXmlDialogEn from './en/DetailXmlDialogEn';
import DetailXmlDialogEs from './es/DetailXmlDialogEs';
import DeletePurchaseXmlDialogPt from './pt/DeletePurchaseXmlDialogPt';
import DeletePurchaseXmlDialogEn from './en/DeletePurchaseXmlDialogEn';
import DeletePurchaseXmlDialogEs from './es/DeletePurchaseXmlDialogEs';
import LocalStorageDialogPt from './pt/LocalStorageDialogPt';
import LocalStorageDialogEn from './en/LocalStorageDialogEn';
import LocalStorageDialogEs from './es/LocalStorageDialogEs';
import PurchasesPt from './pt/PurchasesPt';
import PurchasesEn from './en/PurchasesEn';
import PurchasesEs from './es/PurchasesEs';
import PurchasesFiltersPt from './pt/PurchasesFiltersPt';
import PurchasesFiltersEn from './en/PurchasesFiltersEn';
import PurchasesFiltersEs from './es/PurchasesFiltersEs';
import PurchasesHelpPt from './pt/PurchasesHelpPt';
import PurchasesHelpEn from './en/PurchasesHelpEn';
import PurchasesHelpEs from './es/PurchasesHelpEs';
import PurchaseDetailsPt from './pt/PurchaseDetailsPt';
import PurchaseDetailsEn from './en/PurchaseDetailsEn';
import PurchaseDetailsEs from './es/PurchaseDetailsEs';
import PurchaseDetailsHelpPt from './pt/PurchaseDetailsHelpPt';
import PurchaseDetailsHelpEn from './en/PurchaseDetailsHelpEn';
import PurchaseDetailsHelpEs from './es/PurchaseDetailsHelpEs';
import SuccessOrderPt from './pt/SuccessOrderPt';
import SuccessOrderEn from './en/SuccessOrderEn';
import SuccessOrderEs from './es/SuccessOrderEs';
import MidasCloseOrderPt from './pt/MidasCloseOrderPt';
import MidasCloseOrderEn from './en/MidasCloseOrderEn';
import MidasCloseOrderEs from './es/MidasCloseOrderEs';
import MidaCloseOrdersHelpPt from './pt/MidasCloseOrdersHelpPt';
import MidaCloseOrdersHelpEn from './en/MidasCloseOrdersHelpEn';
import MidaCloseOrdersHelpEs from './es/MidasCloseOrdersHelpEs';
import AcquisitionsOrdersApprovalPt from './pt/AcquisitionsOrdersApprovalPt';
import AcquisitionsOrdersApprovalEn from './en/AcquisitionsOrdersApprovalEn';
import AcquisitionsOrdersApprovalEs from './es/AcquisitionsOrdersApprovalEs';
import ChangeOwnershipRequestPt from './pt/ChangeOwnershipRequestPt';
import ChangeOwnershipRequestEn from './en/ChangeOwnershipRequestEn';
import ChangeOwnershipRequestEs from './es/ChangeOwnershipRequestEs';
import ChangeOwnershipApprovalDialogPt from './pt/ChangeOwnershipApprovalDialogPt';
import ChangeOwnershipApprovalDialogEn from './en/ChangeOwnershipApprovalDialogEn';
import ChangeOwnershipApprovalDialogEs from './es/ChangeOwnershipApprovalDialogEs';
import MyRequestsPt from './pt/ChangeOfOwnershipPt/MyRequestsPt';
import MyRequestsEn from './en/ChangeOfOwnershipEn/MyRequestsEn';
import MyRequestsEs from './es/ChangeOfOwnershipEs/MyRequestsEs';
import SalesReportFiltersPt from './pt/SalesReportFilterPt';
import SalesReportFiltersEn from './en/SalesReportFilterEn';
import SalesReportFiltersEs from './es/SalesReportFilterEs';
import PromotionsPt from './pt/discounts/PromotionsPt';
import PromotionsEs from './es/discounts/PromotionsEs';
import PromotionsEn from './en/discounts/PromotionsEn';
import PickListDialogExcelPt from './pt/PickListDialogExcelPt';
import PickListDialogExcelEn from './en/PickListDialogExcelEn';
import PickListDialogExcelEs from './es/PickListDialogExcelEs';
import DiscountsPt from './pt/DiscountsPt';
import DiscountsEn from './en/DiscountsEn';
import DiscountsEs from './es/DiscountsEs';
import CustomerProfilePt from './pt/CustomerProfilePt';
import CustomerProfileEn from './en/CustomerProfileEn';
import CustomerProfileEs from './es/CustomerProfileEs';
import ContextPt from './pt/context';
import ContextEs from './es/context';
import ContextEn from './en/context';
import AuditPt from './pt/context/AuditPt';
import AuditEn from './en/context/AuditEn';
import AuditEs from './es/context/AuditEs';
import TransactionsContextPt from './pt/context/TransactionsPt';
import TransactionsContextEn from './en/context/TransactionsEn';
import TransactionsContextEs from './es/context/TransactionsEs';
import TelemetryContextPt from './pt/context/TelemetryPt';
import TelemetryContextEn from './en/context/TelemetryEn';
import TelemetryContextEs from './es/context/TelemetryEs';
import CatalogPt from './pt/catalogs/CatalogPt';
import CatalogEn from './en/catalogs/CatalogEn';
import CatalogEs from './es/catalogs/CatalogEs';
import DiscountsContextPt from './pt/context/DiscountsPt';
import DiscountsContextEn from './en/context/DiscountsEn';
import DiscountsContextEs from './es/context/DiscountsEs';
import CustomerRegistrationPt from './pt/CustomerRegistrationPt';
import CustomerRegistrationEn from './en/CustomerRegistrationEn';
import CustomerRegistrationEs from './es/CustomerRegistrationEs';
import MyBillsPt from './pt/MyBillsPt';
import MyBillsEn from './en/MyBillsEn';
import MyBillsEs from './es/MyBillsEs';

Vue.use(VueI18n);

const messages = {
  en: Object.assign({},
    ContextEn,
    AppLoginEn,
    WebNavigationDrawerEn,
    AppEn,
    WebAppBarEn,
    TenantFeedbackDialogEn,
    TenantFeedbackEn,
    DashboardHelpEn,
    HelpEn,
    MobileNavigationDrawerEn,
    DashboardEn,
    TotalPurchaseEn,
    AverageTicketEn,
    BarChartEn,
    PointOfSalesMultipleSelectorEn,
    PosMostSalesEn,
    DateIntervalsEn,
    LastSupplyEn,
    ConnectivityPointOfSaleEn,
    ProductMostSoldEn,
    MarketIntelAuthorizationEn,
    TopSellingProductsEn,
    TopSellingProductsHelpEn,
    InformationEn,
    BillingByResidentsHelpEn,
    BillingByResidentsEn,
    StateSelectorFilterEn,
    MarketAverageTicketHelpEn,
    MarketAverageTicketEn,
    ProductSalesInfoHelpEn,
    ProductSalesInfoEn,
    ValidationsEn,
    DateIntervalIntelCardFilterEn,
    PlanogramMainEn,
    PlanogramCreationDialogEn,
    PointOfSalesSelectorEn,
    PlanogramEn,
    PlanogramAddOrUpdateDialogEn,
    PlanogramEntryDialogEn,
    RulesEn,
    PlanogramRemoveItemDialogEn,
    ProductsFilterEn,
    PlanogramMainHelpEn,
    PlanogramStatusDescriptionDialogEn,
    PlanogramStatusChipEn,
    PlanogramDraftEditionEn,
    AddEntriesBulkEn,
    InventoryChangeDialogEn,
    BulkProgressDialogEn,
    ProductsMasterEn,
    ProductsEn,
    DownloadDialogEn,
    ProductDialogEn,
    EditableImageEn,
    DropzoneOptionsEn,
    ProductDialogHelpEn,
    TaxationsEn,
    CrudSelectEn,
    CategoriesEn,
    ProductGroupsEn,
    ProductGroupsHelpEn,
    ProductGroupDialogHelpEn,
    ProductGroupDialogEn,
    DownloadsEn,
    TransactionsEn,
    InvoiceDialogEn,
    InvoiceXmlEn,
    InvalidationRequestEn,
    InvalidationsDialogEn,
    BulkRetryInvoiceEn,
    TransactionsHelpEn,
    PaymentMethodsEn,
    PointOfSaleEn,
    LocksEn,
    LockFormEn,
    QrCodeOperatorEn,
    InvoiceConfigEn,
    MobileActivationEn,
    PointOfSaleImageSelectorEn,
    PosTypeSelectorEn,
    PointOfSaleTypesEn,
    LocalTypeSelectorEn,
    NetworkIconEn,
    RemoteCreditEn,
    SalesChartEn,
    SupplyEn,
    PushNotificationDialogEn,
    RebootDialogEn,
    PosMachineStatusEn,
    PosWithoutPickListEn,
    NegativeItemsDialogEn,
    NegativeProductDetailsEn,
    PickListsEn,
    PickListTableEn,
    PickListDetailsEn,
    TransactionsReportsFiltersEn,
    TransactionsReportsEn,
    FilterNamesTranslationsEn,
    TaxingBulkRemoveDialogEn,
    TaxingUsaEn,
    TaxingUsaProductsDialogEn,
    TaxingBrazilEn,
    TaxingDialogEn,
    TaxingProductsDialogEn,
    BuildingManagerDropDownEn,
    TenantGroupDialogEn,
    UsersDialogHelpEn,
    UserDialogEn,
    UserEn,
    RolesAndRoutesEn,
    BuildingManagerSalesReportEn,
    DialogProductsUnusedEn,
    DialogCreateDraftProductUnusedEn,
    PosMinoritySalesEn,
    PosMinoritySalesHelpEn,
    PosMostSalesHelpEn,
    NewDashboardEn,
    ProductsCloseToExpirationEn,
    ProductsCloseToExpirationHelpEn,
    LockDialogEn,
    LastSupplyHelpEn,
    LastSupplyNewEn,
    StatusPicklistEn,
    SalesByPaymentMethodEn,
    SalesByPaymentMethodHelpEn,
    StepDialogManualEn,
    InvoiceManualEn,
    ProductsManualEn,
    DetailDialogEn,
    RevenueMonthEn,
    MonthsEn,
    MostSoldCategoriesEn,
    LossesProductAndPosHelpEn,
    LossesProductAndPosEn,
    SalesSummaryEn,
    SalesByWeekdayEn,
    NpsFeedbackDialogEn,
    ProductsBarCodeDropdownEn,
    MidasCloseOrderEn,
    AcquisitionsOrdersApprovalEn,
    MidaCloseOrdersHelpEn,
    SuccessOrderEn,
    StepsDialogMasterEn,
    XmlDialogEn,
    XmlProductsDialogEn,
    DetailXmlDialogEn,
    DeletePurchaseXmlDialogEn,
    LocalStorageDialogEn,
    PurchasesEn,
    PurchasesFiltersEn,
    PurchasesHelpEn,
    PurchaseDetailsEn,
    PurchaseDetailsHelpEn,
    ChangeOwnershipRequestEn,
    ChangeOwnershipApprovalDialogEn,
    MyRequestsEn,
    SalesReportsEn,
    SalesReportFiltersEn,
    PickListDialogExcelEn,
    PromotionsEn,
    DiscountsEn,
    CustomerProfileEn,
    AuditEn,
    TransactionsContextEn,
    TelemetryContextEn,
    DiscountsContextEn,
    CustomerProfileEn,
    CatalogEn,
    CustomerRegistrationEn,
    MyBillsEn,
  ),
  es: Object.assign({},
      ContextEs,
      AppLoginEs,
      WebNavigationDrawerEs,
      AppEs,
      WebAppBarEs,
      TenantFeedbackDialogEs,
      TenantFeedbackEs,
      DashboardHelpEs,
      HelpEs,
      MobileNavigationDrawerEs,
      DashboardEs,
      TotalPurchaseEs,
      AverageTicketEs,
      BarChartEs,
      PointOfSalesMultipleSelectorEs,
      PosMostSalesEs,
      DateIntervalsEs,
      LastSupplyEs,
      ConnectivityPointOfSaleEs,
      ProductMostSoldEs,
      MarketIntelAuthorizationEs,
      TopSellingProductsEs,
      TopSellingProductsHelpEs,
      InformationEs,
      BillingByResidentsHelpEs,
      BillingByResidentsEs,
      StateSelectorFilterEs,
      MarketAverageTicketHelpEs,
      MarketAverageTicketEs,
      ProductSalesInfoHelpEs,
      ProductSalesInfoEs,
      ValidationsEs,
      DateIntervalIntelCardFilterEs,
      PlanogramMainEs,
      PlanogramCreationDialogEs,
      PointOfSalesSelectorEs,
      PlanogramEs,
      PlanogramAddOrUpdateDialogEs,
      PlanogramEntryDialogEs,
      RulesEs,
      PlanogramRemoveItemDialogEs,
      ProductsFilterEs,
      PlanogramMainHelpEs,
      PlanogramStatusDescriptionDialogEs,
      PlanogramStatusChipEs,
      PlanogramDraftEditionEs,
      AddEntriesBulkEs,
      InventoryChangeDialogEs,
      BulkProgressDialogEs,
      ProductsMasterEs,
      ProductsEs,
      DownloadDialogEs,
      ProductDialogEs,
      EditableImageEs,
      DropzoneOptionsEs,
      ProductDialogHelpEs,
      TaxationsEs,
      CrudSelectEs,
      CategoriesEs,
      ProductGroupsEs,
      ProductGroupsHelpEs,
      ProductGroupDialogHelpEs,
      ProductGroupDialogEs,
      DownloadsEs,
      TransactionsEs,
      InvoiceDialogEs,
      InvoiceXmlEs,
      InvalidationRequestEs,
      InvalidationsDialogEs,
      BulkRetryInvoiceEs,
      TransactionsHelpEs,
      PaymentMethodsEs,
      PointOfSaleEs,
      LocksEs,
      LockFormEs,
      QrCodeOperatorEs,
      InvoiceConfigEs,
      MobileActivationEs,
      PointOfSaleImageSelectorEs,
      PosTypeSelectorEs,
      PointOfSaleTypesEs,
      LocalTypeSelectorEs,
      NetworkIconEs,
      RemoteCreditEs,
      SalesChartEs,
      SupplyEs,
      PushNotificationDialogEs,
      RebootDialogEs,
      PosMachineStatusEs,
      PosWithoutPickListEs,
      NegativeItemsDialogEs,
      NegativeProductDetailsEs,
      PickListsEs,
      PickListTableEs,
      PickListDetailsEs,
      TransactionsReportsFiltersEs,
      TransactionsReportsEs,
      FilterNamesTranslationsEs,
      TaxingBulkRemoveDialogEs,
      TaxingUsaEs,
      TaxingUsaProductsDialogEs,
      TaxingBrazilEs,
      TaxingDialogEs,
      TaxingProductsDialogEs,
      BuildingManagerDropDownEs,
      TenantGroupDialogEs,
      UsersDialogHelpEs,
      UserDialogEs,
      UserEs,
      RolesAndRoutesEs,
      BuildingManagerSalesReportEs,
      DialogProductsUnusedEs,
      DialogCreateDraftProductUnusedEs,
      PosMinoritySalesEs,
      PosMinoritySalesHelpEs,
      PosMostSalesHelpEs,
      NewDashboardEs,
      ProductsCloseToExpirationEs,
      ProductsCloseToExpirationHelpEs,
      LockDialogEs,
      LastSupplyHelpEs,
      LastSupplyNewEs,
      StatusPicklistEs,
      SalesByPaymentMethodEs,
      SalesByPaymentMethodHelpEs,
      StepDialogManualEs,
      InvoiceManualEs,
      ProductsManualEs,
      DetailDialogEs,
      RevenueMonthEs,
      MonthsEs,
      MostSoldCategoriesEs,
      LossesProductAndPosHelpEs,
      LossesProductAndPosEs,
      SalesSummaryEs,
      SalesByWeekdayEs,
      NpsFeedbackDialogEs,
      ProductsBarCodeDropdownEs,
      MidasCloseOrderEs,
      AcquisitionsOrdersApprovalEs,
      MidaCloseOrdersHelpEs,
      SuccessOrderEs,
      StepsDialogMasterEs,
      XmlDialogEs,
      XmlProductsDialogEs,
      DetailXmlDialogEs,
      DeletePurchaseXmlDialogEs,
      LocalStorageDialogEs,
      PurchasesEs,
      PurchasesFiltersEs,
      PurchasesHelpEs,
      PurchaseDetailsEs,
      PurchaseDetailsHelpEs,
      ChangeOwnershipRequestEs,
      ChangeOwnershipApprovalDialogEs,
      MyRequestsEs,
      SalesReportsEs,
      SalesReportFiltersEs,
      PickListDialogExcelEs,
      PromotionsEs,
      DiscountsEs,
      CustomerProfileEs,
      AuditEs,
      TransactionsContextEs,
      TelemetryContextEs,
      DiscountsContextEs,
      CustomerProfileEs,
      CatalogEs,
      CustomerRegistrationEs,
      MyBillsEs,
  ),
  pt: Object.assign({},
    ContextPt,
    AppLoginPt,
    WebNavigationDrawerPt,
    AppPt,
    WebAppBarPt,
    TenantFeedbackDialogPt,
    TenantFeedbackPt,
    DashboardHelpPt,
    HelpPt,
    MobileNavigationDrawerPt,
    DashboardPt,
    TotalPurchasePt,
    AverageTicketPt,
    BarChartPt,
    PointOfSalesMultipleSelectorPt,
    PosMostSalesPt,
    DateIntervalsPt,
    LastSupplyPt,
    ConnectivityPointOfSalePt,
    ProductMostSoldPt,
    MarketIntelAuthorizationPt,
    TopSellingProductsPt,
    TopSellingProductsHelpPt,
    InformationPt,
    BillingByResidentsHelpPt,
    BillingByResidentsPt,
    StateSelectorFilterPt,
    MarketAverageTicketHelpPt,
    MarketAverageTicketPt,
    ProductSalesInfoHelpPt,
    ProductSalesInfoPt,
    ValidationsPt,
    DateIntervalIntelCardFilterPt,
    PlanogramMainPt,
    PlanogramCreationDialogPt,
    PointOfSalesSelectorPt,
    PlanogramPt,
    PlanogramAddOrUpdateDialogPt,
    PlanogramEntryDialogPt,
    RulesPt,
    PlanogramRemoveItemDialogPt,
    ProductsFilterPt,
    PlanogramMainHelpPt,
    PlanogramStatusDescriptionDialogPt,
    PlanogramStatusChipPt,
    PlanogramDraftEditionPt,
    AddEntriesBulkPt,
    InventoryChangeDialogPt,
    BulkProgressDialogPt,
    ProductsMasterPt,
    ProductsPt,
    DownloadDialogPt,
    ProductDialogPt,
    EditableImagePt,
    DropzoneOptionsPt,
    ProductDialogHelpPt,
    TaxationsPt,
    CrudSelectPt,
    CategoriesPt,
    ProductGroupsPt,
    ProductGroupsHelpPt,
    ProductGroupDialogHelpPt,
    ProductGroupDialogPt,
    DownloadsPt,
    TransactionsPt,
    InvoiceDialogPt,
    InvoiceXmlPt,
    InvalidationRequestPt,
    InvalidationsDialogPt,
    BulkRetryInvoicePt,
    TransactionsHelpPt,
    PaymentMethodsPt,
    PointOfSalePt,
    LocksPt,
    LockFormPt,
    QrCodeOperatorPt,
    InvoiceConfigPt,
    MobileActivationPt,
    PointOfSaleImageSelectorPt,
    PosTypeSelectorPt,
    PointOfSaleTypesPt,
    LocalTypeSelectorPt,
    NetworkIconPt,
    RemoteCreditPt,
    SalesChartPt,
    SupplyPt,
    PushNotificationDialogPt,
    RebootDialogPt,
    PosMachineStatusPt,
    PosWithoutPickListPt,
    NegativeItemsDialogPt,
    NegativeProductDetailsPt,
    PickListsPt,
    PickListTablePt,
    PickListDetailsPt,
    TransactionsReportsFiltersPt,
    TransactionsReportsPt,
    FilterNamesTranslationsPt,
    TaxingBulkRemoveDialogPt,
    TaxingUsaPt,
    TaxingUsaProductsDialogPt,
    TaxingBrazilPt,
    TaxingDialogPt,
    TaxingProductsDialogPt,
    BuildingManagerDropDownPt,
    TenantGroupDialogPt,
    UsersDialogHelpPt,
    UserDialogPt,
    UserPt,
    RolesAndRoutesPt,
    BuildingManagerSalesReportPt,
    DialogProductsUnusedPt,
    DialogCreateDraftProductUnusedPt,
    PosMinoritySalesPt,
    PosMinoritySalesHelpPt,
    PosMostSalesHelpPt,
    NewDashboardPt,
    ProductsCloseToExpirationPt,
    ProductsCloseToExpirationHelpPt,
    LockDialogPt,
    LastSupplyHelpPt,
    LastSupplyNewPt,
    StatusPicklistPt,
    SalesByPaymentMethodPt,
    SalesByPaymentMethodHelpPt,
    StepDialogManualPt,
    InvoiceManualPt,
    ProductsManualPt,
    DetailDialogPt,
    RevenueMonthPt,
    MonthsPt,
    MostSoldCategoriesPt,
    LossesProductAndPosHelpPt,
    LossesProductAndPosPt,
    SalesSummaryPt,
    SalesByWeekdayPt,
    NpsFeedbackDialogPt,
    ProductsBarCodeDropdownPt,
    MidasCloseOrderPt,
    AcquisitionsOrdersApprovalPt,
    MidaCloseOrdersHelpPt,
    SuccessOrderPt,
    StepsDialogMasterPt,
    XmlDialogPt,
    XmlProductsDialogPt,
    DetailXmlDialogPt,
    DeletePurchaseXmlDialogPt,
    LocalStorageDialogPt,
    PurchasesPt,
    PurchasesFiltersPt,
    PurchasesHelpPt,
    PurchaseDetailsPt,
    PurchaseDetailsHelpPt,
    ChangeOwnershipRequestPt,
    ChangeOwnershipApprovalDialogPt,
    MyRequestsPt,
    DiscountsPt,
    SalesReportsPt,
    SalesReportFiltersPt,
    PickListDialogExcelPt,
    PromotionsPt,
    CustomerProfilePt,
    AuditPt,
    TransactionsContextPt,
    TelemetryContextPt,
    DiscountsContextPt,
    CatalogPt,
    CustomerRegistrationPt,
    MyBillsPt,
    )
 };

const i18n = new VueI18n({
  locale: navigator.language.split('-')[0],
  fallbackLocale: 'pt',
  silentTranslationWarn: false,
  messages,
});

export default i18n;
