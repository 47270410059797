import {defineStore} from "pinia";
import agent from "@/api/agent";
import {ref} from "vue";
import {useAlertsStore} from "@/stores/alertsStore";
import {Catalog, CatalogItem, catalogItemInitialState} from "@/types/AcquisitionOrders";

export const useCatalogsStore = defineStore('catalogs', () => {
  const alertsStore = useAlertsStore()

  const environmentCatalog = ref<Catalog>({items: [], description: '', tenantId: 0});
  const catalogItemToHandle = ref<CatalogItem>(catalogItemInitialState.value);

  const loadingEnvironmentCatalog = ref(false);
  const loadingHandlingCatalogItem = ref(false);

  const acquisitionOrderCatalogItemDetailsDialog = ref(false);
  const environmentCatalogFavoriteDialog = ref(false);
  const environmentCatalogNotFavoriteDialog = ref(false);

  async function getEnvironmentCatalog() {
    loadingEnvironmentCatalog.value = true;
    await agent.Catalogs.getEnvironmentCatalog()
      .then((response) => {
        environmentCatalog.value = response;
      })
      .catch((error) => alertsStore.showErrorSnackbar(error.response.data))
      .finally(() => loadingEnvironmentCatalog.value = false);
  }

  function handleAcquisitionOrderCatalogItemDetails(item: CatalogItem) {
    catalogItemToHandle.value = item;
    acquisitionOrderCatalogItemDetailsDialog.value = true;
  }

  function handleFavoriteCatalogItemSwitcher(item: CatalogItem, newValue: boolean) {
    catalogItemToHandle.value = item;
    chooseFavoriteDialog(newValue);
  }

  function chooseFavoriteDialog(newValue: boolean) {
    if (newValue) {
      environmentCatalogFavoriteDialog.value = true
    } else {
      environmentCatalogNotFavoriteDialog.value = true
    }
  }

  async function undoIsFavoriteCatalogItemState() {
    catalogItemToHandle.value.isFavorite = !catalogItemToHandle.value.isFavorite
  }

  async function favoriteCatalogItem() {
    loadingHandlingCatalogItem.value = true;
    await agent.Catalogs.favoriteItem(catalogItemToHandle.value.id)
      .then(() => {
        environmentCatalogFavoriteDialog.value = false
      })
      .catch((error: any) => {alertsStore.showErrorSnackbar(error.response.data)})
      .finally(() => loadingHandlingCatalogItem.value = false);
  }

  async function notFavoriteCatalogItem() {
    loadingHandlingCatalogItem.value = true;
    await agent.Catalogs.notFavoriteItem(catalogItemToHandle.value.id)
      .then(() => {
        environmentCatalogNotFavoriteDialog.value = false
      })
      .catch((error: any) => {alertsStore.showErrorSnackbar(error.response.data)})
      .finally(() => loadingHandlingCatalogItem.value = false);
  }

  return {
    acquisitionOrderCatalogItemDetailsDialog,
    catalogItemToHandle,
    environmentCatalog,
    environmentCatalogFavoriteDialog,
    environmentCatalogNotFavoriteDialog,
    favoriteCatalogItem,
    getEnvironmentCatalog,
    handleAcquisitionOrderCatalogItemDetails,
    handleFavoriteCatalogItemSwitcher,
    loadingEnvironmentCatalog,
    loadingHandlingCatalogItem,
    notFavoriteCatalogItem,
    undoIsFavoriteCatalogItemState
  }
})
