
import {Vue} from 'vue-property-decorator';
import agent from "@/api/agent";
import CancelDialogButton from "@/components/dialog/button/CancelDialogButton.vue";
import ConfirmDialogButton from "@/components/dialog/button/ConfirmDialogButton.vue";

export default Vue.extend({
  components: {ConfirmDialogButton, CancelDialogButton},
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false,
    },
    averageCostItem: {
      type: Object,
      default: () => {
      },
      required: true,
    }
  },
  data() {
    return {
      averageCost: null as number | null,
      loading: false,
      valid: false,
    }
  },
  computed: {
    dialog: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean): void {
        this.$emit('input', value);
      }
    },
  },
  watch: {
    averageCostItem: {
      handler(): void {
        this.averageCost = this.averageCostItem.averageCost;
      },
      deep: true
    },
    dialog: {
      handler(): void {
        (this.$refs.form as any)?.resetValidation();
      }
    }
  },
  methods: {
    async correctAverageCost(): Promise<void> {
      if (this.averageCost != this.averageCostItem.averageCost && !(this.$refs.form as any).validate()) {
        return;
      }
      this.loading = true;
      const averageCostValue = Number(this.averageCost);
      await agent.Inventory.correctInventoryItemAverageCost(this.averageCostItem.inventoryItemId, averageCostValue)
        .then((averageCostUpdated) => {
          this.$emit("update", averageCostUpdated);
          this.dismissDialog();
        })
        .catch((error: any) => {
          (this.$refs.feedback as any).handleError(this.$t('inventory.items.dialogs.averageCost.messages.error.averageCostUpdated'), error);
        }).finally(() => {
          this.loading = false;
        });
    },
    dismissDialog() {
      this.averageCost = null;
      this.dialog = false;
    },
  }
})
