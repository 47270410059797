export default {
    tittleDialog: 'ATTENTION',
    approvedTextDialogDescription: 'This process refers only to the transfer of ownership of POS within the system. The payment machine transfer with the acquirer <b>is also necessary</b>. <span style="color:#FF5252;">If you have not yet completed the transfer</span>, follow the instructions provided in the following video to complete the terminal migration.</br> If this transfer is not completed, the stores revenue will continue to be credited to the former owner.',
    rejectedTextDialogStart: 'Are you sure you want to <span style="color:#FF5252;">reject</span> this POS transfer? This action is irreversible and will require you to restart the entire process.',
    rejectedTextDialogComment: 'Please leave a comment reporting the reason for the rejection',
    rejectedCommentPlaceHolder: 'Leave the reason here...',
    btnApproved: 'APPROVE',
    btnRejected: 'YES',
    btnClose: 'CLOSE',
    termOfResponsibilityDataWipe: 'I understand that by accepting the change of ownership, I am responsible for wiping all data from the machine before using it, ensuring the security of the information and the correct operation of the equipment.',
    termResponsibilityPOS: 'I understand that by declaring that I have completed the payment machine account transfer, I am aware that completing this step is essential for the proper functioning of the POS and for receiving the revenues.',
    termResponsibilityTransfer: 'I understand that I assume all administrative, operational, and financial responsibilities related to the machines and POS I am receiving from the former owner.',
    msgSuccessMigration: 'Two approvals have been confirmed: Point of sale successfully migrated.',
    dataWipeTitle: 'How to erase the data from the machine',
    dataWipeStep1: 'On the machine, click 5 times on <span style=\'font-weight:700;\'>Um produto AMLabs</span>;',
    dataWipeStep2: 'Enter the administrator code, which by default is 1234;',
    dataWipeStep3: 'Go to <span style=\'font-weight:700;\'>Configurações</span>;',
    dataWipeStep4: 'Scroll down to <span style=\'font-weight:700;\'>Apagar Dados</span>;',
};

