export default {
  newProductDialog: 'New product',
  editProductDialog: 'Edit product',
  ageRestricted: 'Forbidden for minors',
  conversionUnit: 'Conversion unit',
  codeProductDialog: 'Code',
  descriptionProductDialog: 'Description',
  categoryProductDialog: 'Category',
  defaultPriceProductDialog: "Default price ({sign})",
  barCodeProductDialog: 'Barcode',
  ncmProductDialog: 'NCM',
  extipiProductDialog: 'EX-TIPI',
  cestProductDialog: 'CEST',
  originProductDialog: 'Origin',
  purchaseSettings: 'Purchase Settings',
  addTaxGroupProductDialog: 'Add Tax Scenario',
  saveProductDialog: 'Save',
  closeProductDialog: 'Close',
  backToPlanogram: 'Back planogram',
  fiscalInformation: 'Fiscal Information',
  fiscalScenariosProductDialog: 'Fiscal Scenarios',
  productsMustBeAddedByErp: 'Products must be added by ERP',
  noPermissionToAddProduct: 'No permission to add product',
  noFiscalScenariosRegistered: 'No fiscal scenarios registered',
  errorSavingItemProductDialog: 'Error saving item',
  fiscalScenarioProductDialog: 'Fiscal Scenario',
  principalProductDialog: 'Main',
  deleteProductDialog: 'Delete',
  fiscalScenarioAddedSuccessfully: 'Fiscal Scenario added successfully',
  unitProductDialog: 'Unit',
  quantityPerProductDialog: 'Quantity per ',
  informationUpdatedSuccessfully: 'Information updated successfully',
  synchronizedWithErp: 'This product is synchronized with the ERP. Some fields can only be changed by the ERP.',
  noTaxScenarios: 'There are no tax scenarios for the produc',
  uniMustBeA: 'The quanity per "unit" must be 1',
  saveItemErrorProductDialog: 'Error saving product',
  getTaxGroupsCfop: ' - CFOP: ',
};
