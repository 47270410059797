export default {
    resetSelectedPickListDetails: 'Restablecer seleccionados',
    showZeroPickListDetails: 'Mostrar ceros',
    searchProductPickListDetails: 'Buscar producto',
    productCodePickListDetails: 'Código de producto',
    productNamePickListDetails: 'Nombre del producto',
    productBarCodePickListDetails: 'Código de barras',
    categoryPickListDetails: 'Categoría',
    quantityPickListDetails: 'Cantidad',
    currentQuantityPickListDetails: 'Cant. actual',
    desiredQuantityPickListDetails: 'Cant. deseada',
    criticalMinimumPickListDetails: 'Mínimo crítico',
    salesThisWeekPickListDetails: 'Ventas últimos 7 días',
    centralStockQuantity: 'Cant. en almacén central',
    centralReservedStockQuantity: 'Cant. total reservada',
    closePickListDetails: 'Cerrar',
    pickListInfoPickListDetails: 'Lista de recogida',
    pickListInfoPosDetails: 'PDV',
    pickListInfoPlanogramDetails: 'Planograma',
    pickListInfoCreatedDetails: 'Creada: ',
    pickListInfoUpdatedDetails: 'Actualizada: ',
    pickListInfoParentDetails: 'Pick list principal: ',
    changeErrorPickListDetails: 'Error al cambiar la cantidad',
    quantityNotChangedPickListDetails: 'Cantidad no cambiada: formato del número es inválido',
    cancelPickListDetails: 'Cancelar',
    toSavePickListDetails: 'Guardar',
    emptySelectedError: 'Error al restablecer artículos',
    pendingPickListDetails: 'Pendiente',
    usedPickListDetails: 'Usada',
    picklistSplitButton: 'Dividir Pick List',
    picklistSplitError: 'Error al dividir la pick list',
    splitPickListConfirmation: {
      title: 'Dividir pick list',
      splitType: 'Tipo de división',
      splitByStockAddress: 'Por dirección de inventario',
      message: `<p>Para dividir correctamente, la <strong>dirección de inventario</strong> debe seguir el patrón: <strong>A-01</strong> (donde "A" es la letra del pasillo/local y "01" es la posición del producto en el pasillo/local).</p>
      <p>Los productos se agruparán por <strong>letra del pasillo/local</strong>, creando una pick list para cada uno. Los productos sin <strong>dirección de inventario</strong> se colocarán en una pick list separada.</p>
      <p>¿Está seguro de que desea dividir la <strong>pick list principal</strong> en múltiples pick lists, organizadas por <strong>dirección de inventario</strong>?</p>`
    }
};
