<template>
  <v-container class="pa-0 ma-0" fluid>
    <purchases-help/>
    <purchases-filters @modify="updateFilters"></purchases-filters>

    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="options"
      :server-items-length="totalItems"
      @click:row="handleClick"
      :footer-props="{ itemsPerPageOptions: [5, 10, 15, 30] }"
    >
      <template #[`item.cost`]="{ item }">
        {{ $currencyFormatter.format(item.cost) }}
      </template>
      <template #[`item.isCanceled`]="{ item }">
        {{ (item.isCanceled) ? $t('statusCanceled') : $t('statusActive') }}
      </template>
      <template #[`item.nfeNumber`]="{ item }">
        {{ (item.nfeNumber) ? item.nfeNumber : "-"  }}
      </template>
      <template #[`item.nfeCost`]="{ item }">
        {{ (item.nfeCost) ? $currencyFormatter.format(item.nfeCost) : "-"  }}
      </template>
    </v-data-table>

    <br>
    <br>

    <purchase-details
      v-model="purchaseDialog.show"
      :purchase="purchaseDialog.purchase"
      @input="purchaseDialog.purchase = null"
    />
    <feedback ref="feedback"></feedback>
  </v-container>
</template>

<script>
import {shouldRefreshData} from '@/components/TableOptionsComparator';
import {toApiPagination} from '../../components/TableOptionsComparator';
import moment from "moment";
import PurchasesFilters from '@/views/purchases/PurchasesFilters';
import PurchaseDetails from '@/views/purchases/PurchaseDetails';
import PurchasesHelp from "@/views/purchases/helpers/PurchasesHelp.vue";
import agent from "@/api/agent";
import i18n from '@/locales/i18n';

export default {
  components: {
    PurchasesFilters,
    PurchaseDetails,
    PurchasesHelp
  },
  watch: {
    options: {
      handler(newOptions, oldOptions) {
        if (shouldRefreshData(newOptions, oldOptions)) {
          this.getPurchases();
        }
      },
      deep: true
    }
  },
  data() {
    const $currencyFormatter = this.$currencyFormatter;
    return {
      headers: [
        {text: i18n.t('purchasesHeaderIdOperation'), align: 'left', sortable: true, value: 'id'},
        {text: i18n.t('purchasesHeaderStatus'), align: 'left', sortable: false, value: 'isCanceled' },
        {text: i18n.t('purchasesHeaderIdErp'), align: 'left', sortable: true, value: 'externalId'},
        {text: i18n.t('purchasesHeaderNoteNumber'), align: 'left', sortable: true, value: 'nfeNumber'},
        {text: i18n.t('purchasesHeaderInventoryId'), align: 'left', sortable: true, value: 'inventoryId'},
        {text: i18n.t('purchasesHeaderStock'), align: 'left', sortable: true, value: 'inventoryName'},
        {text: i18n.t('purchasesHeaderQuantityItems'), align: 'left', sortable: true, value: 'amount'},
        {text: `${i18n.t('purchasesHeaderNoteValue')} (${$currencyFormatter.getSign()})`, align: 'left', sortable: true, value: 'nfeCost'},
        {text: `${i18n.t('purchasesHeaderTotalCostPurchase')} (${$currencyFormatter.getSign()})`, align: 'left', sortable: true, value: 'cost'},
        {text: i18n.t('purchasesHeaderReceivedDate'), align: 'left', sortable: true, value: 'dateReceived'}
      ],
      items: [],
      loading: false,
      form: {
        minDate: null,
        maxDate: null,
        inventoryId: null,
        productId: null
      },
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['dateReceived'],
        sortDesc: [true]
      },
      search: "",
      totalItems: 0,
      purchaseDialog: {
        show: false,
        purchase: null
      }
    }
  },
  methods: {
    updateFilters(form) {
      this.form = form;
      this.options.page = 1;
      this.getPurchases();
    },
    getPurchases() {
      this.loading = true;
      let params = toApiPagination(this.options, this.search);
      this.insertFiltersFromForm(params);
      agent.Purchases.list(params)
        .then((response) => {
          this.items = response.items;
          this.items.map(i => i.dateReceived = moment(i.dateReceived).format("DD/MM/YYYY, HH:mm:ss"))
          this.totalItems = response.totalItems;
        })
        .catch((error) => this.$refs.feedback.handleError(this.$t('purchasesError'), error))
        .then(() => this.loading = false);
    },
    insertFiltersFromForm(params) {
      const form = this.form;
      params.minDate = form.minDate;
      params.maxDate = form.maxDate;
      params.inventoryId = form.inventoryId;
      params.productId = form.productId;
      return params;
    },
    handleClick(value) {
      this.purchaseDialog.show = true;
      let purchase = value;
      const purchases = this.items;
      purchase.isLastPurchase = purchases.indexOf(purchase) === 0;
      this.purchaseDialog.purchase = purchase;
    },
  }
}
</script>

<style>

</style>
