<template>
  <v-dialog v-model="dialog" @keydown.esc="dialog = false" max-width="70%">
    <v-card v-if="operation != null">
      <v-card-title class="blue-grey lighten-5">
        <v-layout row>
          <v-flex md6>
            <div class="title">{{(operation.pointOfSale) ? operation.pointOfSale.customerName : $t('inventory.operation.details.inventory')}}</div>
            <div class="info-subtitle">
              {{ $t('inventory.operation.details.pos')}} {{(operation.pointOfSale) ? operation.pointOfSale.id : "-"}}<br>
              {{(operation.pointOfSale) ? operation.pointOfSale.localName : "-"}} / 
              {{(operation.pointOfSale) ? operation.pointOfSale.specificLocation : "-"}}<br>
              {{$t('inventory.operation.details.observation')}}: {{(operation.comments) ? operation.comments : "-" }}
            </div>
          </v-flex>
          
          <v-flex md6 class="text-right">
            <div class="info-subtitle mt-2">
              {{$t('inventory.operation.details.started')}} {{ $moment(operation.dateStarted).format('DD/MM/YYYY HH:mm') }}<br>
              {{$t('inventory.operation.details.finished')}} {{ $moment(operation.dateCompleted).format('DD/MM/YYYY HH:mm') }}<br>
              {{$t('inventory.operation.details.elapsedtime')}} {{ operation.totalTime.includes(".") ? operation.totalTime.split(".")[0] : operation.totalTime  }} <br>
              {{$t('inventory.operation.details.type')}}: {{ translateOperationType(operation.type, operation.isBlindOperation) }}
              <span v-if="operation.type != 'Inventory'">
                <br>
                {{ operation.pickListId ? `Pick List ${operation.pickListId}` : $t('inventory.operation.details.completedSupply') }}
              </span>
              <span v-if="operation.userEmail">
                <br>
                {{$t('inventory.operation.details.users')}}: {{ operation.userEmail }}
              </span>
            </div>
          </v-flex>
        </v-layout>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-layout justify-end>
          <v-text-field
            append-icon="search"
            class=" mt-4"
            clearable
            dense
            filled
            hide-details
            :label="$t('filters.labels.search')"
            rounded
            single-line
            v-model="searchItemText"
            @input="searchItem"
            @click:append="searchItem"
            @click:clear="searchItemText = ''; searchItem()"
            style="max-width: 200px;"
          />
        </v-layout>
      </v-card-text>

      <v-data-table
        :headers="createHeaders()"
        fixed-header
        :height="filteredItems.length > 10 ? '500px' : ``"
        :items="filteredItems"
        hide-default-footer
        disable-pagination 
        :loading="loading.filteredItems"
      >
        <template #[`item.totalRemoved`]="{ item }">
          <div v-for="(action, index) in item.actions.filter(a => a.variation < 0)" :key="index">
        {{ -action.variation }} ({{ translateRemovalReason(action.reason) }})
          </div>
        </template>
        <template #[`item.productExpirationDate`]="{ value }">
          {{ value ? $moment.utc(value).format('DD/MM/YYYY') : '' }}
        </template>
        <template #[`item.finalQuantity`]="{ item }">
          <strong>{{ item.finalQuantity }}</strong>
        </template>
      </v-data-table>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          :disabled="operation.isCanceled"
          class="mx-2"
          color="error"
          @click.native="deleteOperation(operation)"
          :loading="loading.undoOperation"
        >
          <v-icon left>mdi-undo-variant</v-icon>{{ $t('buttons.undo')}}
        </v-btn>

        <v-btn 
          text color="primary" 
          @click.native="dialog = false"
        >{{ $t('buttons.close')}}</v-btn>

      </v-card-actions>
    </v-card>
    <feedback ref="feedback"></feedback>
  </v-dialog>
</template>

<script>
import OperationHelper from '@/views/operations/OperationCommon';
import Feedback from '../../components/Feedback.vue';

export default {
  components: { Feedback },
  props: {
    value: { type: Boolean, required: true },
    operation: { type: Object, default: null }
  },
  data() {
    return {
      loading: {
        undoOperation: false,
        filteredItems: false,
      },
      filteredItems: [],
      searchItemText:'',
    };
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.searchItemText = '' 
        this.$emit('input', value);
      }
    },
  },
  watch: {
    operation(op) {
      if (op != null) {
        op.supplyItems.forEach(item => {
          item.totalRemoved = item.actions.filter(a => a.variation < 0)
            .reduce((acc, action) => acc - action.variation, 0);
            
          if (this.operation.type === 'Inventory') {
            item.finalQuantity = (item.confirmedQuantity !== null ? item.confirmedQuantity : item.previousQuantity) - (item.totalRemoved || 0); 
          } else if (this.operation.type === 'Supply') {
            item.finalQuantity = (item.previousQuantity || 0) + (item.suppliedQuantity || 0) - (item.totalRemoved || 0);              
          } else if (this.operation.type === 'Combined') {
            item.finalQuantity = (item.confirmedQuantity !== null ? item.confirmedQuantity : item.previousQuantity) + (item.suppliedQuantity || 0) - (item.totalRemoved || 0);
          } else {
            item.finalQuantity = item.previousQuantity;
          }
        });
        this.filteredItems = op.supplyItems;
      }
    }
  },
  methods: {
    deleteOperation(operation) {
      let uuid = operation.uuid
      this.loading.undoOperation = true;
      this.axios
        .delete(`api/Inventory/Undo/Operation/${uuid}`)
        .then(() => {
          this.handleSuccess(this.$t('inventory.operation.messages.success.undo'));
        }).catch((error) => this.handleError(this.$t('inventory.operation.messages.error.undo'), error))
          .then(() => {
            this.loading.undoOperation = false;
          });
    },
    handleSuccess(message) {
      this.$refs.feedback.handleSuccess(message);
    },
    handleError(message, error) {
      this.$refs.feedback.handleError(message, error);
    },
    createHeaders() {
      const headers = [
        { text: this.$t('tables.headers.productCode'), align: 'left', sortable: true, value: 'productCode' },
        { text: this.$t('tables.headers.product'), align: 'left', sortable: true, value: 'productDescription' },
        { text: this.$t('tables.headers.capacity'), align: 'left', sortable: true, value: 'capacity' },
        { text: this.$t('tables.headers.previousQuantity'), align: 'left', sortable: true, value: 'previousQuantity' },
        { text: this.$t('tables.headers.validDate'), align: 'left', sortable: true, value: 'productExpirationDate' },
      ]
      if (this.operation.type === 'Inventory' || this.operation.type === 'Combined') {
        headers.push({ text: this.$t('tables.headers.confirmedQuantity'), align: 'left', sortable: true, value: 'confirmedQuantity' });
      }
      if (this.operation.type === 'Supply' || this.operation.type === 'Combined') {
        headers.push({ text: this.$t('tables.headers.supply'), align: 'left', sortable: true, value: 'quantityToSupply' });
        headers.push({ text: this.$t('tables.headers.supplyQuantity'), align: 'left', sortable: true, value: 'suppliedQuantity' });
      }
      if (this.operation.type === 'Picking') {
        headers.push({ text: this.$t('tables.headers.separetedQuantity'), align: 'left', sortable: true, value: 'suppliedQuantity' });
        return headers.filter(header => header.value !== 'capacity');
      }
      headers.push({ text: this.$t('tables.headers.removals'), align: 'left', sortable: true, value: 'totalRemoved' });
      headers.push({ text: this.$t('tables.headers.endQuantity'), align: 'left', sortable: true, value: 'finalQuantity' });
      return headers;
    },
    searchItem() {
      this.loading.filteredItems = true;
      if(this.searchItemText === '' || this.searchItemText === null) {
        this.filteredItems = this.operation.supplyItems;
        this.loading.filteredItems = false;
        return;
      }
      
      this.filteredItems = this.operation.supplyItems.filter(item => {
        return item.productCode?.toLowerCase().includes(this.searchItemText.toLowerCase()) || item.productDescription.toLowerCase().includes(this.searchItemText.toLowerCase());
      });
      this.loading.filteredItems = false;
    },
    translateOperationType: OperationHelper.translateOperationType,
    translateRemovalReason: OperationHelper.translateRemovalReason,
  }
}
</script>