export default {
    returnToPrevious: 'Voltar',
    customer: 'Cliente',
    environmentId: 'ID Ambiente',
    email: 'Email',
    permissions: 'Permissões',
    favoritePage: 'Favoritar página',
    removeFromFavorites: 'Remover dos favoritos',
    myAccount: 'MINHA CONTA',
    newOrder: 'Novo Pedido',
    changeOwnership: 'TROCA DE TITULARIDADE',
    requestChangeOwnership: 'Transferir Pontos de Venda',
    viewTransferRequests: 'Aprovar Transferências',
    myBills: 'Minhas Faturas',
    support: 'Suporte',
    changeTenant: 'Alterar tenant',
    changeConfirmation: 'Alterar',
    myProfile: 'Meu Perfil',
    viewAcquisitionsOrders: 'Aprovações de Pedidos',
    environments: 'Ambientes',
    amlabsNotices: 'Gerenciamento de Avisos',
 };
