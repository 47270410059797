export default {
  actions: 'Acciones',
  adminApprovalStatus: 'Estado de aprobación: Administrador',
  adminRejectionReason: 'Motivo de rechazo de la franquicia',
  agreeWithTermOfResponsibilityTransfer: 'Entiendo que al transferir la cuenta de la máquina de pago, todas las responsabilidades administrativas, operativas y financieras relacionadas con este dispositivo pasarán a ser responsabilidad exclusiva del nuevo titular. Esto incluye, pero no se limita a, tarifas mensuales y visibilidad del POS en la telemetría. Como antiguo titular, ya no tendré acceso a los ingresos de este POS.',
  agreeWithTermsOfResponsibilityPOS: 'Entiendo que soy responsable de transferir la cuenta de la máquina de pago y que solo entonces el CNPJ al que estoy transfiriendo podrá recibir los ingresos.',
  cnpjOfDestination: 'CNPJ al cual debe ser transferido',
  comments: 'Comentarios',
  contactSuporte: 'No fue posible actualizar el estado del intercambio. Si el problema persiste, comuníquese con el soporte: +55 19 99959-6630. Motivo del error ',
  customer: 'Cliente',
  destinationApprovalStatus: 'Estado de aprobación: Destino',
  destinationClientName: 'Nombre de la persona que recibirá el intercambio',
  destinationClientPhoneNumber: 'Número de teléfono de la persona que recibirá el intercambio',
  destinationDocumentId: 'Documento de destino',
  destinationEnvironment: 'Entorno de destino',
  destinationRejectionReason: 'Motivo de rechazo del entorno de destino',
  finalizationDate: 'Fecha de finalización',
  fromEnvironment: 'Intercambio de entorno ',
  helpDescription: 'Para completar el cambio de titularidad, es necesario proporcionar el CNPJ de la empresa y los datos completos de la persona responsable de recibir este cambio. Incluya el nombre y el número de teléfono de contacto para que el proceso se realice correctamente y el destinatario pueda ser contactado fácilmente.',
  helpTitle: 'Información del Titular de Destino',
  indoorLocation: 'Ubicación interna',
  linkApproveTransfers: 'Aprobar Transferencias.',
  location: 'Ubicación',
  no: 'No',
  originDocumentId: 'Documento de origen',
  originEnvironment: 'Entorno de origen',
  originRejectionReason: 'Motivo de rechazo del entorno de origen',
  pointsOfSaleHelpDescription: 'Seleccione los puntos de venta primarios (con planograma) para transferir al nuevo CNPJ. Las máquinas secundarias asociadas (que comparten el mismo planograma) se transferirán automáticamente.',
  pointsOfSaleHelpTitle: 'Puntos de venta',
  pointsOfSaleToBeTransferred: 'Puntos de venta a ser transferidos',
  requestDate: 'Fecha de solicitud',
  sameNetworkTransfer: 'Es esta transferencia dentro de la misma red/franquicia',
  seeDetails: 'Ver detalles',
  serialNumber: 'Número de serie',
  submitRequest: 'Enviar solicitud',
  systemCode: 'Código del sistema',
  termOfResponsibilityTransfer: 'TÉRMINOS DE RESPONSABILIDAD PARA LA TRANSFERENCIA DE TITULARIDAD',
  termsAndConditions: 'Términos y Condiciones',
  termsAndConditionsDescription: 'Esta transferencia se refiere solo al POS dentro del sistema y no cambia el lugar donde se acreditará la venta. Para realizar la transferencia de los datos de la máquina de tarjeta, es importante que vea el video a continuación:',
  termsAndConditionsHelp: 'Si tiene alguna duda sobre el proceso, póngase en contacto con nuestro equipo administrativo al ',
  termsOfResponsibilityPOS: 'TÉRMINOS DE RESPONSABILIDAD PARA LA TRANSFERENCIA DE LA MÁQUINA DE PAGO',
  titleDestination: 'Ingrese el CNPJ y los datos de contacto de la persona que recibirá el cambio de titularidad',
  toEnvironment: ' a entorno ',
  transfer: 'Transferencia',
  transferPointOfSale: 'Transferir punto de venta a otro CNPJ',
  transferProcessDescription: 'El proceso de transferencia de titularidad consiste en transferir una tienda de un CNPJ a otro. Para garantizar que este proceso se realice de manera fluida, siga los siguientes pasos. Después de enviar la solicitud, podrá seguir el progreso del proceso de transferencia en el menú ',
  viewComments: 'Ver comentarios',
  watchVideo: 'VER VIDEO',
  yes: 'Sí',
};
