
import Vue from 'vue';
import {MissingProduct} from '@/types/MissingProducts';
import { shouldRefreshData, toApiPagination } from "@/components/TableOptionsComparator";
import MissingProductsFilter from './components/MissingProductsFilter.vue';
import moment from 'moment';
import { MissingProducts } from "@/api/agent";
import download from '@/components/Download';
import DialogConfirmation from '@/components/DialogConfirmation.vue';
import ProductDialog from '../products/ProductDialog.vue';
import PlanogramAddOrUpdateDialog from '@/planogram/PlanogramAddOrUpdateDialog.vue';
import MissingProductsHelp from './helpers/MissingProductsHelp.vue';

export default Vue.extend ({
  data() {
    return {
      missingProducts: [] as MissingProduct [],
      headers: [
        { text: this.$t('tables.headers.barCode'), align: 'left', sortable: true, value: 'barCode' },
        { text: this.$t('tables.headers.productDescription'), align: 'left', sortable: true, value: 'productDescription' },
        { text: this.$t('tables.headers.category'), align: 'left', sortable: true, value: 'category' },
        { text: this.$t('tables.headers.productCode'), align: 'left', sortable: true, value: 'productCode' },
        { text: this.$t('tables.headers.dateOfReading'), align: 'left', sortable: true, value: 'date' },
        { text: this.$t('tables.headers.pointOfSaleReading'), align: 'left', sortable: false, value: 'pointOfSaleName' },
        { text: this.$t('tables.headers.typeOfAlert'), align: 'left', sortable: false, value: 'alertType' },
      ],
      loading: {
        excel: false,
        missingProducts: true,
      },
      form: {
        minDate: null,
        maxDate: null,
        pointOfSaleId: 0,
      },
      totalItems: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ["date"],
        sortDesc: [true],
        mustSort: true,
      },
      search: {
        text: '',
        valid: true,
      },
      productDialog: false as boolean,
      editedEntry: {
        barCode: '' as string,
        productTaxGroups: [] as any[],
      },
      editedIndex: -1,
      productRegistrationDisabled: false as boolean,
      selectedPosId: 0 as number,
      selectedProductId: 0 as number,
      dialogConfirmation: false as boolean,
      refreshProducts: false as boolean,
    };
  },
  watch: {
    options: {
      handler(newOptions, oldOptions) {
        if (shouldRefreshData(newOptions, oldOptions)) {
          this.getMissingProducts();
        }
      },
    },
  },
  methods: {
    updateFilters(form : any) {
      if (form.minDate != null || form.maxDate != null || form.pointOfSaleId != null) {
        this.options.page = 1;
      }
      this.form = form;
      this.getMissingProducts();
    },
    getMissingProducts() {
      this.loading.missingProducts = true;
      let params = toApiPagination(this.options, this.search.text);
      this.insertFiltersFromForm(params);
      MissingProducts.getMissingProducts(params)
        .then((response) => {
          this.loading.missingProducts = false;
          this.missingProducts = response.items;
          this.totalItems = response.totalItems;
        })
        .catch(() => {
          this.loading.missingProducts = false;
        });
    },
    insertFiltersFromForm(params: any) {
      const form = this.form;
      params.pointOfSaleId = form.pointOfSaleId;
      params.minDate = form.minDate;
      params.maxDate = form.maxDate;
      return params;
    },
    formatDate(date : string) {
      return moment(date).isValid() ? moment(date).format('DD/MM/YYYY HH:mm:ss') : "";
    },
    async getExcel() {
      this.loading.excel = true;
      try {
        const response = await MissingProducts.getExcel(this.form)
        download(response, this.$t('missingProducts.excelFileName'));
        (this as any).$refs.filter.notifyExcelDownloaded();
      } catch (error) {
        this.handleError(String(this.$t('error.excel')), error);
      } finally {
      (this as any).$refs.filter.notifyExcelDownloaded();
      }
    },
    openDialogConfirmation(pointOfSaleId: number, productId : number) {
      this.selectedPosId = pointOfSaleId;
      this.selectedProductId = productId;

      this.dialogConfirmation = true;
    },
    onPlanogramInsert() {
      this.dialogConfirmation = false;
      (this as any).$refs.planogramDialog.editedEntry = {
        productId: this.selectedProductId,
        posIds: this.selectedPosId,
      };

      (this as any).$refs.planogramDialog.show = true;
    },
    onEntryCreated() {
      this.handleSuccess((this as any).$t('saveSuccessfulIndividual'));
      this.getMissingProducts();
    },
    onProductDialogOpen(pointOfSaleId: number, barCode: string) {
      this.selectedPosId = pointOfSaleId;
      this.editedEntry = { barCode: barCode,  productTaxGroups: [] as any[] };
      this.productDialog = true;
    },
    onProductAdded(product: any) {
      this.productDialog = false;

      this.handleSuccess((this as any).$t('productSavedSuccessProduct'));
      this.getMissingProducts();
      this.refreshProducts = true;
      this.openDialogConfirmation(this.selectedPosId, product.id);
    },
    handleError(message: string, error : any) {
      (this as any).$refs.feedback.handleError(message, error);
    },
    handleSuccess(message: string) {
      (this as any).$refs.feedback.handleSuccess(message);
    },
  },
  components: {
    MissingProductsFilter,
    DialogConfirmation,
    ProductDialog,
    PlanogramAddOrUpdateDialog,
    MissingProductsHelp,
  },
});
