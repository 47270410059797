export default {
  catalogItemFavoriteDialogMessage: "Tem certeza que deseja <strong class='favorite-text'>Favoritar</strong> esse produto para compra?",
  catalogItemFavoriteDialogWarning: "Ao fazer isso, ele <strong>será exibido</strong> como uma recomendação para compra na página de <strong>Novo produto</strong>.",
  catalogItemNotFavoriteDialogMessage: "Tem certeza que deseja <strong class='not-favorite-text'>Desfavoritar</strong> esse produto para compra?",
  catalogItemNotFavoriteDialogWarning: "Ao fazer isso, ele <strong>deixará de ser exibido</strong> como uma recomendação para compra na página de <strong>Novo produto</strong>.",
  catalogProductsListToChoose: 'Produtos recomendados pela rede',
  catalogTab: 'Catálogo de Produtos',
  clickForDetails: 'Ver detalhes do produto',
  environmentCatalogHeader: 'Favoritar produtos para novos pedidos',
  environmentCatalogSubHeader: 'Selecione os produtos que deseja priorizar a exibição para compra em seus outros ambientes',
  favoriteCatalogItems: 'Favorito',
  finishCatalogItemsSelection: "Comprar",
  seeLess: "Ver menos",
  seeMore: "Ver mais",
  technicalInformation: 'Informações Técnicas',
}
