export default{
  pickLists:{
    title: 'Pick Lists',
    create:{
      title: 'Generate Pick Lists',
      pickListsDescription: 'Pick List description',
      pickListsByCategoryDescription: 'When grouped by category, each Pick List will be named according to the category name',
      pickListsByInventoryAddressDescription: 'When grouped by inventory address, each Pick List will be named according to the inventory address',
      allProducts: 'All planogram products',
      inventoryAddressLabel: 'Inventory address',
      categoryLabel: 'Filter products by category',
      inventoryAddressFilterLabel: 'Filter products by inventory address',
      productRestriction: 'Product restriction',
      criticalLabel: 'Include only products with critical stock',
      inventoryAvailable:'Include only products with an on-hand inventory balance',
      advancedSection: 'Advanced',
      secondaryLabel: 'Mark Pick List as secondary',
      groupByCategoryLabel: 'Generate a Pick List by category',
      groupByCategoryDisabledTooltip: 'To be able to generate a Pick List by category, you must mark it as a secondary Pick List and filter by at least one category',
      error: 'Error generating Pick List',
      success: 'Pick List successfully created'
    }
  }
}