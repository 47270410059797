import SocketService, { SocketResponse } from "@/api/SocketService";

export type CameraHub = {
  id: number;
  tenantId: number;
  groupId: number|null;
  pointOfSaleId: number;
  agent: string;
};

export type CameraHubResponse = {
  cameraHubs: CameraHub[];
}
export class CameraHubVideoRequest {
  constructor(
    public deviceId: number,
    public agent: string,
    public initialDate: string,
    public duration: number
  ) {}
}

export class VideoInfo {
  constructor(
    public pointOfSaleCustomerName: string,
    public pointOfSaleLocalName: string,
    public initialDate: string,
    public duration: number,
    public agent: string
  ) {}
}

export class CameraClickEvent {
  constructor(
    public pointOfSaleId: number,
    public pointOfSaleCustomerName: string,
    public pointOfSaleLocalName: string,
    public initialDate: string,
    public duration: number,
  ) {}
}

export class CameraHubStreamRequest {
  public videoInfo: VideoInfo;
  public videoRequest: CameraHubVideoRequest;

  constructor(
    public socket: SocketService,
    deviceId: number,
    agent: string,
    initialDate: string,
    duration: number,
    pointOfSaleCustomerName: string,
    pointOfSaleLocalName: string,
  ) {
    this.videoRequest = new CameraHubVideoRequest(deviceId, agent, initialDate, duration);
    this.videoInfo = new VideoInfo(pointOfSaleCustomerName, pointOfSaleLocalName, initialDate, duration, agent);
  }
}

export enum CameraHubStreamEventStatus {
  START = "Started",
  RUNNING = "Running",
  COMPLETED = "Completed",
  ERROR = "Error"
}

export interface CameraHubStreamEvent extends SocketResponse {
  event: CameraHubStreamEventStatus,
  message: string|null,
  url: string|null
}
