export default {
    pickListPointOfSaleNameColumn: 'Ponto de Venda',
    pickListIdColumn: 'ID da Pick List',
    pickListDateCreateColumn: 'Data de criação',
    pickListDateUpdateColumn: 'Data de atualização',
    pickListStatusColumn: 'Status',
    pickListPlanogramIdColumn: 'ID do Planograma',
    pickListMachineModelColumn: 'Modelo da máquina',
    pickListManufactureMachineColumn: 'Fabricante da máquina',

    productIdColumn: 'ID do Produto',
    productDescriptionColumn: 'Descrição',
    productQuantityColumn: 'Quantidade',
    productBarCodeColumn: 'Código de barras',
    productCodeColumn: 'Código do produto',
    productCategoryNameColumn: 'Categoria',
    productCurrentQuantityColumn : 'Quantidade atual',
    productQuantityToSupplyColumn: 'Quantidade desejada',
    productMinimumQuantityColumn: 'Mínimo crítico',
    distributionCenterAddressColumn: 'Endereço do estoque',

    PickLisTDialogTitle: 'Quais colunas deseja incluir no seu Excel?',
    PickListDialogSubTitle: 'Customize a pick list com as colunas que devem aparecer',
    PickListDialogFixedColumns: 'Colunas fixas da pick list',
    PickListDialogOptionalColumns: 'Colunas opcionais da pick list',
    PickListDialogItemsFixedColumns: 'Colunas fixas de itens',
    PickListDialogItemsOptionalColumns: 'Colunas opcionais de itens',
    PickListDialogExcelButton: 'Download',
    PickListDialogCloseButton: 'Fechar',
};
