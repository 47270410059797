
import Vue from 'vue'
import agent from "@/api/agent";

interface ConversionUnit {
  value: string;
  text: string;
}

export default Vue.extend({
  props: {
    value: { type: Boolean, required: true },
    item: { type: Object, default: () => {} }
  },
  watch: {
    item: {
      handler(): void {
        this.product = {
          id: this.item.product.id,
          conversionUnitRatio: this.item.product.conversionUnitRatio,
          conversionUnitName: this.item.product.conversionUnit
        }
      },
      deep: true
    }
  },
  computed: {
    show: {
      get(): Boolean {
        return this.value;
      },
      set(value: Boolean) {
        this.$emit('input', value);
      }
    },
  },
  data() {
    return {
      valid: false as boolean,
      loading: false as boolean,
      product: {
        id: 0 as number,
        conversionUnitRatio: 0 as number,
        conversionUnitName: '' as string,
      },
      conversionUnits: [] as ConversionUnit[],
    }
  },
  mounted() {
    this.getConversionUnitsList()
  },
  methods: {
    setUnitConversion() {
      if(!(this as any).$refs.form.validate()) {
        return;
      }

      this.loading = true
      const conversionUnit = {
        conversionUnitName: this.product.conversionUnitName,
        conversionUnitRatio: this.product.conversionUnitRatio
      };

      agent.Products.editConversionUnit(this.product.id, conversionUnit)
        .then(() => {
          this.$emit("updated");
          this.show = false;
        })
        .catch((error: any) => {
          (this.$refs.feedback as any).handleError(this.$t('products.errors.conversionUnit.update'), error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getConversionUnitsList(){
      agent.Products.getConversionUnitsList()
        .then((response) => {
          this.conversionUnits = response;
        })
    },
    unitRatioTextFieldLabel(): string {
      const value = this.conversionUnits.find(u => u.value == this.product.conversionUnitName);
      return `${this.$t('quantityPerProductDialog')} ${ value != null ? value.text : this.$t('unitProductDialog').toString() }`;
    },
    conversionUnitRules() {
      const rules = [];
      rules.push(this.$validations.integer);

      if (this.product.conversionUnitName == "Unit") {
        let isOne = (v: number) => v == 1 || this.$t('uniMustBeA');
        rules.push(isOne);
      }

      if (this.product.conversionUnitName != null) {
        let nonNull = this.$validations.nonEmpty;
        rules.push(nonNull);

        let isPositive = (v: number) => v > 0 || this.$t('isPositiveRules');
        rules.push(isPositive);
      }

      return rules;
    },
  }
})
