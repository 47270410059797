import ButtonPt from './ButtonPt';
import RemoveDialogPt from './RemoveDialogPt';
import PlanogramPt from './PlanogramPt';
import PriceTablesPt from './PriceTablesPt';
import PointOfSalePt from './PointOfSalePt';
import FranchiseePt from './FranchiseePt';
import TablesPt from './TablesPt';
import ReportPt from './ReportPt';
import FiltersPt from './FiltersPt';
import PromotionLogoPt from './PromotionLogoPt';
import SecurityPt from './SecurityPt';
import UnlockPt from './UnlockPt';
import UserPt from './UsersPt';
import BoxPt from './BoxPt';
import ProductsPt from './ProductsPt';
import ValidationsPt from './ValidationsPt';
import TenantPt from './TenantPt';
import InventoryPt from './InventoryPt';
import AlertsPt from './AlertsPt';
import ConnectivityPt from './ConnectivityPt';
import TelemetryPt from './TelemetryPt';
import MissingProductsPt from './MissingProductsPt';
import TaxingsPt from './TaxingsPt';
import PickListsPt from './PickListsPt';
import PurchasePt from './PurchasePt';
import DatesPt from './DatesPt';
import RuptureReportsPt from './RuptureReportsPt';
import PurchaseSuggestionsPt from './PurchaseSuggestionsPt';
import UsersFeedbackPt from './UsersFeedbackPt';

export default Object.assign({},
  PriceTablesPt,
  ButtonPt,
  PlanogramPt,
  RemoveDialogPt,
  PointOfSalePt,
  FranchiseePt,
  TablesPt,
  ReportPt,
  FiltersPt,
  PromotionLogoPt,
  SecurityPt,
  UnlockPt,
  UserPt,
  BoxPt,
  ProductsPt,
  ValidationsPt,
  TenantPt,
  AlertsPt,
  ConnectivityPt,
  TelemetryPt,
  InventoryPt,
  MissingProductsPt,
  TaxingsPt,
  PurchasePt,
  DatesPt,
  PickListsPt,
  RuptureReportsPt,
  PurchaseSuggestionsPt,
  UsersFeedbackPt
);
