export default {
    resetSelectedPickListDetails: 'Zerar selecionados',
    showZeroPickListDetails: 'Mostrar zerados',
    searchProductPickListDetails: 'Procurar produto',
    productCodePickListDetails: 'Código produto',
    productNamePickListDetails: 'Nome do Produto',
    productBarCodePickListDetails: 'Código de Barras',
    categoryPickListDetails: 'Categoria',
    quantityPickListDetails: 'Quantidade',
    currentQuantityPickListDetails: 'Qtd. Atual',
    desiredQuantityPickListDetails: 'Qtd. Desejada',
    criticalMinimumPickListDetails: 'Mínimo Crítico',
    salesThisWeekPickListDetails: 'Vendas Últimos 7 dias',
    centralStockQuantity: 'Qtd. Estoque Central',
    centralReservedStockQuantity: 'Qtd. Total Reservada',
    closePickListDetails: 'Fechar',
    pickListInfoPickListDetails: 'Pick List ',
    pickListInfoPosDetails: 'PDV',
    pickListInfoPlanogramDetails: ' Planograma ',
    pickListInfoCreatedDetails: 'Criada: ',
    pickListInfoUpdatedDetails: 'Atualizada: ',
    pickListInfoParentDetails: 'Pick list Pai: ',
    changeErrorPickListDetails: 'Erro ao alterar quantidade',
    quantityNotChangedPickListDetails: 'Quantidade não alterada: formato do número é inválido',
    cancelPickListDetails: 'Cancelar',
    toSavePickListDetails: 'Salvar',
    emptySelectedError: 'Erro ao alterar zerar itens',
    pendingPickListDetails: 'Pendente',
    usedPickListDetails: 'Usada',
    picklistSplitButton: 'Dividir Pick List',
    picklistSplitError: 'Erro ao dividir pick list',
    splitPickListConfirmation: {
    title: 'Dividir picklist',
    splitType: 'Tipo de divisão',
    splitByStockAddress: 'Por endereço de estoque',
    message: `<p>Para dividir corretamente, o <strong>endereço de estoque</strong> deve seguir o padrão: <strong>A-01</strong> (onde "A" é a letra do local/corredor e "01" é a posição do item no local/corredor).</p>
    <p>Produtos serão agrupados por <strong>letra do local/corredor</strong>, criando uma picklist para cada um. Produtos sem <strong>endereço de estoque</strong> serão colocados em uma picklist separada.</p>
    <p>Tem certeza de que deseja dividir a <strong>picklist principal</strong> em múltiplas picklists, organizadas por <strong>endereço de estoque</strong>?</p>`
    }
};
