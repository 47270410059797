
import Vue from 'vue';
import {useBillsStore} from "@/stores/billsStore";

export default Vue.extend({
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    imageUrl: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      doNotShowAgain: false,
    };
  },
  methods: {
    closeDialog() {
      if (this.doNotShowAgain) {
        const billsStore = useBillsStore(this.$pinia);
        billsStore.updateShowHelperGifDialog();
      }
      this.$emit('input', false);
    },
  },
});
