export default {
  userDialogNewUser: 'Novo Usuário',
  userDialogCreateNewUser: 'Cadastrar novo usuário',
  userDialogEditUser: 'Editar usuário',
  userDialogEmailLabel: 'Email',
  userDialogCheckEmailLabel: 'Confirmar Email',
  userDialogPasswordHint: 'Se você não preencher, uma senha será gerada para você',
  userDialogPasswordLabel: 'Senha (opcional)',
  userDialogCheckPasswordLabel: 'Confirmar Senha',
  userDialogPermissions: 'Permissões',
  userDialogAdministrator: 'Administrador',
  userDialogManagement: 'Gerência',
  userDialogAccounting: 'Contabilidade',
  userDialogManager: 'Gerente',
  userDialogBuildingManager: 'Síndico',
  userDialogSales: 'Vendas',
  userDialogAllFunctions: 'Todas as Funcionalidades',
  userDialogPriceEdit: 'Edição de Preços',
  userDialogPlanogramView: 'Visualização de Planogramas',
  userDialogTransactionsView: 'Visualização de Transações',
  userDialogSupply: 'Estoque e Abastecimento',
  userDialogTouchpayProvider: 'Touchpay Abastecedor',
  userDialogBlindInventory: 'Inventário às cegas e abastecimento',
  userDialogOnlySupplier: 'Apenas Abastecimento',
  userDialogOnlyPicking: 'Apenas Separação',
  userDialogTelemetry: 'Telemetria',
  userDialogRegistrations: 'Cadastros',
  userDialogApp: 'App',
  userDialogSafety: 'Segurança',
  userDialogPrivateLabel: 'Private Label',
  userDialogPrivateLabelAdmin: 'Administrador Private Label',
  userDialogVisualization: 'Visualização',
  userDialogInvoices: 'Notas Fiscais NFCe',
  userDialogClose: 'Fechar',
  userDialogSave: 'Salvar',
  userDialogCreate: 'Criar',
  userDialogEmailConfirmationRule: 'O email digitado está incorreto',
  userDialogPasswordConfirmationRule: 'A senha digitada está incorreta',
  userDialogUserCreated: 'Usuário criado. ',
  userDialogGeneratedPassword: 'Senha gerada: {password}',
  userDialogCreateUserError: 'Não foi possível criar o usuário',
  userDialogUserEdited: 'Usuário {userEmail} editado',
  userDialogEditUserError: 'Não foi possível editar o usuário',
  userDialogOrdersManagerRole: 'Gerenciador de pedidos',
  userDialogRemoteMonitoring: 'Monitoramento Remoto'
}
