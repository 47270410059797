export default {
    addressComplement: 'Complemento',
    addressComplementHint: 'Apartamento/Bloco. Caso contrário, deixe em branco.',
    addressNumber: 'Número do endereço',
    adminHasApproved: 'Aprovado',
    adminHasRejected: 'Reprovado',
    city: 'Cidade',
    confirmPassword: 'Confirmar sua senha',
    copyLink: 'Copiar Link',
    createPassword: 'Criar uma senha',
    CustomerRegistrationTable: 'Tabela de aprovação de novos clientes',
    documentId: 'CNPJ',
    documentIdAlreadyExists: 'CNPJ já cadastrado',
    emailAlreadyExists: 'E-mail já cadastrado',
    emailNotSentError: 'Não foi possível enviar o E-mail de confirmação para o franqueado.',
    environmentsPermissionError: 'Somente administradores de franquia podem acessar a página {pageName}',
    fetchRequestsError: 'Erro ao buscar solicitações',
    fillInPostalCode: 'Preencha o campo CEP',
    finalizeRegistration: 'Finalizar cadastro',
    fullName: 'Nome completo',
    generateNewLink: 'Gerar Novo Link',
    generateNewLinkDialogMessage: 'Ao gerar um novo link, o atual será desativado e não permitirá mais cadastros. Clientes que receberam o link antigo e não efetuaram o cadastro, devem receber este novo link para prosseguir. O link será válido até 23:59 do dia vigente.',
    generateNewLinkDialogTitle: 'Gerar novo link',
    generateRegistrationLink: 'Gerar Link de Cadastro',
    generateRegistrationLinkError: 'Erro ao gerar link de cadastro',
    invalidLink: 'Link expirado ou inválido',
    isRegistrationInfoNewError: 'Erro ao verificar se as informações de cadastro são novas',
    linkCopiedToClipboard: 'Link copiado com sucesso!',
    myFranchisees: 'Meus Franqueados',
    neighborhood: 'Bairro',
    newCustomerRegistrationText: 'Um novo link de cadastro é gerado diariamente, válido até 23:59 do dia vigente. Clique em <span style="font-weight:bold;"> “Copiar link”</span> para copiar o link e envie para seu cliente. Após o preenchimento, os dados do cliente serão exibidos na tabela abaixo, onde você poderá revisar e aprovar o cadastro.',
    newCustomersRegistration: 'Cadastro de Novos Clientes',
    passwordConfirmed: "Senha confirmada com sucesso!",
    passwordLengthError: 'A senha deve ter no mínimo 8 caracteres',
    passwordMismatch: 'As senhas não coincidem',
    personalData: 'Dados pessoais',
    postalCode: 'CEP',
    postalCodeError: 'Erro ao buscar CEP. Tente novamente ou preencha as informações manualmente.',
    postalCodeOnlyNumbers: 'Preencha o CEP sem pontuação, apenas com números!',
    registrationApprovedDialogText: 'Confirmar a <span style="color:#2DCE98;">aprovação</span> desse pedido de cadastro? </br></br> Ao fazer isso, será iniciado o processo de criação de conta para esse novo usuário e ele pertencerá a sua franquia',
    registrationFormInstructions: 'Para iniciar, pedimos que você preencha as informações necessárias com atenção e precisão. Esses dados são fundamentais para criarmos o seu perfil e oferecermos o melhor atendimento. Após o envio do cadastro, ele será revisado e você será notificado sobre o resultado.',
    registrationFormWelcome: 'Bem-vindo ao Cadastro de Novos Clientes!',
    registrationLink: 'Link de cadastro',
    registrationRejectionDialogText: 'Confirmar a <span style="color:#FF5252;">reprovação</span> desse pedido de cadastro? Ao fazer isso, a solicitação será cancelada e todos os dados para cadastro serão perdidos',
    registrationRejectionReason: 'Comentário explicando o motivo da reprova:',
    state: 'UF',
    streetName: 'Nome da rua',
    successfulRegistration: '<span style="font-size:24px;font-weight:bold;line-height:1.5;color:white;">CADASTRO ENVIADO COM SUCESSO!</span> </br></br> <span style="color:white;font-size:20px;">Assim que o seu pedido for revisado, você será informado através do E-mail fornecido.</span>',
}