export default {
  tables: {
    headers: {
      id: 'Id',
      pointOfSaleId: 'POS ID',
      actions: 'Actions',
      customer: 'Customer',
      location: 'Location',
      locationSubtitle: 'Location/Subtitle',
      specificLocation: 'Specific Location',
      idSubEnvironment: 'Sub Environment ID',
      subEnvironment: 'Sub Environment',
      cnpj: 'CNPJ',
      billing: 'Billing (last 30 days)',
      machines: 'Machine count',
      stores: 'Store count',
      storeBrand: 'Store brand',
      nps: 'NPS Average',
      name: 'Name',
      type: 'Type',
      lastUpdated: 'Update date',
      items: 'Items',
      activate: 'Activate',
      remove: 'Delete',
      code: 'Code',
      description: 'Description',
      barCode: 'Barcode',
      categories: 'Categories',
      category: 'Category',
      cost: 'Cost',
      price: 'Price',
      markUp: 'Markup',
      responsible: 'Responsible',
      applyPriceTableOnPointOfSale: 'Apply price table to points of sale',
      removePriceTable: 'Remove price table',
      visualizeFranchiseeEnvironment: 'View Environment',
      result: 'Result',
      value: 'Value',
      card: 'Card',
      verificationStatus: 'Verification Status',
      event: 'Event',
      channel: 'Channel',
      day: 'Day',
      month: 'Month',
      dateHour: 'Date/Hour',
      date: 'Date',
      dayOfWeek: 'Day of the Week',
      initialHour: 'Initial Hour',
      finalHour: 'Final Hour',
      duration: 'Duration',
      ip: 'IP',
      users: 'Users',
      parameters: 'Parameters',
      lockCode: 'Lock Code',
      lockName: 'Lock Name',
      details: 'Details',
      deviceName: 'Device Name',
      openingResponsible: 'Responsible for opening',
      cpf: 'CPF',
      ageRestriction: 'Age Restriction',
      status: 'Status',
      product: 'Product',
      productCode: 'Product Code',
      productCategory: 'Product Category',
      quantity: 'Quantity',
      qty: 'Qty.',
      cmv: 'COGS',
      margin: 'Margin',
      selection: 'Selection',
      nit: 'NIT',
      ein: 'EIN',
      associatedPos: 'Associated POS',
      inventory: 'Inventory',
      inventoryId: 'Inventory ID',
      inventoryAddress: 'Inventory Address',
      lastInventory: 'Last Inventory',
      lastPurchaseCost: 'Last Purchase Cost',
      meanCost: 'Mean Cost',
      totalCost: 'Total Cost',
      ratings: 'Rantings',
      reserved: 'Reserved',
      minimumCritical: 'Minimum Critical',
      minimumQuantity: 'Minimum Purchase Quantity',
      previousQuantity: 'Previous Quantity',
      originInventory: 'Origin inventory',
      movimentQuantity: 'Moved Quantity',
      destinyInventory: 'Destination inventory',
      endQuantity: 'Final Quantity',
      dateCreated: 'Date Created',
      operation: 'Operation',
      operationId: 'Operation ID',
      email: 'Email',
      active: 'Active',
      wiFiPercentage: 'Wi-Fi (%)',
      mobilePercentage: 'Mobile (%)',
      offlinePercentage: 'Offline (%)',
      pointOfSale: 'Point of Sale',
      productDescription: 'Product Description',
      dateOfReading: 'Reading Date',
      pointOfSaleReading: 'Point of Sale Read',
      typeOfAlert: 'Type of Alert',
      operationType: 'Operation Type',
      pickList: 'Pick List',
      finished: 'Finished on',
      elapsedtime: 'Elapsed Time',
      totalLosses: 'Total Losses',
      capacity: 'Capacity',
      validDate: 'Expiration Date',
      confirmedQuantity: 'Confirmed Quantity',
      supply: 'To Supply',
      supplyQuantity: 'Supply Quantity',
      separetedQuantity: 'Separated Quantity',
      removals: 'Removals',
      serialNumber: 'Serial Number',
      distributionCenter: 'Distribution Center',
      posCode: 'POS Code',
      version: 'Version',
      password: 'Password',
      model: 'Model',
      modelManufacturer: 'Model / Manufacturer',
      paymentSystem: 'Payment System',
      expires: 'Expires on',
      currentQty: 'Current Qty.',
      pos: 'POS',
      qtySold: 'Qty. sold',
      soldValue: 'Sold Value',
      expiredQty: 'Expired Qty.',
      expiredValue: 'Expired Value',
      damagedQty: 'Damaged Qty.',
      damagedValue: 'Damaged Value',
      stolenQty: 'Stolen Qty.',
      stolenValue: 'Stolen Value',
      otherLossesQty: 'Other Losses Qty.',
      otherLossesValue: 'Other Losses Value',
      gainQty: 'Gain Qty.',
      gainValue: 'Gain Value',
      defaultPrice: 'Default Price',
      occurrences: 'Occurrences',
      totalDays: 'Total Time (Days)',
      totalLossesValue: 'Total Losses',
      startDate: 'Start Date',
      endDate: 'End Date',
      time: 'Time (Days)',
      losses: 'Losses (Units)',
      lossesValue: 'Losses',
      originalValue: 'Original Value ({sign})',
      discount: 'Discount ({sign})',
      paymentAmount: 'Payment Amount ({sign})',
      marginPercentage: 'Margin (%)',
      marginValue: 'Margin ({sign})',
      totalTaxes: 'Tax ({sign})',
      method: 'Method',
      nfce: 'NFC-e',
      unitsToBuySuggestion: 'Suggestion(Unit)',
      ruptures: 'Breakages: {days} Day(s)',
      withdraw: 'Withdraw: {days} Day(s)',
      minimumQuantityAbbreviated: 'Min. Qty(Unit)',
      conversionUnitRatio: 'Conversion Unit',
      convertedToBuySuggestion: 'Suggestion (C.U.)',
      convertedMinimumPurchaseQuantityAbbreviated:'Min. Qty(C.U.)',
      daysInInventory: 'Inventory Time (days)',
      requestCode: 'Request code',
      requesterName: 'Requester mame',
      requesterEmail: 'Requester email',
      requesterPhone: 'Requester phone',
      requestDate: 'Request date/time',
      approvedOrRejectedBy: 'Approved/rejected by',
      requests: 'Requests',
      documentId: 'ID number',
      previousAverageCost: 'Previous average cost',
      finalAverageCost: 'Final average cost',
      availability: 'Availability',
      groupIdPublic: 'Public',
      groupIdGroup: 'Group: ',
      complete: 'Complete',
      incomplete: 'Incomplete',
      state: 'State',
      cfop: 'CFOP',
      cstIcms: 'CST-ICMS',
      cstPis: 'CST-PIS',
      cstCofins: 'CST-COFINS',
      cstIpi: 'CST-IPI',
      taxation: 'Tax Regime',
      numberOfProducts: 'No. Products',
      comments: 'Comments',
      noComments: 'No Comments',
      route: 'Route',
      qtyInStock: 'Qty in Stock',
      qtyTotalReserved: 'Qty Total Reserved',
      qtyAvailableForPickList: 'Qty Available for Pick List',
    },
    noData: 'No results',
    none: 'No data',
    total: 'Total',
  },
}
