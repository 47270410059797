<template>
  <v-expansion-panels v-model="expansionIndex" hover>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <template #default="{ open }">
          <v-row no-gutters>
            <v-col cols="2">{{$t('filters.filtersActions')}}</v-col>
            <v-col cols="10" class="text--secondary">
              <span v-if="!open">
                {{ $t('filters.labels.period') }}: <strong>{{ formattedMinDate }} - {{ formattedMaxDate }}</strong>,
                {{ $t('filters.labels.customer') }}: <strong>{{ (customers.find(c => c.id === form.customerId)||{}).name||$t('filters.labels.all') }}</strong>,
                {{ $t('filters.labels.location') }}: <strong>{{ (locations.find(l => l.id === form.localId)||{}).name||$t('filters.labels.all')  }}</strong>,
                {{ $t('filters.labels.pos') }}: <strong>{{ (pointsOfSale.find(p => p.value === form.pointOfSaleId)||{}).text||$t('filters.labels.all')  }}</strong>,
                {{ $t('filters.labels.result') }}: <strong>{{ (results.find(r => r.value === form.result)||{}).text||$t('filters.labels.all') }}</strong>
                <strong>{{ form.subtractedItems == true ? `, ${$t('filters.onlySubtractedItems')}`  : "" }}</strong>
              </span>
            </v-col>
          </v-row>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content class='pb-5'>
        <v-form ref="form" v-model="valid" @submit.prevent="getTransactions()">
          <!-- First row -->
          <v-row row wrap>
            <v-col class="search-field" cols="12" sm="6" md="3">
              <v-autocomplete
                prepend-icon="account_box"
                :items="customers"
                v-model="form.customerId"
                :label="$t('filters.labels.customer') "
                item-text="name"
                item-value="id"
                single-line
                clearable>
              </v-autocomplete>
            </v-col>
            <v-col class="search-field" cols="12" sm="6" md="3">
              <v-autocomplete
                prepend-icon="location_on"
                :disabled="form.customerId === null || loading.locals"
                :hint="form.customerId === null ? $t('filters.messages.selectCustomer') : ''"
                :items="locations"
                v-model="form.localId"
                :loading="loading.locals"
                item-text="name"
                item-value="id"
                :label="$t('filters.labels.location')"
                single-line
                persistent-hint
                clearable>
              </v-autocomplete>
            </v-col>
            <v-col class="search-field" cols="12" sm="6" md="3">
              <v-autocomplete
                prepend-icon="business"
                :disabled="form.localId === null || loading.pointsOfSale"
                :hint="form.localId === null ? $t('filters.messages.selectLocation')  : ''"
                :items="pointsOfSale"
                v-model="form.pointOfSaleId"
                :loading="loading.pointsOfSale"
                :label="$t('filters.labels.pointOfSale') "
                single-line
                persistent-hint
                clearable>
              </v-autocomplete>
            </v-col>
            <v-col class="search-field" cols="12" sm="6" md="3">

              <ResultSelectorFilter
                v-model="form.results"
                :multiple="true"
              />

            </v-col>
          </v-row>

          <!-- Second row -->
          <v-row class="pt-2">
            <v-col class="search-field" cols="12" sm="6" md="3">
              <v-text-field
                type="number"
                min="0"
                step="0.01"
                placeholder="0.00"
                :rules="[$validations.currency]"
                prepend-icon="attach_money"
                :label="$t('filters.labels.minimumPrice')"
                v-model="form.minPrice"
                clearable
              ></v-text-field>
            </v-col>
            <v-col class="search-field" cols="12" sm="6" md="3">
              <v-text-field
                type="number"
                min="0"
                step="0.01"
                placeholder="0.00"
                :rules="[$validations.currency]"
                prepend-icon="attach_money"
                 :label="$t('filters.labels.maximumPrice')"
                v-model="form.maxPrice"
                clearable
              ></v-text-field>
            </v-col>
            <v-col class="search-field" cols="12" sm="6" md="3">
              <products-filter
                v-model="form.productId"
                icon="mdi-package-variant-closed"
              ></products-filter>
            </v-col>
            <v-col class="search-field" cols="12" sm="6" md="3">
              <v-text-field
                type="text"
                min="0"
                placeholder="0000"
                prepend-icon="mdi-credit-card"
                 :label="$t('filters.labels.lastCardDigits')"
                v-model="form.cardHolder"
                clearable
                maxlength="4"
              ></v-text-field>
            </v-col>
          </v-row>

          <!-- Third Row -->
          <v-row class="pt-2">
            <v-col class="search-field" cols="12" sm="6" md="3">
              <v-menu
                class="pr-2"
                :close-on-content-click="true"
                v-model="hasMinDate"
                transition="scale-transition"
                offset-y
                :nudge-right="40"
                max-width="290px"
                min-width="290px"
              >
                <template #activator="{ on }">
                  <v-text-field
                    v-on="on"
                    :label="$t('filters.labels.initialDate')"
                    :rules="[$validations.required, $validations.date]"
                    v-model="formattedMinDate"
                    prepend-icon="date_range"
                    readonly
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="form.minDate"
                  no-title
                  scrollable
                  actions
                  locale="pt-br"
                  :allowed-dates="getAllowedMinDates"
                >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="clearMinDate"
                      >{{ $t('buttons.clear') }}</v-btn
                    >
                  </v-card-actions>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col class="search-field" cols="12" sm="6" md="3">
              <TimePicker
                v-model="form.minTime"
                :label="$t('filters.labels.initialHour')"
              />
            </v-col>
            <v-col class="search-field" cols="12" sm="6" md="3">
              <v-menu
                :close-on-content-click="true"
                v-model="hasMaxDate"
                transition="scale-transition"
                offset-y
                :nudge-right="40"
                max-width="290px"
                min-width="290px"
              >
                <template #activator="{ on }">
                  <v-text-field
                    v-on="on"
                    :label="$t('filters.labels.finalDate')"
                    :rules="[$validations.required, $validations.date]"
                    v-model="formattedMaxDate"
                    prepend-icon="event_available"
                    readonly
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="form.maxDate"
                  no-title
                  scrollable
                  actions
                  locale="pt-br"
                  :allowed-dates="getAllowedMaxDates"
                >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="clearMaxDate"
                      >{{ $t('buttons.clear') }}</v-btn
                    >
                  </v-card-actions>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col class="search-field" cols="12" sm="6" md="3">
              <TimePicker
                v-model="form.maxTime"
                :label="$t('filters.labels.finalHour')"
              />
            </v-col>
          </v-row>

          <v-row cols="12">
            <v-col cols="12" md="6" class="search-field">
              <v-select
                prepend-icon="mdi-list-status"
                v-bind:items="status"
                v-model="form.status"
                :label="$t('filters.labels.verificationStatus')"
                :hint="$t('filters.messages.selectResult')"
                multiple
                chips
                clearable
              >
                <template #selection="{ item }">
                  <v-chip small :color="getColor(item.value)" dark>{{item.text}}</v-chip>
                </template>
              </v-select>
            </v-col>
            <v-col class="search-field">
              <v-checkbox v-model="form.subtractedItems"
              :label="$t('filters.labels.viewOnlyTransactionItemsSubtracted')">
              </v-checkbox>
            </v-col>
          </v-row>
          <v-row align-self="end">
            <v-spacer></v-spacer>
            <v-switch
              class="pa-0 pr-2"
              v-model="showProfits"
              :label="$t('filters.labels.viewProfit')">
            ></v-switch>
            <div class="text-right">
              <ClearButton
                @clear="clearForm()"
              />
            </div>
            <div class="text-right">
              <UpdateButton
                :valid="valid"
                @refresh= "emitModification()"
              />
            </div>
          </v-row>
        </v-form>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import moment from 'moment';
import ProductsFilter from '@/components/filters/ProductsFilter.vue';
import TimePicker from '@/components/filters/TimePicker.vue';
import ResultSelectorFilter from "@/components/filters/TransactionResultSelectorFilter.vue";
import UpdateButton from '@/components/buttons/UpdateButton.vue';
import ClearButton from '@/components/buttons/ClearButton.vue';

const filtersInitialState = {
  minDate: null,
  maxDate: null,
  customerId: null,
  localId: null,
  userId: null,
  pointOfSaleId: null,
  productId: null,
  minPrice: null,
  maxPrice: null,
  cardHolder: null,
  minTime: null,
  maxTime: null,
  status: null,
  result: null,
  subtractedItems: null
};

export default {
  components: {
    ProductsFilter,
    TimePicker,
    ResultSelectorFilter,
    ClearButton,
    UpdateButton
  },
  data(){
    return {
      valid: false,
      hasMinDate: null,
      hasMaxDate: null,
      customers: [],
      locations: [],
      users: [],
      pointsOfSale: [],
      status: [
        { text: this.$t('security.deviationAlert.resultDeviationStatus.pending'), value: 'Opened' },
        { text: this.$t('security.deviationAlert.resultDeviationStatus.verifiedOk'), value: 'Verified' },
        { text: this.$t('security.deviationAlert.resultDeviationStatus.deviation'), value: 'HasDeviations' },
        { text: this.$t('security.deviationAlert.resultDeviationStatus.refunded'), value: 'Refunded' },
        { text: this.$t('security.deviationAlert.resultDeviationStatus.lost'), value: 'Lost' }
      ],
      expansionIndex: 0,
      form: {...filtersInitialState},
      loading: {
        locals: false,
        pointsOfSale: false,
      },
      showProfits: true
    }
  },
  computed: {
    formattedMinDate: function(){
      return this.formatDate(this.form.minDate);
    },
    formattedMaxDate: function(){
      return this.formatDate(this.form.maxDate);
    }
  },
  watch: {
    'form.customerId': function(newId) {
      if(newId !== null){
        this.loading.locals = true;
        this.axios.get('api/Customers/' + newId)
          .then((response) => {
            this.loading.locals = false;
            this.form.localId = null;
            this.locations = response.data.locals;
          });
      }
      else {
        this.form.localId = null;
      }
    },
    'form.localId': function(newId) {
      if(newId != null){
        this.loading.pointsOfSale = true;
        this.axios.get('api/Locals/' + newId + '/PointsOfSaleWithDeviation')
          .then((response) => {
            this.loading.pointsOfSale = false;
            this.pointsOfSale = response.data.map((p) => ({ text: p.id + (p.specificLocation !== null ? ' / ' + p.specificLocation : ''), value: p.id }));
          });
      }
      else{
        this.form.pointOfSaleId = null;
      }
    },
    showProfits(value) {
      this.$emit('show-profits-changed', value);
    }
  },
  mounted(){
    const preSelectedStatus = [
      'Opened',
      'HasDeviations'
    ]
    this.form.status = preSelectedStatus;
    this.form.subtractedItems = false;
    const maxDate = new Date();
    const minDate = new Date(maxDate.getTime());
    minDate.setDate(minDate.getDate() - 30);
    this.form.maxDate = moment(maxDate).format('YYYY-MM-DD');
    this.form.minDate = moment(minDate).format('YYYY-MM-DD');
    this.form.results = [];
    this.$emit('modify', this.form);
    this.axios.get('api/Customers/ActiveWithDeviation')
      .then((response) => {
        this.customers = response.data
      });
  },
  methods: {
    clearForm() {
      this.form = {...filtersInitialState}
      this.form.status = ['Opened', 'HasDeviations']
      this.form.subtractedItems = false;
      const maxDate = new Date();
      const minDate = new Date(maxDate.getTime());
      minDate.setDate(minDate.getDate() - 30);
      this.form.maxDate = moment(maxDate).format('YYYY-MM-DD');
      this.form.minDate = moment(minDate).format('YYYY-MM-DD');
    },
    getColor (status) {
      if (status == 'Opened') return 'orange lighten-1'
      else if (status == 'Verified') return 'green'
      else if (status == 'HasDeviations') return 'red lighten-1'
      else if (status == 'Lost') return 'grey darken-1'
      else return 'blue'
    },
    emitModification(){
      if(this.$refs.form.validate()){
        this.$emit('modify', this.form);
        this.expansionIndex = undefined;
      }
    },
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY');
    },
    clearMinDate() {
      const maxDate = new Date();
      const minDate = new Date(maxDate.getTime());
      minDate.setDate(minDate.getDate() - 30);
      this.form.minDate = moment(minDate).format('YYYY-MM-DD');
    },
    clearMaxDate() {
      const data = new Date();
      const maxDate = new Date(data.getTime());
      this.form.maxDate = moment(maxDate).format('YYYY-MM-DD');
    },
    getAllowedMinDates(minDate) {
      if (this.form.maxDate !== null) {
        return this.isDateRangeAllowed(new Date(minDate), new Date(this.form.maxDate));
      }
      return true;
    },
    getAllowedMaxDates(maxDate) {
      if (this.form.minDate !== null) {
        return this.isDateRangeAllowed(new Date(this.form.minDate), new Date(maxDate));
      }
      return true;
    },
    isDateRangeAllowed(min, max){
      return max >= min && Math.round(max - min)/(1000*60*60*24) < 90;
    }
  },
}
</script>
