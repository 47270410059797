
import Vue from 'vue'

export default Vue.extend({
  props: {
    value: {type: Boolean, required: true},
    stockAdressItem: {
      type: Object, default: () => {
      }
    }
  },
  watch: {
    stockAdressItem: {
      handler(): void {
        this.stockAddress = this.stockAdressItem.stockAddress
      },
      deep: true
    }
  },
  computed: {
    show: {
      get(): Boolean {
        return this.value;
      },
      set(value: Boolean) {
        this.stockAddress = '';
        this.$emit('close', value);
      }
    },


  },
  data() {
    return {
      loading: false as boolean,
      stockAddress: '' as string,
      valid: false as boolean,
      stockAddressRule: (v: string) => {
        const regex = /^[A-Z]-\d+$/;
        return regex.test(v) || this.$t('inventory.items.dialogs.itemAddress.messages.error.invalidFormat');
      }
    }
  },

  methods: {
    setStockAddress() {
      this.loading = true


      this.axios.patch(`api/web/inventory/itemStockAddress/${this.stockAdressItem.id}`, {stockAddress: this.stockAddress})
        .then((response) => {
          this.loading = false;
          this.show = false;
          this.$emit("update", response.data)
        })
        .catch((error: any) => {
          (this.$refs.feedback as any).handleError(this.$t('inventory.items.dialogs.itemAddress.messages.error.addressUpdated'), error);
          this.loading = false;
        });
    },

  }
})
