export default {
  resetSelectedPickListDetails: 'Reset selected',
  showZeroPickListDetails: 'Show zeroed',
  searchProductPickListDetails: 'Search product',
  productCodePickListDetails: 'Product code',
  productNamePickListDetails: 'Product name',
  productBarCodePickListDetails: 'Barcode',
  categoryPickListDetails: 'Category',
  quantityPickListDetails: 'Quantity',
  currentQuantityPickListDetails: 'Current qty.',
  desiredQuantityPickListDetails: 'Desired qty.',
  criticalMinimumPickListDetails: 'Critical minimum',
  salesThisWeekPickListDetails: 'Sales last 7 days',
  centralStockQuantity: 'Central stock qty.',
  centralReservedStockQuantity: 'Total reserved qty.',
  closePickListDetails: 'Close',
  pickListInfoPickListDetails: 'Pick List',
  pickListInfoPosDetails: 'POS',
  pickListInfoPlanogramDetails: 'Planogram',
  pickListInfoCreatedDetails: 'Created: ',
  pickListInfoUpdatedDetails: 'Updated: ',
  pickListInfoParentDetails: 'Parent Pick list: ',
  changeErrorPickListDetails: 'Error changing quantity',
  quantityNotChangedPickListDetails: 'Quantity not changed: number format is invalid',
  cancelPickListDetails: 'Cancel',
  toSavePickListDetails: 'Save',
  emptySelectedError: 'Error resetting items',
  pendingPickListDetails: 'Pending',
  usedPickListDetails: 'Used',
  picklistSplitButton: 'Split Pick List',
  picklistSplitError: 'Error splitting pick list',
  splitPickListConfirmation: {
    title: 'Split pick list',
    splitType: 'Split type',
    splitByStockAddress: 'By stock address',
    message: `<p>To split correctly, the <strong>stock address</strong> must follow the pattern: <strong>A-01</strong> (where "A" is the aisle/location letter and "01" is the item position in the aisle/location).</p>
    <p>Products will be grouped by <strong>aisle/location letter</strong>, creating a pick list for each one. Products without a <strong>stock address</strong> will be placed in a separate pick list.</p>
    <p>Are you sure you want to split the <strong>main pick list</strong> into multiple pick lists, organized by <strong>stock address</strong>?</p>`
  }
};
