export default {
  awaitingApproval: 'Awaiting Approval',
  clientRequester: 'Requesting Client',
  paymentProcessorAccounts: 'Payment Processor Accounts',
  apiToken: 'API Token',
  activationCode: 'Activation Code',
  noPaymentProcessorAlert: 'No Payment Processor account available. Register one to select.',
  registerNewAccount: 'Register New Account',
  deliveryAddress: 'Delivery Address',
  address: 'Address',
  editAddress: 'Edit Address',
  termsAndConditions: 'Terms and Conditions',
  termsAndConditionsText: 'By placing your order, you agree to AMLabs Terms of Use',
  finishOrder: 'Finish Order',
  fantasyName: 'Trade Name',
  billingDocumentId: 'Company Document ID',
  customerEmail: 'Customer Email',
  customerPhone: 'Customer Phone',
  productAddedToPurchase: 'Product added to purchase',
  midasCloseOrderAddressNumber: "Address Number",
  midasCloseOrderAddress: "Address",
  midasCloseOrderAddressComplement: "Complement",
  midasCloseOrderCep: "Postal Code",
  midasCloseOrderCity: "City",
  midasCloseOrderState: "State",
  noAddressAvailableAlert: "No address available. Register one to select.",
  withdrawAtCompany: 'Pickup at the company: AV. Monte Castelo, 575 - Jardim Proença, Campinas - SP, Postal Code: 13026-241',
  productSoldByAmLabs: 'Product sold by AMLabs',
  midasCloseOrderQuantity: 'Quantity',
  midasCloseOrderSave: 'Save',
  missingPaymentProcessorError: 'Select a payment processor account to complete the order',
  missingAddressInformationError: 'Delivery address information is missing. Please fill it to complete the order',
  missingPhoneInformationError: 'Customer phone information is missing. Please fill it to complete the order',
  missingEmailInformationError: 'Customer email information is missing. Please fill it to complete the order',
  missingApiTokenInformationError: 'API token information is missing. Please fill it to complete the order',
  missingActivationCodeInformationError: 'Activation code information is missing. Please fill it to complete the order',
  cepRules: 'The Postal Code must contain 8 digits',
  cepNeeded: 'Please enter the Postal Code',
  midasCloseOrderAddressComplementHint: 'Apartment/Block. Otherwise, enter N/A',
  cep: 'Postal Code',
  cepError: 'Invalid Postal Code, please enter a valid one',
  editPagseguroAccount: 'Edit Pagseguro Account',
  documentIdIsOk: "Valid Documento in the Federal Revenue and State Registration",
    documentIdIsNotOk: "Irregular Documento - {documentId} - Status in Federal Revenue: {status} and State Registration: {ieStatus} Please regularize with your accountant/Revenue/Sefaz before proceeding with the order.",
  missingDocumentIdError: "Incorrect or Non-existent Document ID. Please contact our support for verification.",
  statusEnum: {
  Active: "ACTIVE",
    Suspended: "SUSPENDED",
    Unqualified: "UNQUALIFIED",
    Down: "CANCELLED",
    Null: "NULL"
  },
  ieStatus: {
    Ok: "ACTIVE",
      NotOk: "IRREGULAR"
  },
  registerNewAddress: 'Register New or Edit Address',
  recommended: 'Recommended',
  choosePaymentProcessor: 'Which payment terminal would you like to link an account to?',
  existingAccountOrCreateNewOne: 'Do you already have an account with a payment terminal, or do you want to create a new one?',
  doYouHaveAnExistingAccount: 'Do you already have an existing account?',
  existingAccount: 'I already have an account with the payment terminal',
  iWantToCreateAnAccount: 'I want to create an account',
  connectTo: 'Connect to',
  registeredEmailIn: 'Registered email in',
  connect: 'Connect',
  connectionSavedSuccessfully: 'Connection saved successfully!',
  paymentProcessorAccountVinculation: 'Your data has been saved, and the account linking process will continue.',
  createAccountIn: 'Create an account in',
  nameOfPersonResponsibleForCompanyDocumentId: 'Full name of the person responsible for the company’s registration number',
  companyDocumentId: 'Company registration number',
  documentIdOfPersonResponsibleForCompanyDocumentId: 'Individual taxpayer identification number of the person responsible for the company',
  dateOfBirthOfPersonResponsibleForCompanyDocumentId: 'Date of birth of the person responsible for the company',
  emailToRegisterIn: 'Email to register the account',
  foreseenMonthlyRevenue: 'Estimated monthly revenue (TPV)',
  next: 'Next',
  finishRegistration: 'Finish registration',
  registrationSavedSuccessfully: 'Registration saved successfully!',
  paymentProcessorDataSavedSuccessfully: 'Your data has been saved. The account creation process will continue <span style=\'font-weight:700;\'>only</span> if the account provided is used to request the order. <span style=\'font-weight:700;\'>If the order is placed, we will contact you soon</span> to discuss more details about the terminal fees and other necessary information.',
  currentNetworth: 'What is your current net worth?',
  monthlyIncome: 'What is your monthly income?',
  politicallyExposedPerson: 'Are you a politically exposed person?',
  addPersonalDocumentIdPhoto: 'ADD A PHOTO OF YOUR DOCUMENT',
  addCompanyDocumentIdPhoto: 'ADD A PHOTO OF YOUR COMPANY DOCUMENT',
  acceptedFileFormats: 'Accepted file format: (PNG or JPEG). PDF is not accepted<br>Maximum file size: 10MB',
  imageSubmittedSuccessfully: 'Image submitted successfully!',
  missingPagSeguroInfo: 'Missing PagSeguro information. Please verify the API Token and the Activation Code',
  deleteEntry: 'Delete',
};
