import {defineStore} from "pinia";

import {useAlertsStore} from "@/stores/alertsStore";
import {ChangeOwnershipNewRequest} from "@/types/ChangeOwnershipType";
import {ref} from "vue";
import agent from "@/api/agent";



export const useChangeOwnershipNewRequestStore = defineStore('changeOwnershipNewRequestStore', () => {
  const alertsStore = useAlertsStore()

  const loadingCnpjField = ref(false)
  const loadingView = ref(false)
  const cnpjIsValid = ref(false)
  const showDestinationCnpjNotFoundDialog = ref(false)
  const showNameAndPhoneNumber = ref(false)
  const changeOwnershipSentSuccessfully = ref(false)

  const enableChangeOwnershipSameFranchise = ref(false)
  const enableChangeOwnershipAgreedTermsOfPOS = ref(false)
  const enableChangeOwnershipAgreedTermsOfTransfer = ref(false)
  const enableChangeOwnershipDebtFreeOrigin = ref(false)
  const enableChangeOwnershipDebtFreeDestination = ref(false)


  const pointsOfSales = ref<{ title: string, value: string }[]>([]);

   const errorText="O CNPJ informado possui mais de um ambiente,A troca de titularidade não está disponível para esse caso. Por gentileza, consulte o suporte."
  const errorSameClientText= "O CNPJ de destino é o mesmo do CNPJ de origem, a troca de titularidade não está disponível para esse caso. Por gentileza, consulte o suporte."

  const changeOwnershipNewRequestInitialState = ref<ChangeOwnershipNewRequest>({
    destinationCnpj: "",
    originTenantId: 0,
    originGroupId: 0,
    destinationClientName: undefined,
    destinationClientPhoneNumber: undefined,
    pointOfSaleIds: []
  })
  const changeOwnershipNewRequest = ref<ChangeOwnershipNewRequest>(changeOwnershipNewRequestInitialState.value)
  async function createChangeOwnershipNewRequest() {
    loadingView.value = true;
    changeOwnershipSentSuccessfully.value = false;
    try {
      await agent.ChangeOwnershipRequest.createChangeOwnershipRequest(changeOwnershipNewRequest.value);
      alertsStore.showSuccessSnackbar("Solicitação de troca de titularidade criada com sucesso");
      changeOwnershipSentSuccessfully.value = true;
    } catch (error) {
      alertsStore.showErrorSnackbar("Erro em criar a solicitação de troca de titularidade");
    } finally {
      loadingView.value = false;
    }
    return changeOwnershipSentSuccessfully.value;
  }

  async function getAllPointOfSales() {
    loadingView.value = true;
    await agent.ChangeOwnershipRequest.getAllPointsOfSale(
      changeOwnershipNewRequest.value.originGroupId,
    )
      .then((response) => {
        pointsOfSales.value = response.map((pointOfSale) => {
          return {
            title: pointOfSale.completeName,
            value: pointOfSale.id.toString()
          };
        });
      })
      .catch(() => {
        alertsStore.showErrorSnackbar("Erro em buscar os PDV's");
      })
      .finally(() => {
        loadingView.value = false;
      });
  }

  async function verifyIfCnpjHasAEnvironment() {
  loadingCnpjField.value = true
  await agent.ChangeOwnershipRequest.quantityOfEnvironmentByCnpj(changeOwnershipNewRequest.value.destinationCnpj)
    .then((response) => {
      if (response.quantity == 0) {
        showDestinationCnpjNotFoundDialog.value = true
        showNameAndPhoneNumber.value = true
      }
      if (response.quantity > 1) {
        alertsStore.showErrorSnackbar(errorText)
        changeOwnershipNewRequest.value.destinationCnpj = ""
      }
      if (response.quantity == 1
        && changeOwnershipNewRequest.value.originTenantId === response.tenantId
        && changeOwnershipNewRequest.value.originGroupId === response.groupId) {
        alertsStore.showErrorSnackbar(errorSameClientText)
        changeOwnershipNewRequest.value.destinationCnpj = ""
      }
      if (response.quantity == 1) {
        checkIfCustomerIsDebtFree(false, response.tenantId!, response.groupId)
      }
    })
    .catch(() => {
      alertsStore.showErrorSnackbar("Erro em buscar o ambiente do CNPJ")
    })
    .finally(() => loadingCnpjField.value = false);
 }

  async function checkIfCustomerIsDebtFree(isCurrentCustumer: boolean, tenantId: number , groupId?: number, ) {
    loadingView.value = true;
    await agent.Bills.checkIfCustomerIsDebtFree(tenantId, groupId)
      .then((response) => {
        if (!response.isDebtFree) {
          alertsStore.showErrorSnackbar(isCurrentCustumer ? "Cliente está com débitos" : "O CNPJ de destino está com débitos");

          isCurrentCustumer
            ? enableChangeOwnershipDebtFreeOrigin.value = false
            : enableChangeOwnershipDebtFreeDestination.value = false;

        }
        else {
          isCurrentCustumer
            ? enableChangeOwnershipDebtFreeOrigin.value = true
            : enableChangeOwnershipDebtFreeDestination.value = true;
        }
      })
      .catch(() => alertsStore.showErrorSnackbar("Erro em consultar débitos"))
      .finally(() => {
        loadingView.value = false;
        isCurrentCustumer ? enableChangeOwnershipDebtFreeOrigin.value = true : enableChangeOwnershipDebtFreeDestination.value = true;
      });
  }

  return {
    changeOwnershipNewRequest,
    createChangeOwnershipNewRequest,
    verifyIfCnpjHasAEnvironment,
    getAllPointOfSales,
    pointsOfSales,
    loadingCnpjField,
    loadingView,
    checkIfCustomerIsDebtFree,
    enableChangeOwnershipSameFranchise,
    showDestinationCnpjNotFoundDialog,
    showNameAndPhoneNumber,
    enableChangeOwnershipAgreedTermsOfPOS,
    enableChangeOwnershipAgreedTermsOfTransfer,
    enableChangeOwnershipDebtFreeOrigin,
    enableChangeOwnershipDebtFreeDestination,
    cnpjIsValid
  };

})
