import i18n from '@/locales/i18n';

const inventoryOperationTypes = {
  Correction: i18n.t('inventory.operation.types.correction'),
  Sale: i18n.t('inventory.operation.types.sale'),
  Purchase: i18n.t('inventory.operation.types.purchase'),
  Reversed: i18n.t('inventory.operation.types.reversed'),
  AutomaticCorrection: i18n.t('inventory.operation.types.automaticCorrection'),
  Supply: i18n.t('inventory.operation.types.supply'),
  Expired: i18n.t('inventory.operation.types.expired'),
  Damaged: i18n.t('inventory.operation.types.damaged'),
  Return: i18n.t('inventory.operation.types.return'),
  RemovedFromInventory: i18n.t('inventory.operation.types.removedInventory'),
  Other: i18n.t('inventory.operation.types.other'),
  ExpirationDateChanged: i18n.t('inventory.operation.types.expirationDateChanged'),
  ExpirationDateAdded: i18n.t('inventory.operation.types.expirationDateAdded'),
  ExpirationDateRemoved: i18n.t('inventory.operation.types.expirationDateRemoved'),
  CorrectionAverageCost: i18n.t('inventory.operation.types.correctionAverageCost'),
  Picking: i18n.t('inventory.operation.types.picking'),
  PickingReturn: i18n.t('inventory.operation.types.pickingReturn')
};

// obsolete Operation Types not to be used
const allInventoryOperationTypes = {
  ...inventoryOperationTypes,
  Full: "Completo",
  PickList: "Pick-list",
  Repositioning: "Reposicionamento",
  PlanogramChange: "Mudança de Planograma",
  Operation: "Abastecimento/Inventário",
};

export { inventoryOperationTypes, allInventoryOperationTypes };
