<template>
  <v-autocomplete
    prepend-icon="mdi-truck-cargo-container"
    :items="routes"
    v-model="routeId"
    :label="$t('filters.routes')"
    item-text="name"
    item-value="id"
    single-line
    clearable
    :multiple="multiple"
    :rules="rules"
  >
  </v-autocomplete>
</template>

<script>
import agent from '@/api/agent';

export default {
  props: {
    resetComponent: {},
    value: {},
    multiple: { type: Boolean, default: false },
    rules: { type: Array },
  },
  data() {
    return {
      routes: [],
    };
  },
  computed: {
    routeId: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
  },
  mounted() {
    agent.Routes.getRoutes()
      .then((response) => {
        this.routes = response;
      });
  },
  watch: {
    resetComponent: function() {
      this.resetDropDown();
    },
  },
  methods: {
    resetDropDown() {
      this.routeId = null;
    },
  },
};
</script>
