export default {
  filters: {
    filtersActions: 'Filtros e Ações',
    title:'Filtros ',
    groupings: 'Agrupamentos',
    labels: {
      all: 'Todos',
      period: 'Período',
      pos: 'PDV',
      onlySubtractedItems: 'Somente itens subtraídos',
      advanced: 'Avançado',
      calendar: "Calendário",
      inventoryType: 'Tipo de Estoque',
      central: 'Central',
      search: 'Pesquisar',
      searchProducts: 'Pesquisar um produto',
      months: 'Meses',
      month:'Mês',
      day:'Dia',
      dayOfWeek:'Dia da Semana',
      customer: 'Cliente',
      customers: 'Clientes',
      location: 'Localização',
      locationSpecific: 'Localização Específica',
      pointOfSale: 'Ponto de Venda',
      pointsOfSale: 'Pontos de Venda',
      result: 'Resultado',
      minimumPrice: 'Preço Mínimo',
      maximumPrice: 'Preço Máximo',
      product: 'Produto',
      category: 'Categoria',
      lastCardDigits: 'Últimos Dígitos do Cartão',
      date: 'Data',
      initialDate: 'Data Inicial',
      finalDate: 'Data Final',
      initialHour: 'Hora Inicial',
      finalHour: 'Hora Final',
      verificationStatus: 'Status de Verificação',
      viewOnlyTransactionItemsSubtracted: 'Ver apenas transações com itens subtraídos',
      viewProfit: 'Exibir Lucro',
      accessDetails: 'Detalhes de Acesso',
      audit: 'Auditoria',
      actions: 'Ações',
      accessResult: 'Acessar Resultado',
      operationType: 'Tipo de Operação',
      operation:"Operação",
      lastMovement: 'Última movimentação',
      rangeOfDays:'Intervalo de dias: ',
      saveFilters: 'Salvar Filtros',
      savedFilters: 'Filtros Salvos',
      states: 'Estados',
      cardBrands: 'Bandeiras de Cartões',
      paymentMethods: 'Métodos de Pagamento',
      reportName: 'Nome do relatório',
      local: 'Local',
      showAllProducts: 'Mostrar todos os produtos',
      showMinimumCritical: 'Mostrar apenas mínimos críticos',
      lastXDaysFilter:  'Considerando os últimos {days} dias,',
      inventoryForDesiredDays: 'quero estoque para {days} dias',
      inventoryDaysLimit: 'para itens com até {inventoryDaysLeftThreshold} dias de estoque.',
      ignoreRupture: 'Desconsiderar ruptura',
      rating: 'Avaliação',
      enviroment: 'Clima',
      organization: 'Organização',
      products: 'Produtos',
      cardMachine: 'Maquininha',
      payment: 'Pagamento',
      prices: 'Preços',
      others: 'Outros',
      application: 'Aplicativo'
    },
    messages: {
      selectMonth: "Selecione um mês",
      selectCustomer: 'Selecionar um cliente',
      selectLocation: 'Selecionar uma localização',
      selectResult: 'Selecionar resultado para ver',
      selectOperationType: 'Selecione um tipo de operação',
    },
    timelineDateAlert: 'Intervalo máximo de três dias',
    orderSearch: 'Ordenar / Pesquisar',
    status: 'Status',
    customerName: 'Nome do cliente',
    inventory: 'Estoque',
    types: 'Tipos',
    lastSale: 'Última venda',
    creationDate: 'Data de criação (ID do ponto de venda)',
    order: 'Ordenar',
    groupBy: 'Agrupar por',
    sort:{
      asc: 'Crescente',
      desc: 'Decrescente',
    },
    saveReport:{
      title:'Salvar filtros do relatório',
      savedSuccessfully: 'salvo com sucesso',
      deleteConfirmation: 'Deletar filtros do relatório',
      reportName: 'Nome do relatório',
      error:{
        onlyUser: 'Apenas filtros de usuário podem ser excluídos',
        errorWhenSaving: 'Houve um erro ao salvar os filtros',
        unableToRemove: 'Não é possível remover item',
      }
    },
    selectALocation: 'Selecione antes uma localização',
    selectACustomer: 'Selecione antes um cliente',
    routes: 'Rotas',
  },
}
