<template>
<div>
      <v-card outlined>
        <v-card-title   :style="{ 'background-color': '#03009B', 'color': '#ffffff' }">
            {{ $t('importXml') }}
        </v-card-title>

        <v-card-text class="my-2">

          <v-container class="justify-center align-center">
            <v-col class="my-2">
               <load-xmls-purchase
                 ref="loadXmlsPurchase"
                 @File-Completed="fileCompleted"
                 @File-Removed="fileRemoved"
                 :options="dropzoneOptions"
                 :urlRequest="urlRequest"></load-xmls-purchase>
            </v-col>

            <v-col>
              <v-row class="align-center">
                <inventories-drop-down
                    v-model="inventoryId"
                    :inventoryTypeFilter="'distributionCenter'"
                    style="width: 100px;"/>

                    <v-spacer></v-spacer>

                  <v-btn class="mr-2" text color="primary" @click="closeDialog()">{{ $t('xmlDialogClose') }}</v-btn>

                  <v-tooltip bottom :disabled="inventoryId != null && xmlSuccess">
                      <template v-slot:activator="{ on }">
                        <div v-on="on">
                        <v-btn
                          color="primary"
                          @click="importXml()"
                          :disabled="!inventoryId || !xmlSuccess"
                        >
                          {{ $t('buttonImport') }}
                        </v-btn>
                        </div>
                      </template>
                      <span >{{ $t('spanSelectStock') }}</span>
                    </v-tooltip>
              </v-row>
            </v-col>
          </v-container>
        </v-card-text>
      </v-card>
</div>
</template>

<script>
import LoadXmlsPurchase from '@/components/LoadFiles.vue';
import InventoriesDropDown from '@/components/filters/InventoriesDropDown.vue';
import DropzoneOptionsXml from './DropzoneOptionsXml';

export default {
    data() {
        return {
            inventoryId: null,
            xmlSuccess: false,
            dropzoneOptions : DropzoneOptionsXml,
            urlRequest: 'api/imported-purchases',
            valueReturn:{
              fileName:'',
              inventoryId: null
            }
        }
    },
    components: {
        LoadXmlsPurchase,
        InventoriesDropDown
    },
    methods: {
        fileCompleted(uploadSuccess) {
            if(uploadSuccess) {
                this.xmlSuccess = true;
            }
        },
        fileRemoved(uploadSuccess) {
            if(!uploadSuccess) {
                this.xmlSuccess = false;
            }
        },
        importXml() {
          this.valueReturn.fileName = this.$refs.loadXmlsPurchase.filename;
          this.valueReturn.inventoryId = this.inventoryId;
          this.$emit("update", this.valueReturn);
          this.resetData();
        },
        closeDialog() {
          this.resetData();
          this.$emit("close", this.valueReturn);
        },
        resetData() {
          this.xmlSuccess = false;
          this.valueReturn.fileName = '';
          this.$refs.loadXmlsPurchase.onRemove();
        }
    }
}
</script>
