<template>
  <v-dialog
      v-model="itemDialog"
      max-width="1280"
      persistent
      @keydown.esc="itemDialog = false"
  >
    <template  v-if="showActivationButton" #activator="{ on: dialog }">
      <v-tooltip top open-delay="50">
        <template #activator="{ on: tooltip }">
          <div v-on="tooltip">
            <v-btn
              :disabled="productRegistrationDisabled"
              :loading="loading.newProduct"
              color="success"
              v-on="dialog">
              <v-icon left>add</v-icon>
              {{ dialogTitle }}
            </v-btn>
          </div>
        </template>
        {{ productRegistrationDisabled ? $t('noPermissionToAddProduct') : dialogTitle }}
      </v-tooltip>
    </template>
    <v-card>
      <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="saveItem">
        <v-card-title>
          <span class="title">{{ dialogTitle }}</span>
          <product-dialog-help></product-dialog-help>
        </v-card-title>
        <v-card-text>
          <p v-if="editedEntry.isExternal" style="font-style: oblique; color: #D50000">
            {{ $t('synchronizedWithErp') }}
          </p>
          <v-container grid-list-lg>
            <v-layout row wrap>
              <v-flex md4 xs12>
                <editable-image
                    v-if="editedIndex != -1"
                    :entity-id="(editedEntry.id || 0).toString()"
                    :image-url="editedEntry.imageUrl"
                    entity-type="product"
                    @create="(entityId, url) => $emit('image-updated', entityId, url)"
                    @delete="(entityId, url) => $emit('image-updated', entityId, null)"
                ></editable-image>
              </v-flex>

              <v-flex :md8="editedIndex != -1" xs12>
                <v-layout row wrap>
                  <v-flex lg4 md6 xs12>
                    <v-text-field
                        id="code-input"
                        v-model="editedEntry.code"
                        :disabled="editedEntry.isExternal"
                        :rules="[$validations.commonCharacters]"
                        :label="$t('codeProductDialog')"
                    ></v-text-field>
                  </v-flex>

                  <v-flex lg4 md6 xs12>
                    <v-text-field
                        id="description-input"
                        v-model="editedEntry.description"
                        :disabled="editedEntry.isExternal"
                        :rules="[$validations.required, $validations.nonEmpty]"
                        :label="$t('descriptionProductDialog')"
                        required
                    ></v-text-field>
                  </v-flex>

                  <v-flex lg4 md6 xs12>
                    <crud-select
                        v-model="editedEntry.categoryId"
                        :disabled="!enabled.category"
                        :rules="[$validations.nonEmpty]"
                        api-url="api/ProductCategories"
                        clearable
                        item-text="name"
                        item-value="id"
                        :label="$t('categoryProductDialog')"
                        removable
                    ></crud-select>
                  </v-flex>

                  <v-flex lg4 md6 xs12>
                    <v-text-field
                        id="defaultPrice-input"
                        v-model="editedEntry.defaultPrice"
                        :disabled="editedEntry.isExternal"
                        :rules="[$validations.float, $validations.positiveFloat]"
                        :label="$t('defaultPriceProductDialog', {sign: $currencyFormatter.getSign()})"
                        min="0"
                        placeholder="0.00"
                        step="0.01"
                    ></v-text-field>
                  </v-flex>

                  <v-flex lg4 md6 xs12>
                    <v-text-field
                        id="barCode-input"
                        v-model="editedEntry.barCode"
                        :rules="[$validations.barcode]"
                        :label="$t('barCodeProductDialog')"
                    ></v-text-field>
                  </v-flex>
                  <v-checkbox v-model="editedEntry.ageRestricted" :false-value="false"
                              :label="$t('ageRestricted')"></v-checkbox>

                  <v-expansion-panels flat hover popout>

                    <!-- Informações Fiscais -->
                    <v-expansion-panel id = "fiscalInformation" v-show="userIsAccounting">
                      <v-expansion-panel-header>
                        <div>
                          <v-icon>mdi-currency-usd</v-icon>
                          {{ $t('fiscalInformation') }}
                        </div>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content eager>
                        <v-layout row wrap>
                          <v-flex lg4 md6 xs12>
                            <v-text-field
                                id = "ncm-input"
                                v-model="editedEntry.taxInformationNcm"
                                :disabled="editedEntry.isExternal"
                                :rules="[$validations.digitsAndDots]"
                                hint="Código de Nomenclatura Comum do Mercosul."
                                :label="$t('ncmProductDialog')"
                            ></v-text-field>
                          </v-flex>
                          <v-flex lg4 md6 xs12>
                            <v-text-field
                                id = "ex-tipi-input"
                                v-model="editedEntry.taxInformationExTipi"
                                :rules="[$validations.integer]"
                                hint="Código de Exceção na Tabela do IPI."
                                :label="$t('extipiProductDialog')"
                            ></v-text-field>
                          </v-flex>
                          <v-flex lg4 md6 xs12>
                            <v-text-field
                                id = "cest-input"
                                v-model="editedEntry.taxInformationCest"
                                :disabled="editedEntry.isExternal"
                                :rules="[$validations.digitsAndDots]"
                                hint="Código Especificador da Substituição Tributária."
                                :label="$t('cestProductDialog')"
                            ></v-text-field>
                          </v-flex>
                          <v-flex lg4 md6 xs12>
                            <v-text-field
                                id = "origin-input"
                                v-model="editedEntry.taxInformationOrigin"
                                :disabled="editedEntry.isExternal"
                                :rules="[$validations.integer]"
                                hint="Origem da mercadoria ou serviço."
                                :label="$t('originProductDialog')"
                            ></v-text-field>
                          </v-flex>
                        </v-layout>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <!-- Cenários Fiscais -->
                    <v-expansion-panel id = "fiscalScenarios" v-show="userIsAccounting">
                      <v-expansion-panel-header>
                        <div>
                          <v-icon>mdi-format-list-checks</v-icon>
                          {{ $t('fiscalScenariosProductDialog') }}
                        </div>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content eager>
                        <v-row class="px-2" cols="12">
                          <v-col cols="12">
                            <v-data-table
                                :headers="taxGroupsHeaders"
                                :items="editedEntry.productTaxGroups"
                                class="pb-4"
                                hide-default-footer
                                :no-data-text="$t('noTaxScenarios')"
                                sort-by="isPrincipal"
                                sort-desc
                            >
                              <template #item.taxGroupId="{item}">
                                <v-col cols="12" md="12">
                                  <v-autocomplete
                                      id="searchScenario-input"
                                      v-model="item.taxGroupId"
                                      :items="taxGroups"
                                      :loading="!taxGroupsReceived"
                                      item-text="taxGroupSelectable"
                                      item-value="id"
                                      :label="$t('fiscalScenarioProductDialog')"
                                      :no-data-text="$t('noFiscalScenariosRegistered')"
                                      single-line
                                  ></v-autocomplete>
                                </v-col>
                              </template>

                              <template #item.delete="{ item }">
                                <v-icon
                                    :disabled="item.isPrincipal && editedEntry.productTaxGroups.length !== 1"
                                    color="error"
                                    @click="removeTaxGroup(item)">mdi-delete
                                </v-icon>
                              </template>

                              <template #item.isPrincipal="{item}">
                                <v-simple-checkbox
                                    v-model="item.isPrincipal"
                                    :disabled="item.isPrincipal"
                                    @click="changeTaxGroupIsPrincipal(item)"
                                ></v-simple-checkbox>
                              </template>

                              <template #footer>
                                <v-btn
                                    id="addScenario-button"
                                    class="px-0"
                                    color="success"
                                    text @click="addTaxGroup()">
                                  <v-icon>add</v-icon>
                                  {{ $t('addTaxGroupProductDialog') }}
                                </v-btn>
                              </template>

                            </v-data-table>
                          </v-col>
                        </v-row>

                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <!-- Configurações de Compras -->
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        <div>
                          <v-icon>mdi-warehouse</v-icon>
                          {{ $t('purchaseSettings') }}
                        </div>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content eager>
                        <v-row cols="12">
                          <v-col cols="6">
                            <v-select
                              v-model="editedEntry.conversionUnitName"
                              :label="$t('conversionUnit')"
                              :items="conversionUnits"
                            ></v-select>
                          </v-col>
                          <v-col cols="3">
                            <v-text-field
                              :label="unitRatioTextFieldLabel"
                              v-model="editedEntry.conversionUnitRatio"
                              type="number"
                              :rules="conversionUnitRules"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>

                </v-layout>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <router-link
              v-if="posLink != null && planogramLink != null"
              :to="`/${$t('path.namePlanogramMaster')}?pdv=${posLink}&pog=${planogramLink}`"
          >
            <template>
              <v-btn color="primary" outlined @click.native="itemDialog = false">{{ $t('backToPlanogram') }}</v-btn>
            </template>
          </router-link>
          <v-spacer></v-spacer>
          <v-btn v-if="posLink == null && planogramLink == null" color="primary" text @click.native="itemDialog = false">{{ $t('closeProductDialog') }}</v-btn>
          <v-btn id="saveProduct-button"
            :disabled="!valid"
            :loading="loading.newItem"
            color="primary"
            type="submit"
          >
            {{ $t('saveProductDialog') }}
          </v-btn>
        </v-card-actions>
        <DialogConfirmation
          v-model="displayWarningDialog"
          :title="$t('products.warning.barCode.title').toString()"
          :message="$t('products.warning.barCode.description').toString()"
          :confirm-message="$t('buttons.save').toString()"
          :cancel-message="$t('buttons.back').toString()"
          @confirm="acknowledge(true)"
          @cancel="acknowledge(false)"
          />
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import CrudSelect from '@/components/CrudSelect';
import EditableImage from '@/components/EditableImage';
import ProductDialogHelp from '@/views/products/ProductDialogHelp';
import {ACCOUNTING_HIERARCHY} from '@/constants/Roles';
import { Taxations } from '@/constants/Taxations';
import agent from '@/api/agent';
import DialogConfirmation from "@/components/DialogConfirmation.vue";

export default {
  props: {
    value: {type: Boolean, required: true},
    editedIndex: {type: Number, required: true},
    editedEntry: {type: Object},
    posLink: {type: String, default: null},
    planogramLink: {type: String, default: null},
    productRegistrationDisabled: {type: Boolean, default: false},
    showActivationButton: {type: Boolean, default: true},
  },
  data() {
    return {
      taxGroups: [],
      taxGroupsHeaders: [
        {text: this.$t('fiscalScenarioProductDialog'), value: 'taxGroupId', align: 'left', sortable: false},
        {text: this.$t('principalProductDialog'), value: 'isPrincipal', align: 'left', sortable: false, width: 30},
        {text: this.$t('deleteProductDialog'), value: 'delete', align: 'left', sortable: false, width: 10}
      ],
      conversionUnits: [],
      valid: true,
      loading: {
        newItem: false,
      },
      enabled: {
        category: true
      },
      taxGroupsReceived: false,
      displayWarningDialog: false,
      proceed: false,
    };
  },
  mounted() {
    this.getTaxGroups();
    this.IsCategoryEnabled();
    this.getConversionUnitsList();

    if(this.$route.query.openDialog === 'true'){
      this.itemDialog = true;
    }
  },
  computed: {
    itemDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    userIsAccounting() {
      return ACCOUNTING_HIERARCHY.some(role => this.$auth.check(role));
    },
    dialogTitle() {
      return this.editedIndex === -1 ? this.$t('newProductDialog') : this.$t('editProductDialog');
    },
    conversionUnitRules() {
      const rules = [];
      rules.push(this.$validations.integer);

      if (this.editedEntry.conversionUnitName == "Unit") {
        let isOne = (v) => v == 1 || this.$t('uniMustBeA');
        rules.push(isOne);
      }

      if (this.editedEntry.conversionUnitName != null) {
        let nonNull = this.$validations.nonEmpty;
        rules.push(nonNull);

        let isPositive = (v) => v > 0 || this.$t('isPositiveRules');
        rules.push(isPositive);
      }

      return rules;
    },
    unitRatioTextFieldLabel() {
      const value = this.conversionUnits.find(u => u.value == this.editedEntry.conversionUnitName);

      return `${this.$t('quantityPerProductDialog')} ${ value != null ? value.text : this.$t('unitProductDialog') }`;
    }
  },
  watch: {
    itemDialog(isOpen) {
      if (!isOpen) {
        this.$refs.form.resetValidation();
        this.editedEntry.ageRestricted = false;
        this.valid = true;
      }
    },
  },
  methods: {
    IsCategoryEnabled() {
      const type = 'ProductCategory';
      agent.Features.VerifyFeatureIsEnable(type).then((response) => {
        this.enabled.category = response;
      })
    },
    saveItem() {
      if (this.$refs.form.validate()) {
        this.loading.newItem = true;
        this.removeEmptyTaxGroup();
        const editedIndex = this.editedIndex;
        const editedEntry = this.editedEntry;

        if (!editedEntry.barCode && !this.proceed) {
          this.displayWarningDialog = true;
          return;
        }

        if (!editedEntry.barCode) {
          editedEntry.barCode = null;
        }

        if (editedIndex === -1) { // is new item
          agent.Products.postProduct(editedEntry)
            .then((response) => {
              this.$emit('add', response);
            }).catch((error) => this.$emit('error', this.$t('saveItemErrorProductDialog'), error))
            .then(() => this.loading.newItem = false);
        } else {
          agent.Products.putProduct(editedEntry.id, editedEntry)
            .then((response) => {
              this.$emit('update', response);
            }).catch((error) => this.$emit('error', this.$t('errorSavingItemProductDialog'), error))
            .then(() => this.loading.newItem = false);
        }
        this.proceed = false;
      }
    },
    getTaxGroups() {
      if (this.$auth.user().tenantCountry == 'BR') {
        this.getBrazilTaxGroups();
        return;
      }
      if (this.$auth.user().tenantCountry == 'US') {
        this.getUsaTaxGroups();
        return;
      }
      this.getBrazilTaxGroups();
    },
    getBrazilTaxGroups(){
      agent.Taxings.getBrazilianTaxes()
        .then((response) => {
          this.taxGroups = response.items.map((taxGroup) => {
            taxGroup.taxGroupSelectable =
              taxGroup.id
              + (taxGroup.taxation ? " - " + Taxations[taxGroup.taxation] : "")
              + (taxGroup.state ? " - " + taxGroup.state : "")
              + (taxGroup.name ? " - " + taxGroup.name : "")
              + (taxGroup.cfop ? this.$t('getTaxGroupsCfop') + taxGroup.cfop : "");
            this.taxGroupsReceived = true;
            return taxGroup;
          })
        });
    },
    getUsaTaxGroups(){
      agent.Taxings.getUsaTaxes()
        .then((response) => {
          this.taxGroups = response.items.map((taxGroup) => {
            taxGroup.taxGroupSelectable =
              taxGroup.id
              + (taxGroup.name ? " - " + taxGroup.name : "")
              + (taxGroup.fiscalRegionName ? " - " + taxGroup.fiscalRegionName : "")
            this.taxGroupsReceived = true;
            return taxGroup;
          })
        });
    },
    getConversionUnitsList(){
      agent.Products.getConversionUnitsList()
        .then((response) => {
          this.conversionUnits = response;
        })
    },
    addTaxGroup() {
      const newLine = {
        taxGroupId: null,
        isPrincipal: this.editedEntry.productTaxGroups.length === 0
      };
      this.editedEntry.productTaxGroups.push(newLine);
    },
    removeEmptyTaxGroup() {
      this.editedEntry.productTaxGroups.forEach(ptg => {
        if(!ptg.taxGroupId) {
          this.removeTaxGroup(ptg)
        }
      })
    },
    removeTaxGroup(item) {
      this.editedEntry.productTaxGroups = this.editedEntry.productTaxGroups.filter((p) => p !== item);
    },
    changeTaxGroupIsPrincipal(item) {
      let notPrincipalTaxGroups = this.editedEntry.productTaxGroups.filter((p) => p !== item);
      notPrincipalTaxGroups.forEach(taxGroup => {
        taxGroup.isPrincipal = false;
      });
    },
    acknowledge(userConfirmation) {
      this.proceed = userConfirmation;
      if (this.proceed) {
        this.saveItem();
      } else {
        this.loading.newItem = false;
      }
    },
  },
  components: {
    DialogConfirmation,
    CrudSelect,
    EditableImage,
    ProductDialogHelp
  }
};
</script>
