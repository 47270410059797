export default {
    changeImage: 'Change image',
    removeImage: 'Remove image',
    deleteImageConfirmation: 'Are you sure you want to remove the image?',
    noEditableImage: 'No',
    removeEditableImage: 'Remove',
    imageRemoved: 'Image removed',
    unableToRemoveImage: 'Image could not be removed',
    imageSavedSuccessfully: 'Image saved successfully!',
    unableToValidateImage: 'Image could not be validated',
    confirm: 'Confirm',
    cancel: 'Cancel',
    uploadImageError: 'Image could not be uploaded',
    authorizeUploadError: 'Could not authorize image upload: ',
    unknownError: 'Unknown error',
    clickOrDragFileHere: 'Click or drag file here to upload'
};