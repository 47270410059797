<script>
import { useApprovalAcquisitionsOrderStore } from "@/stores/approvalAcquisitionsOrderStore";

export default {
  data() {
    return {
      approvalAcquisitionsOrderStore: useApprovalAcquisitionsOrderStore(),
    }
  },
  methods: {
    async HandleAquisitionOrderApproval() {
      await this.approvalAcquisitionsOrderStore.HandleAquisitionOrderApproval();
    },
  },
}
</script>

<template>
  <v-dialog v-model="approvalAcquisitionsOrderStore.commentsDialog" max-width="600px">
    <v-card style="padding: 10px;">
      <v-form>
        <v-card-title class="d-flex justify-center" v-html="approvalAcquisitionsOrderStore.formattedTitle"></v-card-title>
          <v-card-text style="font-size: 16px; color: #666666;" class="mt-1">
            <p style="text-align: center;" v-html="approvalAcquisitionsOrderStore.formattedText"></p>

            <p v-if ="approvalAcquisitionsOrderStore.isCancelled()" class="comment-spacing">
              {{$t('reasonLabel')}}
            </p>

            <div>
              <v-textarea
                class="textarea-spacing"
                v-model="approvalAcquisitionsOrderStore.aquisitionOrderApproval.comments"
                :placeholder="approvalAcquisitionsOrderStore.reasonPlaceholder"
                :disabled ="approvalAcquisitionsOrderStore.commentsDisabled"
                outlined
                :rules="[(v) => (approvalAcquisitionsOrderStore.isApproved() || !!v)]"
                persistent-hint
                :hint="approvalAcquisitionsOrderStore.hintText"
              ></v-textarea>
              <span
                v-if="!approvalAcquisitionsOrderStore.isApproved() && !approvalAcquisitionsOrderStore.commentsDisabled"
                class="obligatory-field-text">{{$t('requiredField')}}
              </span>
            </div>
          </v-card-text>

        <v-card-actions class="justify-end">
          <v-btn
            v-if="!approvalAcquisitionsOrderStore.commentsDisabled"
            text
            color="primary"
            @click="approvalAcquisitionsOrderStore.closeDialogueComments">
            {{$t('btnClose')}}
          </v-btn>
          <v-btn
            v-if="!approvalAcquisitionsOrderStore.commentsDisabled"
            color="primary"
            :disabled="approvalAcquisitionsOrderStore.aquisitionOrderApproval.comments === '' && !approvalAcquisitionsOrderStore.isApproved()"
            :loading = "approvalAcquisitionsOrderStore.loadingDialog"
            @click="HandleAquisitionOrderApproval"
          >
            {{$t('buttonConfirm')}}
          </v-btn>
          <v-btn
            v-if="approvalAcquisitionsOrderStore.commentsDisabled"
            color="primary"
            @click="approvalAcquisitionsOrderStore.closeDialogueComments"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.textarea-spacing {
  margin-bottom: -26px !important;
}
.comment-spacing {
  margin-top: 30px !important;
}
.obligatory-field-text {
  color: #FF5252;
  font-size: 12px;
}
</style>
