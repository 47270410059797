import {defineStore} from "pinia";
import {computed, ref} from "vue";
import agent from "@/api/agent";
import {useAlertsStore} from "@/stores/alertsStore";
import {
  AcquisitionOrderApprovalTableDto,
  AcquisitionOrderApprovalTableFilterDto,
  OrderStatus,
  AcquisitionOrderUpdateStatusdDto,
} from "@/types/ApprovalAcquisitionsOrderTypes";
import {PaginatedQuery} from "@/types/common/PaginatedQuery";
import {PaginatedList} from "@/types/common/PaginatedList";
import i18n from "@/locales/i18n";
import { formatDateWithoutTimezoneAdjustment, formatDocument } from "@/utility/TextFormatting";

export const useApprovalAcquisitionsOrderStore = defineStore('approvalAcquisitionsOrderStore', () => {

  const alertsStore = useAlertsStore()

  const loadingView = ref(false)
  const loadingDialog = ref(false)
  const commentsDialog = ref(false);
  const commentsDisabled = ref(false);

  const acquisitionOrderApprovalTableFilterDto: AcquisitionOrderApprovalTableFilterDto = {tenantId: 0};
  const paginatedQuery: PaginatedQuery = new PaginatedQuery(1, 10, 'Status', true, null);
  const paginatedListResponse = ref<PaginatedList<AcquisitionOrderApprovalTableDto>>();
  const aquisitionOrderApproval = ref<AcquisitionOrderUpdateStatusdDto>( {id: 0, status: 0, comments: ''});

  const selectedOrder = computed(() => {
    return paginatedListResponse.value?.items.find(
      (x) => x.id === aquisitionOrderApproval.value.id
    ) || null;
  });
  
  const cnpj = computed(() => {
    return selectedOrder.value?.documentId 
      ? formatDocument(selectedOrder.value.documentId)
      : '';
  });
  
  const email = computed(() => {
    return selectedOrder.value?.franchiseUserEmail || null;
  });

  const formattedTitle = computed(() => {
    if (aquisitionOrderApproval.value.status === null && commentsDisabled.value) {
      return i18n.t("seeComment", { cnpj: cnpj.value });
    }
    return i18n.t("attention");
  });
  
  const formattedText = computed(() => {
    if (!aquisitionOrderApproval.value) return '';
    if (aquisitionOrderApproval.value.status === 1) {
      return i18n.t("areYouSureApprove", { cnpj: cnpj.value });
    }
    if (aquisitionOrderApproval.value.status === 2) {
      return i18n.t("areYouSureReject", { cnpj: cnpj.value });
    }
    if (aquisitionOrderApproval.value.status === null) {
      return commentsDisabled.value
        ? ""
        : i18n.t("areYouSurePending", { cnpj: cnpj.value });
    }
    return "";
  });
  
  const reasonPlaceholder = computed(() => {
    return isApproved() 
      ? `${i18n.t('reasonText')} ${i18n.t('optionalTag')}`
      : i18n.t('reasonText');
  });
  
  const hintText = computed(() => {
    if (!email.value) return "";
    return i18n.t("userAcquisitionOrder", {
      user: email.value,
      date: formatDateWithoutTimezoneAdjustment(selectedOrder.value?.statusLastUpdate)
    });
  });

  async function getAllAcquisitionsOrders(tenantId: number) {
    acquisitionOrderApprovalTableFilterDto.tenantId = tenantId;
    loadingView.value = true;
    paginatedQuery.search = filterAlphanumeric(paginatedQuery.search);
    await agent.AcquisitionOrders.getAcquisitionOrders(paginatedQuery, acquisitionOrderApprovalTableFilterDto)
      .then((response) => {
        paginatedListResponse.value = response;

      })
      .catch(() => {
        const errorMessage = i18n.t('errorLoadingData');
        alertsStore.showErrorSnackbar(typeof errorMessage === 'string' ? errorMessage : errorMessage.toString());
      })
      .finally(() => {
        loadingView.value = false;
      });
  }

  function filterAlphanumeric(input: string | null | undefined): string | null | undefined {

    if (input == null) {
      return input;
    }
    return input.replace(/[^a-zA-Z0-9]/g, '');
  }

  function openDialogueComments(id: number, orderStatus: OrderStatus | null, disableComments: boolean) {
    commentsDisabled.value = disableComments;
    aquisitionOrderApproval.value = {
      id: id,
      status: orderStatus,
      comments: paginatedListResponse.value?.items.find(x => x.id === id)?.comments || ''
    };
    commentsDialog.value = true;
  }

  function closeDialogueComments() {
    commentsDisabled.value = false;
    commentsDialog.value = false;
  }

  function isApproved() {
    return aquisitionOrderApproval.value.status === OrderStatus.Approved;
  }

  function isCancelled() {
    return aquisitionOrderApproval.value.status === OrderStatus.Cancelled;
  }

  async function HandleAquisitionOrderApproval() {
    loadingDialog.value = true;
    await agent.AcquisitionOrders.updateAcquisitionOrderStatus(aquisitionOrderApproval.value).then(() => {
      const message = i18n.t('sucessUpdatingAcquisitionOrder');
      alertsStore.showSuccessSnackbar(typeof message === 'string' ? message : message.toString());

    }).catch(() => {
      const message = i18n.t('errorUpdatingAcquisitionOrder');
      alertsStore.showErrorSnackbar(typeof message === 'string' ? message : message.toString());
    }).finally(() => {
      commentsDialog.value = false;
      loadingDialog.value = false;
      getAllAcquisitionsOrders(acquisitionOrderApprovalTableFilterDto.tenantId);
    });
  }

  return {
    aquisitionOrderApproval,
    closeDialogueComments,
    commentsDialog,
    commentsDisabled,
    formattedText,
    formattedTitle,
    getAllAcquisitionsOrders,
    HandleAquisitionOrderApproval,
    hintText,
    isApproved,
    isCancelled,
    loadingDialog,
    loadingView,
    openDialogueComments,
    paginatedListResponse,
    paginatedQuery,
    reasonPlaceholder,
  }
})
