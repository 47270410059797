
import Vue from 'vue';
import {AcquisitionOrders} from '@/api/agent';
import {
  AcquisitionOrderRequest,
  CatalogItem,
  DeliveryAddress,
  PaymentProcessor,
  PaymentProcessorCompany
} from '@/types/AcquisitionOrders'
import IconButton from "@/components/buttons/IconButton.vue";
import BrazilStates from "@/components/BrazilStates";
import {useAlertsStore} from "@/stores/alertsStore";
import {useAcquisitionOrdersStore} from "@/stores/acquisitionOrdersStore";
import {usePaymentProcessorsStore} from "@/stores/paymentProcessorsStore";
import PaymentProcessorCompanySelectionDialog from "@/views/acquisitionOrders/dialogs/paymentProcessor/PaymentProcessorCompanySelectionDialog.vue";
import PaymentProcessorChooseExistingDialog from "@/views/acquisitionOrders/dialogs/paymentProcessor/PaymentProcessorChooseExistingDialog.vue";
import PaymentProcessorCreatingExistingDialog from "@/views/acquisitionOrders/dialogs/paymentProcessor/PaymentProcessorCreatingExistingDialog.vue";
import PaymentProcessorExistingCreatedDialog from "@/views/acquisitionOrders/dialogs/paymentProcessor/PaymentProcessorExistingCreatedDialog.vue";
import PaymentProcessorCreateNewDialog from './dialogs/paymentProcessor/PaymentProcessorCreateNewDialog.vue';
import PaymentProcessorPagSeguroAdditionalInfoDialog from './dialogs/paymentProcessor/PaymentProcessorPagSeguroAdditionalInfoDialog.vue';
import PaymentProcessorNewCreatedDialog from './dialogs/paymentProcessor/PaymentProcessorNewCreatedDialog.vue';
import {useFeatureFlagsStore} from "@/stores/featureFlagsStore";

export default Vue.extend({
  computed: {
    PaymentProcessorCompany() {
      return PaymentProcessorCompany
    },
    BrazilStates() {
      return BrazilStates
    }
  },
  components: {
    PaymentProcessorExistingCreatedDialog,
    PaymentProcessorCreatingExistingDialog,
    PaymentProcessorChooseExistingDialog,
    PaymentProcessorCompanySelectionDialog,
    PaymentProcessorCreateNewDialog,
    PaymentProcessorPagSeguroAdditionalInfoDialog,
    PaymentProcessorNewCreatedDialog,
    IconButton,
  },
  props: {
    parentOrderProducts: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      acquisitionOrdersStore: useAcquisitionOrdersStore(),
      paymentProcessorsStore: usePaymentProcessorsStore(),
      featureFlagsStore: useFeatureFlagsStore(),

      loading: false,
      valid: {
        order: false,
        newPaymentProcessor: false,
        newCustomerAddress: false
      },
      finalOrderProducts: [] as (CatalogItem & {selectedQuantity: number})[],

      // variables for the paymentProcessors radio buttons and dialog
      selectedPaymentProcessorIndex: 0,
      editPaymentProcessorIndex: 0,
      states: BrazilStates.states.map(state => state.value),
      cnpjIsRegular: true,
      // variables for the customerAddresses radio buttons and dialog
      selectedDeliveryAddressIndex: 0,
      cep:0,
      newCustomerAddress: {
        city: "",
        cep: "",
        address: "",
        addressNumber: null,
        addressComplement: null as string | null,
        brazilState: "",
      } as DeliveryAddress,
      newCustomerAddressDialog: false
    }
  },
  watch: {
    async cep(newCep){
      newCep = String(newCep).replace(/[^0-9]/g, '');
      if(newCep.length === 8){
        await AcquisitionOrders.getDeliveryAddress(newCep).then((response) => {
          this.newCustomerAddress = Object.assign(this.newCustomerAddress, response);
        }).catch(() => {
          (this.$refs.feedback as any).handleError(this.$t("cepError"));
          this.newCustomerAddress = {
            city: "",
            cep: "",
            address: "",
            addressNumber: null,
            addressComplement: null,
            brazilState: "",
          }
        });
      }
    }
  },
  async mounted() {
    await Promise.all([
      this.acquisitionOrdersStore.getEnvironmentInfo(),
      this.paymentProcessorsStore.getPaymentProcessorAccounts(),
      this.featureFlagsStore.loadFeatureFlags(),
      this.validateDocumentId()]);
    this.finalOrderProducts = this.parentOrderProducts as any[];
  },
  methods: {
    async validateDocumentId(){
      let alertStore = useAlertsStore();
      await AcquisitionOrders.validateDocumentId()
        .then((response) => {
          if (response.every(r => r.status.toString() === 'Active')
            && response.every(r => r.ieIsValid)) {
            alertStore.showSuccessSnackbar(this.$t('documentIdIsOk').toString());
            this.cnpjIsRegular = true;
          } else {
            this.cnpjIsRegular = false;
            let message = "";

            const invalidDocuments = response.filter(r =>
              r.status.toString() !== 'Active' || !r.ieIsValid
            );

            // Verifique se há documentos inválidos antes de continuar
            if (invalidDocuments.length > 0) {
              invalidDocuments.forEach(r => {
                const localizedMessage = this.$t("documentIdIsNotOk", {
                  status: this.$t(`statusEnum.${r.status}`),
                  ieStatus: this.$t(r.ieIsValid ? "ieStatus.Ok" : "ieStatus.NotOk"),
                  documentId: r.documentId
                }).toString();
                message += localizedMessage + "\n\n\n";
              });

              alertStore.showErrorSnackbar(message,50000);
            } else {
              console.error("Nenhum documento inválido encontrado, mas o fluxo caiu no else.");
            }
          }
        })
        .catch(() => {
          this.cnpjIsRegular = false;
          alertStore.showErrorSnackbar(this.$t('missingDocumentIdError').toString(),50000);
        });

    },
    closeDialog() {
      this.newCustomerAddressDialog = false;
    },
    hasNeedOfPaymentProcessor(){
      const products = this.finalOrderProducts;
      for(let i = 0; i < products.length; i++){
        if(products[i].hasNeedOfPaymentProcessor || products[i].hasNeedOfPaymentProcessor === null){
          return true;
        }
      }
      return false;
    },
    async sendOrder() {
      if ((this.$refs.form as any).validate()) {

        const isLastAddress = this.selectedDeliveryAddressIndex === this.acquisitionOrdersStore.deliveryAddresses.length - 1;
        const selectedAddress = isLastAddress ? null : this.acquisitionOrdersStore.deliveryAddresses[this.selectedDeliveryAddressIndex];

        if (
          selectedAddress != null &&
          Object.entries(selectedAddress as DeliveryAddress)
            .filter(([key]) => key !== 'addressComplement')
            .some(([, value]) => value === null)
        ) {
          (this.$refs.feedback as any).handleError(this.$t("missingAddressInformationError"));
          return;
        }
        const hasNeedOfPaymentProcessor = this.hasNeedOfPaymentProcessor();
        let paymentProcessorDto = {} as PaymentProcessor;
        if(hasNeedOfPaymentProcessor){
          paymentProcessorDto = this.paymentProcessorsStore.paymentProcessorAccounts[this.selectedPaymentProcessorIndex]
          if (paymentProcessorDto.processorCompany === PaymentProcessorCompany.PagSeguro
            && (paymentProcessorDto.activationCode === null || paymentProcessorDto.apiToken === null)
            && !paymentProcessorDto.isNewlyCreatedAccount) {
            (this.$refs.feedback as any).handleError(this.$t("missingPagSeguroInfo"));
            return;
          }
          if (paymentProcessorDto == undefined) {
            (this.$refs.feedback as any).handleError(this.$t("missingPaymentProcessorError"));
            return;
          }
        }

        const orderItems = this.finalOrderProducts.map(product => {
          return {
            quantity: product.selectedQuantity,
            catalogItemId: product.id
          }
        })

        let phone = this.acquisitionOrdersStore.environmentInfo.customerInfo.phone;

        if(phone === null || phone === undefined){
          (this.$refs.feedback as any).handleError(this.$t("missingPhoneInformationError"));
          return;
        }
        if(this.acquisitionOrdersStore.environmentInfo.billingDocumentId === null || this.acquisitionOrdersStore.environmentInfo.billingDocumentId === undefined){
          (this.$refs.feedback as any).handleError(this.$t("missingDocumentIdError"));
          return;
        }

        if (phone.length === 10 || phone.length === 11) {
          phone = `55${phone}`;
        }

        const params: AcquisitionOrderRequest = {
          orderItems: orderItems,
          tenantId: (this as any).$auth.user().tenantId,
          groupId: (this as any).$auth.user().tenantGroupId,
          documentId: this.acquisitionOrdersStore.environmentInfo.billingDocumentId,
          fantasyName: this.acquisitionOrdersStore.environmentInfo.customerInfo.fantasyName,
          email: this.acquisitionOrdersStore.environmentInfo.customerInfo.email,
          phoneNumber: this.acquisitionOrdersStore.environmentInfo.customerInfo.phone,
          deliveryAddress: selectedAddress,
          paymentProcessorId: hasNeedOfPaymentProcessor ? paymentProcessorDto.id : null,
          isPaymentProcessorAccountNewlyCreated: hasNeedOfPaymentProcessor? paymentProcessorDto.isNewlyCreatedAccount : null,
        }

        this.loading = true;
        try {
          await AcquisitionOrders.postAcquisitionOrder(params);
          this.$emit('orderSent');
        } catch (error) {
          (this.$refs.feedback as any).handleError(error);
        } finally {
          this.loading = false;
        }
      }
    },
    pushNewCustomerAddress() {
      (this.$refs.newCustomerAddressForm as any).validate();
      Object.assign(this.acquisitionOrdersStore.deliveryAddresses[0], this.newCustomerAddress);
      this.newCustomerAddressDialog = false;
      this.newCustomerAddress = {
        city: "",
        cep: "",
        address: "",
        addressNumber: null,
        addressComplement: "",
        brazilState: "",
      }
      this.cep = 0;
    },
  }
})
