export default {
    nonEmptyRules: 'Enter a valid name',
    requiredRules: 'This field is required',
    requiredDesiredRules: 'This field is required',
    integerRules: 'Enter an integer',
    floatRules: 'Enter a valid value',
    uniqueSelectionRules: 'This selection already exists',
    requiredIfRules: 'This field is required',
    isUrlRules: 'Enter a valid URL',
    isPositiveRules: 'Enter a valid value',
    validCharactersRules: 'Enter valid characters',
    barCodeRules: 'Enter a valid barcode',
    currencyRules: 'Enter a valid value',
    dateRules: 'Enter a valid date',
    countryCodeRules: 'Enter a valid phone number.',
    integerRulesPositive: 'Enter a positive number',
    onlyNumbers: 'Enter only numbers',
};
