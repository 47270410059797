import {defineStore} from "pinia"
import {ref} from "vue"

export const useAlertsStore = defineStore('alerts', () => {
  const snackbar = ref(false);
  const message = ref<string>();
  const color = ref();
  const timeout = ref(4000)

  function showErrorSnackbar(errorMessage: string, timeoutInput?: number, persistent?: boolean) {
    timeout.value = persistent ? 0 : (timeoutInput ?? 4000)
    snackbar.value = true
    color.value = 'error'
    message.value = errorMessage ?? "Erro Inesperado tente novamente"
  }

  function showSuccessSnackbar(successMessage: string) {
    snackbar.value = true
    color.value = 'success'
    message.value = successMessage
    timeout.value = 6000
  }

  function showInfoSnackbar(infoMessage: string) {
    snackbar.value = true
    color.value = 'info'
    message.value = infoMessage
    timeout.value = 6000
  }

  function showWarningSnackbar(warningMessage: string) {
    snackbar.value = true
    color.value = 'warning'
    message.value = warningMessage
    timeout.value = 6000
  }

  return {
    showErrorSnackbar,
    message,
    snackbar,
    color,
    showSuccessSnackbar,
    showInfoSnackbar,
    showWarningSnackbar,
    timeout
  }
})
