export default {
  actions: 'Actions',
  adminApprovalStatus: 'Approval status: Administrator',
  adminRejectionReason: 'Franchise rejection reason',
  adminRejectionTitle: 'Franchise rejection reason',
  agreeWithTermOfResponsibilityTransfer: 'I understand that by transferring the payment machine account, all administrative, operational, and financial responsibilities related to this device will become the sole responsibility of the new owner. This includes, but is not limited to, monthly fees and POS visibility in telemetry. As the former owner, I will no longer have access to the revenue from this POS.',
  agreeWithTermsOfResponsibilityPOS: 'I understand that I am responsible for transferring the payment machine account and that only then will the CNPJ to which I am transferring be able to receive the revenue.',
  cnpjOfDestination: 'CNPJ to which it must be transferred',
  comments: 'Comments',
  contactSuporte: 'It was not possible to update the status of the exchange. Reason: {error}. If the problem persists, please contact support: +55 19 99959-6630. Error reason ',
  customer: 'Client',
  destinationApprovalStatus: 'Approval status: Destination',
  destinationClientName: 'Name of person who will receive the exchange',
  destinationClientPhoneNumber: 'Telephone number of the person who will receive the exchange',
  destinationDocumentId: 'Destination document Id',
  destinationEnvironment: 'Destination environment',
  destinationRejectionReason: 'Destination environment rejection reason',
  finalizationDate: 'Finalization date',
  fromEnvironment: 'Exchange from environment ',
  helpDescription: 'To complete the ownership transfer, you must provide the company\'s Tax ID (CNPJ) and the full details of the person responsible for receiving this transfer. Include their name and contact phone number so that the process can be completed correctly and the recipient can be easily reached.',
  helpTitle: 'Destination Owner Information',
  indoorLocation: 'Internal location',
  linkApproveTransfers: 'Approve Transfers menu.',
  location: 'Location',
  no: 'No',
  originDocumentId: 'Origin document Id',
  originEnvironment: 'Origin environment',
  pointsOfSaleHelpDescription: 'Select the primary points of sale (with planogram) to transfer to the new CNPJ. Associated secondary machines (sharing the same planogram) will be automatically transferred.',
  pointsOfSaleHelpTitle: 'Points of sale',
  pointsOfSaleToBeTransferred: 'Points of sale to be transferred',
  requestDate: 'Request date',
  sameNetworkTransfer: 'Is this transfer within the same network/franchise',
  seeDetails: 'See details',
  serialNumber: 'Serial number',
  submitRequest: 'Submit request',
  systemCode: 'System code',
  termOfResponsibilityTransfer: 'TERMS OF RESPONSIBILITY FOR OWNERSHIP TRANSFER',
  termsAndConditions: 'Terms and Conditions',
  termsAndConditionsDescription: 'This transfer only refers to the POS within the system and does not change the location where the sale will be credited. To transfer the card machine data, please watch the video below:',
  termsAndConditionsHelp: 'If you have any questions about the process, please contact our administrative team at ',
  termsOfResponsibilityPOS: 'TERMS OF RESPONSIBILITY FOR PAYMENT MACHINE TRANSFER',
  titleDestination: 'Enter the CNPJ and the contact information of the person who will receive the change of ownership',
  toEnvironment: ' to environment ',
  transfer: 'Transfer',
  transferPointOfSale: 'Transfer point of sale to another CNPJ',
  transferProcessDescription: 'The process of ownership transfer consists of transferring the store from one CNPJ to another. To ensure that this process runs smoothly, follow the next steps. After submitting the request, you will be able to track the progress of the transfer process in the ',
  viewComments: 'View Comments',
  watchVideo: 'WATCH VIDEO',
  yes: 'Yes',
};
