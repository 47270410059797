export default {
    company: 'Empresa',
    condominium: 'Condominio',
    dateFieldPlaceHolder: 'dd/mm/aaaa',
    dateOfBirth: 'Fecha de nacimiento',
    editButton: 'Editar',
    infoUpdatedMessage: 'Información actualizada con éxito',
    mainAddresses: 'Seleccione sus principales lugares de operación',
    myInfo: 'Mi información',
    onlyNumbers: 'Solo números',
    other: 'Otro',
    phone: 'Teléfono',
    registrationData: 'Datos de registro',
    registrationDataSubtitle: '¡Mantén siempre tus datos actualizados para garantizar la autenticidad y facilitar la comunicación con tu cuenta!',
    street: 'Calle',
    updateInfoDialogText: '¿Estás seguro de que deseas actualizar tu información?',
    alreadyUpToDate: '¡Información ya actualizada!',
    pleaseUpdateCustomerInfo: '¡Por favor, actualiza tu información de perfil!',
    neededToUpdateCustomerInfo:'Actualización de información necesaria',
    update: 'Actualizar',
    close: 'Después',
    dialogAlreadyUpdated: '¿Estás seguro de que deseas confirmar?'
}
