export default {
    returnToPrevious: 'Back',
    customer: 'Customer',
    environmentId: 'Tenant ID',
    email: 'Email',
    permissions: 'Permissions',
    favoritePage: 'Favorite page',
    removeFromFavorites: 'Remove from favorites',
    myAccount: 'MY ACCOUNT',
    newOrder: 'New Order',
    changeOwnership: 'CHANGE OWNERSHIP',
    requestChangeOwnership: 'Transfer Points of Sale',
    viewTransferRequests: 'Approve Transfers',
    myBills: 'My Bills',
    support: 'Support',
    changeTenant: 'Change tenant',
    changeConfirmation: 'Change',
    myProfile: 'My Profile',
    viewAcquisitionsOrders: 'Order Approvals',
    environments: 'Environments',
 };
