<template>
  <v-dialog v-model="dialog" width="unset" @keydown.esc="resetData">
    <v-card>
        <v-card-title class="blue-grey lighten-5" cols="12">
            <div class="title">
              {{ $t('purchaseDetails') }} {{ purchaseInfo.id }}
              <purchase-details-help/>
              <div class="text-left">
                <div class="info-subtitle">
                  {{ $t('purchaseDetailsIdInERP') }} {{ purchaseInfo.externalId }}<br>
                  {{ $t('purchaseDetailsNoteNumber') }} {{ purchaseInfo.nfeNumber }}<br>
                  {{ $t('purchaseDetailsReceiptDate') }} {{ purchaseInfo.dateReceived }}<br>
                  {{ $t('purchaseDetailsInventory') }} {{ purchaseInfo.inventoryId }} - {{ purchaseInfo.inventoryName }}<br>
                  {{ $t('purchaseDetailsTotalNoteValue') }} {{ $currencyFormatter.getSign() }} {{ $currencyFormatter.format(purchaseInfo.nfeCost) }}<br>
                  {{ $t('purchaseDetailsTotalPurchaseCost') }} {{ $currencyFormatter.getSign() }} {{ $currencyFormatter.format(purchaseInfo.cost) }}<br>
                  {{ $t('purchaseDetailsQuantity') }} {{ purchaseInfo.amount }} {{ $t('purchaseDetailsUnits') }}
                </div>
              </div>
            </div>
            <v-spacer></v-spacer>
            <v-col cols="4">
              <v-text-field
                class="mr-4 pt-0"
                v-model="search"
                append-icon="search"
                :label="$t('purchaseDetailsSearchProduct')"
                single-line
                hide-details
                clearable
                filled
                rounded
                dense
                @click:clear="search = ''; getPurchaseDetails()"
                @click:append="getPurchaseDetails()"
                @keydown.enter="getPurchaseDetails()"
              ></v-text-field>
            </v-col>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :loading="loading.purchaseItems"
            :items="items"
            :options.sync="options"
            :server-items-length="totalItems"
            :footer-props="{ itemsPerPageOptions: [5, 10, 15, 30] }"
          >
            <template #[`item.productCategory`]="{ item }">
              {{ item.productCategory ? item.productCategory : "-" }}
            </template>
            <template #[`item.nfeCost`]="{ item }">
              {{ item.nfeCost ? $currencyFormatter.format(item.nfeCost) : "-" }}
            </template>
            <template #[`item.totalCost`]="{ item }">
              {{ $currencyFormatter.format(item.totalCost) }}
            </template>
          </v-data-table>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click.native="dialog = false" text color="primary">
            {{ $t('purchaseDetailsClose') }}
          </v-btn>
          <v-btn
            :disabled="purchaseInfo.isCanceled"
            class="mx-2"
            color="error"
            @click="deletePurchase"
          >
            <v-icon left>mdi-undo-variant</v-icon>{{ $t('purchaseDetailsUndo') }}
          </v-btn>
        </v-card-actions>
      <purchase-reversal
        v-model="purchaseReversalDialog"
        :purchase-id="purchaseInfo.id"
        :is-last-purchase="purchaseInfo.isLastPurchase"
        @success="handleSuccess"
        @error="handleError"
      />
    </v-card>
    <feedback ref="feedback"></feedback>
  </v-dialog>
</template>

<script>
import { shouldRefreshData } from '@/components/TableOptionsComparator';
import { toApiPagination } from '../../components/TableOptionsComparator';
import PurchaseDetailsHelp from "@/views/purchases/helpers/PurchaseDetailsHelp.vue";
import agent from "@/api/agent";
import i18n from '@/locales/i18n';
import PurchaseReversal from "@/views/purchases/Componentes/PurchaseReversal/PurchaseReversal.vue";

export default {
  components: {
    PurchaseReversal,
    PurchaseDetailsHelp
  },
  props: {
    value: { type: Boolean, required: true },
    purchase: { type: Object, default: null }
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    purchaseInfo() {
      if (this.purchase == null) {
        return {
          id: 0,
          isCanceled: null,
          externalId: "",
          nfeNumber: "",
          dateReceived: null,
          inventoryId: null,
          inventoryName: "",
          amount: null,
          nfeCost: null,
          cost: null,
          isLastPurchase: false,
        }
      }
      else {
        return this.purchase;
      }
    }
  },
  watch: {
    dialog: function(enabled) {
      if (enabled) {
        this.$nextTick(() => this.getPurchaseDetails());
      }
      else {
        this.items = [];
        this.search = "";
        Object.assign(this.options, {
          page: 1,
          itemsPerPage: 10,
          mustSort: true,
          sortBy: ['productId'],
          sortDesc: [false]
        });
        this.$refs.feedback.dismiss();
      }
    },
    options: {
      handler(newOptions, oldOptions) {
        if (shouldRefreshData(newOptions, oldOptions)) {
          this.getPurchaseDetails();
        }
      },
      deep: true
    },
  },
  data() {
    const $currencyFormatter = this.$currencyFormatter;
    return {
      headers: [
        {text: i18n.t('purchaseDetailsHeadersID'), align: 'left', sortable: true, value: 'productId'},
        {text: i18n.t('purchaseDetailsHeadersProduct'), align: 'left', sortable: true, value: 'productDescription'},
        {text: i18n.t('purchaseDetailsHeadersCategory'), align: 'left', sortable: true, value: 'productCategory'},
        {text: i18n.t('purchaseDetailsHeadersCode'), align: 'left', sortable: true, value: 'code'},
        {text: i18n.t('purchaseDetailsHeadersQuantity'), align: 'left', sortable: true, value: 'amount'},
        {text: `${i18n.t('purchaseDetailsHeadersNoteValue')} (${$currencyFormatter.getSign()})`, align: 'left', sortable: false, value: 'nfeCost'},
        {text: `${i18n.t('purchaseDetailsHeadersProductTotalCost')} (${$currencyFormatter.getSign()})`, align: 'left', sortable: false, value: 'totalCost'}
      ],
      items: [],
      loading: {
        purchaseItems: true
      },
      options: {
        page: 1,
        itemsPerPage: 10,
        mustSort: true,
        sortBy: ['productId'],
        sortDesc: [false]
      },
      totalItems: 0,
      search: "",
      purchaseReversalDialog: false,
    }
  },
  methods: {
    getPurchaseDetails() {
      let params = toApiPagination(this.options, this.search);
      const id = this.purchase.id;
      this.loading.purchaseItems = true;
      agent.Purchases.details(id, params)
        .then((response) => {
          this.items = response.items;
          this.totalItems = response.totalItems;
        })
        .catch((error) => this.$refs.feedback.handleError(this.$t('purchaseDetailsErrorFetching'), error))
        .then(() => this.loading.purchaseItems = false)
    },
    deletePurchase() {
      this.purchaseReversalDialog = true;
    },
    handleSuccess(message) {
      this.$refs.feedback.handleSuccess(message);
      this.purchaseInfo.isCanceled = true;
      this.purchaseReversalDialog = false;
    },
    handleError(message, error) {
      this.$refs.feedback.handleError(message, error);
      this.purchaseReversalDialog = false;
    },
    resetData() {
      this.dialog = false;
    }
  }
}
</script>

<style>

</style>
