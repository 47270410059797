export default {
    addressComplement: 'Complemento de dirección',
    addressComplementHint: 'Apartamento/Bloque. De lo contrario, déjelo en blanco.',
    addressNumber: 'Número de dirección',
    adminHasApproved: 'Aprobado',
    adminHasRejected: 'Rechazado',
    city: 'Ciudad',
    confirmPassword: 'Confirmar su contraseña',
    copyLink: 'Copiar enlace',
    createPassword: 'Crear una contraseña',
    CustomerRegistrationTable: 'Tabla de aprobación de nuevos clientes',
    documentId: 'Documento de identidad',
    documentIdAlreadyExists: 'Número de identificación ya registrado',
    emailAlreadyExists: 'E-mail ya registrado',
    emailNotSentError: 'No se pudo enviar el correo de confirmación al franquiciado.',
    environmentsPermissionError: 'Solo los administradores de franquicia pueden acceder a página {pageName}',
    fetchRequestsError: 'Error al buscar solicitudes',
    fillInPostalCode: 'Complete el campo Código postal',
    finalizeRegistration: 'Finalizar registro',
    fullName: 'Nombre completo',
    generateNewLink: 'Generar nuevo enlace',
    generateNewLinkDialogMessage: 'Al generar un nuevo enlace, el actual se desactivará y ya no permitirá registros. Los clientes que recibieron el enlace antiguo y no se registraron deben recibir este nuevo enlace para continuar. El enlace será válido hasta las 23:59 del día vigente.',
    generateNewLinkDialogTitle: 'Generar nuevo enlace',
    generateRegistrationLink: 'Generar enlace de registro',
    generateRegistrationLinkError: 'Error al generar enlace de registro',
    invalidLink: 'Enlace caducado o inválido',
    isRegistrationInfoNewError: 'Error al verificar si la información de registro es nueva',
    linkCopiedToClipboard: '¡Enlace copiado con éxito!',
    myFranchisees: 'Mis Franquiciados',
    neighborhood: 'Barrio',
    newCustomerRegistrationText: 'Se genera un nuevo enlace de registro diariamente, válido hasta las 23:59 del día vigente. Haga clic en <span style="font-weight:bold;"> “Copiar enlace”</span> para copiar el enlace y enviarlo a su cliente. Después de completar, los datos del cliente se mostrarán en la tabla a continuación, donde podrá revisar y aprobar el registro.',
    newCustomersRegistration: 'Registro de Nuevos Clientes',
    passwordConfirmed: "¡Contraseña confirmada con éxito!",
    passwordLengthError: 'La contraseña debe tener al menos 8 caracteres',
    passwordMismatch: 'Las contraseñas no coinciden',
    personalData: 'Datos personales',
    postalCode: 'Código postal',
    postalCodeError: 'Error al buscar el código postal. Inténtelo de nuevo o complete la información manualmente.',
    postalCodeOnlyNumbers: 'Complete el código postal sin puntuación, solo con números!',
    registrationApprovedDialogText: '¿Confirmar la <span style="color:#2DCE98;">aprobación</span> de esta solicitud de registro? </br></br> Al hacerlo, se iniciará el proceso de creación de cuenta para este nuevo usuario y pertenecerá a su franquicia',
    registrationFormInstructions: 'Para comenzar, le pedimos que complete la información necesaria con cuidado y precisión. Estos datos son fundamentales para que podamos crear su perfil y ofrecerle el mejor servicio. Después de enviar el registro, será revisado y se le notificará el resultado.', 
    registrationFormWelcome: 'Bienvenido al Registro de Nuevos Clientes!',
    registrationLink: 'Enlace de registro',
    registrationRejectionDialogText: '¿Confirmar la <span style="color:#FF5252;">reprobación</span> de esta solicitud de registro? Al hacerlo, la solicitud se cancelará y se perderán todos los datos para el registro',
    registrationRejectionReason: 'Comentario explicando el motivo del rechazo:',
    state: 'Estado',
    streetName: 'Nombre de la calle',
    successfulRegistration: '<span style="font-size:24px;font-weight:bold;line-height:1.5;color:white;">¡REGISTRO ENVIADO CON ÉXITO!</span> </br></br> <span style="color:white;font-size:20px;">Se le notificará a través del correo electrónico proporcionado tan pronto como se revise su solicitud.</span>',
}