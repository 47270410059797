
import Vue from 'vue';
import {shouldRefreshData, toApiPagination} from '@/components/TableOptionsComparator';
import {formatDateTimeMinutes, formatDocument, formatDateWithoutTimezoneAdjustment} from '@/utility/TextFormatting';
import {
  ApprovedOwnership,
  ChangeOwnershipDto,
  ChangeOwnershipStatus,
  UserRequestingApproval
} from '@/types/ChangeOwnershipType';
import agent from "@/api/agent";
import DialogApproval from '../ChangeOwnershipRequest/components/DialogApproval.vue';
import IconButton from '@/components/buttons/IconButton.vue';
import CommentsDialog from '../ChangeOwnershipRequest/components/CommentsDialog.vue';

export default Vue.extend({
  data() {
    return {
      search: '',
      loading: true,
      alterColor:'',
      items: [] as ChangeOwnershipDto[],
      dialogApprovalProps:{
        dialog: false,
        email: '' as string,
        requestData: {} as ApprovedOwnership,
      },
      commentsDialog: false,
      selectedItem: null as ChangeOwnershipDto | null,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [true],
        mustSort: true,
      },
      totalItems: 0,
      expanded: [],
      headers: [
        { text: this.$t('originDocumentId'), value: 'originCnpj', sortable: false },
        { text: this.$t('destinationDocumentId'), value: 'destinationCnpj', sortable: false },
        { text: this.$t('requestDate'), value: 'requestDate', sortable: false },
        { text: this.$t('originEnvironment'), value: 'originTenantId', sortable: false },
        { text: this.$t('destinationEnvironment'), value: 'destinationTenantId', sortable: false },
        { text: this.$t('adminApprovalStatus'), value: 'adminApprovalStatus', sortable: false },
        { text: this.$t('destinationApprovalStatus'), value: 'destinationApprovalStatus', sortable: false },
        { text: this.$t('actions'), value: 'actions', sortable: false },
      ],
    };
  },
  components:{
    CommentsDialog,
    DialogApproval,
    IconButton
  },
  watch: {
    options: {
      handler(newOptions: any, oldOptions: any) {
        if (shouldRefreshData(newOptions, oldOptions)) {
          this.updateOwnershipRequests();
        }
      },
      deep: true
    },
  },
  methods: {
    formatDateTimeMinutes,
    formatDocument,
    formatDateWithoutTimezoneAdjustment,
    approveItem(item: ChangeOwnershipDto) {
      const userRequesting = (item.adminApprovalStatus != ChangeOwnershipStatus.NotNeeded && this.belongsToNoGroup()) ?
      UserRequestingApproval.franchise : UserRequestingApproval.destination;

      this.dialogApprovalProps.requestData = {
        changeOwnershipRequestId: item.id,
        status: ChangeOwnershipStatus.Approved,
        userRequesting: userRequesting,
      };

      this.dialogApprovalProps.dialog = true;
    },
    belongsToNoGroup() {
      return (this as any).$auth.user().tenantGroupId === null;
    },
    rejectItem(item: any) {
      const userRequesting = item.adminApprovalStatus != ChangeOwnershipStatus.NotNeeded && this.belongsToNoGroup() ?
      UserRequestingApproval.franchise : UserRequestingApproval.destination;

      this.dialogApprovalProps.requestData = {
        changeOwnershipRequestId: item.id,
        status: ChangeOwnershipStatus.Rejected,
        userRequesting: userRequesting,
      };

      this.dialogApprovalProps.dialog = true;
    },
    getAlterColorStatus (status: ChangeOwnershipStatus) {
      if (status === ChangeOwnershipStatus.Approved || status == ChangeOwnershipStatus.NotNeeded) return 'success'
      if (status === ChangeOwnershipStatus.Pending) return 'warning'
      if (status === ChangeOwnershipStatus.DestinationNotCreated) return 'warning'

      return 'error'
    },
    async updateOwnershipRequests() {
      this.loading = true;
      let params = toApiPagination(this.options, this.search);
      try {
        const response = await agent.ChangeOwnership.getChangeRequests(params)
        this.items = response.items;
        this.totalItems = response.totalItems;
      }
      catch (error) {
        (this.$refs.feedback as any).handleError("Não foi possível carregar as trocas de titularidade", error);
      }
      finally {
        this.loading = false
      }
    },
    FranchisePendingApproval(originTenantId: number, adminApprovalStatus: ChangeOwnershipStatus) {
      return this.belongsToNoGroup()
        && (this as any).$auth.user().tenantId == originTenantId
        && adminApprovalStatus === ChangeOwnershipStatus.Pending;
    },
    isDestinationPendingApproval(destinationTenantId: number, groupIdItem: number, destinationApprovalStatus: ChangeOwnershipStatus) {
      return ((this as any).$auth.user().tenantId == destinationTenantId) && ((this as any).$auth.user().tenantGroupId === groupIdItem) && destinationApprovalStatus === ChangeOwnershipStatus.Pending;
    },
    closedDialog() {
      this.dialogApprovalProps.dialog = false;
      this.dialogApprovalProps.requestData = {} as ApprovedOwnership;

      this.updateOwnershipRequests();
    },
    openCommentsDialog(item: ChangeOwnershipDto) {
      this.selectedItem = item;
      this.commentsDialog = true;
    },
    closeCommentsDialog() {
      this.commentsDialog = false;
      this.selectedItem = null;
    },
  },
  mounted() {
    this.updateOwnershipRequests();
  },
});
