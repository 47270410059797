import { render, staticRenderFns } from "./ClickableText.vue?vue&type=template&id=e98f4b38&scoped=true"
import script from "./ClickableText.vue?vue&type=script&lang=ts&setup=true"
export * from "./ClickableText.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./ClickableText.vue?vue&type=style&index=0&id=e98f4b38&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e98f4b38",
  null
  
)

export default component.exports