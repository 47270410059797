export default {
    addressComplement: 'Address complement',
    addressComplementHint: 'Apartment/Block. Otherwise, leave it blank.',
    addressNumber: 'Address number',
    adminHasApproved: 'Approved',
    adminHasRejected: 'Rejected',
    city: 'City',
    confirmPassword: 'Confirm your password',
    copyLink: 'Copy Link',
    createPassword: 'Create a password',
    CustomerRegistrationTable: 'New customers approval table',
    documentId: 'ID number',
    documentIdAlreadyExists: 'ID number already registered',
    emailAlreadyExists: 'E-mail already registered',
    emailNotSentError: 'Could not send confirmation email to franchisee.',
    environmentsPermissionError: 'Only franchise administrators can access the page {pageName}',
    fetchRequestsError: 'Error fetching requests',
    fillInPostalCode: 'Fill in the Postal code field',
    finalizeRegistration: 'Finalize registration',
    fullName: 'Full name',
    generateNewLink: 'Generate New Link',
    generateNewLinkDialogMessage: 'By generating a new link, the current one will be disabled and will no longer allow registrations. Customers who received the old link and did not register must receive this new link to proceed. The link will be valid until 23:59 of the current day.',
    generateNewLinkDialogTitle: 'Generate new link',
    generateRegistrationLink: 'Generate Registration Link',
    generateRegistrationLinkError: 'Error generating registration link',
    invalidLink: 'Expired or invalid link',
    isRegistrationInfoNewError: 'Error checking if registration information is new',
    linkCopiedToClipboard: 'Link copied successfully!',
    myFranchisees: 'My Franchisees',
    neighborhood: 'Neighborhood',
    newCustomerRegistrationText: 'A new registration link is generated daily, valid for the current day until 23:59. Click on <span style="font-weight:bold;"> “Copy link”</span> to copy the link and send it to your customer. After filling in, the customer data will be displayed in the table below, where you can review and approve the registration.',
    newCustomersRegistration: 'New Customers Registration',
    passwordConfirmed: "Password confirmed successfully!",
    passwordLengthError: 'Password must be at least 8 characters long',
    passwordMismatch: 'Passwords do not match',
    personalData: 'Personal data',
    postalCode: 'Postal code',
    postalCodeError: 'Error fetching postal code. Try again or fill in the information manually.',
    postalCodeOnlyNumbers: 'Fill in the postal code without punctuation, only with numbers!',
    registrationApprovedDialogText: 'Confirm the <span style="color:#2DCE98;">approval</span> of this registration request? </br></br> By doing so, the account creation process for this new user will be started and they will belong to your franchise',
    registrationFormInstructions: 'To start, we ask you to fill in the necessary information carefully and accurately. This data is essential for us to create your profile and offer you the best service. After submitting the registration, it will be reviewed and you will be notified of the result.',
    registrationFormWelcome: 'Welcome to the Registration of New Customers!',
    registrationLink: 'Registration link',
    registrationRejectionDialogText: 'Confirm the <span style="color:#FF5252;">rejection</span> of this registration request? By doing so, the request will be canceled and all data for registration will be lost',
    registrationRejectionReason: 'Comment explaining the reason for rejection:',
    state: 'State',
    streetName: 'Street name',
    successfulRegistration: '<span style="font-size:24px;font-weight:bold;line-height:1.5;color:white;">REGISTRATION SENT SUCCESSFULLY!</span> </br></br> <span style="color:white;font-size:20px;">You will be notified via the provided email as soon as your request is reviewed.</span>',
}