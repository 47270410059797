<script>
import { ref } from "vue";
import ViewFranchiseeWeb from './ViewFranchiseeWeb.vue';
import CustomerRegistrationTab from "../newCustomerRegistration/CustomerRegistrationTab.vue";

export default {
  name: "YourPage",
  components: {
    ViewFranchiseeWeb,
    CustomerRegistrationTab,
  },
  setup() {
    const activeTab = ref(0);

    return {
      activeTab,
    };
  },
};
</script>

<template>
  <div>
    <v-tabs v-model="activeTab" grow height="64" class="mb-1">
      <v-tab>{{ this.$t('routes.nameViewFranchiseeWeb').toString() }}</v-tab>
      <v-tab>{{ this.$t('routes.nameCustomerRegistrationTab').toString() }}</v-tab>
    </v-tabs>

    <v-tabs-items v-model="activeTab">
      <v-tab-item :key="0">
        <ViewFranchiseeWeb/>
      </v-tab-item>

      <v-tab-item :key="1">
         <CustomerRegistrationTab/>
      </v-tab-item>

    </v-tabs-items>
  </div>
</template>

<style scoped>
</style>
