
import Vue from 'vue';
import i18n from '@/locales/i18n';
import agent, { MarketIntelligenceAccess } from '@/api/agent';
import { formatDateTimeMinutes } from '@/utility/TextFormatting';
import PointOfSaleHelp from './helpers/PointOfSaleHelp.vue';
import { PointOfSale } from '@/types/PointOfSale';
import RemoveButton from '@/components/buttons/RemoveButton.vue';
import NoButton from '@/components/buttons/NoButton.vue';
import ExcelButton from "@/components/buttons/ExcelButton.vue";
import DownloadDialog from '@/components/common/DownloadDialog.vue';
import AddPosButton from "@/components/buttons/AddCustomButton.vue";
import {shouldRefreshData} from "@/components/TableOptionsComparator";

export default Vue.extend({
  data() {
    return {
      pos: [] as PointOfSale[],
      headers: [
        { text: this.$t('tables.headers.id'), align: 'left', sortable: true, value: 'id' },
        { text: this.$t('tables.headers.paymentSystem'), align: 'left', sortable: true, value: 'boxId' },
        { text: this.$t('tables.headers.posCode'), align: 'left', sortable: true, value: 'companyCode' },
        { text: this.$t('tables.headers.modelManufacturer'), align: 'left', sortable: true, value: 'machineMachineModelName', width: 100 },
        { text: this.$t('tables.headers.customer'), align: 'left', sortable: true, value: 'localCustomerName' },
        { text: this.$t('tables.headers.location'), align: 'left', sortable: true, value: 'localName', width: 300},
        { text: this.$t('tables.headers.distributionCenter'), align: 'left', sortable: true, value: 'distributionCenterName' },
        { text: this.$t('tables.headers.route'), align: 'left', sortable: true, value: 'route' },
        { text: this.$t('tables.headers.dateCreated'), align: 'left', sortable: true, value: 'dateCreated' },
        { text: this.$t('tables.headers.serialNumber'), align: 'left', sortable: true, value: 'cardReaderId' },
        { text: this.$t('tables.headers.actions'), align: 'left', sortable: true, value: 'incompletePos' },
      ],
      itemToRemove: null as PointOfSale | null,
      removeDialog: false,
      loading:{
        table:true,
        excel:false
      },
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ["id"],
        sortDesc: [false],
        mustSort: true,
      },
      search: {
        valid: true,
        text: "",
      },
      localRelatedPeopleQuantity: null,
      localType: null,
      showIncompletePos: false,
      downloadDialog: {
        show: false,
        fileName: ""
      },
      totalItems: 0,
      totalPages: 0,
    }
  },
  watch: {
    showIncompletePos(value) {
      const header = this.headers.find(header => header.text == this.$t('tables.headers.actions'));
      if (header) {
        header.sortable = value;
      }
    },
    options: {
      handler(newOptions, oldOptions) {
        if (shouldRefreshData(newOptions, oldOptions)) {
          this.getPosPaginated();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getPosPaginated();
    this.getUserIntelligenceRegister();
  },
  methods: {
    getPosPaginated() {
      this.loading.table = true;
      const params = {
        page: this.options.page,
        pageSize: this.options.itemsPerPage,
        sortOrder: this.options.sortBy[0],
        descending: this.options.sortDesc[0],
        search: this.search.text ? this.search.text : null,
      }

      agent.PointsOfSale.getPaginated(params)
        .then((response) => {
          this.loading.table = false;
          this.totalItems = response.totalItems;
          this.totalPages = response.totalPages;
          this.pos = response.items.map((item: PointOfSale) => {
            item.incompletePos = item.localRelatedPeopleQuantity === null || item.localType === null;
            return item;
          });
        })
        .catch((error) => (this as any).$refs.feedback.handleError(this.$t('pointOfSale.posManagement.feedbackError'), error));
    },
    editItem(item: PointOfSale) {
      this.$router.push('/' + i18n.t('path.nameToEditPointOfSale') + '/' + item.id);
    },
    deleteItem(item : any) {
      this.itemToRemove = item;
      this.removeDialog = true;
    },
    onDeleteConfirmed() {
      this.removeDialog = false;
      const itemId = this.itemToRemove?.id;

      if (itemId) {
        agent.PointsOfSale.delete(itemId)
          .then(() => {
            this.pos = this.pos.filter(pos => pos.id !== itemId);
          })
          .catch((error) => (this as any).$refs.feedback.handleError(this.$t('pointOfSale.posManagement.feedbackError'), error));
      }
    },
    async getUserIntelligenceRegister() {
      const params = {
        tenantId: (this as any).$auth.user().tenantId,
        groupId: (this as any).$auth.user().tenantGroupId
      }

      try {
        await MarketIntelligenceAccess.getUserAccess(params)
        this.showIncompletePos = true
      }
      catch (error: any) {
        this.showIncompletePos = error.response?.status !== 404;
      }
    },
    async getExcel() {
      this.loading.excel = true;
      const params = {
        timezoneOffset: new Date().getTimezoneOffset()
      }

      try {
        const response = await agent.PointsOfSale.getExcel(params);
        this.loading.excel = false;
        this.downloadDialog.show = true;
        this.downloadDialog.fileName = response;
      }
      catch(error){
        (this as any).$refs.feedback.handleError(this.$t('report.response.excel.error'), error);
      }
      finally {
        this.loading.excel = false;
      }
    },
    formatDateTimeMinutes
  },
  components: {
    PointOfSaleHelp,
    RemoveButton,
    NoButton,
    ExcelButton,
    DownloadDialog,
    AddPosButton
  }
});
