<template>
    <v-snackbar :timeout="timeout" :color="color" v-model="enabled">
      {{message}}
      <slot name="text-append"></slot>
      <v-btn dark text @click.native="enabled = false">Ok</v-btn>
    </v-snackbar>
</template>

<script>
export default {
  data(){
    return {
      enabled: false,
      message: null,
      color: "error",
      timeout: 4000,
    }
  },
  methods: {
    handleSuccess(message) {
			this.message = message;
			this.color = 'success';
      this.timeout = 4000;
			this.enabled = true;
    },
    handleInfo(message) {
      this.message = message;
      this.color = 'info';
      this.timeout = 6000;
      this.enabled = true;
    },
    handleWarning(message) {
      this.message = message;
      this.color = 'warning';
      this.timeout = 6000;
      this.enabled = true;
    },
		handleError(message, error) {
      let errorString = "";
      const errorData = error?.response?.data;
      if (errorData != null) {
        if (typeof errorData === 'string') errorString = errorData;
        else if (Array.isArray(errorData)) errorString = errorData.join(' ');
        else if (typeof errorData === 'object') {
          if (errorData.title != null) errorString = errorData.title;
          if (typeof errorData.errors === 'object' && errorData.errors !== null) {
            if (errorString.length > 0) errorString += " ";
            Object.keys(errorData.errors).forEach(key => {
              if (Array.isArray(errorData.errors[key])) {
                if (errorString.length > 0) errorString += " ";
                errorString += `[${key}]: `
                errorString += errorData.errors[key].join(' ');
              }
            });
          }
        }
      }
      this.message = message + (errorString == null ? "" : ": " + errorString);
      this.color = "error";
      this.timeout = -1;
      this.enabled = true;
		},
    dismiss() {
      this.enabled = false;
    },
  }
}
</script>
