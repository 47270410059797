export default {
    Administrador: 'Administrador',
    'Administrador App': 'Administrador de Aplicaciones',
    'Administrador PrivateLabel': 'Administrador de Etiqueta Privada',
    Cadastros: 'Registros',
    Contabilidade: 'Contabilidad',
    'Edição Preços': 'Edición de Precios',
    Gerente: 'Gerente',
    'Inventário às cegas e abastecimento': 'Inventario a ciegas y abastecimiento',
    Logistica: 'Logística',
    Manager: 'Gerente',
    'Notas Fiscais': 'Facturas Fiscales',
    Segurança: 'Seguridad',
    Síndico: 'Administrador de Edificio',
    Suporte: 'Soporte',
    Telemetria: 'Telemetría',
    'TouchPay Abastecedor': 'Proveedor de TouchPay',
    Usuário: 'Usuario',
    Vendas: 'Ventas',
    'Visualização Planogramas': 'Visualización de Planogramas',
    'Visualização PrivateLabel': 'Visualización de Etiqueta Privada',
    'Visualização Transações': 'Visualización de Transacciones',
    Mercurio: 'Mercurio',
    PushNotification: 'Notificación Push',
    Video: 'Video',

    routes: {
        nameTransactions: 'Transacciones',
        nameTelemetry: 'Telemetría',
        nameConnectivityMaster: 'Conectividad',
        nameManagePos: 'Gestionar Puntos de Venta',
        nameProductsMaster: 'Productos',
        nameInventoryMaster: 'Inventarios',
        namePurchases: 'Compras',
        nameOrders: 'Órdenes',
        nameNewPointOfSale: 'Nuevo Punto de Venta',
        nameToEditPointOfSale: 'Editar Punto de Venta',
        namePriceTables: 'Tablas de Precios',
        namePriceTableItems: 'Productos de la Tabla de Precios',
        namePlanogramMaster: 'Planogramas',
        nameCharts: 'Informes de Ventas',
        nameTransactionsReports: 'Resumen Dinámico de Ventas',
        nameInventoryReports: 'Resumen Dinámico de Inventario',
        nameRuptures: 'Informes de Rupturas',
        nameLosses: 'Informes Operativos',
        namePickLists: 'Pick Lists',
        nameReplenishment: 'Reabastecimiento',
        nameAccessControl: 'Control de Acceso',
        namePrivateLabel: 'Etiqueta Privada',
        nameDiscountsMaster: 'Descuentos',
        nameNewBox: 'Activar Nuevo TouchPay',
        nameAlerts: 'Alertas',
        nameDownloads: 'Descargas',
        nameContracts: 'Activaciones',
        nameSupport: 'Ayuda',
        nameReleaseNotes: 'Notas de Versión',
        nameAudits: 'Auditorías',
        nameTaxScenarios: 'Escenarios Fiscales',
        namePagSeguro: 'PagSeguro',
        nameSwagger: 'Swagger',
        nameSuspiciousMaster: 'Alertas de Desvíos',
        nameUsers: 'Usuarios',
        nameUsersFeedbacks: 'Comentarios de Usuarios',
        nameMobileUsers: 'Usuarios Móviles',
        nameNotifications: 'Notificaciones',
        nameCustomersMaster: 'Clientes',
        namePurchaseSuggestions: 'Sugerencias de Compras',
        nameDashboard: 'Tablero',
        nameMyBills: 'Mis Cuentas',
        namePromotionalLogos: 'Logotipos Promocionales',
        nameInvoicesMaster: 'Facturas (NFC-e)',
        nameViewFranchiseeWeb: 'Mis Entornos',
        nameTenantsFeedbacks: 'Comentarios de Inquilinos',
        nameCondominiumReports: 'Informes de Condominios',
        nameMidasOrders: 'Nuevo Orden',
        nameChangeOfOwnership: 'Aprobar Transferencias',
        nameChangeOwnershipNewRequest: 'Transferir Punto de Venta',
        nameCustomerProfile: 'Mi Perfil',
        redirect: 'tablero',
        nameMissingProducts: 'Productos no encontrados',
        nameViewAcquisitionsOrders: 'Solicitudes de Aprobación',
        nameCustomerRegistrationForm: 'Registro de Nuevo Cliente',
        nameEnvironmentsTabs: 'Entornos',
        nameCustomerRegistrationTab: 'Registro de Nuevos Clientes',
        nameCustomerRegistrationFeedback: 'Feedback de Registro de Nuevos Clientes',
    },

    path: {
        nameTransactions: 'transacciones',
        nameTelemetry: 'telemetría',
        nameConnectivityMaster: 'conectividad',
        nameManagePos: 'punto-de-ventas',
        nameProductsMaster: 'productos',
        nameInventoryMaster: 'inventario',
        namePurchases: 'compras',
        nameOrders: 'ordenes',
        nameNewPointOfSale: 'nuevo-punto-de-venta',
        nameToEditPointOfSale: 'editar-punto-de-venta',
        namePriceTables: 'tablas-precios',
        namePlanogramMaster: 'planograma',
        nameCharts: 'informes',
        nameTransactionsReports: 'resumen-ventas',
        nameInventoryReports: 'resumen-inventario',
        nameRuptures: 'informes-rupturas',
        nameLosses: 'informes-operativos',
        namePickLists: 'pick-lists',
        nameReplenishment: 'reabastecimiento',
        nameAccessControl: 'control-acceso',
        namePrivateLabel: 'private-label',
        nameDiscountsMaster: 'descuentos',
        nameNewBox: 'activar',
        nameAlerts: 'alertas',
        nameDownloads: 'descargas',
        nameContracts: 'activaciones',
        nameSupport: 'ayuda',
        nameReleaseNotes: 'notas-version',
        nameAudits: 'auditorias',
        nameTaxScenarios: 'escenarios-fiscales',
        namePagSeguro: 'pagseguro',
        nameSwagger: 'swagger',
        nameSuspiciousMaster: 'alertas-desvíos',
        nameUsers: 'usuarios',
        nameUsersFeedbacks: 'comentarios-usuarios',
        nameMobileUsers: 'usuarios-app',
        nameNotifications: 'notificaciones',
        nameCustomersMaster: 'clientes',
        namePurchaseSuggestions: 'sugerencias-compra',
        nameDashboard: 'tablero',
        namePendingBills: 'mis-cuentas',
        namePromotionalLogos: 'logotipo-promocionales',
        nameInvoicesMaster: 'notas-fiscais-nfce',
        nameViewFranchiseeWeb: 'mis-entornos',
        nameTenantsFeedbacks: 'feedbacks',
        nameCondominiumReports: 'relatorios-condominios',
        nameMidasOrders: 'nuevo-orden',
        nameViewAcquisitionsOrders: 'solicitudes-de-aprobaciones',
        nameChangeOfOwnership: 'aprobar-cambio-titularidad',
        nameChangeOwnershipNewRequest: 'transferir-punto-venta',
        nameMissingProducts: 'productos-faltantes',
        nameCustomerProfile: 'mi-perfil',
        nameEnvironmentsTabs: 'entornos',
        nameCustomerRegistrationTab: 'registro-nuevos-clientes',
        nameCustomerRegistrationFeedback: 'feedback-registro',
    }
};
