
import {defineComponent} from 'vue';
import AcquisitionsOrdersApprovalView from "@/views/acquisitionOrdersApproval/AcquisitionsOrdersApprovalView.vue";
import EnvironmentCatalog from "@/views/acquisitionOrdersApproval/EnvironmentCatalog.vue";

export default defineComponent({
  data() {
    return {
      active: 0,
    };
  },
  components: {
    EnvironmentCatalog,
    AcquisitionsOrdersApprovalView
  },
});
