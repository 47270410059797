
import Vue from 'vue';
import EditableImage from '@/components/EditableImage.vue';
import {Image} from "@/types/Image";
import agent from "@/api/agent";
import AddCustomButton from "@/components/buttons/AddCustomButton.vue";

export default Vue.extend({
  name: 'AmlabsNotices',
  components: {
    EditableImage,
    AddCustomButton
  },
  data() {
    return {
      banners: [] as Image[],
      imagePath: null as string | null,
      imageUrl: null as string | null,
      redirectUrl: null as string | null,
      uploadedImagePath: null as string | null,
    };
  },
  mounted() {
    this.getBanner();
  },
  computed: {},
  watch: {},
  methods: {
    getBanner(){
      agent.Images.getBannersActive().then(response => {
        this.banners = response.map((image: any) => {
          return {
            path: image.path,
            url: image.url,
            deleting: false,
          }
        });
      });
    },
    cleanDropBox(){
      this.imagePath = null;
      this.imageUrl = null;
      this.uploadedImagePath = null;
      this.redirectUrl = null;
    },
    onImageUploaded(){
      this.cleanDropBox();
      this.getBanner();
    },
    handleImageUpload(imageData : Image){
      this.uploadedImagePath = imageData.path;
    },
    onImageDeleted(){
      this.cleanDropBox();
      this.getBanner();
    },
    addBanner(){
      agent.Images.activateBanner( {path: this.uploadedImagePath as string, redirectUrl: this.redirectUrl || ""}).then(() => {
        this.getBanner();
        this.cleanDropBox();
      })
    },
    inactiveBanner(imagePath : string){
      agent.Images.updateBannerStatus({path: imagePath, active: false}).then(() => {
        this.getBanner();
      })
    }
  },
});
