<template>
  <v-card class="mb-16">
    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="options"
      no-data-text="Sem resultados"
      must-sort
      :server-items-length="totalItems"
      :footer-props="{ itemsPerPageOptions: [10, 20, 50, 100] }"
      :loading="loading"
    >

      <template v-slot:[`item.grade`]="{ item }">
        <v-rating
          background-color="grey lighten-2"
          color="warning"
          empty-icon="mdi-star-outline"
          full-icon="mdi-star"
          length="5"
          readonly
          :value="item.grade"
          small
        ></v-rating>
      </template>

      <template v-slot:[`item.pointOfSaleId`]="{ item }">
        <div class="justify-center px-0">
          <v-btn icon class="mx-0" @click="openPdvPage(item)">
            {{item.pointOfSaleId}}
          </v-btn>
        </div>
      </template>

      <template v-slot:[`item.date`]="{ item }">
                  {{ $moment(item.date).format('DD/MM/YYYY HH:mm:ss') }}
      </template>

      <template  v-slot:[`item.categories`]="{ item }">
        <v-chip class="ma-2" v-for="category in item.categories" :key="category">
          {{category}}
        </v-chip>
      </template>

      <template  v-slot:[`item.comment`]="{ item }">
        <p class="text-justify">
          {{item.comment}}
        </p>
      </template>

    </v-data-table>
  </v-card>
</template>

<script>
import { shouldRefreshData, toApiPagination } from "@/components/TableOptionsComparator";
import i18n from "@/locales/i18n";
import moment from 'moment';

  export default {
    props: {
      form: { type: Object, required: true }
    },
    watch: {
      options: {
        handler(newOptions, oldOptions) {
          if (shouldRefreshData(newOptions, oldOptions)) {
            this.getFeedbacks();
            }
          },
        deep: true
      },
      form: {
        handler() {
          this.getFeedbacks();
        },
        deep: true
      }
    },

    data () {
      return {
        headers: [
          { text: this.$t('tables.headers.posCode'), align: 'center', sortable: true, value: 'pointOfSaleId', width: 120 },
          { text: this.$t('tables.headers.customer'), align: 'center', sortable: true, value: 'pointOfSaleBrand', width: 150 },
          { text: this.$t('tables.headers.location'), align: 'center', sortable: true, value: 'pointOfSaleLocal', width: 150 },
          { text: this.$t('tables.headers.date'), align: 'center', sortable: true, value: 'date', width: 120 },
          { text: this.$t('tables.headers.categories'), align: 'center', sortable: false, value: 'categories', width: 120 },
          { text: this.$t('tables.headers.ratings'), align: 'center', sortable: true, value: 'grade', width: 150 },
        ],
        items: [],
        totalItems: 0,
        options: {
          page: 1,
          itemsPerPage: 10,
          sortBy: ['date'],
          sortDesc: [true],
      },
      loading: false
      }
    },
    mounted() {
      const maxDate = new Date();
      const minDate = new Date(maxDate.getTime());
      minDate.setDate(minDate.getDate() - 30);
        this.form.maxDate = moment(maxDate).format("YYYY-MM-DD");
        this.form.minDate = moment(minDate).format("YYYY-MM-DD");
        this.getFeedbacks();
    },

     methods: {
      getFeedbacks() {
        this.loading = true;
          const params = toApiPagination(this.options);
          params.discriminators = "MicroMarket"
          Object.assign(params, this.form);
          this.axios.get("/api/UserFeedbackWeb", { params: params})
          .then((response)=>{
              this.items = response.data.items;
              this.loading = false;
              this.totalItems = response.data.totalItems;
          })
      },
      openPdvPage(item){
        this.$router.push(`/${i18n.t('path.nameToEditPointOfSale')}/${item.pointOfSaleId}`);  
      },
    }
  }
</script>
