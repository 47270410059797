export default {
  actions: 'Ações',
  adminApprovalStatus: 'Status aprovação: Administradora',
  adminRejectionReason: 'Motivo de rejeição pela franquia',
  agreeWithTermOfResponsibilityTransfer: 'Entendo que, ao transferir a conta da máquina de pagamento, todas as responsabilidades administrativas, operacionais e financeiras relacionadas a esse dispositivo passarão a ser de inteira responsabilidade do novo titular. Isso inclui, mas não se limita a, mensalidades e visibilidade do PDV na telemetria. Como antigo titular, não terei mais acesso ao faturamento proveniente desse PDV.',
  agreeWithTermsOfResponsibilityPOS: 'Entendo que sou responsável pela transferência da conta da máquina de pagamento e que somente assim o CNPJ para o qual estou transferindo poderá receber o faturamento.',
  cnpjOfDestination: 'CNPJ ao qual deve ser transferido',
  comments: 'Comentários',
  contactSuporte: 'Não foi possível atualizar o status da troca. Se o problema persistir, entre em contato com o suporte: 19 99959-6630. Motivo do erro ',
  customer: 'Cliente',
  destinationApprovalStatus: 'Status aprovação: Destino',
  destinationClientName: 'Nome de quem vai receber a troca',
  destinationClientPhoneNumber: 'Telefone de quem vai receber a troca',
  destinationDocumentId: 'CNPJ de destino',
  destinationEnvironment: 'Ambiente de destino',
  destinationRejectionReason: 'Motivo de rejeição pelo ambiente de destino',
  destinationRejectionTitle: 'Motivo de rejeição pelo ambiente de destino',
  finalizationDate: 'Data de finalização',
  fromEnvironment: 'Troca do ambiente ',
  helpDescription: 'Para concluir a troca de titularidade, é necessário informar o CNPJ da empresa e os dados completos da pessoa responsável por receber essa troca. Inclua o nome e o telefone de contato para que o processo seja feito corretamente e o destinatário possa ser facilmente contatado',
  helpTitle: 'Informações do Titular de Destino',
  indoorLocation: 'Local interno',
  linkApproveTransfers: 'Aprovar Transferências.',
  location: 'Local',
  no: 'Não',
  originDocumentId: 'CNPJ de origem',
  originEnvironment: 'Ambiente de origem',
  pointsOfSaleHelpDescription: 'Selecione os pontos de venda primários (com planograma) para transferir ao novo CNPJ. Máquinas secundárias associadas (compartilhando o mesmo planograma) serão transferidas automaticamente.',
  pointsOfSaleHelpTitle: 'Pontos de venda',
  pointsOfSaleToBeTransferred: 'Pontos de venda a serem transferidos',
  requestDate: 'Data da solicitação',
  sameNetworkTransfer: 'Essa transferência é da mesma rede/franquia',
  seeDetails: 'Ver detalhes',
  serialNumber: 'Número de série',
  submitRequest: 'Enviar solicitação',
  systemCode: 'Código do sistema',
  termOfResponsibilityTransfer: 'TERMO DE RESPONSABILIDADE PARA TROCA DE TITULARIDADE',
  termsAndConditions: 'Termos e Condições',
  termsAndConditionsDescription: 'Essa transferência refere-se apenas ao PDV dentro do sistema e não altera o local onde a venda será creditada. Para realizar a transferência dos dados da máquina de cartão, é importante que assista o vídeo abaixo: ',
  termsAndConditionsHelp: 'Caso tenha dúvidas sobre o processo, entre em contato com nosso time administrativo pelo número ',
  termsOfResponsibilityPOS: 'TERMO DE RESPONSABILIDADE DE TRANSFERÊNCIA DE MÁQUINA DE PAGAMENTO',
  titleDestination: 'Informe o CNPJ e os dados de contato da pessoa que vai receber a troca de titularidade',
  toEnvironment: ' para o ambiente ',
  transfer: 'Transferência',
  transferPointOfSale: 'Transferir ponto de venda para outro CNPJ',
  transferProcessDescription: 'O processo de troca de titularidade consiste na transferência de loja de um CNPJ para outro. Para garantir que esse processo ocorra de maneira fluida, siga os próximos passos. Após o envio da solicitação você poderá acompanhar o andamento do processo de troca no menu ',
  viewComments: 'Ver comentários',
  watchVideo:"ASSISTIR VÍDEO",
  yes: 'Sim',
};
