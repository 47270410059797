
import Vue from 'vue';
import CancelDialogButton from "@/components/dialog/button/CancelDialogButton.vue";
import ConfirmDialogButton from "@/components/dialog/button/ConfirmDialogButton.vue";

export default Vue.extend({
  components: {ConfirmDialogButton, CancelDialogButton},
  props: {
    value: {type: Boolean, default: false},
    title: {type: String, default: '',},
    message: {type: String, default: '',},
    maxWidth: {type: Number, default: 525},
    confirmMessage: {
      type: String,
      default: ''
    },
    cancelMessage: {
      type: String,
      default: ''
    }
  },
  computed: {
    dialog: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      }
    },
    confirmButtonMessage(): string {
      return this.confirmMessage ? this.confirmMessage :this.$t('buttons.confirm').toString();
    },
    cancelButtonMessage(): string {
      return this.cancelMessage ? this.cancelMessage :this.$t('buttons.cancel').toString();
    },
    maxWidthDialog(): string {
      return `${this.maxWidth}px`;
    }
  },
  methods: {
    confirmAction() {
      this.$emit('confirm');
      this.dismiss();
    },
    cancelAction(): void {
      this.$emit('cancel');
      this.dismiss();
    },
    dismiss(): void {
      this.dialog = false;
    }
  },
  data() {
    return {
      loading: false,
    }
  },
});
