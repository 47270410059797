
import agent from '@/api/agent';
import {ChangeOwnershipStatus, ApprovedOwnership} from '@/types/ChangeOwnershipType';
import DataWipeHelperDialog from './DataWipeHelperDialog.vue';

export default {
    props: {
        value: {type: Boolean, required: true},
        approvedOwnershipObject: {type: Object, required: true},
        //originEmailUser: {type: String, required: true},
    },
    components: {
        DataWipeHelperDialog
    },
    data() {
        return {
            checked: false as boolean,
            requestData: {} as ApprovedOwnership,
            termOfResponsibilityTransfer: false as boolean,
            termOfResponsibilityPOS: false as boolean,
            termOfResponsibilityDataWipe: false as boolean,
            rejectionReason: '' as string,
            loading: false as boolean
        };
    },
    watch: {
        approvedOwnershipObject: {
            handler(newVal: ApprovedOwnership) {
                (this as any).requestData = newVal;
            },
            immediate: true
        }
    },
    computed: {
        isApproved() : boolean{
            return (this as any).requestData?.status === ChangeOwnershipStatus.Approved;
        },
        formattedText():string {
            // const strongTag = `<strong>${(this as any).originEmailUser}</strong>`;
            return (this as any).isApproved
            ? `${(this as any).$t('approvedTextDialogDescription')} `
            : `${(this as any).$t('rejectedTextDialogStart')}`;
        },
    },
    methods: {
        async approveOwnership() {
          (this as any).loading = true;
            if (!(this as any).isApproved) {
                (this as any).requestData.comment = (this as any).rejectionReason;
            }
            await agent.ChangeOwnershipRequest.approvalChangeOwnershipRequest((this as any).requestData as ApprovedOwnership)
                .then((response) => {
                    var doubleCheck = response
                    this.showMessageMigrationSuccess(doubleCheck);
                }).catch((error) => {
                    (this as any).$refs.feedback.handleError((this as any).$t('contactSuporte'), error);
                }).finally(() => this.closeDialog());
        },
        showMessageMigrationSuccess(doubleCheck: Boolean) {
            if(doubleCheck){
                (this as any).$refs.feedback.handleSuccess((this as any).$t('msgSuccessMigration'));
            }
        },
        closeDialog() {
            (this as any).checked = false;
            (this as any).requestData = {};
            (this as any).rejectionReason = '';
            (this as any).$emit('close', (this as any).rejectionReason);
            (this as any).loading = false;
        }
    }
};
