export default {
  products: {
    title: 'Products',
    data: {
      purchaseSettings: {
        conversionUnits: {
          title: 'Conversion Units',
          box: 'Box(es)',
          unit: 'Unit(s)',
          package: 'Package(s)',
          bag: 'Bag(s)',
          unknown: 'Unknown',
          ratioAndName: '{ratio} per {name}',
        }
      },
      description: 'Product Description',
      code: 'Code',
      unitValue: 'Unit Value',
    },
    register: {
      select: 'Select a product',
      isSelect: 'Product already selected',
      notRegistred: 'Don\'t have this product registered?',
    },
    help: {
      description: `
        <p>Manage your products by viewing information such as image, ID, Internal Code (SKU), description, category, standard price, Barcode (EAN), creation date, and availability (Public or Private). You can add, edit, delete products, and export the data to Excel.</p>
        <p><strong>For public products, only the network/licensor can edit and delete them. You can use and export the data to Excel, but without the possibility of modification.</strong></p>

        <p><strong>Categories:</strong></p>
        <p>Displays the ID, category name, and the number of products. You can delete categories as needed.</p>

        <p><strong>Groupings:</strong></p>
        <p>Group similar products into a single item. Displays ID, description, category, standard price, and creation date, without including Barcode (EAN) or the option to export to Excel. Ideal for managing variations of the same product, such as different flavors or sizes.</p>
      `,
    },
    warning: {
      barCode: {
        title: 'Product without a barcode',
        description: `If you choose to register the product without a barcode, it will not be recognized by readers or
          barcode-based functions. Registering the barcode is recommended to avoid issues and ensure the proper
          functioning of the system.`
      }
    },
    errors: {
      conversionUnit: {
        update: 'Could not update the conversion unit'
      }
    }
  }
}
