export default {
    usersFeedback: {
      title: 'Reviews',
      help: {
          description: `<h3>Discover what your customers think about your store(s)!</h3>
            <p>
              Receive feedback directly from your customers and enhance the shopping experience in your stores. 
              After completing a purchase, customers are invited to leave a review, helping you understand their opinions about your establishment. 
            </p>
            <p>Reviews are categorized into two sections:</p>
            <ul>
              <li><strong>Via Terminal:</strong> Available to all customers, allowing quick and immediate reviews.</li>
              <li><strong>Via App:</strong> Exclusive to customers using the White Label version of our app. If you have any questions, please contact our commercial team.</li>
            </ul>
            <p></p>
            <p>
              Use these reviews to better understand customer preferences and identify areas that need improvement. 
              Always pay attention to received reviews to ensure your stores offer the best possible experience to your customers.
            </p>
            `,
      },
      application: 'Application',
      pointOfSale: 'Point of Sale',
    },
  };