export default{
  pickLists:{
    title: 'Pick Lists',
    create:{
      title: 'Generar Pick Lists',
      pickListsDescription: 'Descripción de de la Pick List',
      pickListsByCategoryDescription: 'Cuando se agrupe por categoría, cada Pick List tendrá como nombre el nombre de la categoría',
      pickListsByInventoryAddressDescription: 'Cuando se agrupe por dirección de inventario, cada Pick List tendrá como nombre la dirección de inventario',
      allProducts: 'Todos los productos del planograma',
      inventoryAddressLabel: 'Dirección de inventario',
      categoryLabel: 'Filtrar productos por categoría',
      inventoryAddressFilterLabel: 'Filtrar productos por dirección de inventario',
      productRestriction: 'Restricción de producto',
      criticalLabel: 'Incluir solo productos con stock crítico',
      inventoryAvailable: 'Incluir solo productos con saldo de inventario disponible',
      advancedSection: 'Avanzado',
      secondaryLabel: 'Marcar Pick List como secundaria',
      groupByCategoryLabel: 'Generar una Pick List por categoría',
      groupByCategoryDisabledTooltip: 'Para poder generar una Pick List por categoría, debe marcarla como Pick List secundaria y filtrar por al menos una categoría',
      error: 'Error al generar Pick List',
      success: 'Pick List creada con éxito'
    }
  }
}
