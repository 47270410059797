
import Vue from 'vue';
import ConfirmDialogButton from "@/components/dialog/button/ConfirmDialogButton.vue";
import CancelDialogButton from "@/components/dialog/button/CancelDialogButton.vue";

export default Vue.extend({
  components: {CancelDialogButton, ConfirmDialogButton},
  props: {
    value: {type: Boolean, required: true},
    maxWidth: {type: Number, default: 525},
  },
  data() {
    return {
      show: this.value,
      selectedOption: 'inventoryAddress',
      options: [
        {text: this.$t('splitPickListConfirmation.splitByStockAddress'), value: 'inventoryAddress'}
      ],
      loading: false
    };
  },
  computed: {
    dialog: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      },
    },
    maxWidthDialog(): string {
      return `${this.maxWidth}px`;
    },
  },
  watch: {
    value(val) {
      this.show = val;
    },
    show(val) {
      this.$emit('input', val);
    }
  },
  methods: {
    confirm() {
      this.$emit('confirm', this.selectedOption);
      this.show = false;
    },
    dismiss(): void {
      this.dialog = false;
    }
  }

});
