export default {
    systemPaymentPointOfSale: "Sistema de pagamento",
    systemCodePointOfSale: "Código do sistema",
    enabledInvoicePointOfSale: "Nota fiscal habilitada",
    disabledInvoicePointOfSale: "Nota fiscal não habilitada",
    configurePointOfSale: "Configurar",
    appEnabledPointOfSale: "App habilitado",
    appDisabledPointOfSale: "App não habilitado",
    displayPointOfSale: "Exibir",
    registerPointOfSale: "Cadastrar",
    picPayPointOfSale: "PicPay",
    picPayTokenPointOfSale: "Token do PicPay",
    registeredLocksPointOfSale: "Travas cadastradas",
    noLocksPointOfSale: "Nenhuma trava cadastrada",
    changePointOfSale: "Alterar",
    addPointOfSale: "Adicionar",
    thenPointOfSale: "logo",
    clientPointOfSale: "Cliente",
    locationPointOfSale: "Localização",
    internalLocationPointOfSale: "Local interno",
    statePointOfSale: "Estado",
    residentsQuantityPointOfSale: "Quantidade de moradores",
    employeesQuantityPointOfSale: "Quantidade de funcionários",
    studentsQuantityPointOfSale: "Quantidade de estudantes",
    frequentPeopleQuantityPointOfSale: "Quantidade frequente de pessoas",
    stockPointOfSale: "Estoque",
    distributionCenterPointOfSale: "Centro de distribuição",
    sharedStockPointOfSale: "Estoque compartilhado",
    routePointOfSale: "Rota de abastecimento",
    posCodePointOfSale: "Código do PDV",
    manufacturerPointOfSale: "Fabricante",
    modelPointOfSale: "Modelo",
    updatePointOfSale: "Atualizar",
    unableToSavePosPointOfSale: "Não foi possível salvar ponto de venda",
    okPointOfSale: "Ok",
    identificationCodePointOfSale: "Código identificador do sistema.",
    systemNotFoundPointOfSale: "Sistema não encontrado",
    tokensNotFoundPointOfSale: "Tokens não encontrados",
    selectClientPointOfSale: "Selecione um cliente ou adicione um novo.",
    selectLocationPointOfSale: "Selecione um cliente antes de escolher a localização",
    totalNumberOfResidentsPointOfSale: "Selecione um local antes de definir o total de moradores",
    localTypePointOfSale: "Tipo local",
    nameDistributionCenterPointOfSale: "Nome do centro de distrubuição",
    distributionCenterNotRegistered: "Não há centros de distribuição cadastrados",
    distributionCenterConfiguration : `Aqui configuramos o centro de distribuição que está associado a este ponto de venda.
    Quando um abastecimento for realizado nesse PDV, os produtos serão subtraídos dele.
    Além disso, ao gerar Pick Lists para esse PDV, este será
    o centro de distribuição considerado para checar a disponibilidade de cada produto.
    Vendas realizadas nesse PDV também terão o custo médio correspondente ao custo médio do
    produto do centro de distrubuição no momento da compra.`,
    cannotBeChangedPointOfSale: `Como o PDV tem o estoque compartilhado com o ponto de venda {masterPointOfSaleName},
    seu estoque central não pode ser alterado.`,
    accessToFunctionality: "Para ter acesso a essa funcionalidade, é necessário ter o módulo de estoque contratado.",
    noPointsOfSalePointOfSale: "Sem pontos de venda",
    withMoreTerminalPointOfSale: "Para lojas com mais de um terminal",
    microMarketsFunction: `Essa função apenas é para micro-markets que possuem
    mais de um terminal de pagamento na mesma loja.`,
    terminalInTheSameStore: `No caso em que há mais de um terminal na mesma loja, é
    necessário um ponto de venda para cada terminal. Apesar de
    serem PDVs distintos, por se tratar de uma única loja,
    deve haver um único planograma e um único estoque. Dessa
    forma, quando qualquer um dos PDVs da loja realiza uma
    venda, o mesmo estoque é subtraído.`,
    registrationAtOnlyOnePdv: `O planograma deve ser cadastrado em apenas um PDV, que
    chamamos de <strong>PDV principal</strong>. A consulta de
    estoque também deve ser feita no PDV principal. Os outros
    PDVs da loja são os <strong>PDVs secundários</strong>.
    Todos os PDVs secundários devem ter o PDV principal no
    campo <strong>Estoque compartilhado</strong>. Para o PDV
    principal, esse campo fica vazio.`,
    supplyRouteToBetterManagementOfThePos: `Para gerenciar melhor seus abastecimentos, 
    você pode criar rotas de abastecimento, facilitando a organização 
    e a distribuição dos produtos do estoque central.`,
    selectARouteToLinkToThisPos: `Selecione uma rota de abastecimento cadastrada para 
    ser vinculada a este ponto de vendas. Para criar uma rota de abastecimento você
    deve ir em Cadastros > Rotas de abastecimento.`,
    touchPayReplenishmentPointOfSale: "TouchPay Abastecedor",
    machinePointOfSale: "Máquina",
    errorFindingTokenPointOfSale: "Não foi possível encontrar um token para o PDV.",
    taxRegion: 'Região fiscal',
    dclubeLabel: 'DClube',
    dclubeHint: 'Chave de acesso DClube',
    dclubeHelperHeader: 'O que é a DClube?',
    dclubeHelperDescription: `<strong>DClube</strong> é uma plataforma de <strong>descontos e pontos</strong> que
    conecta <strong>marcas e consumidores</strong>, oferecendo vantagens exclusivas na hora da compra.
    O DClube <strong>ajuda a fidelizar seus clientes e incentiva compras recorrentes</strong> no seu minimercado,
    tornando-o ainda mais atrativo.`,
    dclubeHelperDescriptionHowItWorks: `
    <p><h3>Como funciona?</h3></p>
    <strong>O DClube disponibiliza descontos instantâneos</strong> no ato da compra para produtos parceiros.
    Para aproveitar os benefícios, o cliente precisa:
    <p>- Ter um cadastro no aplicativo DClube.</p>
    <p>- Inserir o CPF no início da compra nos pontos de venda que possuem DClube ativada.</p>
    Dessa forma, o desconto é aplicado automaticamente, incentivando mais compras e aumentando o fluxo no seu negócio.`,
    dclubeHelperDescriptionHowToActivate: `
    <p><h3>Como fazer parte do programa?</h3></p>
    Quer oferecer essa vantagem no seu minimercado? 🚀 <strong>Entre em contato pelo WhatsApp: (19) 99853-3154</strong> e saiba como
    ativar o <strong>DClube</strong> no seu ponto de venda!`
};
