export default {
  catalogItemFavoriteDialogMessage: "¿Está seguro de que desea <strong class='favorite-text'>marcar como favorito</strong> este producto para comprar?",
  catalogItemFavoriteDialogWarning: "Al hacer esto, <strong>se mostrará</strong> como un producto recomendado para comprar en la página de <strong>Nuevo producto</strong>.",
  catalogItemNotFavoriteDialogMessage: "¿Está seguro de que desea <strong class='not-favorite-text'>quitar de favoritos</strong> este producto para comprar?",
  catalogItemNotFavoriteDialogWarning: "Al hacer esto, <strong>ya no se mostrará</strong> como un producto recomendado para comprar en la página de <strong>Nuevo producto</strong>.",
  catalogProductsListToChoose: "Productos recomendados por la red",
  catalogTab: 'Catálogo de Productos',
  clickForDetails: 'Ver detalles del producto',
  environmentCatalogHeader: 'Agregar productos a favoritos para nuevos pedidos',
  environmentCatalogSubHeader: 'Seleccione los productos que desea priorizar para la compra en sus otros entornos',
  favoriteCatalogItems: 'Favorito',
  finishCatalogItemsSelection: "Comprar",
  productsList: 'Productos disponibles',
  seeLess: "Ver menos",
  seeMore: "Ver más",
  technicalInformation: 'Información Técnica',
}
