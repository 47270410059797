export type CustomerRegistrationRequestDto = {
    name: string;
    dateOfBirth: Date;
    email: string;
    phoneNumber: string;
    documentId:string;
    postalCode: string;
    state: string;
    city: string;
    neighborhood: string;
    streetName: string;
    addressNumber: string;
    addressComplement: string | null;
    token: string;
  }

  export type CustomerAddress = {
    postalCode: string | null;
    state: string | null;
    city: string | null;
    neighborhood: string | null;
    streetName: string | null;
    addressNumber: string | null;
    addressComplement: string | null;
  }

  export type RegistrationRequestForApproval = {
    id: number;
    requestCode: string;
    name: string;
    documentId: string;
    email: string;
    phoneNumber: string;
    dateCreated: string;
    adminEmail: string | null;
    adminApprovalStatus: RegistrationRequestStatus;
    adminComment: string | null;
  }

  export type RegistrationRequestAdminEvaluation ={
    registrationRequestId: number;
    adminApprovalStatus: RegistrationRequestStatus;
    adminEmail: string;
    comment?: string;
  }

  export enum RegistrationRequestStatus {
    Pending = 'Pending',
    Approved = 'Approved',
    Rejected = 'Rejected',
  }

  export type IsRegistrationInfoNew = {
    isDocumentIdNew: boolean;
    isEmailNew: boolean;
  }