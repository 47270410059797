export default {
    removePickListsPickListTable: 'Remove Pick Lists',
    selectAtLeastOnePickList: 'Select at least one Pick List',
    excelPickListTable: 'Excel',
    synchronizationWithManagementSystem: 'Synchronize pick-lists with the management system',
    noResultsPickListTable: 'No results',
    idPickListTable: 'ID Pick List',
    description: 'Description',
    primaryPickListTable: 'Primary',
    customerPickListTable: 'Customer',
    locationPickListTable: 'Location',
    specificLocationPickListTable: 'Specific Location',
    pointOfSalePickListTable: 'POS',
    routePickListTable: 'Route',
    machinePickListTable: 'Machine',
    startedByPickListTable: 'Started by',
    updatedActionPickListTable: 'Updated in',
    syncWithOmiePickListTable: 'Synchronization with Omie management system',
    erpManagementSystem: 'ERP',
    removeConfirmationPickListTable: 'Are you sure you want to remove',
    pickListPickListTable: ' pick ',
    noPickListTable: 'No',
    removePickListTable: 'Remove',
    successRemovePickListTable: 'Pick List ',
    removeSuccessPickListTable: ' removed',
    errorRemovePickListTable: 'Unable to remove Pick List',
    synchronizationStarted: 'A pick-list',
    synchronizationStartedPickListTable: ' is being synchronized with Omie and will be available in a few moments.',
    syncErrorPickListTable: 'Unable to sync pick-list',
    generateReportErrorPickListTable: 'Unable to generate report',
    createdSuccessPickListTable: ' created for POS ',
    createdErrorPickListTable: 'Unable to create the Pick List for the POS ',
    syncSuccessPickListTable: 'The pick-lists are being synchronized with the AMLabs ERP.',
    syncErrorPickList: 'Unable to sync pick-lists.',
    editPickListTable: '(edited)',
    synchronizationPickListTable: 'Synchronization',
    releasedPicklistSuccessPickListTable: 'The picklist was successfully released.',
    releasedPicklistErrorPickListTable: 'Unable to release the Pick List.',
    releasePickListDialogTitle: 'Release the Pick List ?',
    releasePickListDialogMessage: 'By confirming this action, the Pick List will be released and another operator will be able to perform it.',
};
