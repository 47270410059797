
import {Vue} from 'vue-property-decorator';

/**
 * A cancel button intended to close a dialog and cancel any ongoing operations within it.
 * It can optionally display tooltips and supports styling through provided props.
 * When clicked, it emits a "close" event, making it ideal for canceling actions within dialogs.
 */
export default Vue.extend({
  name: 'CancelDialogButton',
  props: {
    label: {type: String, default: ''},
    kind: {type: String, default: 'default'},
    disabled: {type: Boolean, default: false},
    asTextButton: {type: Boolean, default: false},
    displayTooltip: {type: Boolean, default: true},
    tooltip: {type: String, default: ''},
  },
  computed: {
    buttonLabel(): string {
      return this.label || this.$t('buttons.close').toString();
    },
    color(): string {
      switch (this.kind) {
        case 'default':
          return 'primary';
        case 'danger':
          return 'error';
        default:
          return 'primary';
      }
    },
    tooltipText(): string {
      return this.tooltip || this.buttonLabel;
    }
  }
})
