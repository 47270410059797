export default {
  purchaseSuggestions: {    
    title: "Sugestões de Compras",
    report: 'Erro ao gerar relatório de sugestão de compras', 
    warnings:{
      pickListButton: 'Gere um relatório apenas para um PDV',
      withdrawAndRuptures: 'As saídas e rupturas agora mostram o valor total de acordo com o filtro "Considerando os últimos X dias".',
    },
    help:{
      title: "Sugestões de Compras",
      description: `<p>
        Essa página apresenta sugestões de compra seguindo a seguinte lógica:
      </p>
      <p>
        <strong>(Média Saídas X dias) * X dias + (Média Rupturas X dias) * X dias - Estoque = Sugestão de compra (Un.) /
          Unidade de conversão = Sugestão de compra (U.C.).</strong>
      </p>
      <p>
        Para <strong>definir os valores de X</strong> (Período de análise) e período desejado para compra, basta
        preencher nos filtros
        "Considerando os últimos X dias, quero um estoque para (Período desejado) em dias".
      </p>
      <p>
        Por padrão, os produtos que possuem o valor de Sugestão de Compra menor que a Quantidade Mínima de Compra
        ficarão ocultos, para poder visualizá-los basta ativar a opção <strong>"Mostrar todos os produtos"</strong>.
      </p>
      <p><strong>Tempo de estoque</strong> é uma estimativa em <strong>dias</strong>, de quanto o estoque de determinado item de inventário pode
        acabar caso não seja abastecido, é obtido utilizando a seguinte lógica:</p>
      <p><strong>(Média de Saídas por dia + Média de Rupturas por dia) / Quantidade Atual no Estoque</strong></p>
      <p>
        *Os valores de <strong>Quantidade Mínima de Compra</strong> são definidos na aba de estoque de <strong>Inventário</strong>.<br>
        *Os valores de <strong>Unidade de Conversão</strong> são definidos no cadastro de cada <strong>produto</strong>.
      </p>`,
    }
  },
}