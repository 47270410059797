export default {
  approvalTab: 'PEDIDO PARA APROBACIÓN',
  search: 'Buscar',
  idAcquisitionOrder: 'Código de Solicitud',
  fantasyNameAcquisitionOrder: 'Nombre Comercial',
  documentIdAcquisitionOrder: 'CNPJ',
  emailAcquisitionOrder: 'Correo Electrónico',
  phoneNumberAcquisitionOrder: 'Número de Teléfono',
  dateCreatedAcquisitionOrder: 'Fecha de Creación',
  taxArrangementAcquisitionOrder: 'Régimen Tributario',
  statusAcquisitionOrder: 'Estado',
  itensDetailsAcquisitionOrder: 'Detalles de los Artículos',
  commentsAcquisitionOrder: 'Comentarios',
  attention: 'ATENCIÓN',
  image: 'Imagen',
  name: 'Nombre',
  quantity: 'Cantidad',
  description: 'Descripción',
  technicalInformation: 'Información Técnica',
  itemType: 'Tipo de Artículo',
  Leased: 'Comodato',
  Owned: 'Venta',
  PessoaFisica: 'Persona Física',
  SimplesNacional: 'Régimen Nacional Simplificado',
  Mei: 'Microempresario',
  Other: 'Otro',
  NotSpecified: 'No especificado',
  ErrorOnCheck: 'Error en la Verificación',
  areYouSureReject: '¿Confirmar el <span style="color:#FF5252;">rechazo</span> de la solicitud del <span style="font-weight:700;">CNPJ: {cnpj}</span>? Esta acción es irreversible y requerirá que el solicitante reinicie todo el proceso.',
  areYouSureApprove: '¿Confirmal la <span style="color:#2DCE98;">aprobación</span> de la solicitud del <span style="font-weight:700;">CNPJ: {cnpj}</span>? Esta acción es irreversible.',
  areYouSurePending: '¿Agregar un comentario a la solicitud del <span style="font-weight:700;">CNPJ: {cnpj}</span>?',
  userAcquisitionOrder: 'Actualizado por: {user} el {date}',
  seeComment: 'Ver comentarios de la solicitud del&nbsp<span style="font-weight:700;">CNPJ: {cnpj}</span>',
  reasonLabel: 'Deje un comentario explicando el motivo',
  reasonText: 'Agregar un comentario',
  optionalTag: '(Opcional)',
  Approved: 'Aprobado',
  Cancelled: 'Rechazado',
  requiredRules: 'Campo obligatorio para Rechazar',
  errorLoadingData: 'Error al cargar datos',
  errorUpdatingAcquisitionOrder: 'Error al actualizar la solicitud',
  sucessUpdatingAcquisitionOrder: 'Solicitud actualizada con éxito',
  save: 'Guardar',
  cancel: 'Cancelar',
};
