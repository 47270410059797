export default {
    filtersAndActions: 'Filtros y acciones',
    customerTransactions: 'Cliente',
    minimumPriceTransactions: 'Precio mínimo',
    initialDateTransactions: 'Fecha inicial',
    clearTransactions: 'Limpiar',
    updateTransactions: 'Actualizar',
    excelTransactions: 'Excel',
    nfceTransactions: 'NFC-e',
    downloadXmlTransactions: 'Descargar XMLs',
    downloadExcelTransactions: 'Descargar Excel',
    reissueInBulk: 'Reemisión en lote',
    invalidateNumbering: 'Anular numeración',
    consultInvalidations: 'Consultar anulaciones',
    showOnlyInvoiceErrors: 'Mostrar solo errores',
    maxDateTransactions: 'Fecha final',
    paymentTypeTransactions: 'Método',
    lastCardDigits: 'Últimos Dígitos de la Tarjeta',
    maxTimeTransactions: 'Hora Final',
    minTimeTransactions: 'Hora Inicial',
    noResultsTransactions: 'Sin resultados',
    approvedInvoiceTransactions: 'NFC-e generada con éxito, haga clic para más detalles',
    invoiceErrorTransactions: 'Problema al generar la NFC-e, haga clic para más detalles',
    notEnabledForPos: 'NFC-e no habilitada para este PDV',
    productCode: 'Código de producto',
    productTransactions: 'Producto',
    productCategoryTransactions: 'Categoría del producto',
    quantityTransactions: 'Cantidad',
    cmvTransactions: 'CMV({sign})',
    valueTransactions: 'Valor({sign})',
    totalTaxes: 'Impuesto({sign})',
    marginValueTransactions: 'Margen({sign})',
    marginPercentageTransactions: 'Margen(%)',
    selectionTransactions: 'Selección',
    resultTransactions: 'Resultado',
    locationTransactions: 'Ubicación',
    posTransactions: 'PDV',
    methodTransactions: 'Método',
    cardTransactions: 'Tarjeta',
    dateTimeTransactions: 'Fecha/hora',
    todayTransactions: 'Hoy',
    sevenDaysTransactions: 'Siete',
    unknownTransactions: 'Desconocido',
    dispensingFailure: 'Fallo al dispensar',
    refundedTransactions: 'Estornada',
    refundCancelledTransactions: 'Estorno cancelado',
    refundErrorTransactions: 'Error al estornar',
    productNotSelectedTransactions: 'Producto no seleccionado',
    selectClientFirstTransactions: 'Seleccione primero un cliente',
    transactionLocation: 'Ubicación de la transacción',
    maximumPriceTransactions: 'Precio máximo',
    selectLocationTransactions: 'Seleccione primero una ubicación',
    pointOfSaleTransactions: 'Punto de venta',
    errorWhenDownloadingXml: 'No se pudo descargar los XML de las facturas',
    errorDownloadingReport: 'No se pudo descargar el informe de las facturas',
    noneTransactions: 'ninguno',
    noDate: 'Sin fecha',
    failureToCommunicate: 'Fallo al comunicarse con el sistema emisor',
    errorWhenGeneratingReport: 'No se pudo generar el informe',
    errorWhenGeneratingReportNFCe: 'No se pudo generar el informe de la NFC-e',
    showProfits: 'Mostrar ganancias'
};
