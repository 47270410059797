<template>
  <v-container fluid>
    <v-row>

      <v-app-bar
        app
        dense
        :clipped-left="false"
        :dark="true"
        :color="'#1A70AD'"
      >
        <v-toolbar-title class="pl-3 py-2 px-2 ml-2" v-text="$route.name"></v-toolbar-title>
      </v-app-bar>
    
      <!-- Main Content Area -->
      <v-col cols="12">
        <v-card flat>
          <!-- Main Title and Subtitle -->
          <v-card-title class="custom-margin-left">
            <h2 class="text-h5">{{ $t('registrationFormWelcome') }}</h2>
          </v-card-title>
          <v-card-subtitle class="mt-2 custom-margin-left">
            <span>{{ $t('registrationFormInstructions') }}</span>
          </v-card-subtitle>

          <!-- Success or error alert -->
          <v-container v-if="updateSuccessMessage || updateErrorMessage">
            <v-alert v-if="updateSuccessMessage" type="success" dismissible>
              {{ updateSuccessMessage }}
            </v-alert>
            <v-alert v-if="updateErrorMessage" type="error" dismissible>
              {{ updateErrorMessage }}
            </v-alert>
          </v-container>

          <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
            
          <v-container class="mt-3">
            <span class="text-h5"> {{ $t('personalData') }} </span>
            <v-divider class="mt-2 mb-2"></v-divider>
            <v-col>
              <!-- Name Field Block -->
              <v-row>
                <v-col class="fields-spacing">
                  <v-text-field
                    v-model="name"
                    :label="$t('fullName')"
                    :error-messages="nameError"
                    prepend-icon="mdi-account-circle-outline"
                  >
                  </v-text-field>
                </v-col>

              <!-- Date of Birth Field Block -->
                <v-col class="fields-spacing">
                  <v-text-field
                    v-model="dateOfBirthFormatted"
                    :label="$t('dateOfBirth')"
                    :placeholder="$t('dateFieldPlaceHolder')"
                    :error-messages="dateError"
                    prepend-icon="mdi-calendar-account-outline"
                  >
                  </v-text-field>
                </v-col>

                <!-- Email Field Block -->
                <v-col>
                  <v-text-field
                    v-model="email"
                    label="Email"
                    :error-messages="emailError"
                    prepend-icon="mdi-email-outline"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-col>

            <v-col>
              <v-row class="mt-2">
                <!-- Phone Field Block -->
                <v-icon class="ml-3">mdi-cellphone</v-icon>
                <v-col style="flex: 0 0 6%;" class="flag-spacing">
                    <country-flag-dropdown v-model="selectedCountryCode"/>
                    <v-messages style="margin-top: -13px;"
                      v-if="countryCodeError"
                      :value="[countryCodeError]"
                      color="error"
                    />
                </v-col>
                <v-col style="flex: 0 0 25%;" class="fields-spacing">
                  <v-text-field
                    v-model="phone"
                    :prefix="selectedCountryCode"
                    :label="$t('phone')"
                    :placeholder="$t('onlyNumbers')"
                    :error-messages="phoneError"
                  >
                  </v-text-field>
                </v-col>
              
                <!-- ID Number Field Block -->
                <v-col style="flex: 0 0 31%;">
                  <v-text-field
                    v-model="documentIdFormatted"
                    :label="$t('documentId')"
                    :error-messages="documentIdError"
                    prepend-icon="mdi-domain"
                  >
                  </v-text-field>
                </v-col>
              </v-row>                  
            </v-col>
          </v-container>

          <v-container>
            <span class="text-h5 mt-3"> {{ $t('address') }} </span>
            <v-divider class="mt-2 mb-2"></v-divider>
            <v-col>
              <!-- Postal Code Field Block -->
              <v-row>
                <v-col class="fields-spacing">
                  <v-text-field
                    v-model="postalCode"
                    :label="$t('postalCode')"
                    :hint="$t('postalCodeOnlyNumbers')"
                    persistent-hint
                    :error="!!postalCodeError"
                    prepend-icon="mdi-map-outline"
                    append-icon="mdi-map-search-outline"
                    @keydown.enter="searchPostalCode"
                    @click:append="searchPostalCode"
                  >
                  <template v-slot:message>
                    <div v-if="postalCodeError" class="mb-2" style="color: red;">
                      <span>{{ postalCodeError }}</span>
                    </div>
                    <div>
                      <span>{{ $t('postalCodeOnlyNumbers') }}</span>
                    </div>
                  </template>
                  </v-text-field>
                </v-col>

              <!-- Address Number Field Block -->
                <v-col class="fields-spacing">
                  <v-text-field
                    v-model="addressNumber"
                    :label="$t('addressNumber')"
                    :error-messages="addressNumberError"
                    prepend-icon="mdi-map-marker-outline"
                  >
                  </v-text-field>
                </v-col>

              <!-- Address Complement Field Block -->
                <v-col>
                  <v-text-field
                    v-model="addressComplement"
                    :label="$t('addressComplement')"
                    :hint="$t('addressComplementHint')"
                    persistent-hint
                    prepend-icon="mdi-home-outline"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-col>

            <v-col>
              <v-row class="mt-2">
                <!-- Street Field Block -->
                <v-col class="fields-spacing">
                  <v-text-field
                    v-model="streetName"
                    :label="$t('streetName')"
                    :disabled="!hasTriedPostalCodeSearch"
                    :placeholder="$t('fillInPostalCode')"
                    :persistent-placeholder="!hasTriedPostalCodeSearch"
                    :error-messages="streetNameError"
                    prepend-icon="mdi-road-variant"
                  >
                  </v-text-field>
                </v-col>
              
                <!-- Neighborhood Field Block -->
                <v-col class="fields-spacing">
                  <v-text-field
                    v-model="neighborhood"
                    :label="$t('neighborhood')"
                    :disabled="!hasTriedPostalCodeSearch"
                    :placeholder="$t('fillInPostalCode')"
                    :persistent-placeholder="!hasTriedPostalCodeSearch"
                    :error-messages="neighborhoodError"
                    prepend-icon="mdi-home-city-outline"
                  >
                  </v-text-field>
                </v-col>

                <!-- City Field Block -->
                <v-col class="fields-spacing">
                  <v-text-field
                    v-model="city"
                    :label="$t('city')"
                    :disabled="!hasTriedPostalCodeSearch"
                    :placeholder="$t('fillInPostalCode')"
                    :persistent-placeholder="!hasTriedPostalCodeSearch"
                    :error-messages="cityError"
                    prepend-icon="mdi-city-variant-outline"
                  >
                  </v-text-field>
                </v-col>

                <!-- State Field Block -->
                <v-col cols="1">
                  <v-autocomplete
                    v-model="state"
                    :label="$t('state')"
                    :items="states"
                    :disabled="!hasTriedPostalCodeSearch"
                  ></v-autocomplete>
                </v-col>

              </v-row>                  
            </v-col>
          </v-container>

          <!-- Finalize Registration Button -->
          <v-row>
            <v-col cols="12" class="d-flex justify-end">
              <v-btn
                color="#2196F3"
                class="finalize-registration-btn"
                @click="checkForm"
                >
                {{ $t('finalizeRegistration') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
  
<script>

import agent from '@/api/agent';
import { formatDateTimeToDate } from '@/utility/TextFormatting';
import { formatDateStringToDate } from '@/utility/TextFormatting';
import CountryFlagDropdown from '../CountryFlagDropdown.vue';
import BrazilStates from "@/components/BrazilStates";
import moment from "moment";
import { parseGenericStringToDate, formatCnpj } from "@/utility/TextFormatting";

export default {
  components: {
    CountryFlagDropdown,
  },
  computed: {
    BrazilStates() {
      return BrazilStates;
    },
    dateOfBirthFormatted: {
      get() {
        return this.dateOfBirth ? moment(this.dateOfBirth).format('DD/MM/YYYY') : '';
      },
      set(userInput) {
        this.dateOfBirth = parseGenericStringToDate(userInput);
      }
    },
    documentIdFormatted: {
      get() {
        return this.documentId ? formatCnpj(this.documentId) : '';
      },
      set(userInput) {
        this.documentId = userInput.replace(/\D/g, '');
      }
    }
  },
  data() {
    return {
      name: '',
      dateOfBirth: '',
      email: '',
      phone: '',
      selectedCountryCode: null,
      documentId: '',
      postalCode: '',
      hasTriedPostalCodeSearch: false,
      addressNumber: '',
      addressComplement: '',
      streetName: '',
      neighborhood: '',
      city: '',
      states: BrazilStates.states.map(state => state.value),
      nameError: '',
      dateError: '',
      emailError: '',
      countryCodeError: '',
      phoneError: '',
      documentIdError: '',
      postalCodeError: '',
      addressNumberError: '',
      streetNameError: '',
      neighborhoodError: '',
      cityError: '',
      stateError: '',
      updateSuccessMessage: '',
      updateErrorMessage: '',
      loading: false,
    };
  },
  methods: {
    checkForm() {
      let isFormValid = true;

      const isNameValid = this.$validations.required(this.name);
      if (isNameValid !== true) {
        this.nameError = isNameValid;
        isFormValid = false;
      }
      else {
        this.nameError = '';
      }

      const isDateValid = this.$validations.date(this.dateOfBirthFormatted);
      if (isDateValid !== true) {
        this.dateError = isDateValid;
        isFormValid = false;
      }
      else {
        this.dateError = '';
      }

      const isEmailValid = this.$validations.emailPattern(this.email);
      if (isEmailValid !== true) {
        this.emailError = isEmailValid;
        isFormValid = false;
      }
      else {
        this.emailError = '';
      }

      const isCountryCodeValid = this.$validations.required(this.selectedCountryCode);
      if (isCountryCodeValid !== true) {
        this.countryCodeError = isCountryCodeValid;
        isFormValid = false;
      }
      else {
        this.countryCodeError = '';
      }

      const isPhoneValid = this.$validations.phonePattern(this.phone);
      if (isPhoneValid !== true) {
        this.phoneError = isPhoneValid;
        isFormValid = false;
      }
      else {
        this.phoneError = '';
      }

      const isDocumentIdInformed = this.$validations.required(this.documentIdFormatted);
      if (isDocumentIdInformed !== true) {
        this.documentIdError = isDocumentIdInformed;
        isFormValid = false;
      }
      else {
        const isDocumentIdValid = this.$validations.validateCNPJ(this.documentIdFormatted);
        if (isDocumentIdValid !== true) {
          this.documentIdError = isDocumentIdValid;
          isFormValid = false;
        }
        else {
          this.documentIdError = '';
        }
      }

      const isPostalCodeValid = this.$validations.cep(this.postalCode);
      if (isPostalCodeValid !== true) {
        this.postalCodeError = isPostalCodeValid;
        isFormValid = false;
      }
      else {
        this.postalCodeError = '';
      }

      const isAddressNumberValid = this.$validations.required(this.addressNumber);
      if (isAddressNumberValid !== true) {
        this.addressNumberError = isAddressNumberValid;
        isFormValid = false;
      }
      else {
        this.addressNumberError = '';
      }

      const isStreetNameValid = this.$validations.required(this.streetName);
      if (isStreetNameValid !== true) {
        this.streetNameError = isStreetNameValid;
        isFormValid = false;
      }
      else {
        this.streetNameError = '';
      }

      const isNeighborhoodValid = this.$validations.required(this.neighborhood);
      if (isNeighborhoodValid !== true) {
        this.neighborhoodError = isNeighborhoodValid;
        isFormValid = false;
      }
      else {
        this.neighborhoodError = '';
      }

      const isCityValid = this.$validations.required(this.city);
      if (isCityValid !== true) {
        this.cityError = isCityValid;
        isFormValid = false;
      }
      else {
        this.cityError = '';
      }

      const isStateValid = this.$validations.required(this.state);
      if (isStateValid !== true) {
        this.stateError = isStateValid;
        isFormValid = false;
      }
      else {
        this.stateError = '';
      }

      if (isFormValid) {
        this.isRegistrationInfoNew();
      }
    },
    isRegistrationInfoNew() {
      this.documentIdError = '';
      this.emailError = '';

      agent.CustomerRegistration.isRegistrationInfoNew(this.documentId, this.email)
        .then((response) => {
          if (response.isDocumentIdNew === false) {
            this.documentIdError = this.$t('documentIdAlreadyExists');
          }
          if (response.isEmailNew === false) {
            this.emailError = this.$t('emailAlreadyExists');
          }
          if (response.isDocumentIdNew && response.isEmailNew) {
            this.finalizeRegistration();
          }
        })
        .catch(() => {
          this.updateErrorMessage = this.$t('isRegistrationInfoNewError');
        });
    },
    searchPostalCode() {
      this.hasTriedPostalCodeSearch = true;
      agent.CustomerRegistration.searchPostalCode(this.postalCode)
        .then((response) => {
          if (response.streetName !== "") {
            this.streetName = response.streetName;
          }
          if (response.neighborhood !== "") {
            this.neighborhood = response.neighborhood;
          }
          if (response.city !== "") {
            this.city = response.city;
          }
          if (response.state !== "") {
            this.state = response.state;
          }
          this.postalCodeError = '';
        })
        .catch(() => {
          this.postalCodeError = this.$t('postalCodeError');
        });
    },
    finalizeRegistration() {
      this.loading = true;

      this.updateSuccessMessage = '';
      this.updateErrorMessage = '';
  
      const CustomerRegistrationRequestDto = {
        name: this.name,
        dateOfBirth: this.formatDateStringToDate(this.dateOfBirth),
        email: this.email,
        phoneNumber: `${this.selectedCountryCode.replace('+', '')}${this.phone}`,
        documentId: this.documentId,
        postalCode: this.postalCode,
        state: this.state,
        city: this.city,
        neighborhood: this.neighborhood,
        streetName: this.streetName,
        addressNumber: this.addressNumber,
        addressComplement: this.addressComplement,
        token: this.$route.query.token,
      };

      agent.CustomerRegistration.newRegistration(CustomerRegistrationRequestDto)
        .then(() => {
          this.updateSuccessMessage = this.$t('successfulRegistration');
          this.$router.push({name: this.$t('routes.nameCustomerRegistrationFeedback').toString(),});
        })
        .catch((error) => {
          this.updateErrorMessage = error.message;
        })
        .finally(() => {
          this.loading = false
        });
    },
    formatDateTimeToDate,
    formatDateStringToDate,
  },
};
</script>

<style scoped>
.custom-margin-left {
  margin-left: 50px;
}
.negative-ml {
  margin-left: -24px;
}
.custom-mt {
  margin-top: 80px !important;
}
.non-editable {
  pointer-events: none;
}
.edit-btn {
  pointer-events: auto;
}
.fields-spacing {
  margin-right: 30px;
}
.flag-spacing {
  margin-right: -5px;
}
.finalize-registration-btn {
  background-color: #2196F3;
  color: white;
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>
  