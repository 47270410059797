export default {
    nonEmptyRules: 'Insira algum nome válido',
    requiredRules: 'Este campo é obrigatório.',
    requiredDesiredRules: 'Este campo é obrigatório.',
    integerRules: 'Insira um número inteiro',
    floatRules: 'Insira um valor válido',
    uniqueSelectionRules: 'Essa seleção já existe',
    requiredIfRules: 'Este campo é obrigatório.',
    isUrlRules: 'Insira uma URL válida',
    isPositiveRules: 'Insira um valor válido',
    validCharactersRules: 'Insira caracteres válidos',
    barCodeRules: 'Insira um código de barras válido',
    currencyRules: 'Insira um valor válido',
    dateRules: 'Insira uma data válida',
    countryCodeRules: 'Insira um celular válido.',
    integerRulesPositive: 'Insira um número positivo',
    onlyNumbers: 'Insira apenas números',
};
