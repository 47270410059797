import { render, staticRenderFns } from "./PaymentProcessorPagSeguroAdditionalInfoDialog.vue?vue&type=template&id=45b9e040&scoped=true"
import script from "./PaymentProcessorPagSeguroAdditionalInfoDialog.vue?vue&type=script&lang=ts&setup=true"
export * from "./PaymentProcessorPagSeguroAdditionalInfoDialog.vue?vue&type=script&lang=ts&setup=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45b9e040",
  null
  
)

export default component.exports