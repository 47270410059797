<template>
      <v-autocomplete
        ref="autocomplete"
        v-model="product"
        :items="products"
        :loading="isLoading"
        :search-input.sync="search"
        cache-items
        item-text="searchText"
        item-value="id" 
        :label="labelProductFilter"
        :placeholder="placeholderEnterTheSearchFilter"
        :prepend-icon="icon"
        :required="required"
        :no-data-text="noDataEnterInTheSearchFilter"
        :rules="rules"
        :disabled="disabled"
        :class="displayClass"
        clearable
        @change="changeProduct"
      >
      </v-autocomplete>
</template>

<script>
import agent from "@/api/agent";
  export default {
    name: "ProductsFilter",
    props: {
      value: { type: Number, default: null },
      label: { type: String, default: null },
      placeholder: { type: String, default: null },
      icon: { type: String, default:'' },
      required: { type: Boolean, default: false },
      noData: { type: String, default: null },
      rules: { type: Array },
      disabled: { type: Boolean, default: false },
      displayClass: { type: String, default: '' },
      posId: { type: Number, default: null },
    },
    computed: {
      product: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit('input', value);
        }
      },
      labelProductFilter() {
        return this.label || this.$t('productFilter');
      },
      placeholderEnterTheSearchFilter() {
        return this.placeholder || this.$t('enterTheSearchFilter');
      },
      noDataEnterInTheSearchFilter() {
        return this.noData || this.$t('enterInTheSearchFilter');
      },
    },
    data() {
      return {
        products: [],
        productObject: {},
        isLoading: false,
        search: null,
      }
    },
    // If product is mounted with a value (generally this happens when it comes from
    // the parent as a prop), it will not appear in the dropdown. The component must
    // then recover it from the back end to insert it in the array.
    mounted() {
      if (this.product != null) {
        this.getProduct(this.product);
      }
    },
    rules: {
      nonEmpty: (v) => /\S/.test(v) || this.$t('nonEmptyRules'),
      required: (v) => !!v || this.$t('requiredRules'),
    },
    watch: {
      search(val) {
        if (/^\s+/.test(val)) { // Se o usuário usar " " para apagar
          this.product = null;
          return;
        } 
        //Inicia busca somente após 3 caracteres digitados
        if (val?.length >= 3 && val != this.initialSearch) {
          this.getProductsFromBase(val);
        }  
      },
      // If product is changed to one that is not already in the products array
      // (generally this happens when it comes from the parent as a prop),
      // it will not appear in the dropdown. The component must then recover it from the
      // back end to insert it in the array. This watcher serves this purpose.
      product(value) {
        if (value != null && this.products.find(product => product.id == value) == undefined) {
          this.getProduct(value);
        }
      }
    },
    methods: {
      getProductsFromBase(val){
        this.isLoading = true;

        const params = {
          Page: 1,
          PageSize: 30,
          Search: val
      };
      agent.Products.simpleInfo(params)
            .then((response)=>{
              this.isLoading = false;
              this.products =  response.items.map((prod) => {
                prod.searchText =
                (prod.code !== null ? prod.code + " - " : "") + prod.description + (prod.barCode !== null ? " - " + prod.barCode : "");
                return prod;
              });
            })
            .catch(err => {
              console.log(err)
            })
            .finally(() => (this.isLoading = false));
      },
      getProduct(productId) {
        this.isLoading = true;
        agent.Products.getProductBaseSimpleInfoById(productId)
          .then((response) => {
            let prod = response.data;
            prod.searchText =
              (prod.code !== null ? prod.code + " - " : "") + prod.description + (prod.barCode !== null ? " - " + prod.barCode : "");
            this.products.push(prod);
          })
          .catch(() => {})
          .then(() => this.isLoading = false);
      },
      changeProduct() {
        this.$nextTick(() => {
          if (this.value != null) {
            const productObject = this.$refs.autocomplete.$data.cachedItems.find(ob => ob.id == this.value);
            this.$emit('product-changed', productObject);
            this.productObject = productObject;
          }
        })
      }
    },
  }
</script>