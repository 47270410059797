export default {
  catalogItemFavoriteDialogMessage: "Are you sure you want to <strong class='favorite-text'>favorite</strong> this product for purchase?",
  catalogItemFavoriteDialogWarning: "By doing this, it <strong>will be displayed</strong> as a recommended product to buy on the <strong>New Product</strong> page.",
  catalogItemNotFavoriteDialogMessage: "Are you sure you want to <strong class='not-favorite-text'>unfavorite</strong> this product for purchase?",
  catalogItemNotFavoriteDialogWarning: "By doing this, it <strong>will no longer be displayed</strong> as a recommended product to buy on the <strong>New Product</strong> page.",
  catalogProductsListToChoose: "Franchise recommended products",
  catalogTab: 'Product Catalog',
  clickForDetails: 'View product details',
  environmentCatalogHeader: 'Favorite products for new orders',
  environmentCatalogSubHeader: 'Select the products you want to prioritize for purchase in your other environments',
  favoriteCatalogItems: 'Favorite',
  finishCatalogItemsSelection: "Buy",
  productsList: 'Available products',
  seeLess: "See less",
  seeMore: "See more",
  technicalInformation: 'Technical Information',
}
