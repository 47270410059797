export default {
  purchase : {
    title: 'Compras',
    entry: {
      product: {
        index: 'Produto {index} de {total}',
        value: 'Valor Unitário do produto',
        requests: {
          error: {
            selectGrouping: 'Não é possível selecionar um agrupamento',
            conversionUnit: 'Erro ao buscar unidades de conversão'
          }
        },
        quantity: {
          total: 'Quantidade Total do produto',
          item: 'Quantidade do item',
        },
        help: {
          title: 'Informações dos produtos',
          description: `Nesta página, os dados extraídos de envios via XML estão disponíveis para associação a produtos específicos,
          permitindo a seleção da unidade de conversão para atualizar o estoque.`,
          product : {
            title: 'Produtos',
            description: `Caso o código de barras do produto da nota, for igual ao código de um produto no sistema o mesmo já virá pré-selecionado`
          },
          conversionUnit: {
            title: 'Unidade de conversão / Unidades',
            description: `Se a unidade estiver classificada como desconhecida, isso indica que nosso sistema não conseguiu interpretar a unidade mencionada no XML.
            Para corrigir, basta selecionar o preço por unidade (como unidades, caixas ou fardos) e indicar quantas unidades compõem essa medida de conversão
            (por exemplo, unidade = 1, caixa = 10, fardo = 6).
            Observação: a quantidade informada não aceita número decimal.`
          },
          quantity: {
            title: 'Quantidade Total do produto',
            description: `Após inserir os dados de quantidade e custo, nosso sistema realizará automaticamente o cálculo do total de unidades o preço unitário correspondente.
            Por exemplo, ao inserir duas caixas, cada uma contendo 10 unidades, ao custo de 10 reais por caixa: Total de unidades: 20 | Preço unitário: R$ 1,00.
            Essa funcionalidade simplifica a visualização do total de produtos e seu custo por unidade, tornando mais fácil a análise das informações.`
          },
        },
        xml: {
          stepTitle: 'Adicionar Produtos',
          title: 'INFORMAÇÕES DOS PRODUTOS',
          invalid: 'Para prosseguir preencha: valor unitário, produto, unidade de conversão, quantidade.',
        },
        manual: {

        },
      },
    },
    reversal: {
      title: 'Desfazer compra',
      message: '<p>Ao <b class="font-weight-bold red--text">desfazer</b> a entrada da nota <b>{purchaseId}</b>:</p>',
      confirm: 'Desfazer compra',
      cancel: 'Não alterar nada agora',
      success: 'Compra desfeita com sucesso!',
      error: 'Erro ao desfazer compra',
      warning: {
        quantity: "A quantidade em estoque será alterada descontando o valor dos itens que estão sendo cancelados.",
        averageCostChanges: "O custo médio dos itens será alterado para o custo anterior a entrada da nota.",
        averageCostDoesNotChange: 'O custo médio dos itens não será alterado.'
      }
    }
  }
};
