
import Vue from 'vue';
import agent from "@/api/agent";
// eslint-disable-next-line no-unused-vars
import {PurchaseSuggestion, PurchaseSuggestionsFilter} from '@/types/PurchaseSuggestions';
import PurchaseSuggestionsFilters from "@/views/purchaseSuggestions/PurchaseSuggestionsFilters.vue";
import PurchaseSuggestionsHelp from "@/views/purchaseSuggestions/helpers/PurchaseSuggestionsHelp.vue";
import PickListCreationDialog from "@/views/picklist/PickListCreationDialog.vue";
import ConversionUnitDialog from './ConversionUnitDialog.vue';

export default Vue.extend({
  components: {
    PickListCreationDialog,
    PurchaseSuggestionsHelp,
    PurchaseSuggestionsFilters,
    ConversionUnitDialog
  },
  data() {
    return {
      headers: [
        {text: this.$t('tables.headers.productCode'), value: 'product.code', align: 'left', sortable: true},
        {text: this.$t('tables.headers.barCode'), value: 'product.barCode', align: 'left', sortable: true},
        {text: this.$t('tables.headers.product'), value: 'product.description', align: 'left', sortable: true},
        {text: this.$t('tables.headers.category'), value: 'product.category', align: 'left', sortable: true},
        {text: this.$t('tables.headers.conversionUnitRatio'), value: 'product.conversionUnitRatio', align: 'left', sortable: true},
        {text: this.$t('tables.headers.withdraw'), value: 'withdraw', align: 'left', sortable: true},
        {text: this.$t('tables.headers.ruptures'), value: 'ruptures', align: 'left', sortable: true},
        {text: this.$t('tables.headers.inventory'), value: 'inventoryItem.quantity', align: 'left', sortable: true},
        {text: this.$t('tables.headers.daysInInventory'), value: 'inventoryDaysLeft', align: 'left', sortable: true},
        {text: this.$t('tables.headers.unitsToBuySuggestion'), value: 'unitsToBuySuggestion', align: 'left', sortable: true},
        {text: this.$t('tables.headers.convertedToBuySuggestion'), value: 'convertedToBuySuggestion', align: 'left', sortable: true},
        {text: this.$t('tables.headers.minimumQuantityAbbreviated'), value: 'inventoryItem.minimumPurchaseQuantity', align: 'left', sortable: true},
        {text: this.$t('tables.headers.convertedMinimumPurchaseQuantityAbbreviated'), value: 'convertedMinimumPurchaseQuantity', align: 'left', sortable: true},
        {text: this.$t('tables.headers.minimumCritical'), value: 'inventoryItem.minimumQuantity', align: 'left', sortable: true},
      ] as object[],
      filteredHeaders: [] as object[],
      filters: {} as PurchaseSuggestionsFilter,
      loading: false as boolean,
      purchaseSuggestions: [] as PurchaseSuggestion[],
      search: null as string | null,
      pickListCreationDialog: {
        selectedPos: [] as number[] | null
      },
      generatePicklistButton: {
        isAbleToGeneratePickList: false,
        loading: false,
      },
      acquiredPointOfSales: [] as object[],
      conversionUnitDialog: false,
      conversionUnitDialogItem: {} as PurchaseSuggestion,
      translations: {
        conversionUnit: {
          unit: this.$t("products.data.purchaseSettings.conversionUnits.unit"),
          box: this.$t("products.data.purchaseSettings.conversionUnits.box"),
          package: this.$t("products.data.purchaseSettings.conversionUnits.package"),
          bag: this.$t("products.data.purchaseSettings.conversionUnits.bag"),
          unknown: this.$t("products.data.purchaseSettings.conversionUnits.unknown"),
        }
      }
    };
  },
  methods: {
    performPickListRequirements: function (filters: PurchaseSuggestionsFilter, acquiredPointOfSales: object[]) {
      if (filters.inventoryType === 'pointOfSale' && filters.inventoryIds.length === 1 && this.purchaseSuggestions.length > 0) {
        this.generatePicklistButton.isAbleToGeneratePickList = true;
        this.pickListCreationDialog.selectedPos = this.acquirePointOfSaleId(acquiredPointOfSales);
      } else {
        this.generatePicklistButton.isAbleToGeneratePickList = false;
      }
    },
    acquirePointOfSaleId: function (acquiredPointOfSales: object[]): number[] {
      let posId: number[] = [];
      acquiredPointOfSales.forEach((pos: any) => {
        if (pos.inventoryId === this.filters.inventoryIds[0]) {
          posId.push(pos.id);
        }
      });
      return posId;
    },
    getPurchaseSuggestions: async function (filters: PurchaseSuggestionsFilter, acquiredPointOfSales: object[]) {
      // Change table headers text
      let headers = this.headers;
      this.acquiredPointOfSales = acquiredPointOfSales;

      let withdraw = headers.find(he => (he as any).value == 'withdraw');
      Object.assign(withdraw as any, {text: this.$t('tables.headers.withdraw', {days: filters.pastDays})});

      let ruptures = headers.find(he => (he as any).value == 'ruptures');
      Object.assign(ruptures as any, {text:  this.$t('tables.headers.ruptures', {days: filters.pastDays})});

      this.filteredHeaders = filters.ignoreRupture
        ? headers = headers.filter((header: any) => header.value !== 'ruptures')
        : headers;

      this.loading = true;
      this.filters = filters;
      let params = this.filters;

      try {
        this.purchaseSuggestions = await agent.PurchaseSuggestions.list(params);
      } catch (error) {
        this.handleError(this.$t('purchaseSuggestions.report') as string, error);
      } finally {
        this.loading = false;
        this.performPickListRequirements(this.filters, this.acquiredPointOfSales);
      }
    },
    onPickListCreation() {
      (this.$refs.feedback as any).handleSuccess(this.$t('pickLists.create.success') as string)
    },
    onResetFilters() {
      this.generatePicklistButton.isAbleToGeneratePickList = false;
    },
    onSinglePicklistCreationError(error: any) {
      this.handleError(this.$t('pickLists.create.error') as string, error);
    },
    handleError(message: string, error: any) {
      (this.$refs.feedback as any).handleError(message, error);
    },
    belowOrEqualMinimumQuantity(item: PurchaseSuggestion) {
      return item.inventoryItem.minimumQuantity >= item.inventoryItem.quantity;
    },
    openConversionUnitDialog(item: PurchaseSuggestion) {
      this.conversionUnitDialog = true;
      this.conversionUnitDialogItem = item;
    },
    onConversionUnitUpdated() {
      this.getPurchaseSuggestions(this.filters, this.acquiredPointOfSales);
    },
    getConversionUnitText(item: PurchaseSuggestion) {
      return item.product.conversionUnit != "Unit"
        ? this.$t("products.data.purchaseSettings.conversionUnits.ratioAndName", {
          ratio: item.product.conversionUnitRatio,
          name: this.unitTypeText(item.product.conversionUnit) })
        : this.translations.conversionUnit.unit;
    },
    unitTypeText(unit: string) {
      switch (unit) {
        case "Unit":
          return this.translations.conversionUnit.unit;
        case "Box":
          return this.translations.conversionUnit.box;
        case "Package":
          return this.translations.conversionUnit.package;
        case "Bag":
          return this.translations.conversionUnit.bag;
        default:
          return this.translations.conversionUnit.unknown;
      }
    },
    getColor(item: PurchaseSuggestion) {
      if (this.belowOrEqualMinimumQuantity(item)) {
        return 'red lighten-5';
      }
      return '';
    }
  },
});
