<template>
  <div class="mb-14">
    <portal to="toolbarTitle">
      <acquisitions-orders-approval-helper/>
    </portal>
      <v-card >

        <v-card-title>
          <v-spacer></v-spacer>
          <v-col cols="12" md="3">
            <v-text-field
              v-model="approvalAcquisitionsOrderStore.paginatedQuery.search"
              :label="$t('search')"
              append-icon="search"
              class="mr-4 pt-0"
              clearable
              dense
              filled
              hide-details
              rounded
              single-line
              @click:append="loadAcquisitionsOrders"
            ></v-text-field>
          </v-col>
        </v-card-title>

        <v-divider/>

        <v-data-table
          :headers="filteredHeaders()"
          :items="approvalAcquisitionsOrderStore.paginatedListResponse.items"
          :search="approvalAcquisitionsOrderStore.paginatedQuery.search"
          :loading="approvalAcquisitionsOrderStore.loadingView"
          :footer-props="{ itemsPerPageOptions: [10, 20, 30] }"
          :server-items-length="approvalAcquisitionsOrderStore.paginatedListResponse.totalItems"
          :options.sync="options"
          item-key="id"
          show-expand
          :single-expand="true"
          :expanded.sync="expanded"
        >
          <template v-slot:item.dateCreated="{ item }">
            {{ new Date(item.dateCreated).toLocaleDateString('pt-BR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
          }) }}
          </template>

          <template v-slot:item.documentId="{ item }">
            {{ formatDocument(item.documentId) }}
          </template>

          <template v-slot:item.phoneNumber="{ item }">
            {{ formatPhoneNumber(item.phoneNumber) }}
          </template>

          <template v-slot:item.taxArrangement="{ item }">
            {{ $t(item.taxArrangement) }}
          </template>

          <template v-slot:item.comments="{ item }">
            <v-btn
              icon
              color="blue"
              @click="approvalAcquisitionsOrderStore.openDialogueComments(item.id, null, true);"
              v-if="item.comments !== null && item.comments !== ''"
            >
              <v-icon>mdi-comment-text-outline </v-icon>
            </v-btn>
            <v-btn
              icon color="success"
              @click="approvalAcquisitionsOrderStore.commentsDisabled = false; approvalAcquisitionsOrderStore.openDialogueComments(item.id, null, false);"
              v-if="item.comments === null || item.comments === ''"
            >
              <v-icon>mdi-comment-plus-outline</v-icon>
            </v-btn>
          </template>

          <template v-slot:item.status="{ item }">
            <v-row v-if="item.status === 'Pending'">
              <v-btn icon color="error" @click="approvalAcquisitionsOrderStore.openDialogueComments(item.id, OrderStatus.Cancelled, false)" class="ml-2">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-btn icon color="success" @click="approvalAcquisitionsOrderStore.openDialogueComments(item.id, OrderStatus.Approved, false)" class="ml-2">
                <v-icon>mdi-check</v-icon>
              </v-btn>
            </v-row>
            <v-chip
              v-if="item.status !== 'Pending'"
              :color="getStatusColor(item.status)"
              text-color="white"
            >
              {{ $t(item.status) }}
            </v-chip>
          </template>

          <template v-slot:expanded-item="{ item }">
            <td :colspan="headers.length +1" class="ma-0 pa-0" >
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th>{{$t("imagem")}}</th>
                    <th>{{$t("name")}}</th>
                    <th>{{$t("quantity")}}</th>
                    <th>{{$t("description")}}</th>
                    <th>{{$t("technicalInformation")}}</th>
                    <th>{{$t("itemType")}}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(detail, index) in item.itensDetails" :key="index">
                    <td>
                      <v-img
                        :src="detail.imageUrl"
                        max-width="170"
                      ></v-img>
                    </td>
                    <td class="limited-column">{{ detail.name }}</td>
                    <td>{{ detail.quantity }}</td>
                    <td class="limited-column">{{ detail.description }}</td>
                    <td>
                      <ul>
                        <li v-for="(info, idx) in detail.technicalInformation.split('\n')" :key="idx">
                          {{ info }}
                        </li>
                      </ul>
                    </td>
                    <td>
                      {{ $t(detail.itemType.toString()) }}
                    </td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </td>
          </template>
        </v-data-table>
      <acquisitions-orders-approval-comments-dialog/>
      </v-card>

  </div>
</template>

<style scoped>
.limited-column {
  max-width: 200px; /* Define a largura máxima */
  word-wrap: break-word; /* Quebra as palavras longas */
  white-space: normal; /* Permite a quebra de linha */
}
</style>

<script>


import {useApprovalAcquisitionsOrderStore} from "@/stores/approvalAcquisitionsOrderStore";
import {shouldRefreshData} from "@/components/TableOptionsComparator";
import i18n from "@/locales/i18n";
import AcquisitionsOrdersApprovalCommentsDialog
  from "@/views/acquisitionOrdersApproval/dialogs/AcquisitionsOrdersApprovalCommentsDialog.vue";
import AcquisitionsOrdersApprovalHelper
  from "@/views/acquisitionOrdersApproval/helpers/AcquisitionsOrdersApprovalHelper.vue";
import { OrderStatus } from "@/types/ApprovalAcquisitionsOrderTypes";
import { formatDocument, formatPhoneNumber } from "@/utility/TextFormatting";

export default {
  computed: {
  },
  components: {
    AcquisitionsOrdersApprovalHelper,
    AcquisitionsOrdersApprovalCommentsDialog
  },
  data() {
    return {
      approvalAcquisitionsOrderStore: useApprovalAcquisitionsOrderStore(),
      OrderStatus,
      formatDocument,
      formatPhoneNumber,
      headers: [
        { text: i18n.t('idAcquisitionOrder'), align: 'left',sortable: false,  value: 'id' },
        { text: i18n.t('fantasyNameAcquisitionOrder'), align: 'left', sortable: false, value: 'fantasyName' },
        { text: i18n.t('documentIdAcquisitionOrder'), align: 'left',sortable: false,  value: 'documentId' },
        { text: i18n.t('emailAcquisitionOrder'), align: 'left', sortable: false, value: 'email' },
        { text: i18n.t('phoneNumberAcquisitionOrder'), align: 'left',sortable: false,  value: 'phoneNumber' },
        { text: i18n.t('dateCreatedAcquisitionOrder'), align: 'left', sortable: false, value: 'dateCreated' },
        { text: i18n.t('taxArrangementAcquisitionOrder'), align: 'left', sortable: false, value: 'taxArrangement' },
        { text: i18n.t('statusAcquisitionOrder'), align: 'left', sortable: true, value: 'status', width: '100px' },
        { text: i18n.t('commentsAcquisitionOrder'), align: 'left', sortable: false, value: 'comments', width: '50px' },
      ],
      expanded: [],
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [true],
        mustSort: true,
      },
    };
  },
  watch: {
    options: {
      handler(newOptions, oldOptions) {
        if (shouldRefreshData(newOptions, oldOptions)) {
          this.loadAcquisitionsOrders();
        }
      },
      deep: true
    },
  },
  methods: {
    loadAcquisitionsOrders() {
      const tenantId = this.$auth.user().tenantId;
      this.approvalAcquisitionsOrderStore.paginatedQuery.pageSize = this.options.itemsPerPage;
      this.approvalAcquisitionsOrderStore.paginatedQuery.page = this.options.page;
      this.approvalAcquisitionsOrderStore.paginatedQuery.descending = this.options.sortDesc[0];
      this.approvalAcquisitionsOrderStore.paginatedQuery.sortOrder = this.options.sortBy[0];
      this.approvalAcquisitionsOrderStore.getAllAcquisitionsOrders(tenantId);
    },
    filteredHeaders(){
      const allItemsNotNeeded = this.approvalAcquisitionsOrderStore.paginatedListResponse.items.every(item => item.status === 'NotNeeded');
      if (allItemsNotNeeded) {
        return this.headers.filter(header => header.value !== 'status');
      }
      return this.headers;
    },
    getStatusColor(status) {
      switch (status) {
        case 'Approved':
          return 'success';
        case 'Cancelled':
          return 'error';
        case 'NotNeeded':
          return 'success';
        default:
          return 'warning';
      }
    }
  },
  mounted() {
    this.loadAcquisitionsOrders();
  }

}
</script>
