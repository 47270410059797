export default {
  connectivity: {
    title: 'Connectivity',
    noPosDescription: 'Terminal not associated with any POS',
    help: {
      description: `<p>In the <strong>Connectivity</strong> tab, you can monitor the connection status of each POS (Point of Sale). 
      The page displays the POS name and details how long it remained connected via Wi-Fi, data chip, or when it was offline. 
      This allows you to assess the stability of the terminals' connection to the internet, which is essential for sales operations.</p>
      <p>In the <strong>Timeline</strong> tab, you'll find a graphical visualization that illustrates the POS connectivity over time. 
      With this visualization, you can track connectivity and ensure that the POS remains operational at all times.</p>`
    },
    timeline: {
      title: 'Timeline',
      chart: {
        title: 'Connectivity timeline',
        xAxis: 'Date and time',
        yAxis: 'Point of Sale measurements',
      },
      help: {
        description: `<p align="justify">This chart shows the connectivity of points of sale during a specific period.</p>
        <p align="justify">Connectivity is recorded from the telemetry of a point of sale.
        Each point on the chart represents a telemetry event. Telemetries should occur every five minutes.
        If two successive points have more than five minutes between them, it indicates a connectivity issue during that period.</p>
        <p align="justify">In "<strong>Date</strong>" you select the day you want to view the telemetries of a point of sale.</p>
        <p align="justify">In "<strong>Point of Sale</strong>" you choose which of your points of sale you want to analyze the telemetries from.</p>`
      }
    },
    warning: 'Terminals that no longer have any links to POS will be displayed with the message "Terminal not associated with any POS".',
    errors: {
      loadConnectivity: 'Failed to load the connectivity of the Points of Sale',
      loadTimeline: 'Failed to load the Points of Sale timeline',
      excel: 'Failed to download the file, please try again',
    },
  },
}