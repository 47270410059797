
import {Vue} from 'vue-property-decorator';
import ConfirmDialogButton from "@/components/dialog/button/ConfirmDialogButton.vue";
import CancelDialogButton from "@/components/dialog/button/CancelDialogButton.vue";
import agent from "@/api/agent";

export default Vue.extend({
  components: {CancelDialogButton, ConfirmDialogButton},
  props: {
    value: { type: Boolean, required: true, default: false },
    stockQuantityItem: { type: Object, required: true, default: () => {} },
  },
  computed: {
    dialog: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean): void {
        this.$emit('input', value);
      }
    }
  },
  watch: {
    stockQuantityItem: {
      handler(): void {
        this.stockQuantity = this.stockQuantityItem.currentQuantity;
      },
      deep: true
    },
    dialog: {
      handler(): void {
        (this.$refs.form as any)?.resetValidation();
      }
    }
  },
  data() {
    return {
      valid: false,
      loading: false,
      stockQuantity: null as number | null
    }
  },
  methods: {
    async saveChanges() {
      if (!(this.$refs.form as any).validate()) {
        return;
      }
      this.loading = true;
      const inventoryItemId = this.stockQuantityItem.inventoryItemId;
      const quantity = Number(this.stockQuantity);
      await agent.Inventory
        .correctInventoryItemQuantity(inventoryItemId, quantity)
        .then((updatedQuantity) => {
          this.$emit("update", updatedQuantity);
          this.dismissDialog();
        }).catch((error) => {
          (this.$refs.feedback as any).handleError(this.$t('errorSavingInventoryChange'), error);
        }).finally(() => {
          this.loading = false;
        });
    },
    dismissDialog() {
      this.stockQuantity = null;
      this.dialog = false;
    },
  }
});
