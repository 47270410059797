export default {
  users: {
    title: "Usuarios",
    group: "Grupo",
    roles: {
      admin: "Administrador",
      adminApp: "Administrador App",
      adminPrivateLabel: "Administrador Private Label",
      registrations: "Registros",
      accounting: "Contabilidad",
      priceEditing: "Edición de Precios",
      orderManager: "Gestor de Pedidos",
      manager: "Gerente",
      blindInventoryAndSupply: "Inventario ciego y suministro",
      onlyPicking: "Solo Separación",
      onlySupply: "Solo Abastecimiento",
      logistics: "Logística",
      remoteMonitoring: "Monitoreo Remoto",
      invoices: "Facturas",
      pushNotification: "Notificación Push",
      security: "Seguridad",
      buildingManager: "Administrador de Edificio",
      support: "Soporte",
      telemetry: "Telemetría",
      touchPaySupplier: "TouchPay Abastecedor",
      sales: "Ventas",
      planogramView: "Visualización de Planogramas",
      privateLabelView: "Visualización de Private Label",
      transactionView: "Visualización de Transacciones",
    },
    help: {
      page: {
        title: "Usuarios",
        description: `
        <p>
          En esta página, puedes ver, crear y gestionar usuarios dentro del sistema. Se muestran las siguientes informaciones sobre los usuarios registrados: correo electrónico, grupo, si es aplicable, y permisos. Para cada usuario, hay opciones de edición o eliminación, lo que permite ajustes en las configuraciones o la eliminación del sistema.
        </p>
        <p>
          Puedes crear nuevos usuarios proporcionando solo el correo electrónico y la contraseña. Para ello, solo necesitas utilizar la funcionalidad de <strong>Nuevo Usuario</strong>, que permite registrar y definir los accesos.
        </p>
        <p>
          Los grupos en TouchPay centralizan la información de los licenciados o franquiciados en un único entorno de visualización. Cada grupo tiene acceso exclusivo a sus propias operaciones e información, sin visualizar los datos de otros grupos. Esta funcionalidad permite que redes de franquicias sigan sus operaciones de forma centralizada, manteniendo la privacidad entre los grupos.
        </p>`,
      },
    },
  }
};
