export default {
  purchaseSuggestions: {
    title: "Purchase Suggestions",
    report: 'Error generating purchase suggestion report',
    warnings: {
      pickListButton: 'Generate a report for only one POS',
      withdrawAndRuptures: 'Withdraw and breakages now show the total value according to the "Considering the last X days" filter.',
    },
    help: {
      title: "Purchase Suggestions",
      description: `<p>This page presents purchase suggestions following this logic:
        </p>
        <p>
            <strong>(Average Sales X days) * X days + (Average Breakages X days) * X days - Stock = Purchase Suggestion (Unit) / 
            Conversion Unit = Purchase Suggestion (C.U.).</strong>
        </p>
        <p>
            To <strong>define the X values</strong> (Analysis Period) and the desired purchase period, simply fill out the filters: 
            "Considering the last X days, I want stock for (Desired period) in days."
        </p>
        <p>
            By default, products with a Purchase Suggestion value lower than the Minimum Purchase Quantity will be hidden. To view them, just enable the <strong>"Show all products"</strong> option.
        </p>
        <p><strong>Days in Inventory</strong> is an estimate in <strong>days</strong> of how long a specific inventory item will last if not replenished. It is calculated using the following logic:</p>
        <p><strong>(Average Withdraw + Average Breakages) / Current Inventory Quantity</strong></p>
        <p>
            *The values for <strong>Minimum Purchase Quantity</strong> are defined in the <strong>Inventory</strong> stock tab.<br>
            *The values for the <strong>Conversion Unit</strong> are defined in each <strong>product's</strong> registration.
        </p>`,
    }
  },
}