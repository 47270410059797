export default {
  purchaseSuggestions: {
    title: "Sugerencias de Compras",
    report: 'Error al generar un informe de sugerencias de compra',
    warnings: {
      pickListButton: 'Genere un informe solo para un PDV',
      withdrawAndRuptures: 'Las salidas y roturas ahora muestran el valor total según el filtro "Considerando los últimos X días".',
    },
    help: {
      title: "Sugerencias de Compras",
      description: `<p>Esta página presenta sugerencias de compra siguiendo esta lógica:
        </p>
        <p>
            <strong>(Promedio de Salidas X días) * X días + (Promedio de Rupturas X días) * X días - Inventario = Sugerencia de Compra (Unidad) / 
            Unidad de Conversión = Sugerencia de Compra (U.C.).</strong>
        </p>
        <p>
            Para <strong>definir los valores de X</strong> (Período de análisis) y el período deseado para la compra, simplemente complete los filtros: 
            "Considerando los últimos X días, quiero un Inventario para (Período deseado) en días."
        </p>
        <p>
            De forma predeterminada, los productos con un valor de Sugerencia de Compra menor que la Cantidad Mínima de Compra estarán ocultos. Para visualizarlos, simplemente active la opción <strong>"Mostrar todos los productos"</strong>.
        </p>
        <p><strong>Días en Inventario</strong> es una estimación en <strong>días</strong> de cuánto tiempo puede durar un artículo específico de inventario si no se repone. Se calcula utilizando la siguiente lógica:</p>
        <p><strong>(Promedio de Salídas Diarias + Promedio de Rupturas Diarias) / Cantidad Actual en el Inventario</strong></p>
        <p>
            *Los valores de <strong>Cantidad Mínima de Compra</strong> se definen en la pestaña de stock del <strong>Inventario</strong>.<br>
            *Los valores de la <strong>Unidad de Conversión</strong> se definen en el registro de cada <strong>producto</strong>.
        </p>`,
    }
  },
}