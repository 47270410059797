export default {
    createPickLists: 'Crear',
    pendingPickLists: 'Pendientes',
    separatedPickLists: 'Separadas',
    suppliedPickLists: 'Abastecidas',
    pickListCreationPickLists: 'Creación de Pick Lists',
    helpPickList: `<p>
    Aquí se muestran los puntos de venta que tienen un planograma activo o pendiente, pero que no tienen
    ninguna Pick List pendiente.
    </p>
    <p>
    <strong>Generación de Pick Lists:</strong>
    </p>
    <ul>
    <li>
        Para crear una Pick List, simplemente selecciona uno o varios puntos de venta y haz clic en
        <strong>"Crear Pick List"</strong>,
        pudiendo configurar la Pick List en 2 tipos:
        <ul>
        <li>
            <strong>Pick List Primaria:</strong>
            <ul>
            <li>
                la Pick List primaria sirve como la Pick List principal, normalmente con la mayoría de los productos
                de tu planograma. Solo se puede tener una Pick List principal a la vez;
            </li>
            <li>
                las Pick Lists primarias aparecen con el símbolo de <v-icon><i class="mdi mdi-check" style="font-size: 22px"></i></v-icon> en la pestaña "Pendientes";
            </li>
            </ul>
        </li>
        <li>
            <strong>Pick List Secundaria:</strong>
            <ul>
            <li>
                la Pick List secundaria sirve como Pick List auxiliar, pudiendo tener más de una Pick List
                secundaria por punto de venta. Puedes nombrarlas según el contenido;
            </li>
            <li>
                para marcar la Pick List como secundaria, simplemente haz clic en <strong>"Avanzado"</strong> y marca
                la opción;
            </li>
            <li>
                las Pick Lists secundarias aparecen con el símbolo de 
                <v-icon><i class="mdi mdi-close" style="font-size: 22px"></i></v-icon>
                 en la pestaña "Pendientes";
            </li>
            <li>
                al seleccionar la opción "Filtrar productos por categoría" + 
                "Generar pick list por categoría", esto creará una pick list secundaria
                separada para cada categoría seleccionada, para cada punto de venta seleccionado.
            </li>
            </ul>
        </li>
        </ul>
    </li>
    <li>
        Además de los 2 tipos de Pick List, puedes configurar el contenido de la Pick List, pudiendo seleccionar todos
        los productos de tu planograma, o filtrar por categorías específicas;
    </li>
    <li>
        También puedes incluir solo los productos que tienen stock crítico, independientemente de si son todos
        los productos o solo las categorías seleccionadas.
    </li>
    </ul>`, 
    pickListPendingPickLists: 'Pick Lists Pendientes', 
    pendingPickListsHelp: `<p>
    La Pick List es una lista con los productos que se llevarán en el próximo abastecimiento del punto de venta.
    Para ver y cambiar los productos y sus respectivas cantidades, haz clic en una Pick List.
    </p>
    <p>
    Si hay una Pick List pendiente para un punto de venta,
    al usar la función <strong>Abastecimiento</strong> del TouchPay Operador o del Terminal,
    la cantidad de elementos de la Pick List se sumará al stock del punto de venta.
    La Pick List luego entrará en la tabla de Pick Lists <strong>abastecidas</strong>,
    y el punto de venta volverá a la tabla <strong>Puntos de venta sin Pick List pendiente</strong>.
    </p>`,  
    pickListSeparatedPickLists: 'Pick Lists Separadas',
    pickListSeparatedPickListsHelp: `<p>
    Aquí se muestran las Pick Lists que se usaron a través de la función <strong>Separar</strong>
    del TouchPay Operador o del terminal. Son similares a las Pick Lists pendientes, pero ya se han separado sus productos
    en el stock, quedando solo para ser usadas para abastecer realmente el Punto de Venta.
    </p>`,
    pickListSuppliedPickLists: 'Pick Lists Abastecidas',
    pickListSuppliedPickListsHelp: `<p>
    Aquí se muestran las Pick Lists que se usaron la función <strong>Abastecimiento</strong>
    del TouchPay Operador o del Terminal. Son solo para consulta, no tienen ningún efecto en los próximos
    abastecimientos de los puntos de venta.</p>`,
    separationDatePickLists: 'Separada en ',
    supplyDatePickLists: 'Abastecida en ',
};
