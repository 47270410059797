export default {
    company: 'Company',
    condominium: 'Condominium',
    dateFieldPlaceHolder: 'dd/mm/yyyy',
    dateOfBirth: 'Date of Birth',
    editButton: 'Edit',
    infoUpdatedMessage: 'Information updated successfully',
    mainAddresses: 'Select your main operating locations',
    myInfo: 'My Info',
    onlyNumbers: 'Only numbers',
    other: 'Other',
    phone: 'Phone',
    registrationData: 'Registration Data',
    registrationDataSubtitle: 'Always keep your data up to date to ensure authenticity and facilitate communication with your account!',
    street: 'Street',
    updateInfoDialogText: 'Are you sure you want to update your information?',
    alreadyUpToDate: 'Information already updated!',
    pleaseUpdateCustomerInfo: 'Please update your profile information!',
    neededToUpdateCustomerInfo:'Information update needed',
    update: 'Update',
    close: 'Later',
    dialogAlreadyUpdated: 'Are you sure you want to confirm?'
 }
